import i18next from 'i18next'

export function formatEnum<T>(enumName: string, enumValue: T, suffix?: string): string {
  const contextValue = suffix ? `${enumValue}_${suffix}` : enumValue
  if (!i18next.exists(`Enum.${enumName}`, { context: contextValue })) {
    // when no translation is available return the raw enum value
    // IE, the country_codes have not translation, their value is literally 'NL'
    return enumValue as unknown as string
  }
  // we return the translated value if a translation is available
  return i18next.t(`Enum.${enumName}`, { context: contextValue })
}
