import { IShopWholesaler } from '@cbgms/api/modules/wholesaler'

import { GetShopWholesalersSuccess } from './actions'
import { GET_WHOLESALERS_SUCCESS } from './types'

type State = IShopWholesaler[]
type Actions = GetShopWholesalersSuccess

export const wholesalersReducer = (state: State = [], action: Actions): State => {
  switch (action.type) {
    case GET_WHOLESALERS_SUCCESS:
      return action.payload.data.Data
    default:
      return state
  }
}
