export const GET_CONSULT_REQUEST = 'GET_CONSULT_REQUEST'
export const GET_CONSULT_REQUEST_SUCCESS = 'GET_CONSULT_REQUEST_SUCCESS'
export const GET_CONSULT_REQUEST_FAILED = 'GET_CONSULT_REQUEST_FAILED'

export const PARSE_CONSULT_RESPONSE = 'PARSE_CONSULT_RESPONSE'
export const PARSE_CONSULT_RESPONSE_SUCCESS = 'PARSE_CONSULT_RESPONSE_SUCCESS'
export const PARSE_CONSULT_RESPONSE_FAILED = 'PARSE_CONSULT_RESPONSE_FAILED'

export const RESET_CONSULT_RESPONSE = 'RESET_CONSULT_RESPONSE'

export const GET_CACHED_DEFECT_LIST = 'GET_CACHED_DEFECT_LIST'
export const GET_CACHED_DEFECT_LIST_SUCCESS = 'GET_CACHED_DEFECT_LIST_SUCCESS'
export const GET_CACHED_DEFECT_LIST_FAILED = 'GET_CACHED_DEFECT_LIST_FAILED'

export const CLEAR_DEFECT_LIST = 'CLEAR_DEFECT_LIST'

export const GET_DEFECT_LIST_REQUEST = 'GET_DEFECT_LIST_REQUEST'
export const GET_DEFECT_LIST_REQUEST_SUCCESS = 'GET_DEFECT_LIST_REQUEST_SUCCESS'
export const GET_DEFECT_LIST_REQUEST_FAILED = 'GET_DEFECT_LIST_REQUEST_FAILED'

export const PARSE_DEFECT_LIST_RESPONSE = 'PARSE_DEFECT_LIST_RESPONSE'
export const PARSE_DEFECT_LIST_RESPONSE_SUCCESS = 'PARSE_DEFECT_LIST_RESPONSE_SUCCESS'
export const PARSE_DEFECT_LIST_RESPONSE_FAILED = 'PARSE_DEFECT_LIST_RESPONSE_FAILED'

export const GET_RDW_APK_RECORD = 'GET_APK_RECORD'
export const GET_RDW_APK_RECORD_SUCCESS = 'GET_RDW_APK_RECORD_SUCCESS'
export const GET_RDW_APK_RECORD_FAILED = 'GET_RDW_APK_RECORD_FAILED'

export const GET_RDW_APK_RECORD_BY_UUID = 'GET_APK_RECORD_BY_UUID'
export const GET_RDW_APK_RECORD_BY_UUID_SUCCESS = 'GET_RDW_APK_RECORD_BY_UUID_SUCCESS'
export const GET_RDW_APK_RECORD_BY_UUID_FAILED = 'GET_RDW_APK_RECORD_BY_UUID_FAILED'

export const DELETE_RDW_APK_RECORD_BY_UUID = 'DELETE_APK_RECORD_BY_UUID'
export const DELETE_RDW_APK_RECORD_BY_UUID_SUCCESS = 'DELETE_RDW_APK_RECORD_BY_UUID_SUCCESS'
export const DELETE_RDW_APK_RECORD_BY_UUID_FAILED = 'DELETE_RDW_APK_RECORD_BY_UUID_FAILED'

export const PUT_RDW_APK_RECORD = 'PUT_RDW_APK_RECORD'
export const PUT_RDW_APK_RECORD_SUCCESS = 'PUT_RDW_APK_RECORD_SUCCESS'
export const PUT_RDW_APK_RECORD_FAILED = 'PUT_RDW_APK_RECORD_FAILED'

export const POST_RDW_APK_RECORD = 'POST_RDW_APK_RECORD'
export const POST_RDW_APK_RECORD_SUCCESS = 'POST_RDW_APK_RECORD_SUCCESS'
export const POST_RDW_APK_RECORD_FAILED = 'POST_RDW_APK_RECORD_FAILED'

export const GET_FINISH_INSPECTION_REQUEST = 'GET_FINISH_INSPECTION_REQUEST'
export const GET_FINISH_INSPECTION_REQUEST_SUCCESS = 'GET_FINISH_INSPECTION_REQUEST_SUCCESS'
export const GET_FINISH_INSPECTION_REQUEST_FAILED = 'GET_FINISH_INSPECTION_REQUEST_FAILED'

export const POST_FINISH_INSPECTION_RESPONSE = 'POST_FINISH_INSPECTION_RESPONSE'
export const POST_FINISH_INSPECTION_RESPONSE_SUCCESS = 'POST_FINISH_INSPECTION_RESPONSE_SUCCESS'
export const POST_FINISH_INSPECTION_RESPONSE_FAILED = 'POST_FINISH_INSPECTION_RESPONSE_FAILED'

export const GET_INSPECTION_AUTHORITY_INFO_REQUEST = 'GET_INSPECTION_AUTHORITY_INFO_REQUEST'
export const GET_INSPECTION_AUTHORITY_INFO_REQUEST_SUCCESS = 'GET_INSPECTION_AUTHORITY_INFO_REQUEST_SUCCESS'
export const GET_INSPECTION_AUTHORITY_INFO_REQUEST_FAILED = 'GET_INSPECTION_AUTHORITY_INFO_REQUEST_FAILED'

export const PARSE_INSPECTION_AUTHORITY_INFO_RESPONSE = 'PARSE_INSPECTION_AUTHORITY_INFO_RESPONSE'
export const PARSE_INSPECTION_AUTHORITY_INFO_RESPONSE_SUCCESS = 'PARSE_INSPECTION_AUTHORITY_INFO_RESPONSE_SUCCESS'
export const PARSE_INSPECTION_AUTHORITY_INFO_RESPONSE_FAILED = 'PARSE_INSPECTION_AUTHORITY_INFO_RESPONSE_FAILED'

export const GET_INSPECTOR_INFO_REQUEST = 'GET_INSPECTOR_INFO_REQUEST'
export const GET_INSPECTOR_INFO_REQUEST_SUCCESS = 'GET_INSPECTOR_INFO_REQUEST_SUCCESS'
export const GET_INSPECTOR_INFO_REQUEST_FAILED = 'GET_INSPECTOR_INFO_REQUEST_FAILED'

export const PARSE_INSPECTOR_INFO_RESPONSE = 'PARSE_INSPECTOR_INFO_RESPONSE'
export const PARSE_INSPECTOR_INFO_RESPONSE_SUCCESS = 'PARSE_INSPECTOR_INFO_RESPONSE_SUCCESS'
export const PARSE_INSPECTOR_INFO_RESPONSE_FAILED = 'PARSE_INSPECTOR_INFO_RESPONSE_FAILED'

export const GET_APK_REPORT_REQUEST = 'GET_APK_REPORT_REQUEST'
export const GET_APK_REPORT_REQUEST_SUCCESS = 'GET_APK_REPORT_REQUEST_SUCCESS'
export const GET_APK_REPORT_REQUEST_FAILED = 'GET_APK_REPORT_REQUEST_FAILED'

export const POST_APK_REPORT_RESPONSE = 'POST_APK_REPORT_RESPONSE'
export const POST_APK_REPORT_RESPONSE_SUCCESS = 'POST_APK_REPORT_RESPONSE_SUCCESS'
export const POST_APK_REPORT_RESPONSE_FAILED = 'POST_APK_REPORT_RESPONSE_FAILED'

export const GET_CONFIRM_RANDOM_CHECK_REQUEST = 'GET_CONFIRM_RANDOM_CHECK_REQUEST'
export const GET_CONFIRM_RANDOM_CHECK_REQUEST_SUCCESS = 'GET_CONFIRM_RANDOM_CHECK_REQUEST_SUCCESS'
export const GET_CONFIRM_RANDOM_CHECK_REQUEST_FAILED = 'GET_CONFIRM_RANDOM_CHECK_REQUEST_FAILED'

export const POST_CONFIRM_RANDOM_CHECK_RESPONSE = 'POST_CONFIRM_RANDOM_CHECK_RESPONSE'
export const POST_CONFIRM_RANDOM_CHECK_RESPONSE_SUCCESS = 'POST_CONFIRM_RANDOM_CHECK_RESPONSE_SUCCESS'
export const POST_CONFIRM_RANDOM_CHECK_RESPONSE_FAILED = 'POST_CONFIRM_RANDOM_CHECK_RESPONSE_FAILED'

export const GET_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_REQUEST = 'GET_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_REQUEST'
export const GET_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_REQUEST_SUCCESS = 'GET_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_REQUEST_SUCCESS'
export const GET_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_REQUEST_FAILED = 'GET_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_REQUEST_FAILED'

export const POST_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_RESPONSE = 'POST_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_RESPONSE'
export const POST_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_RESPONSE_SUCCESS = 'POST_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_RESPONSE_SUCCESS'
export const POST_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_RESPONSE_FAILED = 'POST_CONFIRM_RANDOM_CHECK_WITHOUT_VEHICLE_RESPONSE_FAILED'

export const SET_CURRENT_SELECTED_RDW_PROVIDER = 'SET_CURRENT_SELECTED_RDW_PROVIDER'
