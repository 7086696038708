import { retrieveError, toastErrorCode } from 'middlewares/errorHandler/utils'

const defaultHandler = ({ error, action }: any) => {
  if (action?.payload?.ignoreError) {
    return
  }
  const errorCode = retrieveError(error)
  toastErrorCode(errorCode)
}

export default defaultHandler
