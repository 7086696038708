import { DesktopDateTimePicker, DesktopDateTimePickerProps, renderTimeViewClock } from '@mui/x-date-pickers'
import DateType from './date-helpers/date-type'
import useIsAmPm from './date-helpers/use-is-am-pm'
import useOpenOnFocus, { useOpenOnFocusInputProps } from './date-helpers/use-open-on-focus'
import { TextField } from './TextField'

export interface DateTimePickerProps extends DesktopDateTimePickerProps<DateType>, useOpenOnFocusInputProps {}

export function DateTimePicker(props: DateTimePickerProps) {
  const isAmPm = useIsAmPm() // am pm can't be determined via locale for locales that support both 12 and 24 hour time (like uk).
  const useOpenOnFocusProps = useOpenOnFocus(props.openOnFocus || false, props)

  return (
    <DesktopDateTimePicker
      ampm={isAmPm}
      // enable clock for time pickers in desktop mode
      // this is disabled by default in MUI6 to provide a better UX on desktop
      // but we want to keep the old behaviour for now
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock
      }}
      {...props}
      slots={{
        textField: TextField,
        ...props.slots
      }}
      slotProps={{
        textField: useOpenOnFocusProps.InputProps,
        openPickerButton: useOpenOnFocusProps.OpenPickerButtonProps,
        ...props.slotProps
      }}
      onClose={useOpenOnFocusProps.onClose}
      open={useOpenOnFocusProps.open}
    />
  )
}
