import React from 'react'
import isFunction from 'lodash/fp/isFunction'

import { FileUploaderBase } from './FileUploaderBase'
import { FileUploadReducer, createFileUploaderReducer, createFileUploaderValidation, useFileUploader } from './useFileUploader'
import { AcceptedFileTypes } from './types'

export interface Props
  extends Partial<Pick<React.ComponentProps<typeof FileUploaderBase>, 'PaperComponent' | 'multiple' | 'accept' | 'maximumFilesize'>> {
  /**
   * This prop is invoked when files state changes, e.g. on selecting a file.
   */
  onChange?: (files: File[]) => void
  /**
   * This prop can be provided to alter behavior of state change.
   */
  reducer?: FileUploadReducer
  /**
   * This prop can be used to render a custom component beside the select file button.
   * e.g. an upload button can be placed here.
   */
  children?: (uploader: ReturnType<typeof useFileUploader>) => JSX.Element
}

export function FileUploader({
  multiple = false,
  accept = ['jpg', 'jpeg', 'png', 'svg'] as AcceptedFileTypes[],
  maximumFilesize = 5,
  reducer = createFileUploaderReducer(createFileUploaderValidation(multiple, accept, maximumFilesize)),
  onChange,
  children,
  PaperComponent
}: Props) {
  const uploader = useFileUploader(reducer)

  React.useEffect(() => {
    onChange && onChange(uploader.files)
  }, [uploader.files])

  return (
    <FileUploaderBase {...uploader} PaperComponent={PaperComponent} multiple={multiple} accept={accept} maximumFilesize={maximumFilesize}>
      {children && isFunction(children) ? children(uploader) : children}
    </FileUploaderBase>
  )
}
