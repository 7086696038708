import React from 'react'
import { Option } from './SplitButton'
interface SplitButtonContext {
  registerOption: (option: Option) => void
  unregisterOption: (id: string) => void
}

export const SplitButtonContext = React.createContext<SplitButtonContext | null>(null)

export function useSplitButtonContext(): SplitButtonContext {
  const context = React.useContext(SplitButtonContext)

  if (!context) throw new Error("useSplitButtonContext is being used outside of it's context provider")

  return context
}
