import { AnyAction } from 'redux'
import { ICountry } from '@cbgms/api/modules/miscellaneous/countries'
import * as types from 'core/widgets/CountriesDropdown/types'
import { Locales } from '@carsys/enums/locales'

export interface State {
  readonly countries: ICountry[]
  readonly lastFetched: Date
  readonly lastLocale?: Locales
  readonly isFetching: boolean
}
const defaultState: State = {
  countries: [],
  lastFetched: new Date(0),
  lastLocale: undefined,
  isFetching: false
}

export function allReducer(state: State = defaultState, action: AnyAction): State {
  switch (action.type) {
    case types.GET_COUNTRIES_SUCCESS:
      return {
        countries: action.payload.data.Data,
        lastFetched: new Date(),
        lastLocale: action.request.request.params.locale,
        isFetching: false
      }
    case types.LOCK_GET_CACHED_COUNTRIES:
      return {
        ...state,
        isFetching: true
      }
    default:
      return state
  }
}
