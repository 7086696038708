export const LOAD_PARTS = 'LOAD_PARTS'
export const LOAD_PARTS_SUCCESS = 'LOAD_PARTS_SUCCESS'
export const LOAD_PARTS_FAILED = 'LOAD_PARTS_FAILED'

export const LOAD_PART = 'LOAD_PART'
export const LOAD_PART_SUCCESS = 'LOAD_PART_SUCCESS'
export const LOAD_PART_FAILED = 'LOAD_PART_FAILED'

export const LOAD_NO_SUPPLIERS_PARTS = 'LOAD_NO_SUPPLIERS_PARTS'
export const LOAD_NO_SUPPLIERS_PARTS_SUCCESS = 'LOAD_NO_SUPPLIERS_PARTS_SUCCESS'
export const LOAD_NO_SUPPLIERS_PARTS_FAILED = 'LOAD_NO_SUPPLIERS_PARTS_FAILED'

export const CREATE_PART = 'CREATE_PART'
export const CREATE_PART_SUCCESS = 'CREATE_PART_SUCCESS'
export const CREATE_PART_FAILED = 'CREATE_PART_FAILED'

export const UPDATE_PART = 'UPDATE_PART'
export const UPDATE_PART_SUCCESS = 'UPDATE_PART_SUCCESS'
export const UPDATE_PART_FAILED = 'UPDATE_PART_FAILED'

export const DELETE_PART = 'DELETE_PART'
export const DELETE_PART_SUCCESS = 'DELETE_PART_SUCCESS'
export const DELETE_PART_FAILED = 'DELETE_PART_FAILED'

export const LOAD_PART_STOCK = 'LOAD_PART_STOCK'
export const LOAD_PART_STOCK_SUCCESS = 'LOAD_PART_STOCK_SUCCESS'
export const LOAD_PART_STOCK_FAILED = 'LOAD_PART_STOCK_FAILED'

export const UPDATE_PART_STOCK = 'UPDATE_PART_STOCK'
export const UPDATE_PART_STOCK_SUCCESS = 'UPDATE_PART_STOCK_SUCCESS'
export const UPDATE_PART_STOCK_FAILED = 'UPDATE_PART_STOCK_FAILED'

export const UPDATE_PART_LOCATION = 'UPDATE_PART_LOCATION'
export const UPDATE_PART_LOCATION_SUCCESS = 'UPDATE_PART_LOCATION_SUCCESS'
export const UPDATE_PART_LOCATION_FAILED = 'UPDATE_PART_LOCATION_FAILED'

export const RESET_PART = 'RESET_PART'
