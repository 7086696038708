export const LOAD_OKR_HISTORY = 'LOAD_OKR_HISTORY'
export const LOAD_OKR_HISTORY_SUCCESS = 'LOAD_OKR_HISTORY'
export const LOAD_OKR_HISTORY_FAILED = 'LOAD_OKR_HISTORY_FAILED'

export const LOAD_OKR_HISTORY_ITEM = 'LOAD_OKR_HISTORY_ITEM'
export const LOAD_OKR_HISTORY_ITEM_SUCCESS = 'LOAD_OKR_HISTORY_ITEM'
export const LOAD_OKR_HISTORY_ITEM_FAILED = 'LOAD_OKR_HISTORY_ITEM_FAILED'

export const LOAD_UNSENT_OKR_RECORDS = 'LOAD_UNSENT_OKR_RECORDS'
export const LOAD_UNSENT_OKR_RECORDS_SUCCESS = 'LOAD_UNSENT_OKR_RECORDS_SUCCESS'
export const LOAD_UNSENT_OKR_RECORDS_FAILED = 'LOAD_UNSENT_OKR_RECORDS_FAILED'
