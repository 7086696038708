import { useCallback } from 'react'

import { useAccordionDispatch } from './AccordionContext'

export function useCollapseAll() {
  const dispatch = useAccordionDispatch()

  return useCallback(() => {
    dispatch({ type: 'collapseAll' })
  }, [])
}
