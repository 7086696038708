import React from 'react'
import { Alert, AlertTitle, Box, Button, Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DropZoneIndicator } from './DropZoneIndicator'
import { FileList } from './FileList'
import { fileTypesToAcceptString } from './utils'
import { useHumanReadableFilesize } from './use-human-readable-filesize'

import type { AcceptedFileTypes } from './types'

export function FileUploaderBase({
  accept,
  multiple,
  maximumFilesize,
  files,
  error,
  setFiles,
  setError,
  children,
  PaperComponent = DefaultPaperComponent
}: {
  /** An array of acceptable file types for uploading. */
  accept: AcceptedFileTypes[]
  /**
   * Maximum file size in megabytes.
   */
  maximumFilesize: number
  /**
   * This prop can be used to allow multiple
   * files to be uploaded.
   */
  multiple?: boolean
  /**
   * This props holds files state.
   * Will reflect the listed files.
   */
  files: File[]
  /**
   * This props holds the error state.
   * When filled it shows an alert.
   */
  error: string | null
  /**
   * This prop is invoked when changing files state, e.g. on selecting a file.
   * Can be used to update state.
   */
  setFiles: (files: File[]) => void
  /**
   * This prop is invoked when changing error state, e.g. close error message.
   * Can be used to update the error state.
   */
  setError: (error: string | null) => void
  /**
   * This props holds the child, e.g. the upload button.
   */
  children?: React.ReactNode
  /**
   * You can provide this prop to use something
   * other than the default paper component.
   */
  PaperComponent?: React.ComponentType<React.PropsWithChildren<{}>>
}) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [dragDepth, setDragDepth] = React.useState(0)

  const { t, i18n } = useTranslation()

  const formatter = useHumanReadableFilesize()

  function removeFile(fileToRemove: File) {
    const result = files.filter(file => file !== fileToRemove)
    setFiles(result)
  }

  function updateFiles(fileList: FileList) {
    const files = Array.from(fileList)
    setFiles(files)
  }

  function clearInputRefValue() {
    if (inputRef.current) inputRef.current.value = ''
  }

  React.useEffect(() => {
    if (files.length === 0) clearInputRefValue()
  }, [files])

  const dragging = dragDepth > 0

  return (
    // Hooking up drag and drop to the Box component makes it easier to
    // override the PaperComponent. We tried putting it on the
    // PaperComponent but this left you with a more complex props
    // interface. The TypeScript compiler also can't check if custom
    // components use the correct interface. The downside is that there's
    // some styling on the Box that you can't override.
    <PaperComponent>
      <Box
        sx={{ p: 2, position: 'relative' }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()

          setDragDepth(previousDragDepth => previousDragDepth + 1)
        }}
        onDragLeave={e => {
          e.preventDefault()
          e.stopPropagation()

          setDragDepth(previousDragDepth => previousDragDepth - 1)
        }}
        onDragOver={e => {
          e.preventDefault()
          e.stopPropagation()

          e.dataTransfer.dropEffect = 'copy'
        }}
        onDrop={e => {
          e.preventDefault()
          e.stopPropagation()

          setDragDepth(0)

          updateFiles(e.dataTransfer.files)

          e.dataTransfer.clearData()
        }}
      >
        {dragging && <DropZoneIndicator />}
        <Typography gutterBottom variant='h6'>
          {t('FileUploader.Title')}
        </Typography>
        <Typography variant='body2'>
          {t('FileUploader.AcceptedExtensions')}&nbsp;{fileTypesToAcceptString(accept)}
        </Typography>
        <Typography gutterBottom variant='body2'>
          {t('FileUploader.MaximumFilesize')}&nbsp;{formatter(maximumFilesize)}
        </Typography>
        <Grid container spacing={1}>
          <FileList files={files} onRemove={removeFile} />
          {error != null && (
            <Grid item xs={12}>
              <Alert closeText={t('FileUploader.Errors.Close')} onClose={() => setError(null)} severity='error'>
                <AlertTitle>{t('FileUploader.Errors.Title')}</AlertTitle>
                {i18n.exists(error) ? t(error) : error}
              </Alert>
            </Grid>
          )}
          <Grid item xs>
            <Button component='label' fullWidth variant='outlined'>
              <input
                ref={inputRef}
                accept={fileTypesToAcceptString(accept)}
                hidden
                multiple={multiple}
                type='file'
                onChange={event => {
                  if (!event.target.files) return

                  updateFiles(event.target.files)
                }}
              />
              {t('FileUploader.Buttons.SelectFile')}
            </Button>
          </Grid>
          {children}
        </Grid>
      </Box>
    </PaperComponent>
  )
}

function DefaultPaperComponent({ children }: React.PropsWithChildren<{}>) {
  return <Paper>{children}</Paper>
}
