import { Reducer } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import * as types from 'app/settings/state/Employees/types'

export type LoadingReducerState = boolean

const defaultState: LoadingReducerState = true

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type LoadingReducerBehavior<S = LoadingReducerState> = Record<typeof types.LOAD_EMPLOYEE_STATUS, Reducer<S, any>> &
  Record<typeof types.LOAD_EMPLOYEE_STATUS_SUCCESS, Reducer<S, any>> &
  Record<typeof types.LOAD_EMPLOYEE_STATUS_FAILED, Reducer<S, any>>

const status = createReducer<LoadingReducerState, LoadingReducerBehavior>(defaultState, {
  [types.LOAD_EMPLOYEE_STATUS]: () => true,
  [types.LOAD_EMPLOYEE_STATUS_SUCCESS]: () => false,
  [types.LOAD_EMPLOYEE_STATUS_FAILED]: () => false
})

export default status
