import { Reducer } from 'redux'

import { IRevenueGroup } from '@cbgms/api/modules/revenuegroups'
import { createReducer } from '@cbgms/base/utils/redux'

import { LOAD_REVENUE_GROUP_SUCCESS } from 'app/settings/state/RevenueGroups/types'

export type RevenueGroupState = Readonly<Partial<IRevenueGroup>>

const defaultState: RevenueGroupState = {}

type RevenueGroupBehavior<S = RevenueGroupState> = Record<typeof LOAD_REVENUE_GROUP_SUCCESS, Reducer<S, any>>

const revenueGroup = createReducer<RevenueGroupState, RevenueGroupBehavior>(defaultState, {
  [LOAD_REVENUE_GROUP_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export default revenueGroup
