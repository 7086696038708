import * as types from './types'
import * as requests from '@cbgms/api/modules/vehicles/requests'
import { getGenericVehicleByRegistration } from '@cbgms/api/modules/generic-vehicle/requests'
import {
  CheckOdometerResponse,
  GETLatestOdometerLogResponse,
  GETMDMAttributesResponse,
  GETVehicleResponse,
  GETVehicleTypesResponse,
  GETVehicleWithRelatedResponse,
  GETVehiclesResponse,
  GetInvoicesByVehicleResponse,
  GetRecallsByVehicleResponse,
  GetVehicleRegisteredInformationResponse,
  IGenericVehicle,
  IPostVehicleDetailOptions,
  IVehicle,
  IVehicleAttribute,
  IVehicleType,
  ManufacturerResponse,
  ModelResponse,
  VehicleDetailsResponse
} from '@cbgms/api/modules/vehicles'
import { Locales } from '@carsys/enums/locales'
import { VehicleIdType, VehicleProvider } from '@cbgms/api/modules/enums'
import { IPaginatedRequest } from '@cbgms/api/request'
import { AsyncAction } from '@cbgms/base/redux/async-action'
import { APIResponse, DELETEResponse, IResponse, IUUIDResponse } from '@cbgms/api'
import { getVehicleDamageRequest, putVehicleDamageRequest } from '@cbgms/api/modules/vehicle-damages/requests'
import { GETVehicleActiveWarranties } from '@cbgms/api/modules/vehicle-warranties'
import { getVehicleActiveWarrantiesRequest } from '@cbgms/api/modules/vehicles/requests'

export const getVehicles = (params: IPaginatedRequest) =>
  AsyncAction<GETVehiclesResponse>({
    type: [types.LOAD_VEHICLES, types.LOAD_VEHICLES_SUCCESS, types.LOAD_VEHICLES_FAILED],
    payload: {
      api: 'gms',
      request: requests.getVehicles(params)
    }
  })

export const getVehiclesByRegistration = (registration: string) =>
  AsyncAction<GETVehiclesResponse>({
    type: [types.GET_VEHICLES_BY_REGISTRATION, types.GET_VEHICLES_BY_REGISTRATION_SUCCESS, types.GET_VEHICLES_BY_REGISTRATION_FAILED],
    payload: {
      api: 'gms',
      request: requests.getVehiclesByRegistration(registration)
    }
  })

export const getVehiclesByVin = (vin: string) =>
  AsyncAction<GETVehiclesResponse>({
    type: [types.GET_VEHICLES_BY_VIN, types.GET_VEHICLES_BY_VIN_SUCCESS, types.GET_VEHICLES_BY_VIN_FAILED],
    payload: {
      api: 'gms',
      request: requests.getVehiclesByVin(vin)
    }
  })

export const getVehicleWithRelated = (vehicleUUID: string, ignoreError?: boolean) =>
  AsyncAction<GETVehicleWithRelatedResponse>({
    type: [types.GET_VEHICLE_WITH_RELATED, types.GET_VEHICLE_WITH_RELATED_SUCCESS, types.GET_VEHICLE_WITH_RELATED_FAILED],
    payload: {
      api: 'gms',
      request: requests.getVehicleWithRelated(vehicleUUID)
    },
    shouldIgnoreError: ignoreError
  })

export const getVehicle = (vehicleUUID: string) =>
  AsyncAction<GETVehicleResponse>({
    type: [types.GET_VEHICLE, types.GET_VEHICLE_SUCCESS, types.GET_VEHICLE_FAILED],
    payload: {
      api: 'gms',
      request: requests.getVehicle(vehicleUUID)
    }
  })

// Deprecated: Please consider using : VehicleContextProvider setNewVehicleByUUID method.
export const loadVehicle = (vehicleUUID: string, ignoreError?: boolean) =>
  AsyncAction<GETVehicleResponse>({
    type: [types.LOAD_VEHICLE, types.LOAD_VEHICLE_SUCCESS, types.LOAD_VEHICLE_FAILED],
    payload: {
      api: 'gms',
      request: requests.getVehicle(vehicleUUID)
    },
    shouldIgnoreError: ignoreError
  })

export const loadOdometerLog = (vehicleUUID: string) =>
  AsyncAction<GETLatestOdometerLogResponse>({
    type: [types.LOAD_ODOMETER_LOG, types.LOAD_ODOMETER_LOG_SUCCESS, types.LOAD_ODOMETER_LOG_FAILED],
    payload: {
      api: 'gms',
      request: requests.getOdometerLog(vehicleUUID)
    }
  })

export const resetOdometerLog = () => ({
  type: types.RESET_ODOMETER_LOG
})

export const resetVehicleDetail = () => ({
  type: types.RESET_VEHICLE_DETAIL
})

export const setVehicleDetail = (vehicle: IVehicle) => ({
  type: types.SET_VEHICLE_DETAIL,
  vehicle
})

export const deleteVehicle = (vehicleUUID: string, dryRun: boolean) =>
  AsyncAction<DELETEResponse>({
    type: [types.DELETE_VEHICLE, types.DELETE_VEHICLE_SUCCESS, types.DELETE_VEHICLE_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'DELETE',
        url: `/modules/vehicles/${vehicleUUID}?DryRun=${dryRun}`
      }
    }
  })

export const loadVehiclesWithRelated = (params: IPaginatedRequest) => ({
  type: [types.LOAD_VEHICLES_WITH_RELATED, types.LOAD_VEHICLES_WITH_RELATED_SUCCESS, types.LOAD_VEHICLES_WITH_RELATED_FAILED],
  payload: {
    api: 'gms',
    request: {
      params,
      method: 'GET',
      url: '/modules/vehicles/with-related'
    }
  }
})

export const setLoadedVehicle = (vehicle: any) => ({
  type: types.LOAD_VEHICLE_SUCCESS,
  payload: { data: { Data: vehicle } }
})

export const updateVehicle = (vehicleUUID: string, data: any) =>
  AsyncAction<IUUIDResponse>({
    type: [types.UPDATE_VEHICLE, types.UPDATE_VEHICLE_SUCCESS, types.UPDATE_VEHICLE_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'PUT',
        url: `/modules/vehicles/${vehicleUUID}`,
        data
      }
    }
  })

export const createVehicle = (vehicle: Partial<IVehicle>) =>
  AsyncAction<IUUIDResponse>({
    type: [types.CREATE_VEHICLE, types.CREATE_VEHICLE_SUCCESS, types.CREATE_VEHICLE_FAILED],
    payload: {
      api: 'gms',
      request: requests.postVehicle(vehicle)
    }
  })

export const getMDMAttributes = (vehicleUUID: string) =>
  AsyncAction<GETMDMAttributesResponse>({
    type: [types.UPDATE_MDM_ATTRIBUTES, types.UPDATE_MDM_ATTRIBUTES_SUCCESS, types.UPDATE_MDM_ATTRIBUTES_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url: `/modules/vehicles/${vehicleUUID}/mdmattributes`
      }
    }
  })

export const updateMDMAttributes = (vehicleUUID: string, data: Partial<IPostVehicleDetailOptions>) =>
  AsyncAction<GETMDMAttributesResponse>({
    type: [types.UPDATE_MDM_ATTRIBUTES, types.UPDATE_MDM_ATTRIBUTES_SUCCESS, types.UPDATE_MDM_ATTRIBUTES_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'PUT',
        url: `/modules/vehicles/${vehicleUUID}/mdmattributes`,
        data
      }
    }
  })

export const refreshMDMAttributes = (vehicleUUID: string) =>
  AsyncAction<GETMDMAttributesResponse>({
    type: [types.REFRESH_MDM_ATTRIBUTES, types.REFRESH_MDM_ATTRIBUTES_SUCCESS, types.REFRESH_MDM_ATTRIBUTES_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'PUT',
        url: `/modules/vehicles/${vehicleUUID}/refresh-mdmattributes`
      }
    }
  })

export const getHaynesProAdjustmentData = (vehicleUUID: string) => ({
  type: [types.LOAD_HAYNESPRO_ADJUSTMENTDATA, types.LOAD_HAYNESPRO_ADJUSTMENTDATA_SUCCESS, types.LOAD_HAYNESPRO_ADJUSTMENTDATA_FAILED],
  payload: {
    api: 'gms',
    vehicleUUID,
    request: {
      method: 'GET',
      url: `/modules/vehicles/${vehicleUUID}/haynespro/adjustment-data`
    }
  }
})

export const getHaynesProTechnicalManuals = (vehicleUUID: string) => ({
  type: [
    types.LOAD_HAYNESPRO_TECHNICALMANUALS,
    types.LOAD_HAYNESPRO_TECHNICALMANUALS_SUCCESS,
    types.LOAD_HAYNESPRO_TECHNICALMANUALS_FAILED
  ],
  payload: {
    api: 'gms',
    vehicleUUID,
    request: {
      method: 'GET',
      url: `/modules/vehicles/${vehicleUUID}/haynespro/technical-manuals`
    }
  }
})

export const getHaynesProTechnicalManual = (vehicleUUID: string, storyID: any) => ({
  type: [types.LOAD_HAYNESPRO_TECHNICALMANUAL, types.LOAD_HAYNESPRO_TECHNICALMANUAL_SUCCESS, types.LOAD_HAYNESPRO_TECHNICALMANUAL_FAILED],
  payload: {
    api: 'gms',
    vehicleUUID,
    storyID,
    request: {
      method: 'GET',
      url: `/modules/vehicles/${vehicleUUID}/haynespro/technical-manuals/${storyID}`
    }
  }
})

export const resetHaynesData = () => ({
  type: types.RESET_HAYNESPRO_DATA
})

export const enrichVehicle = (vehicleUUID: string, target: VehicleIdType, ignoreError?: boolean) =>
  AsyncAction<APIResponse<IVehicle[]>>({
    type: [types.ENRICH_VEHICLE, types.ENRICH_VEHICLE_SUCCESS, types.ENRICH_VEHICLE_FAILED],
    payload: {
      api: 'gms',
      ignoreError,
      request: requests.enrichVehicle(vehicleUUID, target)
    }
  })

export const closeSelectVehicleType = () => ({
  type: types.CLOSE_SELECT_VEHICLE_TYPE
})

export const loadBrands = (_locale: Locales = Locales.nl_NL, vehicleCountry = 'NL', provider = 'hbase') =>
  AsyncAction<ManufacturerResponse>({
    type: [types.LOAD_BRANDS, types.LOAD_BRANDS_SUCCESS, types.LOAD_BRANDS_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url: `/modules/vehicletree/manufacturers?Provider=${provider}&Locale=${_locale}&VehicleCountry=${vehicleCountry}`
      }
    }
  })

export const loadModels = (manufacturer: string, _locale: Locales, vehicleCountry = 'NL', provider = 'hbase') =>
  AsyncAction<ModelResponse>({
    type: [types.LOAD_MODELS, types.LOAD_MODELS_SUCCESS, types.LOAD_MODELS_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url:
          `/modules/vehicletree/models?Provider=${provider}&ManufacturerID=${manufacturer}` +
          `&Locale=${_locale}&VehicleCountry=${vehicleCountry}`
      }
    }
  })

export const loadTypes = (manufacturer: string, model: string, _locale: Locales, vehicleCountry = 'NL', provider = 'hbase') =>
  AsyncAction<GETVehicleTypesResponse>({
    type: [types.LOAD_TYPES, types.LOAD_TYPES_SUCCESS, types.LOAD_TYPES_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url:
          `/modules/vehicletree/types?Provider=${provider}&ManufacturerID=${manufacturer}&ModelID=${model}` +
          `&Locale=${_locale}&VehicleCountry=${vehicleCountry}`
      }
    }
  })

export const loadVehicleDetails = (_locale?: Locales, vehicleCountry?: string, attributes?: IVehicleAttribute[]) =>
  AsyncAction<VehicleDetailsResponse>({
    type: [types.LOAD_DETAILS, types.LOAD_DETAILS_SUCCESS, types.LOAD_DETAILS_FAILED],
    payload: {
      api: 'gms',
      request: requests.postGetVehicleDetails(_locale, vehicleCountry, attributes)
    }
  })

export const getVehicleRefreshedData = (vehicleUUID: string, provider: VehicleProvider) =>
  AsyncAction<APIResponse<IVehicleType[]>>({
    type: [types.LOAD_REFRESH_DATA_VEHICLE, types.LOAD_REFRESH_DATA_VEHICLE_SUCCESS, types.LOAD_REFRESH_DATA_VEHICLE_FAILED],
    payload: {
      api: 'gms',
      request: requests.getVehicleExternalData(vehicleUUID, provider)
    }
  })

export const getAPKHistoryOnVehicle = (vehicleUUID: string) => ({
  type: [types.GET_APK_HISTORY, types.GET_APK_HISTORY_SUCCESS, types.GET_APK_HISTORY_FAILED],
  payload: {
    api: 'gms',
    request: requests.getAPKHistoryOnVehicle({ vehicleUUID })
  }
})

export const getMOTHistoryOfVehicle = (vehicleUUID: string) => ({
  type: [types.GET_MOT_HISTORY, types.GET_MOT_HISTORY_SUCCESS, types.GET_MOT_HISTORY_FAILED],
  payload: {
    api: 'gms',
    request: requests.getMOTHistoryOfVehicle(vehicleUUID)
  }
})

export const checkOdometerOfVehicle = (vehicleUUID: string, newOdometerUnit: string, newOdometerValue: string) =>
  AsyncAction<CheckOdometerResponse>({
    type: [types.CHECK_ODOMETER, types.CHECK_ODOMETER_SUCCESS, types.CHECK_ODOMETER_FAILED],
    payload: {
      api: 'gms',
      request: requests.checkOdometerOfVehicle(vehicleUUID, newOdometerUnit, newOdometerValue)
    }
  })

export const getInvoicesByVehicle = (vehicleUUID: string, query: string) =>
  AsyncAction<GetInvoicesByVehicleResponse>({
    type: [types.GET_INVOICES_BY_VEHICLE, types.GET_INVOICES_BY_VEHICLE_SUCCESS, types.GET_INVOICES_BY_VEHICLE_FAILED],
    payload: {
      api: 'gms',
      request: requests.getInvoicesByVehicle(vehicleUUID, query)
    }
  })

export const getRecallsByVehicle = (vehicleUUID: string, options: { skipVehicleSelection: boolean }) =>
  AsyncAction<GetRecallsByVehicleResponse>({
    type: [types.GET_RECALLS_BY_VEHICLE, types.GET_RECALLS_BY_VEHICLE_SUCCESS, types.GET_RECALLS_BY_VEHICLE_FAILED],
    payload: {
      // params for error handling:
      vehicleUUID,
      skipVehicleSelection: options?.skipVehicleSelection,

      // Actual requests params
      api: 'gms',
      request: requests.getRecallsByVehicle(vehicleUUID)
    }
  })

export const getVehicleSuggestionsWithProvider = (vehicleUUID: string, provider: VehicleProvider) =>
  AsyncAction<IResponse<IGenericVehicle[]>>({
    type: [
      types.GET_VEHICLE_SUGGESTIONS_WITH_PROVIDER,
      types.GET_VEHICLE_SUGGESTIONS_WITH_PROVIDER_SUCCESS,
      types.GET_VEHICLE_SUGGESTIONS_WITH_PROVIDER_FAILED
    ],
    payload: {
      api: 'gms',
      request: requests.getSuggestionsForVehicleWithProvider(vehicleUUID, provider)
    }
  })

export const getVehicleRegisteredInformation = (vehicleUUID: string) =>
  AsyncAction<GetVehicleRegisteredInformationResponse>({
    type: [
      types.GET_VEHICLE_REGISTERED_INFORMATION,
      types.GET_VEHICLE_REGISTERED_INFORMATION_SUCCESS,
      types.GET_VEHICLE_REGISTERED_INFORMATION_FAILED
    ],
    payload: {
      api: 'gms',
      request: requests.getVehicleRegisteredInformation(vehicleUUID)
    }
  })

export const getVehicleDamage = (vehicleUUID: string) =>
  AsyncAction<GETVehicleResponse>({
    type: [types.GET_VEHICLE_DAMAGE, types.GET_VEHICLE_DAMAGE_SUCCESS, types.GET_VEHICLE_DAMAGE_FAILED],
    payload: {
      api: 'gms',
      request: getVehicleDamageRequest(vehicleUUID)
    }
  })

export const updateVehicleDamage = (vehicleUUID: string, data: any) =>
  AsyncAction<IUUIDResponse>({
    type: [types.UPDATE_VEHICLE_DAMAGE, types.UPDATE_VEHICLE_DAMAGE_SUCCESS, types.UPDATE_VEHICLE_DAMAGE_FAILED],
    payload: {
      api: 'gms',
      request: putVehicleDamageRequest(vehicleUUID, data)
    }
  })

export const getVehicleActiveWarranties = (vehicleUUID: string) =>
  AsyncAction<GETVehicleActiveWarranties>({
    type: [types.GET_VEHICLE_ACTIVE_WARRANTIES, types.GET_VEHICLE_ACTIVE_WARRANTIES_SUCCESS, types.GET_VEHICLE_ACTIVE_WARRANTIES_FAILED],
    payload: {
      api: 'gms',
      request: getVehicleActiveWarrantiesRequest(vehicleUUID)
    }
  })

export const getVehicleDataByRegistration = (registration: string, provider: VehicleProvider) =>
  AsyncAction<APIResponse<IVehicleType[]>>({
    type: [
      types.GET_VEHICLE_DATA_BY_REGISTRATION,
      types.GET_VEHICLE_DATA_BY_REGISTRATION_SUCCESS,
      types.GET_VEHICLE_DATA_BY_REGISTRATION_FAILED
    ],
    payload: {
      api: 'gms',
      provider,
      request: getGenericVehicleByRegistration(registration, provider)
    },
    shouldIgnoreError: true
  })
