import useApplicatioName from 'utils/use-application-name'

const useTitleSuffix = () => {
  const applicationName = useApplicatioName()
  if (applicationName === 'carsys') return 'CarSys'
  if (applicationName === 'carcat') return 'CarCat'
  return ''
}

export default useTitleSuffix
