import { DesktopTimePicker, DesktopTimePickerProps, renderTimeViewClock } from '@mui/x-date-pickers'
import DateType from './date-helpers/date-type'
import useIsAmPm from './date-helpers/use-is-am-pm'
import { TextField } from './TextField'

export interface TimePickerProps extends DesktopTimePickerProps<DateType> {}

export function TimePicker(props: TimePickerProps) {
  const isAmPm = useIsAmPm() // am pm can't be determined via locale for locales that support both 12 and 24 hour time (like uk).

  return (
    // We might be able to replace this with DesktopTimePicker when this issue has been resolved:
    // https://github.com/mui/mui-x/issues/8025
    <DesktopTimePicker
      ampm={isAmPm}
      // enable clock for time pickers in desktop mode
      // this is disabled by default in MUI6 to provide a better UX on desktop
      // but we want to keep the old behaviour for now
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock
      }}
      {...props}
      slots={{
        textField: TextField,
        ...props.slots
      }}
    />
  )
}
