import React, { memo } from 'react'
import { SnackbarProvider } from 'notistack'
import { CustomSnackBarComponents } from '@cbgms/base/snackbar/types'
import { useSelector } from 'react-redux'

interface MenuState {
  menu: { sidebarOpen: boolean }
}

const SnackBarProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const snackBarAdjust: boolean = useSelector<MenuState, boolean>(state => !!state.menu?.sidebarOpen)
  return (
    <SnackbarProvider
      maxSnack={3}
      Components={CustomSnackBarComponents}
      autoHideDuration={5000}
      classes={{
        containerRoot: snackBarAdjust ? 'snackbar_menu-expanded' : 'snackbar_menu-collapsed'
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default memo(SnackBarProvider)
