import React from 'react'
import { setEnvironment } from 'state/environment/actions'
import { types } from 'app/auth/state'
import { useDispatch, useSelector } from 'react-redux'
import { EnvironmentContext } from 'contexts/EnvironmentContext'
import { IRootReducerState } from 'state/rootReducer'

const ReduxApiSetup: React.FC = ({ children }) => {
  const { environment, resolvedURLs } = React.useContext(EnvironmentContext)
  const dispatch = useDispatch()
  const apiIsInitialised = useSelector((state: IRootReducerState) => Boolean(state.auth.urls.gms && state.auth.urls.sso))

  React.useEffect(() => {
    dispatch({
      type: types.SET_API_URLS,
      payload: resolvedURLs
    })

    dispatch(setEnvironment(environment))
  }, [resolvedURLs, environment])

  if (!apiIsInitialised) return null

  return (children as React.ReactElement) || null
}

export default ReduxApiSetup
