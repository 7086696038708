import { faker } from '@faker-js/faker/locale/en'

import { IEmployee, ISupportEmployee } from './'
import { MockFn, referenceDate } from '../../mock'
import { CountryCode } from '@carsys/enums/country-code'
import { Locales } from '@carsys/enums/locales'
import { EmployeeAvailability, EmployeeStatus, FunctionType } from '@cbgms/api/modules/enums'

export const mockEmployee: MockFn<IEmployee> = (extend?) => ({
  BankBicNumber: faker.finance.bic(),
  BankIbanNumber: faker.finance.iban(),
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  DateInService: faker.date.past(50, referenceDate).toISOString(),
  DateOfBirth: faker.date.past(50, referenceDate).toISOString(),
  DateOutOfService: faker.date.past(50, referenceDate).toISOString(),
  Department: faker.commerce.department(),
  EmailAddress: faker.internet.email(),
  EmailAddressWork: faker.internet.email(),
  FirstName: faker.name.firstName(),
  Functions: [FunctionType.ShopManager, FunctionType.Mechanic],
  Initials: '',
  MobileNumber: faker.phone.number(),
  MOT: '',
  Name: faker.name.lastName(),
  NamePrefix: faker.name.prefix(),
  PhoneNumber: faker.phone.number(),
  PostalAddress: faker.address.streetAddress(),
  PostalAddressExtra: faker.address.street(),
  PostalCity: faker.address.city(),
  PostalCountry: CountryCode.NL,
  PostalHouseNumber: faker.datatype.number(1000).toString(),
  PostalHouseNumberAddition: faker.random.alphaNumeric(1),
  PostalPostalCode: faker.address.zipCode(),
  PostalState: faker.address.state(),
  ProfileColour: faker.color.human(),
  ProfilePicture: faker.image.people(),
  ProfilePictureThumb: faker.image.people(),
  Skills: [faker.datatype.uuid()],
  SsoUsername: '',
  Title: faker.name.prefix(),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  UUID: faker.datatype.uuid(),
  VisitorAddress: faker.address.streetAddress(),
  VisitorAddressExtra: faker.address.street(),
  VisitorCity: faker.address.city(),
  VisitorCountry: CountryCode.NL,
  VisitorHouseNumber: faker.datatype.number(1000).toString(),
  VisitorHouseNumberAddition: faker.random.alphaNumeric(1),
  VisitorPostalCode: faker.address.zipCode(),
  VisitorState: faker.address.state(),
  CurrentShop: 'NL999999',
  EmployeeStatus: EmployeeStatus.InService,
  EmployeeAvailability: EmployeeAvailability.OnDuty,
  HasAcceptedGarageAdminTermsAndConditions: true,
  HasAcceptedPortalTermsAndConditions: true,
  HasAcceptedGMSTermsAndConditions: true,
  ...extend
})

export const emptyEmployee: MockFn<IEmployee> = (extend?) => ({
  BankBicNumber: '',
  BankIbanNumber: '',
  CreatedAt: '',
  DateInService: '',
  DateOfBirth: '',
  DateOutOfService: '',
  Department: '',
  EmailAddress: '',
  EmailAddressWork: '',
  FirstName: '',
  Functions: [],
  Initials: '',
  MobileNumber: '',
  MOT: '',
  Name: '',
  NamePrefix: '',
  PhoneNumber: '',
  PostalAddress: '',
  PostalAddressExtra: '',
  PostalCity: '',
  PostalCountry: CountryCode.NL,
  PostalHouseNumber: '',
  PostalHouseNumberAddition: '',
  PostalPostalCode: '',
  PostalState: '',
  ProfileColour: '',
  ProfilePicture: '',
  ProfilePictureThumb: '',
  Skills: [],
  SsoUsername: '',
  Title: '',
  UpdatedAt: '',
  UUID: '',
  VisitorAddress: '',
  VisitorAddressExtra: '',
  VisitorCity: '',
  VisitorCountry: CountryCode.NL,
  VisitorHouseNumber: '',
  VisitorHouseNumberAddition: '',
  VisitorPostalCode: '',
  VisitorState: '',
  CurrentShop: '',
  EmployeeStatus: EmployeeStatus.InService,
  EmployeeAvailability: EmployeeAvailability.OnDuty,
  HasAcceptedGarageAdminTermsAndConditions: false,
  HasAcceptedPortalTermsAndConditions: false,
  HasAcceptedGMSTermsAndConditions: false,
  ...extend
})

export const mockSupportEmployee: MockFn<ISupportEmployee> = (extend?) => ({
  ...mockEmployee(),
  CompanyUUID: faker.datatype.uuid(),
  SsoUsername: faker.name.lastName(),
  ...extend
})

export const mockSupportEmployeeUser: MockFn<ISupportEmployee> = (extend?) => ({
  ...mockEmployee(),
  CompanyUUID: faker.datatype.uuid(),
  SsoUsername: faker.name.lastName(),
  Locale: Locales.nl_NL,
  EmailAddress: faker.internet.email(),
  ...extend
})
