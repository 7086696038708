import { IShop, IShopResponse, PublicShop } from '@cbgms/api/modules/shops'
import { AsyncAction } from '@cbgms/base/redux/async-action'

import * as types from './types'
import { UUIDResponse } from '@cbgms/api'

export const updateShop = (shopID: string, data: Partial<IShop>) =>
  AsyncAction<UUIDResponse>({
    type: [types.UPDATE_SHOP, types.UPDATE_SHOP_SUCCESS, types.UPDATE_SHOP_FAILED],
    payload: {
      api: 'gms',
      request: {
        url: `/modules/shops/${shopID}`,
        method: 'PUT',
        data
      }
    }
  })

export const getShop = (shopID: string) =>
  AsyncAction<IShopResponse>({
    type: [types.GET_SHOP, types.GET_SHOP_SUCCESS, types.GET_SHOP_FAILED],
    payload: {
      api: 'gms',
      request: {
        url: `/modules/shops/${shopID}`,
        method: 'GET'
      }
    }
  })

export const getCurrentShop = () =>
  AsyncAction<IShopResponse>({
    type: [types.GET_CURRENT_SHOP, types.GET_CURRENT_SHOP_SUCCESS, types.GET_CURRENT_SHOP_FAILED],
    payload: {
      api: 'gms',
      request: {
        url: `/modules/shop`,
        method: 'GET'
      }
    }
  })

export const getCompanyShops = () => ({
  type: [types.GET_COMPANY_SHOPS, types.GET_COMPANY_SHOPS_SUCCESS, types.GET_COMPANY_SHOPS_FAILED],
  payload: {
    api: 'gms',
    request: {
      url: `/modules/shops`,
      method: 'GET'
    }
  }
})

// set a publicSHop in redux, in order to use permissions on myWorkorder
export const setPublicShop = (shop: PublicShop) => ({
  type: types.GET_SHOP_SUCCESS,
  payload: { data: { Data: shop } }
})
