import { useEffect } from 'react'

import { useAccordionDispatch } from './AccordionContext'

export function useRegisterAccordion(accordion: string) {
  const dispatch = useAccordionDispatch()

  useEffect(() => {
    dispatch({ type: 'register', payload: accordion })

    return () => {
      dispatch({ type: 'unregister', payload: accordion })
    }
  }, [])
}
