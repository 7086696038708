import { Reducer } from 'redux'

import { IWorkHours } from '@cbgms/api/modules/opening-hours'
import { createReducer } from '@cbgms/base/utils/redux'

import * as types from 'app/settings/state/Employees/types'

export type WorkHoursReducerState = Readonly<Partial<IWorkHours>>

const defaultState: WorkHoursReducerState = {}

type WorkHoursReducerBehavior<S = WorkHoursReducerState> = Record<typeof types.LOAD_WORK_HOURS_SUCCESS, Reducer<S, any>>

const workHours = createReducer<WorkHoursReducerState, WorkHoursReducerBehavior>(defaultState, {
  [types.LOAD_WORK_HOURS_SUCCESS]: (_, { payload }) => payload.data.Data
})

export default workHours
