import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { getOptions } from './selectors'
import { DropdownItemProps } from 'semantic-ui-react'

interface IEmployeeSkillsReducerState {
  detail: any
  options: DropdownItemProps[]
}

type EmployeeSkillsReducerState = Readonly<IEmployeeSkillsReducerState>

const defaultState: EmployeeSkillsReducerState = {
  detail: {},
  options: []
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type DetailReducerBehavior<S> = Record<typeof types.LOAD_EMPLOYEE_SKILL_SUCCESS, Reducer<S, any>> &
  Record<typeof types.LOAD_ALL_EMPLOYEE_SKILLS_SUCCESS, Reducer<S, any>> &
  Record<typeof types.RESET_EMPLOYEE_SKILL, Reducer<S, any>>

const employeeSkills = createReducer<EmployeeSkillsReducerState, DetailReducerBehavior<EmployeeSkillsReducerState>>(defaultState, {
  [types.LOAD_EMPLOYEE_SKILL_SUCCESS]: (state, { payload }) => ({
    ...(state || defaultState),
    detail: payload.data.Data
  }),
  [types.LOAD_ALL_EMPLOYEE_SKILLS_SUCCESS]: (state, { payload }) => ({
    ...(state || defaultState),
    options: getOptions(payload.data)
  }),
  [types.RESET_EMPLOYEE_SKILL]: state => ({
    ...(state || defaultState),
    detail: {}
  })
})

export default employeeSkills
