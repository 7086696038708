import { AnyAction } from 'redux'

import { LOAD_ADMINISTRATION_EXTERNAL_SUCCESS, LOAD_ADMINISTRATION_SUCCESS } from 'app/settings/state/Administrations/types'

import { IAdministration } from '@cbgms/api/modules/administrations'

const defaultState = null

export const administrationReducer = (state: IAdministration | null = defaultState, action: AnyAction) => {
  switch (action.type) {
    case LOAD_ADMINISTRATION_SUCCESS:
      return action.payload.data.Data as IAdministration
    case LOAD_ADMINISTRATION_EXTERNAL_SUCCESS:
      return action.payload.data.Data as IAdministration
    default:
      return state
  }
}
