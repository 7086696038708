import MUIAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'

export function Autocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  return <MUIAutocomplete {...props} />
}

Autocomplete.defaultProps = {
  variant: 'outlined',
  size: 'small'
}
