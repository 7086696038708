import { AsyncAction } from './async-action'

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'PATCH'

type AllowedParamTypes = string | number | boolean | undefined

export interface ApiActionPayload {
  api: API
  request: RequestConfig
}

export interface ApiAction<TPayload = ApiActionPayload> {
  type: [string, string, string]
  payload: TPayload
}

interface SearchParams {
  [index: string]: AllowedParamTypes | AllowedParamTypes[]
}

interface RequestConfig<B = {}, S extends SearchParams = SearchParams> {
  method: HTTPMethod
  url: string
  /** The request body as a plain JavaScript object. This will automatically get stringified. */
  data?: B
  /** The query parameters as a plain JavaScript object. */
  params?: S
}

type API = 'gms' | 'support'

function isRequestConfigObject(input: any): input is RequestConfig {
  const requiredKeys = ['url', 'method']

  return typeof input === 'object' && requiredKeys.every(key => key in input)
}

/**
 * This function creates asynchronous Redux actions. You can use these actions
 * to communicate with the API.
 *
 * @param api The name of the API you want to send the request to.
 * @param request A plain JavaScript object containing at least the
 * properties `url` and `method`. Use this to create a specific request.
 */
export function createAsyncApiAction<R, B = {}, S extends SearchParams = SearchParams>(
  api: API,
  request: RequestConfig<B, S>
): AsyncAction<R>

/**
 * This function creates asynchronous Redux actions. You can use these actions
 * to communicate with the API.
 *
 * @deprecated Do not use the 3 parameter overload. Use the 2
 * parameter overload instead. The 2 parameter overload is as decoupled
 * from Redux as we can currently get.
 *
 * @param type A string to use for the action's types.
 * @param api The name of the API you want to send the request to.
 * @param request A plain JavaScript object containing at least the
 * properties `url` and `method`. Use this to create a specific request.
 */
export function createAsyncApiAction<R, B = {}, S extends SearchParams = SearchParams>(
  type: string,
  api: API,
  request: RequestConfig<B, S>
): AsyncAction<R>

export function createAsyncApiAction<R, B = {}, S extends SearchParams = SearchParams>(
  type: string | API,
  api: API | RequestConfig<B, S>,
  request?: RequestConfig<B, S>
): AsyncAction<R> {
  if (isRequestConfigObject(request) && !isRequestConfigObject(api)) {
    return AsyncAction<R>({
      type: [type, `${type}_SUCCESS`, `${type}_FAILED`],
      payload: {
        api,
        request
      }
    })
  }

  if (isRequestConfigObject(api)) {
    const url = prependSlashIfMissing(api.url)

    return AsyncAction<R>({
      // Do not use these action types in any reducer under any circumstance.
      type: [
        `@@api-request/${api.method}${url}@init`,
        `@@api-request/${api.method}${url}@success`,
        `@@api-request/${api.method}${url}@error`
      ],
      payload: {
        api: type,
        request: api
      }
    })
  }

  throw new TypeError('This should never occur. The TypeScript compiler catches impossible parameter usage.')
}

function prependSlashIfMissing(input: string): string {
  if (input.startsWith('/')) return input

  return '/'.concat(input)
}
