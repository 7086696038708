import { ReactNode } from 'react'
import { TabContext as MuiTabContext } from '@mui/lab'
import { Redirect, useLocation } from 'react-router-dom'

interface Props {
  children: ReactNode
  initialValue: string
}

export function TabContext({ children, initialValue }: Props) {
  const location = useLocation()

  if (location.hash === '') {
    return <Redirect to={{ ...location, hash: initialValue }} />
  }

  return <MuiTabContext value={location.hash}>{children}</MuiTabContext>
}
