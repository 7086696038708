import { useCallback } from 'react'
import { sendRemoveFromCartEvent } from 'utils/TagManager/tag-manager'
import { ILogContextData } from './useLogContextData'
import { IWorkorderLine } from '@cbgms/api'

export interface RemoveFromCartEventParams {
  order: {
    orderUUID: string
    orderLines: IWorkorderLine[]
  }
}

export type RemoveFromCartEvent = (data: RemoveFromCartEventParams) => void
/**
 * usePurchaseLoggerFunc will create the purchase logging function,
 * Calling Google analytics / tag manager and other logging platforms
 */
export const useRemoveFromCartLoggerFunc = (ctx: ILogContextData) => {
  const googleTagManagerRemoveFromCartLogger = useCallback(
    (data: RemoveFromCartEventParams) => sendRemoveFromCartEvent(ctx, data.order),
    [sendRemoveFromCartEvent, ctx]
  )

  return useCallback(
    (data: RemoveFromCartEventParams) => {
      try {
        googleTagManagerRemoveFromCartLogger(data)
      } catch (e) {
        console.error('unable to log navigation event', e)
      }
    },
    [googleTagManagerRemoveFromCartLogger]
  )
}
