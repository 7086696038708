import { APIResponse, NoContent, PUTRequestBody } from '@cbgms/api/helpers'

export enum Month {
  January = 'january',
  February = 'february',
  March = 'march',
  April = 'april',
  May = 'may',
  June = 'june',
  July = 'july',
  August = 'august',
  September = 'september',
  October = 'october',
  November = 'november',
  December = 'december'
}

export interface TyreChangeSettings {
  SummerMinThreshold: number
  UseSummerThreshold: boolean
  WinterMinThreshold: number
  UseWinterThreshold: boolean
  SummerChangeWarningMonths: Month[]
  UseSummerChangeWarning: boolean
  WinterChangeWarningMonths: Month[]
  UseWinterChangeWarning: boolean
  ChangeTyresActivityUUID: string
  CreatedAt: string
  UpdatedAt: string
}

export type GETTyreChangeSettingsResponse = APIResponse<TyreChangeSettings>

export const getTyreChangeSettings = () => ({
  method: 'GET',
  url: '/modules/tyre-changes/settings'
})

export type PUTTyreChangeSettingsResponse = NoContent
export type PUTTyreChangeSettingsBody = PUTRequestBody<TyreChangeSettings>

export const putTyreChangeSettings = (data: PUTTyreChangeSettingsBody) => ({
  method: 'PUT',
  data,
  url: '/modules/tyre-changes/settings'
})
