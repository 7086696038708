import { faker } from '@faker-js/faker/locale/en'

import { ITheme } from './'
import { MockFn, referenceDate } from '../../mock'

export const mockTheme: MockFn<ITheme> = (extend?) => ({
  UUID: faker.datatype.uuid(),
  Name: faker.company.bsBuzz(),
  LogoURL: faker.image.imageUrl(),
  LogoSmallURL: faker.image.imageUrl(),
  MainColour: faker.color.human(),
  MainColourText: faker.color.human(),
  IsDefault: false,
  IsActive: true,
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  ...extend
})

export const emptyTheme: MockFn<ITheme> = (extend?) => ({
  UUID: '',
  Name: 'fallback',
  LogoURL: '',
  LogoSmallURL: '',
  MainColour: '#005596', // assets/styles/ui.less @lkq
  MainColourText: '#fff', // assets/styles/ui.less @MainColourText
  IsDefault: false,
  IsActive: true,
  CreatedAt: '',
  UpdatedAt: '',
  ...extend
})
