import { grey } from '@mui/material/colors'
import { stepLabelClasses } from '@mui/material/StepLabel'
import { ThemeOptions, formLabelClasses } from '@mui/material'
import tinyColor from 'tinycolor2'

export const defaultColors = {
  mainColour: '#005596',
  mainColourText: '#fff',
  mainDarkenedColour: tinyColor('#005596').darken(10).toString(),
  mainExtraDarkenedColour: tinyColor('#005596').darken(15).toString(),
  mainLightenedColour: tinyColor('#005596').lighten(10).toString()
}

export const defaultThemeOptions: ThemeOptions = {
  palette: {
    LKQGrey: {
      background: { modest: grey[600], highlight: grey[700], surface: grey[100], surfaceHover: grey[50] },
      border: { modest: 'rgba(0, 0, 0, 0.12)' }
    },
    muted: {
      main: grey[400],
      dark: grey[600],
      light: grey[400],
      contrastText: '#333',
      backgroundHighlight: grey[200]
    },
    primary: {
      main: defaultColors.mainColour,
      light: defaultColors.mainLightenedColour,
      dark: defaultColors.mainDarkenedColour,
      contrastText: defaultColors.mainColourText
    },
    primaryThemeColor: {
      main: defaultColors.mainColour,
      light: defaultColors.mainLightenedColour,
      dark: defaultColors.mainDarkenedColour,
      extraDark: defaultColors.mainExtraDarkenedColour,
      contrastText: defaultColors.mainColourText
    },
    secondary: { main: '#fff', contrastText: '#005596', light: '#f3f3f4' }
  },
  components: {
    // default props have changed in mui v5, so set it back to the old default
    MuiTextField: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },

    MuiFormLabel: {
      styleOverrides: {
        asterisk: ({ theme, ownerState }) => ({
          color: !ownerState.disabled ? theme.palette.error.main : theme.palette.action.disabled,
          [`& .${formLabelClasses.error}`]: {
            color: theme.palette.error.main
          }
        })
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary'
      },
      styleOverrides: {
        textPrimary: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.primary.dark,
            backgroundColor: 'inherit'
          }
        }),
        /**
         * When a contained button is used as a Link component, the text colour turns blue when you hover over it.
         * This is due to SemanticUI and will fix itself once it has been removed, but until then we have to override the styling here to
         * make sure the button text stays white.
         */
        containedPrimary: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.common.white
          }
        }),
        root: ({ ownerState, theme }) => {
          // extend color="muted" buttons to still show visible text
          if (ownerState.color === 'muted') {
            return {
              color: theme.palette.muted.contrastText,
              backgroundColor: '#fff',
              '&:hover': { backgroundColor: theme.palette.muted.backgroundHighlight }
            }
          }
          return {}
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          [`&.${stepLabelClasses.alternativeLabel}`]: {
            marginTop: theme.spacing(1)
          }
        })
      }
    },
    MuiDivider: {
      styleOverrides: {
        wrapperVertical: {
          paddingBottom: 0,
          paddingTop: 0
        }
      }
    }
  }
}
