export interface APIResponse<TEntity> {
  Data: TEntity
}

export interface APIResponseWithPagination<TEntity> {
  Data: TEntity
  Paging?: IPaging
}

export type GETResponse<TEntity> = APIResponse<TEntity>
export type GETResponseWithPagination<TEntity> = APIResponseWithPagination<TEntity>

export type GETArrayResponse<TEntity> = APIResponse<TEntity[]>

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
export type POSTRequestBody<TEntity, TRequiredKeys extends keyof TEntity> = Required<Pick<TEntity, TRequiredKeys>> &
  Partial<Omit<TEntity, TRequiredKeys>>

export type PUTRequestBody<TEntity> = Partial<TEntity>

export interface NoContent {}

export enum DeleteRejection {
  Activity = 'activity',
  ChecklistOpenTask = 'checklist_open_task',
  Employee = 'employee',
  FinishedAPK = 'finished_apk',
  InvoiceSequenceUsed = 'invoice_sequence_used',
  IsWalkInAccount = 'is_walk_in_account',
  IsInternalAccount = 'is_internal_account',
  LabourRateUsedAsSetting = 'labour_rate_used_as_setting',
  Ledger = 'ledger',
  OrderAccount = 'order_account',
  Partgroup = 'partgroup',
  PartstockNotZero = 'partstock_not_zero',
  ProductGroup = 'product_group',
  Sundries = 'sundry',
  ThemeUsedAsDefault = 'theme_used_as_default',
  UsedByPart = 'part',
  Vehicle = 'vehicle',
  WholesalerAccountUsedAsDefault = 'wholesaler_account_used_as_default',
  Workorder = 'workorder',
  WorkorderLine = 'workorder_line',
  ROBWorkorder = 'rob_workorder',
  InventoryVehicle = 'inventory_vehicle',
  InventoryVehicleTransaction = 'inventory_vehicle_transaction',
  InventoryVehicleQuotation = 'inventory_vehicle_quotation',
  InventoryVehicleInvoice = 'inventory_vehicle_transaction_invoice',
  WorkorderInvoicePayment = 'workorder_invoice_payment',
  CourtesyVehicle = 'courtesy_vehicle',
  RentalVehicle = 'rental_vehicle',
  RentalContract = 'rental_contract',
  LabourRate = 'labour_rate',
  RevenueExternalPartDefault = 'revenue_external_part_default',
  WorkorderLineOrderedAndMissingDeleteReason = 'workorder_line_ordered_and_missing_delete_reason',
  WorkorderLineAccepted = 'workorder_line_accepted',
  WorkorderLinePending = 'workorder_line_pending',
  WorkstationAlreadyPlanned = 'workstation_already_planned',
  PaymentMethodRouting = 'payment_method_routing',
  VehicleWarrantyPeriod = 'vehicle_warranty_period',
  ExpenseOf = 'expense_of',
  RdwProvider = 'rdw_provider',
  TyresInStorage = 'tyres_in_storage',
  ActiveSubscription = 'active_subscription'
}

export interface IRejection {
  Type: DeleteRejection
  UUID: string
  IsOtherShop: boolean
}

export enum DeleteWarning {
  Account = 'account',
  Appointment = 'appointment',
  FinishedAPK = 'finished_apk',
  Ledgers = 'ledgers',
  Theme = 'theme',
  UnfinishedAPK = 'unfinished_apk',
  HasWorkorderLines = 'has_workorder_lines',
  HasOrderedLines = 'has_ordered_lines',
  HasAcceptedLines = 'has_accepted_lines',
  HasPendingLines = 'has_pending_lines',
  HasSmartAlert = 'has_smart_alert'
}

export interface IWarning {
  Type: DeleteWarning
  UUID: string
  IsOtherShop: boolean
}

export interface IDefaultDeleteResponse {
  Rejections: IRejection[]
  Warnings: IWarning[]
}

export type DELETEResponse<TResponse = IDefaultDeleteResponse> = APIResponse<TResponse>

export type UUIDResponse = APIResponse<{ UUID: string }>

export type ToStringStructure<TEntity> = { [TProperty in keyof TEntity]: { [TNestedProperty in keyof TEntity[TProperty]]: string } }

interface ListStructure<TEntity> {
  Structure: ToStringStructure<TEntity>
}

interface IFieldParams {
  Field: string
  Model: string
}

export enum SortDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
  None = ''
}

interface IOrderByParams {
  Direction: SortDirection
  FieldName: string
  Index: number
  Model: string
}

interface IPaging {
  Fields: IFieldParams[] | null
  OrderBy: IOrderByParams[] | null
  Page: number
  PerPage: number
  Total: number
  TotalPages: number
}

interface ListPaging {
  Paging: IPaging
}

export interface IPagination {
  Page?: number
  PerPage?: number
  Total?: number
  TotalPages?: number
}

export enum Pagination {
  Page = 'Page',
  PerPage = 'PerPage',
  Total = 'Total',
  TotalPages = 'TotalPages'
}

export interface PaginatedResponse<TEntity> extends APIResponse<TEntity[]>, ListStructure<TEntity>, ListPaging {}
