import { useCallback, useMemo, useState } from 'react'

export interface useOpenOnFocusInputProps {
  openOnFocus?: boolean // custom extension to open the Popover modal when focusing the field
}

interface IProps {
  open?: boolean
  [key: string]: any
}

/**
 * useOpenOnFocus can be to open datepicker popovers automatically when the field is selected.
 * The code is somewhat based on https://stackoverflow.com/a/61282128/1203738
 *
 * On first click, the popover is opened and focuses. keyboard can be used to select a different date.
 * on second click, popover is closed and foucs transfers to the actual textbox. keyboard can be used to change date
 *
 * @returns a set of props that can be spread into the component
 * when false is passed, the props will be empty, spreading them will not have any effect.
 */
const useOpenOnFocus = (openOnFocus: boolean, props: IProps) => {
  // the component becomes controlled
  const [isOpen, setIsOpen] = useState(props.open || false)
  const onClose = useCallback(() => {
    setIsOpen(false)
    props?.onClose?.()
  }, [setIsOpen])

  const InputProps = useMemo(
    () => ({
      onClick: () => {
        setIsOpen(true)
      }
    }),
    [setIsOpen]
  )

  const OpenPickerButtonProps = useMemo(() => ({ onClick: () => setIsOpen(true) }), [setIsOpen])
  return useMemo(() => {
    if (openOnFocus) {
      // make controlled component
      return { open: isOpen, InputProps, OpenPickerButtonProps, onClose }
    }
    // return noop props
    return {}
  }, [openOnFocus, isOpen, InputProps, OpenPickerButtonProps, onClose])
}

export default useOpenOnFocus
