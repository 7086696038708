import { Reducer, combineReducers } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'

import { LOAD_INVOICE_SEQUENCE_SUCCESS, TEST_INVOICE_SEQUENCE_SUCCESS } from 'app/settings/state/InvoiceSequences/types'
import { InvoiceSequence } from '@cbgms/api/modules/invoicesequences'

type InvoiceSequenceState = Readonly<InvoiceSequence> | null

const defaultDetailState: InvoiceSequenceState = null

type DetailInvoiceSequenceBehavior<S = InvoiceSequenceState> = Record<typeof LOAD_INVOICE_SEQUENCE_SUCCESS, Reducer<S, any>>

const detail = createReducer<InvoiceSequenceState, DetailInvoiceSequenceBehavior>(defaultDetailState, {
  [LOAD_INVOICE_SEQUENCE_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

type TestInvoiceSequenceBehavior<S = string> = Record<typeof TEST_INVOICE_SEQUENCE_SUCCESS, Reducer<S, any>>

const example = createReducer<string, TestInvoiceSequenceBehavior>('', {
  [TEST_INVOICE_SEQUENCE_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export interface IInvoiceSequenceReducerState {
  detail: InvoiceSequenceState
  example: string
}

export default combineReducers<IInvoiceSequenceReducerState>({
  detail,
  example
})
