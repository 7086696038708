import { IFilter, ProductFilterValueObject } from '@cbgms/api'
import mapValues from 'lodash/fp/mapValues'
import omit from 'lodash/fp/omit'

export const removeDuplicateFilters = (currentFilters: ProductFilterValueObject, payload: IFilter[]) => {
  // Remove filter categories that don't exist anymore
  let updatedFilters = omit(
    Object.keys(currentFilters).filter(key => !payload.find((filter: IFilter) => filter.Key === key)),
    currentFilters
  )

  // Check in every filter category if it contains a filtervalue that matches the ones we have currently selected
  // Then remove the ones that don't exist anymore
  updatedFilters = mapValues(
    filters => filters.filter(selectedFilter => payload.some(filter => !!filter.Values.find(value => value.Value === selectedFilter))),
    updatedFilters
  )

  return updatedFilters || {}
}
