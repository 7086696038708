export const formatBytes = (bytes: any, decimals?: number) => {
  if (bytes === 0) return '0 Bytes'
  const kilobyteSize = 1024
  const amountOfDecimals = decimals || 0
  const decimalsToAdd = amountOfDecimals <= 0 ? 0 : amountOfDecimals || 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const roundedValue = Math.floor(Math.log(bytes) / Math.log(kilobyteSize))
  const valueToAdd = bytes / kilobyteSize ** roundedValue
  return `${parseFloat(valueToAdd.toFixed(decimalsToAdd))} ${sizes[roundedValue]}`
}
