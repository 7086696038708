// [ISO-639]-[ISO-3166] as in [language]-[country]
export enum Locales {
  development = 'development',
  fr_BE = 'fr-BE',
  nl_BE = 'nl-BE',
  de_CH = 'de-CH',
  fr_CH = 'fr-CH',
  it_CH = 'it-CH',
  rm_CH = 'rm-CH',
  cs_CZ = 'cs-CZ',
  de_DE = 'de-DE',
  fr_FR = 'fr-FR',
  en_GB = 'en-GB',
  it_IT = 'it-IT',
  de_LU = 'de-LU',
  fr_LU = 'fr-LU',
  lb_LU = 'lb-LU',
  nl_NL = 'nl-NL',
  pl_PL = 'pl-PL'
}
