import { faker } from '@faker-js/faker/locale/en'
import { MockFn } from '../../mock'
import { SmartAlert } from './smart-alerts'
import { SmartAlertNotificationType } from '@carsys/enums/smart-alert-notification-type'
import { SmartAlertStatus } from '@carsys/enums/smart-alert-status'
import { NotificationLinkedEntityType } from '@carsys/enums/smart-alert-linked-entity-type'

export const mockSmartAlert: MockFn<SmartAlert> = (extend?) => ({
  UUID: faker.datatype.uuid(),
  Type: SmartAlertNotificationType.MotExpiry,
  Status: SmartAlertStatus.Open,
  EventOccursAt: '2023-05-28',
  CreatedAt: new Date(Date.UTC(2023, 4, 22, 16, 41, 22, 123)).toISOString(),
  UpdatedAt: new Date(Date.UTC(2023, 4, 22, 16, 41, 22, 123)).toISOString(),
  LinkedEntityType: NotificationLinkedEntityType.Vehicle,
  LinkedEnitityUUID: faker.datatype.uuid(),
  Comments: [
    {
      CreatedByEmployeeUUID: faker.datatype.uuid(),
      CreatedAt: new Date(Date.UTC(2023, 4, 22, 16, 41, 22, 123)).toISOString(),
      CreatedByEmployeeName: faker.name.firstName(),
      Comment: faker.lorem.paragraph()
    }
  ],
  ...extend
})
