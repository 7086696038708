import { NoContent } from '@cbgms/api/helpers'
import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'
import { SmartAlertsSettings, SmartAlertsSettingsResponse } from './smart-alerts-setting'
import { SmartAlertResponse } from './smart-alerts'

const smartAlertsRoute = '/modules/smart-alerts'

export const updateSmartAlerts = () =>
  createAsyncApiAction<NoContent>('gms', {
    method: 'PUT',
    url: `${smartAlertsRoute}/refresh`
  })

export const completeSmartAlert = (smartAlertUUID: string) =>
  createAsyncApiAction<NoContent>('gms', {
    method: 'PUT',
    url: `${smartAlertsRoute}/${smartAlertUUID}/complete`
  })

export const getSmartAlertsSettings = () =>
  createAsyncApiAction<SmartAlertsSettingsResponse>('gms', {
    method: 'GET',
    url: `${smartAlertsRoute}/settings`
  })

export const postSmartAlertComment = (smartAlertUUID: string, comment: string) =>
  createAsyncApiAction<SmartAlertResponse>('gms', {
    method: 'POST',
    url: `${smartAlertsRoute}/${smartAlertUUID}/comments`,
    data: { Comment: comment }
  })

export const updateSmartAlertsSettings = (data: SmartAlertsSettings) =>
  createAsyncApiAction<SmartAlertsSettingsResponse>('gms', {
    method: 'PUT',
    url: `${smartAlertsRoute}/settings`,
    data
  })
