export const LOAD_CALENDAR = 'LOAD_CALENDAR'
export const LOAD_CALENDAR_SUCCESS = 'LOAD_CALENDAR_SUCCESS'
export const LOAD_CALENDAR_FAILED = 'LOAD_CALENDAR_FAILED'

export const GET_APPOINTMENTS = 'GET_APPOINTMENTS_'
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS'
export const GET_APPOINTMENTS_FAILED = 'GET_APPOINTMENTS_FAILED'

export const RESET_CALENDAR = 'RESET_CALENDAR'

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT'
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS'
export const CREATE_APPOINTMENT_FAILED = 'CREATE_APPOINTMENT_FAILED'

export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT'
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS'
export const UPDATE_APPOINTMENT_FAILED = 'UPDATE_APPOINTMENT_FAILED'

export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT'
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS'
export const DELETE_APPOINTMENT_FAILED = 'DELETE_APPOINTMENT_FAILED'

export const LOAD_CALENDAR_SETTINGS = 'LOAD_CALENDAR_SETTINGS'
export const LOAD_CALENDAR_SETTINGS_SUCCESS = 'LOAD_CALENDAR_SETTINGS_SUCCESS'
export const LOAD_CALENDAR_SETTINGS_FAILED = 'LOAD_CALENDAR_SETTINGS_FAILURE'

export const UPDATE_CALENDAR_SETTINGS = 'UPDATE_CALENDARS_SETTINGS'
export const UPDATE_CALENDAR_SETTINGS_SUCCESS = 'UPDATE_CALENDARS_SETTINGS_SUCCESS'
export const UPDATE_CALENDAR_SETTING_FAILED = 'UPDATE_CALENDARS_SETTINGS_FAILURE'
