import { APIResponse, GETResponse, POSTRequestBody, PUTRequestBody, PaginatedResponse } from '@cbgms/api'

export interface SharedClockingItem {
  Name: string
  UUID: string
}

// Created this construction so these fields
// don't show up in the DataGrid as possible columns.
export interface IBaseClockingItem extends SharedClockingItem {
  UrlToPage?: string
  Brand?: string
}

export enum ClockingItemType {
  Generic = 'generic',
  WorkOrder = 'workorder'
}

export interface ModifyClockingItem {
  Name: string
  IsActive: boolean
  ClockingItemType: ClockingItemType
}

export interface IClockingItem extends SharedClockingItem, ModifyClockingItem {}

export interface ClockingItemRow {
  ClockingItem: IClockingItem
}

export type GETClockingItemResponse = GETResponse<IClockingItem>

export type GETClockingItemsResponse = PaginatedResponse<GETClockingItemsResponseRow>

export interface GETClockingItemsResponseRow {
  ClockingItem: IClockingItem
}

export type POSTClockingItemBody = POSTRequestBody<IClockingItem, 'Name' | 'IsActive'>

export type POSTClockingItemResponse = APIResponse<{ UUID: string }>

export type PUTClockingItemBody = PUTRequestBody<IClockingItem>

export type PUTClockingItemResponse = APIResponse<{ UUID: string }>
