import { RefObject, useEffect, useState } from 'react'

const getDimensions = (ref: RefObject<HTMLElement | undefined> | null) => ({
  width: ref?.current?.offsetWidth || 0,
  height: ref?.current?.offsetHeight || 0
})

/**
 * useContainerDimensions will return the size of the container element.
 * and recalculates on window resizes.
 * @param myRef is the ref of the container you want to measure
 * @returns width and height in px
 */
export const useContainerDimensions = (ref: RefObject<HTMLElement | undefined> | null) => {
  const [dimensions, setDimensions] = useState(getDimensions(ref))

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions(ref))
    }

    if (ref?.current) {
      setDimensions(getDimensions(ref))
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  return dimensions
}

export default useContainerDimensions
