export const GET_PRODUCT_GROUPS = 'GET_PRODUCT_GROUPS'
export const GET_PRODUCT_GROUPS_SUCCESS = 'GET_PRODUCT_GROUPS_SUCCESS'
export const GET_PRODUCT_GROUPS_FAILED = 'GET_PRODUCT_GROUPS_FAILED'
export const RESET_PRODUCT_GROUPS = 'RESET_PRODUCT_GROUPS'

export const GET_PRODUCT_GROUPS_SEARCH = 'GET_PRODUCT_GROUPS_SEARCH'
export const GET_PRODUCT_GROUPS_SEARCH_SUCCESS = 'GET_PRODUCT_GROUPS_SEARCH_SUCCESS'
export const GET_PRODUCT_GROUPS_SEARCH_FAILED = 'GET_PRODUCT_GROUPS_SEARCH_FAILED'

export const GET_SEARCH_ATTRIBUTES_BY_PRODUCT_GROUPS = 'GET_SEARCH_ATTRIBUTES_BY_PRODUCT_GROUPS'
export const GET_SEARCH_ATTRIBUTES_BY_PRODUCT_GROUPS_SUCCESS = 'GET_SEARCH_ATTRIBUTES_BY_PRODUCT_GROUPS_SUCCESS'
export const GET_SEARCH_ATTRIBUTES_BY_PRODUCT_GROUPS_FAILED = 'GET_SEARCH_ATTRIBUTES_BY_PRODUCT_GROUPS_FAILED'

export const RESET_PRODUCT_STATE = 'RESET_PRODUCT_STATE'
export const RESET_PRODUCT_STATE_SUCCESS = 'RESET_PRODUCT_STATE_SUCCESS'
export const RESET_PRODUCT_STATE_FAILED = 'RESET_PRODUCT_STATE_FAILED'

export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED'
export const RESET_PRODUCT = 'RESET_PRODUCT'

export const SET_PRODUCT_FILTER = 'SET_PRODUCT_FILTER'
export const VALIDATE_PRODUCT_FILTER = 'VALIDATE_PRODUCT_FILTER'
export const RESET_PRODUCT_FILTER = 'RESET_PRODUCT_FILTER'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED'

export const GET_PRODUCTS_BY_ATTRIBUTES = 'GET_PRODUCTS_BY_ATTRIBUTES'
export const GET_PRODUCTS_BY_ATTRIBUTES_SUCCESS = 'GET_PRODUCTS_BY_ATTRIBUTES_SUCCESS'
export const GET_PRODUCTS_BY_ATTRIBUTES_FAILED = 'GET_PRODUCTS_BY_ATTRIBUTES_FAILED'

export const RESET_PRODUCTS = 'RESET_PRODUCTS'

export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS'
export const SEARCH_PRODUCTS_FAILED = 'SEARCH_PRODUCTS_FAILED'

export const GET_GENERIC_PRODUCTS = 'GET_GENERIC_PRODUCTS'
export const GET_GENERIC_PRODUCTS_SUCCESS = 'GET_GENERIC_PRODUCTS_SUCCESS'
export const GET_GENERIC_PRODUCTS_FAILED = 'GET_GENERIC_PRODUCTS_FAILED'

export const GET_PRODUCT_AVAILABILITY = 'GET_PRODUCT_AVAILABILITY'
export const GET_PRODUCT_AVAILABILITY_SUCCESS = 'GET_PRODUCT_AVAILABILITY_SUCCESS'
export const GET_PRODUCT_AVAILABILITY_FAILED = 'GET_PRODUCT_AVAILABILITY_FAILED'

export const GET_PRODUCT_VEHICLE_COMPATIBILITY = 'GET_PRODUCT_VEHICLE_COMPATIBILITY'
export const GET_PRODUCT_VEHICLE_COMPATIBILITY_SUCCESS = 'GET_PRODUCT_VEHICLE_COMPATIBILITY_SUCCESS'
export const GET_PRODUCT_VEHICLE_COMPATIBILITY_FAILED = 'GET_PRODUCT_VEHICLE_COMPATIBILITY_FAILED'

export const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS'
export const GET_RELATED_PRODUCTS_SUCCESS = 'GET_RELATED_PRODUCTS_SUCCESS'
export const GET_RELATED_PRODUCTS_FAILED = 'GET_RELATED_PRODUCTS_FAILED'
