import * as types from '../types'
import { AnyAction } from 'redux'
import { decrementSupplierOrderPart, getSupplierOrderParts, incrementSupplierOrderPart } from '../selectors'

export interface ISupplierOrderPartsReducerState {
  UUID: string
  WorkorderUUID: string
  ParentLineUUID: string
  UseParentQuantity: boolean
  VatType: string
  Quantity: number
  Discount: number
  DiscountIsAmount: boolean
  UseSalesPriceCustom: boolean
  SalesPriceCustom: number
  UseCodeCustom: boolean
  CodeCustom: string
  UseDescriptionCustom: boolean
  DescriptionCustom: string
  SortOrder: number
  LineStatus: string
  LineType: string
  LineTypeUUID: string
  OrderAccountUUID: string
  SupplierAccountUUID: string
  SupplierProductID: string
  SupplierProductGroupID: string
  ForeignType: string
  ForeignID: string
  GenericArticleID: string
  GenericArticleDescription: string
  CreatedAt: string
  UpdatedAt: string
  LineCode: string
  LineDescription: string
  LineUnitPrice: number
  LinePriceVatExcl: number
  LineDiscountAmount: number
  LineDiscountPercentage: number
  OrderStatus: string
  OrderedQuantity: number
  Error?: string
}

export const workorderSupplierOrderPartsReducer = (
  state: { PartLines: ISupplierOrderPartsReducerState[] } = { PartLines: [] },
  action: AnyAction
) => {
  switch (action.type) {
    case types.GET_SUPPLIER_ORDER_PARTS_SUCCESS:
      return { PartLines: getSupplierOrderParts(action.payload) }
    case types.INCREMENT_SUPPLIER_ORDER_PART:
      return { PartLines: incrementSupplierOrderPart(state, action.payload) }
    case types.DECREMENT_SUPPLIER_ORDER_PART:
      return { PartLines: decrementSupplierOrderPart(state, action.payload) }
    case types.RESET_SUPPLIER_ORDER_PART:
      return { PartLines: [] }
    // this is the call that is performed for step 2, when this fails it can contain errors about specific product lines
    case types.GET_SUPPLIER_ORDER_PARAMS_FAILED:
      return addErrorsToPartLines(state, action.payload)
    default:
      return state
  }
}

const addErrorsToPartLines = (state: { PartLines: ISupplierOrderPartsReducerState[] }, action: any) => {
  if (action.data.Error !== 'ErrHasOrderLinesError') {
    return state
  }

  return {
    PartLines: state.PartLines.map(partLine => ({
      ...partLine,
      Error: action.data.Data.OrderLines.find((orderLine: any) => orderLine.ProductID === partLine.SupplierProductID)?.Error
    }))
  }
}
