import { IShop } from '@cbgms/api'
import { useSelector } from 'react-redux'
import { IRootReducerState } from 'state/rootReducer'

export interface LogShopData {
  shopInfo: IShop
}

export const useShopLoggingData = () => {
  return useSelector<IRootReducerState, IShop>(state => state.auth.shop)
}
