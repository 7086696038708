import { DefaultTheme } from '@mui/system'

export const bgDefault = '#e9f5fe'
export const txDefault = '#03a9f4'
export const bgFast = '#edf7ed'
export const txFast = '#4caf50'
export const bgMedium = '#fff4e5'
export const txMedium = '#ff9800'
export const bgSlow = '#e9f5fe'
export const txSlow = '#03a9f4'
export const bgSalesAgent = (theme: DefaultTheme) => theme.palette.muted.backgroundHighlight
export const txSalesAgent = (theme: DefaultTheme) => theme.palette.muted.dark
export const bgUnknown = (theme: DefaultTheme) => theme.palette.muted.backgroundHighlight
export const txUnknown = (theme: DefaultTheme) => theme.palette.muted.dark
