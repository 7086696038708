export enum NotificationStatus {
  All = '',
  Processing = 'PROCESSING',
  Retry = 'RETRY',
  Failure = 'FAILURE',
  Bounce = 'BOUNCE',
  Delivery = 'DELIVERY',
  Complaint = 'COMPLAINT'
}

export enum ChannelType {
  Email = 'EMAIL',
  SMS = 'SMS',
  Push = 'PUSH'
}

export enum NotificationMessageType {
  MOT = 'mot',
  ServiceReminder = 'service-reminder',
  AppointmentReminder = 'appointment-reminder',
  WinterTyreChangeReminder = 'tyre-change-winter',
  SummerTyreChangeReminder = 'tyre-change-summer'
}

export enum NotificationChannelType {
  Email = 'email',
  SMS = 'sms'
}

export enum Weekday {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday'
}

export enum PeriodUnit {
  days = 'day',
  weeks = 'week',
  months = 'month',
  years = 'year'
}

export enum Frequency {
  daily = 'daily',
  monthly = 'monthly',
  weekly = 'weekly'
}

export enum QueueStatus {
  unsent = 'unsent',
  sent = 'sent',
  deleted = 'deleted',
  replaced = 'replaced'
}
