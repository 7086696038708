import moment from 'moment'
import { apiFormat } from './apiFormat'
import { formatDateTime } from './formatDateTime'
import { format } from '@cbgms/base/utils/datetime/dateFormat'
import { TFunction } from 'i18next'

export const formatDeliveryTime = (deliveryTime: string) => {
  if (moment(deliveryTime, apiFormat.iso).year() <= 1) {
    return false
  }

  // Display date:
  // - longer than (or equal to) a week : long format: Thu, Sep 4, 1986 8:30 PM
  // - within one week: shorter format : Monday 13:30
  return formatDateTime(deliveryTime, moment().diff(deliveryTime, 'week') < 0 ? 'llll' : 'dddd HH:mm')
}

export const formatRelativeDeliveryTime = (t: TFunction, deliveryTime: string) => {
  if (!deliveryTime || !moment(deliveryTime, apiFormat.iso).isValid() || moment(deliveryTime, apiFormat.iso).year() <= 1) {
    return false
  }

  // Display date and time, relative to current date :
  // - longer than (or equal to) a week : long format: Thu, Sep 4, 1986 8:30 PM
  // - within one week: shorter format : Monday 13:30
  // - within a day: Tomorrow 13:30
  // - same day: Today 13:30
  return moment(deliveryTime, apiFormat.iso).calendar(null, {
    sameDay: `[${t('AbsenceSchedule.Today')}] ${format.time}`,
    nextDay: `[${t('AbsenceSchedule.Tomorrow')}] ${format.time}`,
    nextWeek: `${format.weekday} ${format.time}`,
    lastDay: `[${t('AbsenceSchedule.Yesterday')}] ${format.time}`,
    lastWeek: `[${t('global.last')}] dddd ${format.time}`,
    sameElse: `${format.date} ${format.time}`
  })
}
