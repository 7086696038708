import { VariantType } from 'notistack'
import CustomSnackBar from './CustomSnackBar'
import { Undo } from 'notifier/types'

type CustomSnackBarComponentsType = {
  [variant in VariantType]?: React.JSXElementConstructor<any>
}

export const CustomSnackBarComponents: CustomSnackBarComponentsType = {
  success: CustomSnackBar,
  warning: CustomSnackBar,
  error: CustomSnackBar,
  info: CustomSnackBar
}

declare module 'notistack' {
  interface VariantOverrides {
    success: {
      title?: string
      undo?: Undo | undefined
    }
    warning: {
      title?: string
      undo?: Undo | undefined
    }
    error: {
      title?: string
      undo?: Undo | undefined
    }
    info: {
      title?: string
      undo?: Undo | undefined
    }
  }
}
