import { Reducer, combineReducers } from 'redux'
import { IExportedCSVFile, IExportedCSVFileData } from '@cbgms/api'
import { createReducer } from '@cbgms/base/utils/redux'
import { LOAD_CSV_EXPORTS_SUCCESS, LOAD_CSV_EXPORT_SUCCESS, RESET_CSV_EXPORT_OPTIONS } from 'app/settings/state/Administrations/types'
import { EmptyUUID } from '@cbgms/base/utils/uuid'

export type ExportCSVListState = ReadonlyArray<IExportedCSVFile>

const defaultListState: ExportCSVListState = []

type ExportCSVListBehavior<S = ExportCSVListState> = Record<typeof LOAD_CSV_EXPORTS_SUCCESS, Reducer<S, any>>

const list = createReducer<ExportCSVListState, ExportCSVListBehavior>(defaultListState, {
  [LOAD_CSV_EXPORTS_SUCCESS]: (_, { payload }) => {
    return payload.data.Data.Objects.map((object: IExportedCSVFile) => {
      return {
        UUID: object.UUID,
        AdministrationSoftware: object.AdministrationSoftware,
        AdministrationDescription: object.AdministrationDescription,
        ExportName: object.ExportName,
        Files: object.Files.map((file: IExportedCSVFileData) => {
          return {
            ExportType: file.ExportType,
            Filename: file.Filename,
            Filesize: file.Filesize
          }
        }),
        ExportedAt: object.ExportedAt
      }
    })
  }
})

export type ExportCSVDetailState = Readonly<Partial<IExportedCSVFile>>

const defaultDetailState: ExportCSVDetailState = {
  UUID: EmptyUUID,
  AdministrationSoftware: '',
  AdministrationDescription: '',
  ExportName: '',
  Files: [{ Filename: '', Filesize: '', ExportType: '' }],
  ExportedAt: ''
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type ExportCSVBehavior<S = ExportCSVDetailState> = Record<typeof LOAD_CSV_EXPORT_SUCCESS, Reducer<S, any>> &
  Record<typeof RESET_CSV_EXPORT_OPTIONS, Reducer<S, any>>

const detail = createReducer<ExportCSVDetailState, ExportCSVBehavior>(defaultDetailState, {
  [LOAD_CSV_EXPORT_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [RESET_CSV_EXPORT_OPTIONS]: () => defaultDetailState
})

export interface IExportCSVReducerState {
  list: typeof defaultListState
  detail: typeof defaultDetailState
}

export default combineReducers<IExportCSVReducerState>({
  list,
  detail
})
