export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts && parts.length < 2) {
    return undefined
  } else {
    const cookie = parts.pop()!.split(';').shift()
    return cookie
  }
}
