import { Reducer, combineReducers } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { ICmsContent } from '@cbgms/api/modules/cms'
import moment from 'moment'

export type BannersReducerState = Readonly<{
  content: { [key: string]: ICmsContent }
  retrievedAt: string
  orderAccountUUID: string
  locale: string
}>
const defaultBannersState: BannersReducerState = {
  retrievedAt: '',
  orderAccountUUID: '',
  locale: '',
  content: {}
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type BannersReducerBehavior<S> = Record<typeof types.LOAD_CMS_BANNERS_SUCCESS, Reducer<S, any>> &
  Record<typeof types.LOAD_CMS_BANNERS_FAILED, Reducer<S, any>>

const banners = createReducer<BannersReducerState, BannersReducerBehavior<BannersReducerState>>(defaultBannersState, {
  [types.LOAD_CMS_BANNERS_SUCCESS]: (_, { payload, request }) => {
    const newState = { content: {}, retrievedAt: moment(), orderAccountUUID: request.orderAccountUUID, locale: request.locale } as any
    payload.data.Data.forEach((banner: ICmsContent) => {
      // name and id are set to the same enum value for all banners except drupal
      // there can be more drupal banners than the default 3
      // we generate a new name based on index in the backend instead of using the enumvalue
      newState.content[banner.Name] = banner
    })
    return newState
  },
  [types.LOAD_CMS_BANNERS_FAILED]: () => {
    return defaultBannersState
  }
})

export interface ICmsReducerState {
  banners: BannersReducerState
}

export type CmsReducerState = Readonly<ICmsReducerState>

const cms = combineReducers<CmsReducerState>({
  banners
})

export default cms
