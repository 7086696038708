import { createReducer } from '@cbgms/base/utils/redux'
import { combineReducers } from 'redux'
import * as typeConsts from './types'
import { IDay } from '@cbgms/api'

interface CalendarState {
  Days: IDay[]
  NextDay: string
  PreviousDay: string
  isLoading: boolean
}

const defaultState: CalendarState = { Days: [], NextDay: '', PreviousDay: '', isLoading: false }

const data = createReducer(defaultState, {
  [typeConsts.LOAD_CALENDAR]: state => {
    return { ...(state || defaultState), isLoading: true }
  },
  [typeConsts.LOAD_CALENDAR_SUCCESS]: (_, { payload }) => {
    return { ...payload.data.Data, isLoading: false }
  },
  [typeConsts.LOAD_CALENDAR_FAILED]: () => {
    return defaultState
  },
  [typeConsts.RESET_CALENDAR]: () => {
    return defaultState
  }
})

const settings = createReducer(
  {},
  {
    [typeConsts.LOAD_CALENDAR_SETTINGS_SUCCESS]: (_, { payload }) => {
      return payload.data.Data
    }
  }
)

const calendar = combineReducers({
  data,
  settings
})

export default calendar
