import { Reducer } from 'redux'

import { ISmsHistoryItem } from '@cbgms/api/modules/sms'
import { createReducer } from '@cbgms/base/utils/redux'

import * as types from './types'

interface ISmsHistoryReducerState {
  detail: ISmsHistoryItem | null
}

export type SmsHistoryReducerState = Readonly<ISmsHistoryReducerState>

const defaultState: SmsHistoryReducerState = {
  detail: null
}

type SmsHistoryReducerBehavior<S> = Record<typeof types.LOAD_SMS_HISTORY_ITEM_SUCCESS, Reducer<S, any>>

const SmsHistory = createReducer<SmsHistoryReducerState, SmsHistoryReducerBehavior<SmsHistoryReducerState>>(defaultState, {
  [types.LOAD_SMS_HISTORY_ITEM_SUCCESS]: (_, { payload }) => ({
    detail: payload.data.Data
  })
})

export default SmsHistory
