import React from 'react'
import useApplicatioName from 'utils/use-application-name'

export const useSetFavicon = () => {
  const applicationName = useApplicatioName()
  React.useEffect(() => {
    const fav16 = document.getElementById('favicon-16')
    const fav32 = document.getElementById('favicon-32')
    const faviconFolder = '/assets/favicons'

    if (applicationName === 'carsys') {
      fav16?.setAttribute('href', `${faviconFolder}/favicon-carsys-16x16.png`)
      fav32?.setAttribute('href', `${faviconFolder}/favicon-carsys-32x32.png`)
    } else if (applicationName === 'carcat') {
      fav16?.setAttribute('href', `${faviconFolder}/favicon-carcat-16x16.png`)
      fav32?.setAttribute('href', `${faviconFolder}/favicon-carcat-32x32.png`)
    }
  }, [applicationName])
}
