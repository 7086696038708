import { AnyAction, Middleware } from 'redux'

import { POSTChallengeBody, POSTChallengeResponse } from '@cbgms/api'
import { CognitoChallenge } from '@carsys/enums/cognito-challenge'
import { AsyncAction } from '@cbgms/base/redux/async-action'

interface ChallengeHandler {
  (action: AnyAction): {
    type: CognitoChallenge
    payload: any
  }
}

interface ChallengeHandlers {
  [CognitoChallenge.AdminNoSecureRemotePasswordAuth]?: never
  [CognitoChallenge.CustomChallenge]?: never
  [CognitoChallenge.DevicePasswordVerifier]?: never
  [CognitoChallenge.DeviceSecureRemotePasswordAuth]?: never
  [CognitoChallenge.MultiFactorAuthenticationSetup]?: never
  [CognitoChallenge.NewPasswordRequired]: ChallengeHandler
  [CognitoChallenge.PasswordVerifier]?: never
  [CognitoChallenge.SMSMultiFactorAuthentication]?: never
  [CognitoChallenge.SelectMultiFactorAuthenticationType]?: never
  [CognitoChallenge.SoftwareTokenMultiFactorAuthentication]?: never
}

const RESPOND_TO_CHALLENGE = 'RESPOND_TO_CHALLENGE'
const RESPOND_TO_CHALLENGE_SUCCESS = 'RESPOND_TO_CHALLENGE_SUCCESS'
const RESPOND_TO_CHALLENGE_FAILED = 'RESPOND_TO_CHALLENGE_FAILED'

const respondToChallenge = (data: POSTChallengeBody) =>
  AsyncAction<POSTChallengeResponse>({
    type: [RESPOND_TO_CHALLENGE, RESPOND_TO_CHALLENGE_SUCCESS, RESPOND_TO_CHALLENGE_FAILED],
    payload: {
      api: 'sso',
      request: {
        method: 'POST',
        url: '/modules/auth/challenge',
        data
      }
    }
  })

export const challenges: ChallengeHandlers = {
  [CognitoChallenge.NewPasswordRequired]: action => {
    const data = action.payload.data.Data
    const request = action.request.request

    const payload = respondToChallenge({
      ChallengeName: data.ChallengeName,
      ChallengeResponses: {
        EMAIL: request.data.EmailAddress
      },
      Session: data.Session
    })

    return { type: CognitoChallenge.NewPasswordRequired, payload }
  }
}

const isCognitChallenge = (challenge: any): challenge is CognitoChallenge => {
  return Object.values(CognitoChallenge).some(cognitoChallenge => cognitoChallenge === challenge)
}

export const challengeMiddleware: Middleware = store => next => action => {
  const challenge = action?.payload?.data?.Data?.ChallengeName

  if (!challenge) {
    return next(action)
  }

  if (!isCognitChallenge(challenge)) {
    return next(action)
  }

  const handler = challenges[challenge]

  if (handler) {
    const challengeResponse = handler(action)

    store.dispatch(challengeResponse)
  }
}
