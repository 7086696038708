import * as types from '../types'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AnyAction } from 'redux'
import { IDeliveryOption, IPaymentOption, ISupplierAddress, SupplierOrderLine } from '@cbgms/api'

export interface ISupplierOrderParams {
  OrderLines: SupplierOrderLine[]
  DeliveryAddress?: ISupplierAddress[]
  PickupAddress?: ISupplierAddress[]
  DeliveryOptions?: IDeliveryOption[]
  PaymentOptions?: IPaymentOption[]
  ExternalPlaceOrderURL: string
  DefaultCustomerOrderReference: string
  orderAccountUUID?: string
}

const defaultSupplierOrderParams: ISupplierOrderParams = {
  OrderLines: [],
  ExternalPlaceOrderURL: '',
  DefaultCustomerOrderReference: ''
}

export const workorderSupplierOrderParamsReducer = persistReducer(
  { key: 'supplierOrderParams', storage },
  (state: ISupplierOrderParams = defaultSupplierOrderParams, action: AnyAction) => {
    switch (action.type) {
      case types.GET_SUPPLIER_ORDER_PARAMS_SUCCESS:
        return { ...action.payload.data?.Data, orderAccountUUID: action.request.orderAccountUUID } as ISupplierOrderParams
      case types.GET_SUPPLIER_ORDER_PARAMS_FAILED:
        // on failure, clear the state, so no old order are shown to the user.
        return { ...defaultSupplierOrderParams, orderAccountUUID: action.request.orderAccountUUID } as ISupplierOrderParams
      case types.DELETE_ORDERPARTLINE:
        // Possible Bug: Might need to re-retrieve the supplier order params. they could change because less lines are requested
        return {
          ...state,
          OrderLines: state.OrderLines.filter(
            (orderline: any) =>
              !(orderline.WorkorderLineUUID === action.payload.uuid && orderline.DeliveryTime === action.payload.deliveryTime)
          )
        }
      default:
        return state
    }
  }
)
