import React, { useEffect } from 'react'
import Inf, { Props } from 'react-infinite-scroll-component'

const InfiniteScroll: React.FC<Props & { isFetching: boolean; scrollableTarget?: string; disableScrollbarPath?: boolean }> = props => {
  // Inf doesn't automatically fetches items till a scrollbar shows up.
  // attempt to fetch page till either a scrollbar shows up or all pages are loaded.
  useEffect(() => {
    if (!props.disableScrollbarPath && props.hasMore && !props.isFetching && props.scrollableTarget) {
      // check if the scrollbar is visible, else attempt to load more items
      const el = document?.getElementById?.(props.scrollableTarget)
      if (el && el.scrollHeight <= el.clientHeight) {
        props.next()
      }
    }
  }, [props.hasMore, props.isFetching])

  return <Inf {...props} />
}

export default InfiniteScroll
