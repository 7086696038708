export const getCountryCodes = (locale?: string) => ({
  method: 'GET',
  url: '/miscellaneous/countrycodes',
  params: { locale }
})

export const getAllCountries = (locale?: string) => ({
  method: 'GET',
  url: '/miscellaneous/countries',
  params: { locale }
})

export const getEnvironmentCountryCodes = () => ({
  method: 'GET',
  url: '/miscellaneous/api-supported-countrycodes'
})
