import { EmployeeStatus, FunctionType, IWorkorderLine, WorkorderLineState } from '@cbgms/api'
import { ICartTotals } from 'app/order/components/Cart/OrderCartContext'

/**
 * IAnalyticsCTX is a collection of context variables pushed to GTM
 */
export interface IAnalyticsCTX {
  ctx: IAnalyticsCTXData
  shop: IAnalyticsShopInfoData
  employee: IAnalyticsEmployeeData
  supplier: IAnalyticsSupplierData
}

/**
 * IAnalyticsEmployeeData contains information about the user
 */
export interface IAnalyticsEmployeeData {
  CreatedAt: string
  DateInService: string
  Functions: FunctionType[]
  MOT: string
  ProfileColour: string
  UUID: string
  EmployeeStatus: EmployeeStatus
  HasAcceptedGarageAdminTermsAndConditions: boolean
  HasAcceptedPortalTermsAndConditions: boolean
  HasAcceptedGMSTermsAndConditions: boolean
}

/**
 * IAnalyticsCTXData contains information about the environment. Like Stage and whitelabel
 */
export interface IAnalyticsCTXData {
  ApplicationName: string
  IsPortal: boolean
  IsGMS: boolean
  IsGarageAdmin: boolean
}

/**
 * IAnalyticsShopInfoData contains information about the shop, like active modules
 */
export interface IAnalyticsShopInfoData {
  CompanyID: string
  ShopID: string
  ShopName: string
  ShopCountry: string
  ModuleGMSEnabled: boolean
  ModulePortalEnabled: boolean
  ModuleIsSupportEnabled: boolean
  ModuleTimeRegistrationEnabled: boolean
  ModuleVehicleSalesEnabled: boolean
  ModuleTyreChangeEnabled: boolean
  ModuleCourtesyVehiclesEnabled: boolean
  ModuleRentalVehiclesEnabled: boolean
  ModuleAddOrderAccount: boolean
  ModuleFleetLeaseEnabled: boolean
  ModuleSwitchDefaultOrderAccount: boolean
  ModuleCRMEnabled: boolean
  ModuleInternalInvoicingEnabled: boolean
  ModuleFleetLeaseWhiteLabelEnabled: boolean
}

/**
 * IAnalyticsSupplierData contains information of the currently active supplier/order-account
 */
export interface IAnalyticsSupplierData {
  SupplierName: string
  OrderSystem: string
  CMSSystem: string
  CMSURL: string
  TicketSystem: string
  Country: string
  SupplierAccountUserID: string
  SupplierActiveServices: string[]
  CatalogType: string
}

export interface Order extends OrderLineCollection {
  totals: ICartTotals
}

export interface OrderLineCollection {
  orderUUID: string
  orderLines: (IWorkorderLine | WorkorderLineState)[]
}

export interface NavigationEvent {
  pathname: string
  search: string
  hash: string
}

export interface EcommerceBase {
  currency: string
  value: number
  coupon?: string
  items: Item[]
}

export interface AddShipping extends EcommerceBase {
  shipping_tier: string
}

export interface AddPayment extends EcommerceBase {
  payment_type: string
}

export interface Purchase extends EcommerceBase {
  transaction_id: string
  tax?: number
  shipping?: string
}

export interface Refund extends EcommerceBase {
  transaction_id: string
  shipping?: string
  tax?: number
}

export interface Promotion {
  creative_name: string
  creative_slot: string
  promotion_id: string
  promotion_name: string
  items: Item[]
}

export interface ListItem {
  item_list_id: string
  item_list_name: string
  items: Item[]
}

export interface ItemActions {
  currency: string
  value: number
  items: Item[]
}

export interface Item {
  item_id: string
  item_name: string
  affiliation?: string
  coupon?: string
  discount?: number
  index?: number
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  location_id?: string
  price?: number
  quantity?: number
}

export enum CommerceActions {
  ViewPromotion = 'view_promotion', // View of promotions.
  SelectPromotion = 'select_promotion', // Click on a promotion.
  ViewItemList = 'view_item_list', // View of item impressions in a list.
  SelectItem = 'select_item', // Click on an item in a list.
  ViewItem = 'view_item', // View item details.
  AddToCart = 'add_to_cart', // Add item(s) to cart.
  AddToWishlist = 'add_to_wishlist', // Add item(s) to a wishlist.
  RemoveFromCart = 'remove_from_cart', // Remove item(s) from the cart.
  ViewCart = 'view_cart', // Remove item(s) from the cart.
  BeginCheckout = 'begin_checkout', // Initiate the checkout process.
  AddShippingInfo = 'add_shipping_info', // Add shipping info during the checkout flow.
  AddPaymentInfo = 'add_payment_info', // Add payment info during the checkout flow.
  Purchase = 'purchase', // Purchase items that were checked out.
  Refund = 'refund' // Refund one or more items.
}
