import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'

import { type ListMediaResponse, MediaAccess, type MediaCategory, PublicMediaCategories } from './types'

export function queryRequiredAccess(category: MediaCategory): MediaAccess {
  return Object.values(PublicMediaCategories).some(publicCategory => publicCategory === category) ? MediaAccess.Public : MediaAccess.Private
}

export interface ListMediaParams {
  category: MediaCategory
  subcategory?: string
}

export const getMediaItemList = ({ category, subcategory }: ListMediaParams) => {
  const access = queryRequiredAccess(category)
  const url = subcategory ? `/media/${access}/${category}/${subcategory}` : `/media/${access}/${category}`
  return createAsyncApiAction<ListMediaResponse>('gms', { method: 'GET', url })
}

interface GetMediaParams {
  category: MediaCategory
  filename: string
}

export const getMediaItem = ({ category, filename }: GetMediaParams) => {
  const access = queryRequiredAccess(category)
  return createAsyncApiAction<Blob>('gms', { method: 'GET', url: `/media/${access}/${category}/${filename}` })
}

export interface PostMediaParams {
  category: MediaCategory
  subcategory?: string
  file: File
}

export const postMediaItem = ({ category, file, subcategory }: PostMediaParams) => {
  const access = queryRequiredAccess(category)
  const url = subcategory ? `/media/${access}/${category}/${subcategory}/${file.name}` : `/media/${access}/${category}/${file.name}`
  return createAsyncApiAction<void>('gms', { method: 'POST', url, data: file })
}

export interface DeleteMediaParams {
  category: MediaCategory
  filename: string
}

export const deleteMediaItem = ({ category, filename }: DeleteMediaParams) => {
  const access = queryRequiredAccess(category)
  return createAsyncApiAction<void>('gms', { method: 'DELETE', url: `/media/${access}/${category}/${filename}` })
}
