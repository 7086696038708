import { Reducer } from 'redux'
import { IClockingActionData } from '@cbgms/api/modules/clocking-actions'

import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'

export type TimeRegistrationReducerState = Readonly<IClockingActionData | null>

type TimeRegistrationReducerBehavior<S> = Record<typeof types.SET_CLOCKING_ACTIONS, Reducer<S, any>>

const initialState = null

const timeRegistration = createReducer<TimeRegistrationReducerState, TimeRegistrationReducerBehavior<TimeRegistrationReducerState>>(
  initialState,
  {
    [types.SET_CLOCKING_ACTIONS]: (_state, { payload }) => payload
  }
)

export default timeRegistration
