import { Reducer, combineReducers } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { IArchiveRow } from '@cbgms/api/modules/archive'
import { WorkorderTotalsState } from 'app/workorder/state/workorder/reducers/WorkorderTotalsState'
import totals from 'app/archive/state/totals/state/reducers'
import lines, { ArchiveLinesState } from 'app/archive/state/lines/state/reducers'

export type detailsDefaultState = Readonly<IArchiveRow> | null
const defaultState: detailsDefaultState = null

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type ArchiveDetailsReducerBehavior<S> = Record<typeof types.LOAD_ARCHIVED_ORDER_SUCCESS, Reducer<S, any>> &
  Record<typeof types.CLEAR_ARCHIVED_ORDER, Reducer<S, any>>

const details = createReducer<detailsDefaultState, ArchiveDetailsReducerBehavior<detailsDefaultState>>(defaultState, {
  [types.LOAD_ARCHIVED_ORDER_SUCCESS]: (_, { payload }) => {
    return {
      ...payload.data.Data
    }
  },
  [types.CLEAR_ARCHIVED_ORDER]: () => {
    return null
  }
})

export interface ArchiveReducerState {
  details: detailsDefaultState
  totals: WorkorderTotalsState
  lines: ArchiveLinesState
}

const archive = combineReducers<ArchiveReducerState>({
  details,
  totals,
  lines
})

export default archive
