import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import MUIBreadcrumbs, { BreadcrumbsProps, breadcrumbsClasses } from '@mui/material/Breadcrumbs'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import { Crumb, ICrumbProps } from './Crumb'

interface Props extends BreadcrumbsProps {
  hideLevel?: number
}
export const Breadcrumbs: React.FC<Props> = ({ children, hideLevel = 1, ...props }) => {
  const { pathname } = useLocation()

  const mapCrumbs = React.Children.toArray(children).filter(child => {
    if (React.isValidElement<ICrumbProps>(child) && child.type === Crumb) {
      return matchPath(pathname, { exact: child.props.exact, path: child.props.path })
    }
    return false
  })

  // Don't render empty breadcrumb elements
  // Default is 1 to hide crumb on first level (same a page title)
  if (mapCrumbs.length <= hideLevel) return null
  return (
    <MUIBreadcrumbs
      sx={{
        [`& .${breadcrumbsClasses.separator}`]: { m: 0 },
        lineHeight: 1
      }}
      data-testid='breadcrumb'
      separator={<NavigateNextIcon fontSize='small' sx={{ opacity: 0.5 }} />}
      {...props}
    >
      {mapCrumbs}
    </MUIBreadcrumbs>
  )
}

export default Breadcrumbs
