import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import { ILubricants } from '@cbgms/api/modules/vehicles/lubricants'
import * as types from './types'

export type LubricantsReducerState = ReadonlyArray<ILubricants>

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type LubricantsReducerBehavior<S> = Record<typeof types.GET_FLUID_LOOKUP_SUCCESS, Reducer<S, any>> &
  Record<typeof types.RESET_FLUID_LOOKUP, Reducer<S, any>>

const lubricants = createReducer<LubricantsReducerState, LubricantsReducerBehavior<LubricantsReducerState>>([], {
  [types.GET_FLUID_LOOKUP_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [types.RESET_FLUID_LOOKUP]: () => {
    return []
  }
})

export default lubricants
