import * as types from './types'
import { Locales } from '@carsys/enums/locales'
import { getAllCountries, getCountryCodes, getEnvironmentCountryCodes } from '@cbgms/api/modules/miscellaneous/countries/requests'
import { GETCountriesResponse, GETCountryCodes } from '@cbgms/api'
import { AsyncAction } from '@cbgms/base/redux/async-action'

export const getCountries = (locale: Locales) =>
  AsyncAction<GETCountriesResponse>({
    type: [types.GET_COUNTRIES, types.GET_COUNTRIES_SUCCESS, types.GET_COUNTRIES_FAILED],
    payload: {
      api: 'gms',
      request: getAllCountries(locale)
    }
  })

export const getSupportedCountries = () =>
  AsyncAction<GETCountryCodes>({
    type: [types.GET_SUPPORTED_COUNTRIES, types.GET_SUPPORTED_COUNTRIES_SUCCESS, types.GET_SUPPORTED_COUNTRIES_FAILED],
    payload: {
      api: 'gms',
      request: getCountryCodes()
    }
  })

export const getEnvironmentCountries = () =>
  AsyncAction<GETCountryCodes>({
    type: [types.GET_ENVIRONMENT_COUNTRIES, types.GET_ENVIRONMENT_COUNTRIES_SUCCESS, types.GET_ENVIRONMENT_COUNTRIES_FAILED],
    payload: {
      api: 'gms',
      request: getEnvironmentCountryCodes()
    }
  })

export const getCachedCountries = () => ({
  type: types.GET_CACHED_COUNTRIES
})

export const lockGetCachedCountries = () => ({
  type: types.LOCK_GET_CACHED_COUNTRIES
})
