import { combineReducers } from 'redux'
import maintenancePeriods, { MaintenancePeriodsReducerState } from 'app/vehicles/state/serviceSchedule/maintenancePeriods'
import wearPartIntervals, { WearPartIntervalsReducerState } from 'app/vehicles/state/serviceSchedule/wearPartIntervals'

export interface IServiceScheduleReducerState {
  maintenancePeriods: MaintenancePeriodsReducerState
  wearPartIntervals: WearPartIntervalsReducerState
}

export type ServiceScheduleReducerState = Readonly<IServiceScheduleReducerState>

export default combineReducers<ServiceScheduleReducerState>({
  maintenancePeriods,
  wearPartIntervals
})
