import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'

import * as types from './types'

interface IWorkorderNumberState {
  NumberPattern: string
  NumberPatternExample: string
}

export type WorkorderNumbersState = Readonly<IWorkorderNumberState>

const defaultState: WorkorderNumbersState = {
  NumberPattern: '',
  NumberPatternExample: ''
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type WorkorderNumbersBehavior<S = WorkorderNumbersState> = Record<typeof types.LOAD_WORKORDER_NUMBER_SUCCESS, Reducer<S, any>> &
  Record<typeof types.UPDATE_WORKORDER_NUMBER_SUCCESS, Reducer<S, any>> &
  Record<typeof types.LOAD_WORKORDER_NUMBER_PREVIEW_SUCCESS, Reducer<S, any>>

const workorderNumbers = createReducer<WorkorderNumbersState, WorkorderNumbersBehavior>(defaultState, {
  [types.LOAD_WORKORDER_NUMBER_SUCCESS]: (state, { payload }) => ({
    ...(state || defaultState),
    ...payload.data.Data
  }),
  [types.UPDATE_WORKORDER_NUMBER_SUCCESS]: (state, { payload }) => ({
    ...(state || defaultState),
    ...payload.data.Data
  }),
  [types.LOAD_WORKORDER_NUMBER_PREVIEW_SUCCESS]: (state, { payload }) => ({
    ...(state || defaultState),
    NumberPatternExample: payload.data.Data.join('\n')
  })
})

export default workorderNumbers
