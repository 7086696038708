import { enqueueSnackbar } from 'notistack'
import { INotifier, Undo, VariantType } from './types'

export class Notifier implements INotifier {
  constructor(
    private enqueue: (
      title: string,
      message: React.ReactNode,
      variant: VariantType,
      action?: React.ReactNode,
      undo?: Undo,
      autoHideDuration?: number
    ) => void
  ) {}

  public success(title: string, message?: React.ReactNode, action?: React.ReactNode, undo?: Undo, autoHideDuration?: number): void {
    this.enqueue(title, message, 'success', action, undo, autoHideDuration)
  }

  public error(title: string, message?: React.ReactNode, action?: React.ReactNode, undo?: Undo, autoHideDuration?: number): void {
    this.enqueue(title, message, 'error', action, undo, autoHideDuration)
  }

  public info(title: string, message?: React.ReactNode, action?: React.ReactNode, undo?: Undo, autoHideDuration?: number): void {
    this.enqueue(title, message, 'info', action, undo, autoHideDuration)
  }

  public warning(title: string, message?: React.ReactNode, action?: React.ReactNode, undo?: Undo, autoHideDuration?: number): void {
    this.enqueue(title, message, 'warning', action, undo, autoHideDuration)
  }
}

function snackBarMessage(
  _title: string,
  _message: React.ReactNode,
  variant: VariantType,
  action?: React.ReactNode,
  undo?: Undo,
  autoHideDuration?: number
) {
  // When only one parameter is provided the 'title' becomes the message
  const title = _message ? _title : undefined
  const message = _message || _title
  switch (variant) {
    case 'success':
      return enqueueSnackbar(message, {
        variant: 'success',
        action,
        undo,
        title,
        autoHideDuration
      })
    case 'error':
      return enqueueSnackbar(message, {
        variant: 'error',
        action,
        undo,
        title,
        autoHideDuration
      })
    case 'warning':
      return enqueueSnackbar(message, {
        variant: 'warning',
        action,
        undo,
        title,
        autoHideDuration
      })
    case 'info':
      return enqueueSnackbar(message, {
        variant: 'info',
        action,
        undo,
        title,
        autoHideDuration
      })
    default:
      return enqueueSnackbar(message, {
        variant: 'info',
        action,
        undo,
        title,
        autoHideDuration
      })
  }
}

/**
 *
 * Object to display system Toast/SnackBar messages for NON React functional components.
 * Based on React MUI & Notistack.
 *
 * @param message (Required) Toast/SnackBar description to show the user.
 *
 * @param undo (Not Required) The function to fire as an undo action.
 * An UNDO button will be shown as an action instead of a Close button, along with a progress bar
 * showing the time remaining until the Toast/SnackBar is hidden.
 *
 * @param autoHideDuration (Not Required) The time in MS (1000ms = 1s) before the Toast/SnackBar will be hidden. Default of 5000ms.
 * Can be used to increase the time the Toast/SnackBar is shown to the user so the user has more time to notice and act upon the
 * Toast/SnackBar.
 *
 * @example
 *
 * import React from 'react'
 * import { notifier } from '@cbgms/base/notifier/use-notifier'
 *
 * const onSubmit = () => {
 *   notifier.success('The success message for the toast/snackbar')
 * }
 *
 * const onError = (error) => {
 *   notifier.error(`The error message for the toast/snackbar. Error: ${error}`)
 * }
 *
 * const undoFunction = () => {
 *   // do something
 * }
 *
 * const onUndo = () => {
 *   notifier.success('The success message for the toast/snackbar', undoFunction(), 10000)
 * }
 *
 * function ExampleComponent() {
 *
 *   return (<component />)
 * }
 */

export const notifier = new Notifier(snackBarMessage)

/**
 *
 * Custom React Hook to display system Toast/SnackBar messages.
 * Based on React MUI & Notistack.
 *
 * @param message (Required) Toast/SnackBar description to show the user.
 *
 * @param undo (Not Required) The function to fire as an undo action.
 * An UNDO button will be shown as an action instead of a Close button, along with a progress bar
 * showing the time remaining until the Toast/SnackBar is hidden.
 *
 * @param action (Not Required) ReactNode used for rendering additional action buttons on the snackbar.
 * Accepts a ReactNode, expected to be a button.
 *
 * @param autoHideDuration (Not Required) The time in MS (1000ms = 1s) before the Toast/SnackBar will be hidden. Default of 5000ms.
 * Can be used to increase the time the Toast/SnackBar is shown to the user so the user has more time to notice and act upon the
 * Toast/SnackBar.
 *
 * @example
 *
 * import React from 'react'
 * import { useNotifier } from '@cbgms/base/notifier'
 *
 * const ExampleComponent: React.FC = () => {
 *   const notifier = useNotifier()
 *
 *   const onSubmit = () => {
 *     notifier.success('The success message for the toast/snackbar')
 *   }
 *
 *   const onError = (error) => {
 *     notifier.error(`The error message for the toast/snackbar. Error: ${error}`)
 *   }
 *
 *   const undoFunction = () => {
 *     // do something
 *   }
 *
 *   const onUndo = () => {
 *     notifier.success('The success message for the toast/snackbar', undefined, undoFunction(), 10000)
 *   }
 *
 *   const actionFunction = () => {
 *     return <Button size='small' onClick={someFunc()}>Example</Button>
 *   }
 *
 *   const onAction = () => {
 *     notifier.success('The success message for the toast/snackbar', actionFunction())
 *   }
 *
 *   return (<component />)
 * }
 */
export function useNotifier(): INotifier {
  return notifier
}
