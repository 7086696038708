import { HttpMethod } from '@cbgms/api/http-method'
import { DELETEResponse } from '@cbgms/api/helpers'

export type DELETEWorkstationResponse = DELETEResponse

export const deleteWorkstationRequest = (workstationUUID: string, dryRun: boolean) => ({
  method: HttpMethod.DELETE,
  url: `modules/workstations/${workstationUUID}`,
  params: {
    DryRun: dryRun
  }
})
