export const LOAD_REVENUE_GROUPS = 'LOAD_REVENUE_GROUPS'
export const LOAD_REVENUE_GROUPS_SUCCESS = 'LOAD_REVENUE_GROUPS_SUCCESS'
export const LOAD_REVENUE_GROUPS_FAILED = 'LOAD_REVENUE_GROUPS_FAILED'

export const LOAD_REVENUE_GROUP = 'LOAD_REVENUE_GROUP'
export const LOAD_REVENUE_GROUP_SUCCESS = 'LOAD_REVENUE_GROUP_SUCCESS'
export const LOAD_REVENUE_GROUP_FAILED = 'LOAD_REVENUE_GROUP_FAILED'

export const CREATE_REVENUE_GROUP = 'CREATE_REVENUE_GROUP'
export const CREATE_REVENUE_GROUP_SUCCESS = 'CREATE_REVENUE_GROUP_SUCCESS'
export const CREATE_REVENUE_GROUP_FAILED = 'CREATE_REVENUE_GROUP_FAILED'

export const UPDATE_REVENUE_GROUP = 'UPDATE_REVENUE_GROUP'
export const UPDATE_REVENUE_GROUP_SUCCESS = 'UPDATE_REVENUE_GROUP_SUCCESS'
export const UPDATE_REVENUE_GROUP_FAILED = 'UPDATE_REVENUE_GROUP_FAILED'

export const DELETE_REVENUE_GROUP = 'DELETE_REVENUE_GROUP'
export const DELETE_REVENUE_GROUP_SUCCESS = 'DELETE_REVENUE_GROUP_SUCCESS'
export const DELETE_REVENUE_GROUP_FAILED = 'DELETE_REVENUE_GROUP_FAILED'

export const LOAD_REVENUE_LEDGERS_FOR_ADMINISTRATION = 'LOAD_REVENUE_LEDGERS_FOR_ADMINISTRATION'
export const LOAD_REVENUE_LEDGERS_FOR_ADMINISTRATION_SUCCESS = 'LOAD_REVENUE_LEDGERS_FOR_ADMINISTRATION_SUCCESS'
export const LOAD_REVENUE_LEDGERS_FOR_ADMINISTRATION_FAILED = 'LOAD_REVENUE_LEDGERS_FOR_ADMINISTRATION_FAILED'

export const UPDATE_REVENUE_LEDGER_FOR_ADMINISTRATION = 'UPDATE_REVENUE_LEDGER_FOR_ADMINISTRATION'
export const UPDATE_REVENUE_LEDGER_FOR_ADMINISTRATION_SUCCESS = 'UPDATE_REVENUE_LEDGER_FOR_ADMINISTRATION_SUCCESS'
export const UPDATE_REVENUE_LEDGER_FOR_ADMINISTRATION_FAILED = 'UPDATE_REVENUE_LEDGER_FOR_ADMINISTRATION_FAILED'
