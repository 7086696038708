import { IResponse } from '@cbgms/api/response'
import { getTotals } from 'app/workorder/state/workorder/selectors'
import {
  CreateWorkorderLineResponse,
  DeleteWorkorderLineResponse,
  GETWorkorderLinesResponse,
  PlaceOrderUpdateWorkorderLineResponse,
  UpdateWorkorderLineResponse
} from '@cbgms/api/modules/workorder'
import { defaultState } from 'app/workorder/state/workorder/reducers/WorkorderTotalsState'

import * as types from '../types'

interface AddProduct {
  type: typeof types.ADD_PRODUCT_SUCCESS
  payload: {
    data: IResponse<CreateWorkorderLineResponse>
  }
}

interface AddOfflineWorkorderLines {
  type: typeof types.ADD_OFFLINE_WORKORDER_LINES_SUCCESS
  payload: {
    data: IResponse<CreateWorkorderLineResponse>
  }
}

interface AddProductFromIframe {
  type: typeof types.PUT_WHOLESALER_IFRAME_CART_TO_WORKORDER_SUCCESS
  payload: {
    data: IResponse<CreateWorkorderLineResponse>
  }
}

interface GetWorkorderLines {
  type: typeof types.GET_WORKORDER_LINES_SUCCESS
  payload: {
    data: GETWorkorderLinesResponse
  }
}

interface DeleteWorkorderLines {
  type: typeof types.DELETE_WORKORDER_LINES_SUCCESS
  payload: {
    data: IResponse<DeleteWorkorderLineResponse>
  }
}

interface UpdateWorkorderLines {
  type: typeof types.UPDATE_WORKORDER_LINES_SUCCESS
  payload: {
    data: IResponse<UpdateWorkorderLineResponse>
  }
}

interface UpdateWorkorderLinesSort {
  type: typeof types.UPDATE_WORKORDER_LINES_SORT_SUCCESS
  payload: {
    data: IResponse<UpdateWorkorderLineResponse>
  }
}

interface UpdateRepairTimes {
  type: typeof types.PUT_REPAIR_TIMES_SUCCESS
  payload: {
    data: any
  }
}

interface PlaceOrder {
  type: typeof types.PLACE_ORDER_SUCCESS
  payload: {
    data: IResponse<PlaceOrderUpdateWorkorderLineResponse>
  }
}

interface SetDiscount {
  type: typeof types.SET_DISCOUNT_SUCCESS
  payload: {
    data: IResponse<UpdateWorkorderLineResponse>
  }
}

type TotalsActions =
  | AddProduct
  | AddOfflineWorkorderLines
  | AddProductFromIframe
  | GetWorkorderLines
  | DeleteWorkorderLines
  | UpdateWorkorderLines
  | UpdateWorkorderLinesSort
  | UpdateRepairTimes
  | PlaceOrder
  | SetDiscount

export default function totals(state = defaultState, action: TotalsActions) {
  switch (action.type) {
    case types.ADD_PRODUCT_SUCCESS: {
      return getTotals(action.payload.data.Data.Totals)
    }
    case types.ADD_OFFLINE_WORKORDER_LINES_SUCCESS: {
      return getTotals(action.payload.data.Data.Totals)
    }
    case types.GET_WORKORDER_LINES_SUCCESS: {
      return getTotals(action.payload.data.Data.Totals)
    }
    case types.DELETE_WORKORDER_LINES_SUCCESS: {
      return getTotals(action.payload.data.Data.Totals)
    }
    case types.UPDATE_WORKORDER_LINES_SUCCESS: {
      return getTotals(action.payload.data.Data.Totals)
    }
    case types.UPDATE_WORKORDER_LINES_SORT_SUCCESS: {
      return getTotals(action.payload.data.Data.Totals)
    }
    case types.PUT_REPAIR_TIMES_SUCCESS: {
      return getTotals(action.payload.data.Data.WorkorderLines.Totals)
    }
    case types.PLACE_ORDER_SUCCESS: {
      return getTotals(action.payload.data.Data.Totals)
    }
    case types.PUT_WHOLESALER_IFRAME_CART_TO_WORKORDER_SUCCESS: {
      return getTotals(action.payload.data.Data.Totals)
    }
    case types.SET_DISCOUNT_SUCCESS: {
      return getTotals(action.payload.data.Data.Totals)
    }
    default: {
      return state
    }
  }
}
