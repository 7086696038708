import { Loader } from '@carsys/ui'
import Box from '@mui/material/Box'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import lazyWithReload from 'utils/lazy-with-reload'

const OnboardingContainer = lazyWithReload(() => import(/* webpackPrefetch: true */ 'app/auth/views/OnboardingContainer'))
const AuthContainer = lazyWithReload(() => import(/* webpackPrefetch: true */ 'app/auth/views/Container'))
const MyWorkorderContainer = lazyWithReload(() => import(/* webpackPrefetch: true */ 'app/myWorkorder/views/Container'))
const ActionRoutes = lazyWithReload(() => import(/* webpackPrefetch: true */ 'routes/action/ActionRoutes'))
const Main = lazyWithReload(() => import(/* webpackPrefetch: true */ './Main'))

const ApplicationRoutes = () => {
  return (
    <React.Suspense
      fallback={
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center' }}>
          <Loader loading fading />
        </Box>
      }
    >
      <Switch>
        <Route path='/auth/onboarding' component={OnboardingContainer} />
        <Route path='/auth' component={AuthContainer} />
        <Route path='/my-workorder' component={MyWorkorderContainer} />
        <Route path='/action' component={ActionRoutes} />
        <Route path='/' component={Main} />
      </Switch>
    </React.Suspense>
  )
}

export default ApplicationRoutes
