import { faker } from '@faker-js/faker/locale/en'
import {
  IAPKDefect,
  IAPKRecord,
  IApkWithStatus,
  IRDWDefectPoint,
  IRDWInspector,
  IRDWServiceProviderAccount,
  IRDWSupportedServiceProvider,
  IRDWTransactionLogItem,
  InspectorAcknowledgement
} from './index'
import { CountryCode } from '@carsys/enums/country-code'
import {
  APKStatus,
  RDWCapability,
  RDWDefectType,
  RDWInspectionAuthorityAcknowledgementType,
  RDWInspectionResult,
  RDWNotificationType,
  RDWObfcmReason,
  RDWProviderSystem,
  RDWSpecialAddressType,
  RDWTransactionLogStatus,
  RDWVehicleType
} from '../enums'
import { MockFn, referenceDate } from '@cbgms/api/mock'
import { IAddress } from '../address'

export const mockRDWServiceProviderAccount: MockFn<IRDWServiceProviderAccount> = (extend?) => ({
  UpdatedAt: faker.date.past(0, referenceDate).toISOString(),
  CreatedAt: faker.date.past(0, referenceDate).toISOString(),
  UUID: faker.datatype.uuid(),
  Name: faker.company.name(),
  Username: faker.internet.userName(),
  Password: faker.internet.password(),
  System: faker.helpers.arrayElement<RDWProviderSystem>(Object.keys(RDWProviderSystem) as RDWProviderSystem[]),
  CustomerID: faker.random.alphaNumeric(),
  InspectionAuthorityID: faker.random.alphaNumeric(6),
  InspectionLocationID: faker.random.alphaNumeric(6),
  RDWCompanyID: faker.random.alphaNumeric(6),
  RDWCapabilities: Object.keys(RDWCapability) as RDWCapability[],
  ...extend
})

export const emptyRDWServiceProviderAccount: MockFn<IRDWServiceProviderAccount> = (extend?) => ({
  UpdatedAt: '',
  CreatedAt: '',
  UUID: '',
  Name: '',
  Username: '',
  Password: '',
  System: RDWProviderSystem.A2SP,
  CustomerID: '',
  InspectionAuthorityID: '',
  InspectionLocationID: '',
  RDWCompanyID: '',
  RDWCapabilities: [],
  ...extend
})

export const mockRDWSupportedServiceProvider: MockFn<IRDWSupportedServiceProvider> = (extend?) => ({
  Name: faker.company.name(),
  System: faker.helpers.arrayElement<RDWProviderSystem>(Object.keys(RDWProviderSystem) as RDWProviderSystem[]),
  LogoURL: faker.image.imageUrl(),
  Capabilities: Object.keys(RDWCapability) as RDWCapability[],
  ...extend
})

export const mockAPKRecord: MockFn<IAPKRecord> = (extend?) => ({
  UUID: faker.datatype.uuid(),
  ShopID: 'NL999999',
  WorkorderUUID: faker.datatype.uuid(),
  VehicleUUID: faker.datatype.uuid(),
  EmployeeUUID: faker.datatype.uuid(),
  ProviderAccountUUID: faker.datatype.uuid(),
  ReportingCode: faker.datatype.number({ min: 1000, max: 9999 }).toString(),
  AcceptanceStatus: faker.helpers.arrayElement<RDWInspectionResult>(Object.values(RDWInspectionResult)),
  InspectorAcknowledgement: RDWInspectionAuthorityAcknowledgementType.Light,
  FuelConsumptionNotProvidedReason: faker.helpers.arrayElement<RDWObfcmReason>(Object.values(RDWObfcmReason)),
  IsProcessed: faker.datatype.boolean(),
  Defects: new Array(faker.datatype.number(10)).fill(null).map(_ => mockAPKDefect()),
  ProcessedTimestamp: faker.date.past(0, referenceDate).toISOString(),
  HasRandomCheck: faker.datatype.boolean(),
  RandomCheckExpiryTimestamp: faker.date.past(0, referenceDate).toISOString(),
  CreatedAt: faker.date.past(0, referenceDate).toISOString(),
  UpdatedAt: faker.date.past(0, referenceDate).toISOString(),
  ...extend
})

export const mockAPKDefect: MockFn<IAPKDefect> = (extend?) => ({
  Action: faker.helpers.arrayElement<RDWDefectType>(Object.values(RDWDefectType)),
  Code: faker.system.semver(),
  Count: faker.datatype.number(10),
  Description: faker.lorem.words(20),
  InternalCode: faker.datatype.uuid(),
  Text: faker.lorem.words(4),
  ...extend
})

const categories = [
  ['1', '2', '3'],
  ['1.1', '2.2', '3.3', ''],
  ['1.1.1', '2.2.2', '3.3.3', '']
]

export const mockRDWDefectPoint: MockFn<IRDWDefectPoint> = (extend?) => ({
  Code: faker.datatype.uuid(),
  DefectTypes: [faker.helpers.arrayElement(Object.values(RDWDefectType)) as RDWDefectType],
  Description: faker.lorem.words(10),
  InternalCode: faker.datatype.uuid(),
  SortKey: {
    Key: faker.random.word(),
    Components: [
      {
        Description: faker.helpers.arrayElement(categories[0]),
        Key: faker.random.word(),
        Level: 0
      },
      {
        Description: faker.helpers.arrayElement(categories[1]),
        Key: faker.random.word(),
        Level: 1
      },
      {
        Description: faker.helpers.arrayElement(categories[2]),
        Key: faker.random.word(),
        Level: 2
      }
    ]
  },
  VehicleTypes: [faker.helpers.arrayElement<RDWVehicleType>(Object.values(RDWVehicleType))],
  ...extend
})

export const mockRDWInspector: MockFn<IRDWInspector> = (extend?) => ({
  Notification: {
    Text: faker.random.word(),
    Number: faker.datatype.number(),
    Type: faker.helpers.arrayElement<RDWNotificationType>(Object.values(RDWNotificationType))
  },
  Error: faker.hacker.phrase(),
  InitialsName: faker.name.firstName()[0],
  LastName: faker.name.lastName(),
  MobileNumber: faker.phone.number(),
  EmailAddress: faker.internet.email(),
  APKPassNumber: `${faker.datatype.number(100000)}`,
  InspectorLicenseEndDate: faker.date.past(0, referenceDate).toISOString(),
  AddressAdditionalLine: faker.lorem.lines(1),
  AddressSpecialType: faker.helpers.arrayElement<RDWSpecialAddressType>(Object.values(RDWSpecialAddressType)),
  Address: mockAddress(),
  Acknowledgements: new Array(faker.datatype.number(10)).fill(null).map(_ => mockRDWInspectorAcknowledgement()),
  ...extend
})

export const mockAddress: MockFn<IAddress> = (extend?) => ({
  Address: faker.address.street(),
  HouseNumber: faker.datatype.number().toString(),
  PostalCode: faker.address.zipCode(),
  Country: faker.helpers.arrayElement<CountryCode>(Object.values(CountryCode)),
  City: faker.address.city(),
  State: faker.address.state(),
  Latitude: faker.address.latitude() as any,
  Longitude: faker.address.longitude() as any,
  ...extend
})

export const mockRDWInspectorAcknowledgement: MockFn<InspectorAcknowledgement> = (extend?) => ({
  AcknowledgementType: faker.helpers.arrayElement<RDWInspectionAuthorityAcknowledgementType>(
    Object.values(RDWInspectionAuthorityAcknowledgementType)
  ),
  StartDate: faker.date.past(0, referenceDate).toISOString(),
  EndDate: faker.date.past(0, referenceDate).toISOString(),
  DateBlocked: faker.date.past(0, referenceDate).toISOString(),
  DateReorientationTest: faker.date.past(0, referenceDate).toISOString(),
  Cusum: faker.datatype.number(10),
  ...extend
})

export const mockApkHistoryItem: MockFn<IRDWTransactionLogItem> = (extend?) => ({
  APKUUID: faker.datatype.uuid(),
  TransactionUUID: faker.datatype.uuid(),
  VehicleUUID: faker.datatype.uuid(),
  WorkorderUUID: faker.datatype.uuid(),
  InspectionResult: faker.helpers.arrayElement<RDWInspectionResult>(Object.keys(RDWInspectionResult) as RDWInspectionResult[]),
  InspectorID: faker.random.alphaNumeric(4),
  InvoiceUUID: faker.datatype.uuid(),
  InvoiceNumber: faker.random.alphaNumeric(),
  WorkorderID: faker.random.alphaNumeric(),
  APKID: faker.random.alphaNumeric(),
  ProviderSystem: faker.helpers.arrayElement<RDWProviderSystem>(Object.keys(RDWProviderSystem) as RDWProviderSystem[]),
  TransactionType: faker.helpers.arrayElement<IRDWTransactionLogItem['TransactionType']>(['apk', 'rv']),
  IsProcessed: faker.datatype.boolean(),
  ProcessedTimestamp: faker.date.past(0, referenceDate).toISOString(),
  HasRandomCheck: faker.datatype.boolean(),
  RandomCheckExpiryTimestamp: faker.date.past(0, referenceDate).toISOString(),
  RandomCheckIsSeen: faker.datatype.boolean(),
  RandomCheckIsSeenTimestamp: faker.date.past(0, referenceDate).toISOString(),
  Registration: `NL|${faker.random.alphaNumeric(6)}`,
  Status: faker.helpers.arrayElement<RDWTransactionLogStatus>(Object.keys(RDWTransactionLogStatus) as RDWTransactionLogStatus[]),
  CreatedAt: faker.date.past(0, referenceDate).toISOString(),
  VIN: faker.random.alphaNumeric(15),
  UpdatedAt: faker.date.past(0, referenceDate).toISOString(),
  ...extend
})

export const mockWorkorderWithAPK: MockFn<IApkWithStatus> = (extend?) => ({
  APK: mockAPKRecord(),
  ConsultVehicleExpired: faker.datatype.boolean(),
  ConsultVehicleTimestamp: faker.date.past(0, referenceDate).toISOString(),
  Status: faker.helpers.arrayElement<APKStatus>(Object.keys(APKStatus) as APKStatus[]),
  UUID: faker.datatype.uuid(),
  ...extend
})
