export const LOAD_CLOSING_STATEMENTS = 'LOAD_CLOSING_STATEMENTS'
export const LOAD_CLOSING_STATEMENTS_SUCCESS = 'LOAD_CLOSING_STATEMENTS_SUCCESS'
export const LOAD_CLOSING_STATEMENTS_FAILED = 'LOAD_CLOSING_STATEMENTS_FAILED'

export const LOAD_ALL_CLOSING_STATEMENTS = 'LOAD_ALL_CLOSING_STATEMENTS'
export const LOAD_ALL_CLOSING_STATEMENTS_SUCCESS = 'LOAD_ALL_CLOSING_STATEMENTS_SUCCESS'
export const LOAD_ALL_CLOSING_STATEMENTS_FAILED = 'LOAD_ALL_CLOSING_STATEMENTS_FAILED'

export const LOAD_CLOSING_STATEMENT = 'LOAD_CLOSING_STATEMENT'
export const LOAD_CLOSING_STATEMENT_SUCCESS = 'LOAD_CLOSING_STATEMENT_SUCCESS'
export const LOAD_CLOSING_STATEMENT_FAILED = 'LOAD_CLOSING_STATEMENT_FAILED'

export const CREATE_CLOSING_STATEMENT = 'CREATE_CLOSING_STATEMENT'
export const CREATE_CLOSING_STATEMENT_SUCCESS = 'CREATE_CLOSING_STATEMENT_SUCCESS'
export const CREATE_CLOSING_STATEMENT_FAILED = 'CREATE_CLOSING_STATEMENT_FAILED'

export const UPDATE_CLOSING_STATEMENT = 'UPDATE_CLOSING_STATEMENT'
export const UPDATE_CLOSING_STATEMENT_SUCCESS = 'UPDATE_CLOSING_STATEMENT_SUCCESS'
export const UPDATE_CLOSING_STATEMENT_FAILED = 'UPDATE_CLOSING_STATEMENT_FAILED'

export const DELETE_CLOSING_STATEMENT = 'DELETE_CLOSING_STATEMENT'
export const DELETE_CLOSING_STATEMENT_SUCCESS = 'DELETE_CLOSING_STATEMENT_SUCCESS'
export const DELETE_CLOSING_STATEMENT_FAILED = 'DELETE_CLOSING_STATEMENT_FAILED'

export const LOAD_CLOSING_STATEMENT_SETTINGS = 'LOAD_CLOSING_STATEMENT_SETTINGS'
export const LOAD_CLOSING_STATEMENT_SETTINGS_SUCCESS = 'LOAD_CLOSING_STATEMENT_SETTINGS_SUCCESS'
export const LOAD_CLOSING_STATEMENT_SETTINGS_FAILED = 'LOAD_CLOSING_STATEMENT_SETTINGS_FAILURE'

export const UPDATE_CLOSING_STATEMENT_SETTINGS = 'UPDATE_CLOSING_STATEMENTS_SETTINGS'
export const UPDATE_CLOSING_STATEMENT_SETTINGS_SUCCESS = 'UPDATE_CLOSING_STATEMENTS_SETTINGS_SUCCESS'
export const UPDATE_CLOSING_STATEMENT_SETTING_FAILED = 'UPDATE_CLOSING_STATEMENTS_SETTINGS_FAILURE'
