import React from 'react'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LngDetector from 'i18next-browser-languagedetector'
import Backend from './Backend'
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor'
import moment from 'moment'
import { supportedLanguages } from '@cbgms/base/translate/locales'
import { i18nInterpolationFormat } from '@cbgms/base/translate/i18nInterpolationFormat'

i18next.on('languageChanged', (lng: string) => {
  moment.locale(lng)
})

const localStorageKey = 'phrase_context_editor_enabled'

interface UseTogglePhraseContextEditorProps {
  /** `onToggle` syncs the state to the local storage and reloads the page. */
  onToggle: () => void
  enabled: boolean
}

export function useTogglePhraseContextEditor(): UseTogglePhraseContextEditorProps {
  const [enabled, setEnabled] = React.useState(() => {
    return Boolean(window.localStorage.getItem(localStorageKey))
  })

  const onToggle = React.useCallback(() => {
    setEnabled(previousState => {
      const enabled = !previousState

      enabled ? window.localStorage.setItem(localStorageKey, '1') : window.localStorage.removeItem(localStorageKey)

      setTimeout(() => {
        window.location.reload()
      }, 500)

      return enabled
    })
  }, [setEnabled])

  return {
    enabled,
    onToggle
  }
}

const contextEditorEnabled = Boolean(localStorage.getItem(localStorageKey))

if (contextEditorEnabled) {
  i18next.use(
    new PhraseInContextEditorPostProcessor({
      autoLowercase: false,
      phraseEnabled: true,
      projectId: '2c474b7581a6265cdeb92122abab66ee',
      sso: {
        enabled: true,
        enforced: true,
        identifier: 'car-systems-cd-bv',
        provider: 'saml'
      }
    })
  )
}

i18next
  .use(LngDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: __DEV__,
    interpolation: {
      escapeValue: false,
      format: i18nInterpolationFormat
    },
    fallbackLng: 'en-GB',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      convertDetectedLanguage: l => {
        // sometimes we will detects 'nl-NL' or 'nl-BE' if it's a direct match we can set to that language
        const directMatch = supportedLanguages.find(sl => sl.toLowerCase() === l.toLowerCase())
        if (directMatch) {
          // the language is set via user-locale, for not logged in users. this might trigger
          return directMatch
        }
        // sometimes we will detects 'nl' without Country, since this is for non-logged in users.
        // we still want to serve nl-NL or similar language-countrycode instead of defaulting to en-gb
        const matchOnLanguage = supportedLanguages.find(sl => sl.toLowerCase().startsWith(l.toLowerCase()))
        if (matchOnLanguage) {
          return matchOnLanguage
        }
        // this is not a language we recognize
        return l
      }
    },
    load: 'currentOnly',
    supportedLngs: __DEV__ ? undefined : supportedLanguages,
    react: { useSuspense: true },
    postProcess: contextEditorEnabled ? ['phraseInContextEditor'] : [],
    returnEmptyString: false
  })

export default i18next
