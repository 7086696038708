import { AnyAction, combineReducers } from 'redux'

import labourRates from './LabourRates/reducers'
import defaultOrderAccount from './DefaultOrderAccount/reducers'
import employees, { EmployeesReducerState } from 'app/settings/state/Employees/reducers'
import employeeSkills from './Employees/Skills/reducers'
import partGroup, { PartGroupState } from 'app/settings/state/PartGroups/reducers'
import openingHours, { OpeningHoursState } from 'app/settings/state/OpeningHours/reducers'
import revenueGroup, { RevenueGroupState } from 'app/settings/state/RevenueGroups/reducers'
import invoiceSequence, { IInvoiceSequenceReducerState } from 'app/settings/state/InvoiceSequences/reducers'
import administration from 'app/settings/state/Administrations/reducers'
import sms from 'app/settings/state/SmsProvider/reducers'
import vehicleSearchByCountry from 'app/settings/state/VehicleSearchByCountry/reducers'
import closingStatements, { ClosingStatementsReducerState } from 'app/settings/state/ClosingStatements/reducers'
import ledger, { ILedgerReducerState } from 'app/settings/state/Ledgers/reducers'
import debitCreditNumbers, { DebitCreditNumbersState } from 'app/settings/state/DebitCreditNumbers/reducers'
import workorderActivities from 'app/settings/state/WorkorderActivities/reducers'
import workorderNumbers, { WorkorderNumbersState } from 'app/settings/state/WorkorderNumbers/reducers'
import journalEntryNumber, { JournalEntryNumberState } from 'app/settings/state/JournalEntryNumber/reducers'
import rdwServiceProvider, { IRDWServiceProviderState } from './RDWServiceProviderAccounts/reducers'
import csvExports, { IExportCSVReducerState } from 'app/settings/state/ExportedInvoices/reducers'
import { SET_CURRENT_SHOP } from 'app/auth/state/types'
import timeCorrection from 'app/settings/state/TimeCorrection/reducers'

interface ISettingsReducerState {
  closingStatements: ClosingStatementsReducerState
  defaultOrderAccount: ReturnType<typeof defaultOrderAccount>
  labourRates: ReturnType<typeof labourRates>
  employees: EmployeesReducerState
  employeeSkills: any
  partGroup: PartGroupState
  openingHours: OpeningHoursState
  revenueGroup: RevenueGroupState
  rdwServiceProvider: IRDWServiceProviderState
  invoiceSequence: IInvoiceSequenceReducerState
  administration: ReturnType<typeof administration>
  workorderActivities: ReturnType<typeof workorderActivities>
  workorderNumbers: WorkorderNumbersState
  journalEntryNumber: JournalEntryNumberState
  ledger: ILedgerReducerState
  csvExports: IExportCSVReducerState
  debitCreditNumbers: DebitCreditNumbersState
  sms: ReturnType<typeof sms>
  vehicleSearchByCountry: ReturnType<typeof vehicleSearchByCountry>
  timeCorrection: ReturnType<typeof timeCorrection>
}

export type SettingsReducerState = Readonly<ISettingsReducerState>

const settingsReducer = combineReducers<SettingsReducerState>({
  closingStatements,
  defaultOrderAccount,
  labourRates,
  employees,
  employeeSkills,
  partGroup,
  openingHours,
  revenueGroup,
  rdwServiceProvider,
  invoiceSequence,
  administration,
  workorderActivities,
  workorderNumbers,
  journalEntryNumber,
  ledger,
  csvExports,
  debitCreditNumbers,
  sms,
  vehicleSearchByCountry,
  timeCorrection
})

export default (state: SettingsReducerState | undefined, action: AnyAction) => {
  // Clear the settings state when switching to a different shop.
  // We should probably move this higher up eventually, because there's more state
  // that needs to be reset when switching shops.
  // For now, since outdated settings have been the most noticable, this lives here.
  if (action.type === SET_CURRENT_SHOP) {
    return settingsReducer(undefined, action)
  }

  return settingsReducer(state, action)
}
