import React from 'react'

type CopyFunction = (text: string) => Promise<void>

/**
 * This hook returns a function that allows you to copy a piece of text.
 *
 * It first tries to use the `navigator.clipboard` API if that fails
 * it will use `document.execComand` as a fallback.
 */
export function useClipboardCopy(): CopyFunction {
  const copyRootId = 'copy-root'
  const copyRootRef = React.useRef(document.getElementById(copyRootId))
  const copyContainerRef = React.useRef(document.createElement('div'))

  React.useEffect(() => {
    if (!copyRootRef.current) {
      const copyRoot = document.createElement('div')
      copyRoot.id = copyRootId

      document.body.appendChild(copyRoot)

      copyRootRef.current = copyRoot
    }

    copyRootRef.current?.appendChild(copyContainerRef.current)

    return () => {
      copyRootRef.current?.removeChild(copyContainerRef.current)
    }
  }, [])

  return React.useCallback(async (text: string) => {
    let successful = false

    // prefer to use native clipboard, else fallback on polyfill clipboard
    if (window?.navigator?.clipboard?.writeText) {
      return window.navigator.clipboard.writeText(text)
    }

    // attempt a clipboard polyfill
    if (copyRootRef.current) {
      const input = document.createElement('input')
      input.style.position = 'absolute'
      input.style.top = '-9999px'
      input.style.left = '-9999px'
      input.type = 'text'
      input.value = text

      copyContainerRef.current.appendChild(input)

      input.select()

      try {
        successful = document.execCommand('copy')
      } catch {
        // do nothing successful is already false.
      }

      copyContainerRef.current.removeChild(input)
    }

    if (!successful) {
      throw new Error('failed to copy to clipboard.')
    }
  }, [])
}
