import { faker } from '@faker-js/faker/locale/en'
import { IVehicleWarranty } from '.'
import { MockFn } from '../../mock'
import { MileageType, WarrantyType } from '../enums'

export const mockVehicleWarranty: MockFn<IVehicleWarranty> = (extend?) => ({
  UUID: faker.datatype.uuid(),
  Code: faker.random.alphaNumeric(4),
  Description: faker.random.words(),
  MonthsFrom: WarrantyType.AscriptionDate,
  DurationMonths: faker.datatype.number(),
  Mileage: faker.datatype.number(),
  MileageType: MileageType.Mileage,
  ...extend
})
