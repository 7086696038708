import * as React from 'react'
import { CustomContentProps, SnackbarContent, SnackbarKey, useSnackbar } from 'notistack'
import { Alert, AlertTitle, Button, IconButton, SxProps, alertClasses } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import SnackBarVariantIcon from './ui/SnackBarVariantIcon'
import SnackBarUndoTimerBar from './ui/SnackBarUndoTimerBar'
import { useTranslation } from 'react-i18next'
import { Undo } from 'notifier/types'

interface CustomSnackBarProps extends CustomContentProps {
  /**
   * Override default title
   */

  title?: string
  /**
   * Function to fire when a user clicks the undo button in the snackbar.
   */
  undo?: Undo

  /**
   * ReactNode used for rendering additional action buttons on the snackbar.
   */
  action: React.ReactNode
}

const AlertStyleFn = (undo: boolean) => {
  const AlertStyle: SxProps = {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#FFF',
    color: '#333',
    width: '100%',
    py: 1,
    px: 2,
    pb: !undo ? 1 : 1.75,
    [`& .${alertClasses.action}`]: { alignItems: 'center' },
    '&:hover > div > div': {
      animationPlayState: 'paused'
    }
  }

  return AlertStyle
}

interface ActionOrUndoProps {
  id: SnackbarKey
  undo?: Undo
  action?: React.ReactNode
}

const ActionOrUndo: React.FC<ActionOrUndoProps> = ({ id, undo, action }) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const handleUndo = () => {
    try {
      undo!()
    } catch (e) {
      enqueueSnackbar(`${t('Error.API.ErrUnhandledAPIError')}. Error: ${e}`, { variant: 'error', persist: true })
    } finally {
      closeSnackbar(id)
    }
  }

  return (
    <>
      {action}
      {undo && (
        <Button variant='text' size='small' onClick={() => handleUndo()} sx={{ color: 'inherit' }}>
          {t('global.undo')}
        </Button>
      )}
      <IconButton onClick={() => closeSnackbar(id)}>
        <CloseOutlinedIcon />
      </IconButton>
    </>
  )
}

const CustomSnackBar = React.forwardRef<HTMLDivElement, CustomSnackBarProps>((props, ref) => {
  const {
    // notistack props
    id,
    message,
    autoHideDuration,
    variant,
    // custom props
    title: overrideTitle,
    undo,
    action
  } = props
  const { t } = useTranslation()

  let title = overrideTitle
  if (!title) {
    switch (variant) {
      case 'success':
        title = t('global.success')
        break
      case 'warning':
        title = t('global.warning')
        break
      case 'error':
        title = t('global.error')
        break
      case 'info':
        title = t('global.information')
        break
      default:
        title = t('global.information')
    }
  }

  return (
    <SnackbarContent ref={ref} role='alert'>
      <Alert
        elevation={1}
        icon={<SnackBarVariantIcon variant={variant} />}
        sx={AlertStyleFn(!!undo)}
        action={<ActionOrUndo id={id} undo={undo} action={action} />}
      >
        <AlertTitle>{title}</AlertTitle>
        {message}
        {undo && <SnackBarUndoTimerBar autoHideDuration={autoHideDuration} />}
      </Alert>
    </SnackbarContent>
  )
})

export default CustomSnackBar
