import { GETResponse } from '@cbgms/api'

interface ReportParameter {
  Key: string
  Required: boolean
  TranslationKeyName: string
  Type: string
  SuggestedValue: string
}

export interface Report {
  Key: string
  Locale: string
  Parameters: ReportParameter[]
  TranslationKeyDescription: string
  TranslationKeyName: string
}

export interface ReportSubCategory {
  Key: string
  Reports: Report[]
  TranslationKeyName: string
}

export interface ReportCategory {
  Key: string
  SubCategories: ReportSubCategory[]
  TranslationKeyName: string
}

export interface Reports {
  Categories: ReportCategory[]
}

export interface ReportDataParams {
  category: string
  subCategory: string
  report: string
  format: string
  search?: string
}

export enum TimeRegistrationReportTypeEnum {
  Annual = 'Annual',
  Quarter = 'Quarter'
}

export type GETReports = GETResponse<Reports>

export type GETReport = GETResponse<string>

export const getReports = () => ({
  method: 'GET',
  url: '/modules/reporting/reports/get-structure'
})

export const getReport = ({ category, subCategory, report, format, search }: ReportDataParams) => ({
  method: 'GET',
  url: `/modules/reporting/reports/${category}/${subCategory}/${report}/${format}${search}`
})
