import { SubmissionError } from 'redux-form'
import { Middleware } from 'redux'
import { IFailedRequest } from './errorHandler/errorHandler'
import navigation from 'utils/navigation'
import { IHttpAdapterDictionary, IHttpRequest } from './types'

const INVALID_UUID = 'ErrInvalidUUID'
const WORKORDER_NOT_FOUND = 'ErrWorkorderNotFound'
const INVOICE_NOT_FOUND = 'ErrInvoiceNotFound'

const httpMiddleware =
  (httpAdapters: IHttpAdapterDictionary, errorHandler: (req: IFailedRequest) => any): Middleware =>
  store =>
  next =>
  action => {
    const { type, payload, shouldIgnoreError } = action
    if (!Array.isArray(type) || type.length !== 3) {
      return next(action)
    }
    if (!type.every(type => typeof type === 'string')) {
      throw new Error('http middleware: Expected an array of three string types.')
    }
    const [requestType, successType, failureType] = type
    const httpRequest = payload as IHttpRequest
    store.dispatch({ type: requestType })

    const handleRequest = async () => {
      try {
        httpRequest.request.url = encodeURI(httpRequest.request.url)
        const data = await httpAdapters[httpRequest.api].request(httpRequest.request)
        store.dispatch({
          payload: {
            data
          },
          request: payload,
          type: successType
        })

        return data
      } catch (error: any) {
        if (!shouldIgnoreError) {
          const result = await errorHandler({ action, error, request: handleRequest, store })
          const errorMessage = error.response?.data?.Error || null

          if (errorMessage === INVALID_UUID || errorMessage === WORKORDER_NOT_FOUND || errorMessage === INVOICE_NOT_FOUND) {
            navigation.push('/404', error)
          }

          if (!result) {
            store.dispatch({
              error: true,
              payload: error.response,
              request: payload,
              type: failureType
            })
            throw error
          }

          if (result instanceof SubmissionError) {
            store.dispatch({
              error: true,
              payload: error.response,
              request: payload,
              type: failureType
            })
            throw result
          }
          return result
        }
        throw error
      }
    }

    return handleRequest()
  }

export default httpMiddleware
