import { forwardRef } from 'react'
import { LoadingButtonProps, LoadingButton as MUILoadingButton } from '@mui/lab'

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>((props: LoadingButtonProps, ref) => {
  const loadingPosition = props.loadingPosition || (props.startIcon ? 'start' : undefined) || (props.endIcon ? 'end' : undefined)
  const variant = props.variant || 'contained'

  return <MUILoadingButton ref={ref} {...props} loadingPosition={loadingPosition} variant={variant} />
})

export default LoadingButton
