import React from 'react'
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export interface DialogTitleWithCloseButtonProps extends DialogTitleProps {
  draggableIDHandler?: string
  children: React.ReactNode
  onClose: () => void
}

export const DialogTitleWithCloseButton = (props: DialogTitleWithCloseButtonProps) => {
  const { children, onClose, draggableIDHandler, ...other } = props
  return (
    <MuiDialogTitle
      sx={{ m: 0, p: 2 }}
      data-draggableid={draggableIDHandler}
      style={{ cursor: draggableIDHandler ? 'move' : '' }}
      {...other}
    >
      {children}
      <IconButton
        data-testid='dialog-close-button'
        aria-label='close'
        sx={{
          position: 'absolute',
          right: theme => theme.spacing(1),
          top: theme => theme.spacing(1),
          color: theme => theme.palette.grey[500]
        }}
        onClick={onClose}
        size='large'
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
}
