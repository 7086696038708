import React from 'react'
import { useTranslation } from 'react-i18next'
import Upload from '@mui/icons-material/Upload'
import { LoadingButton } from '@mui/lab'

interface Props {
  onUpload: () => void
  disabled: boolean
  loading: boolean
}
export const UploadButton: React.FC<Props> = ({ onUpload, disabled, loading }) => {
  const { t } = useTranslation()
  return (
    <LoadingButton disabled={disabled} fullWidth loading={loading} onClick={onUpload} startIcon={<Upload />} variant='contained'>
      {t('FileUploader.Buttons.Upload')}
    </LoadingButton>
  )
}
