import { FormErrors, SubmissionError } from 'redux-form'
import i18next from 'i18next'
import set from 'lodash/fp/set'

import { ValidationErrorResponse, ValidationErrors } from '../utils'

class ValidationError extends SubmissionError {
  /**
   * We're extending Redux-Form's submission error with `unformattedErrors`. We need
   * this property for so we can translate validation errors properly when
   * using Formik.
   */
  constructor(errors: FormErrors<any, any>, public unformattedErrors: ValidationErrors) {
    super(errors)
  }
}

function validationError({ error }: { error: ValidationErrorResponse }) {
  const errors = error.response.data.ValidationErrors

  const validationErrors = errors.reduce((prev, curr) => {
    const error = i18next.exists(`global.${curr.ErrorType}`) ? i18next.t(`global.${curr.ErrorType}`) : curr.ErrorType

    return set(curr.FieldName, error, prev)
  }, {})

  return new ValidationError(validationErrors, errors)
}

export default validationError
