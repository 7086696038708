import React from 'react'
import { CircularProgress, IconButton } from '@mui/material'
import Save from '@mui/icons-material/Save'

interface IProps {
  onClick: () => void
  disabled?: boolean
  loading?: boolean
  edge?: 'start' | 'end' | false
}

export const SaveIconButton: React.FC<IProps> = ({ onClick, disabled, loading, edge }) => (
  <IconButton aria-label='save' onClick={onClick} disabled={disabled} edge={edge} size='large'>
    {loading ? <CircularProgress size={24} /> : <Save />}
  </IconButton>
)

SaveIconButton.defaultProps = {
  disabled: false,
  loading: false
}
