import { AnyAction } from 'redux'

import {
  LOAD_ADMINISTRATION_ADMINISTRATIONS_SUCCESS,
  LOAD_ADMINISTRATION_JOURNALS_SUCCESS,
  LOAD_ADMINISTRATION_LEDGERS_SUCCESS,
  LOAD_ADMINISTRATION_VATCODES_SUCCESS,
  RESET_ADMINISTRATION_OPTIONS
} from 'app/settings/state/Administrations/types'

import { DropdownItemProps } from 'semantic-ui-react'

export interface AdministrationOptionState {
  administrations: DropdownItemProps[]
  vatCodes: DropdownItemProps[]
  journals: DropdownItemProps[]
  ledgers: DropdownItemProps[]
}

const defaultState = {
  administrations: [],
  vatCodes: [],
  journals: [],
  ledgers: []
}

interface CodeAndDescription {
  Code: string
  Description: string
}

const mapToAdministrationOptions = (option: CodeAndDescription): DropdownItemProps => {
  return {
    key: option.Code,
    value: option.Code,
    text: `${option.Code} ${option.Description}`
  }
}

export const administrationOptionsReducer = (
  state: AdministrationOptionState = defaultState,
  action: AnyAction
): AdministrationOptionState => {
  switch (action.type) {
    case LOAD_ADMINISTRATION_ADMINISTRATIONS_SUCCESS:
      return {
        ...state,
        administrations: action.payload.data.Data.map((option: { ID: string; Code: string; Description: string }) => ({
          key: option.ID,
          value: option.ID,
          text: option.Description,
          code: option.Code
        }))
      }
    case LOAD_ADMINISTRATION_JOURNALS_SUCCESS:
      return {
        ...state,
        journals: action.payload.data.Data.map(mapToAdministrationOptions)
      }
    case LOAD_ADMINISTRATION_VATCODES_SUCCESS:
      return {
        ...state,
        vatCodes: action.payload.data.Data.map(mapToAdministrationOptions)
      }
    case LOAD_ADMINISTRATION_LEDGERS_SUCCESS:
      return {
        ...state,
        ledgers: action.payload.data.Data.map(mapToAdministrationOptions)
      }
    case RESET_ADMINISTRATION_OPTIONS:
      return defaultState
    default:
      return state
  }
}
