import { useQuery } from '@cbgms/base/http/use-query'
import { isEmptyUUID } from '@cbgms/base/utils/uuid'
import { getWholesalerByUUID } from 'app/auth/state/actions'
import { useDispatch } from 'react-redux'

export const ONBOARDING_CACHE_KEY = 'ONBOARDING'
export const EXISTING_SHOP_CACHE_KEY = 'EXISTING_SHOP'

const getWholesalerCacheKey = (uuid: string) => [ONBOARDING_CACHE_KEY, EXISTING_SHOP_CACHE_KEY, uuid]

export const useGetOnboardingWholesaler = (uuid: string) => {
  const dispatch = useDispatch()
  const result = useQuery(getWholesalerCacheKey(uuid), async () => {
    if (isEmptyUUID(uuid)) {
      return null
    }
    return dispatch(getWholesalerByUUID(uuid))
  })

  return result
}
