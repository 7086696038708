import { dateConverter } from './dateConverter'
import { changeFormat, changeFormatters, format } from './dateFormat'
import { apiFormat } from './apiFormat'
import { TimeNotation } from '@carsys/enums/time-notation'

export const setTimeNotation = (time: TimeNotation) => {
  const timeFormat = time === TimeNotation.Wrong ? 'hh:mm A' : 'HH:mm'
  const timeFormatSeconds = time === TimeNotation.Wrong ? 'hh:mm:ss A' : 'HH:mm:ss'

  changeFormat({
    shortDateFull: 'L',
    longDateFormat: `L - ${timeFormat}`,
    longWeekdayDateTime: `dddd L ${timeFormat}`,
    longWeekdayDate: 'dddd L',
    dayDateTime: `L ${timeFormat}`,
    time: timeFormat,
    fullDateTime: `L ${timeFormatSeconds}`,
    fullTime: timeFormatSeconds,
    date: 'L',
    monthShort: 'MMM',
    monthLong: 'MMMM',
    year: 'YYYY',
    weekday: 'dddd'
  })

  changeFormatters({
    date: dateConverter(format.shortDateFull, apiFormat.yearMonthDay),
    datetime: dateConverter(format.longDateFormat, apiFormat.iso),
    time: dateConverter(format.time, apiFormat.time)
  })
}
