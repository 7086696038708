import { combineReducers } from 'redux'

import { accountReducer } from './accountReducer'
import { accountNumberReducer } from './accountNumberReducer'
import { wholesaleReducer } from './wholesaler/reducer'

const account = combineReducers({
  detail: accountReducer,
  numbers: accountNumberReducer,
  wholesale: wholesaleReducer
})

export default account
