import { IAccountNumber } from '@cbgms/api/modules/accounts'

import { LoadAccountNumbersSuccess, ResetAccount } from './actions'
import { LOAD_ACCOUNT_NUMBERS_SUCCESS, RESET_ACCOUNT } from './types'

type State = readonly IAccountNumber[]
type Actions = LoadAccountNumbersSuccess | ResetAccount

export const accountNumberReducer = (state: State = [], action: Actions): State => {
  switch (action.type) {
    case LOAD_ACCOUNT_NUMBERS_SUCCESS:
      return action.payload.data.Data
    case RESET_ACCOUNT:
      return []
    default:
      return state
  }
}
