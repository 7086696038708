import moment from 'moment'
import 'moment-timezone'
import setup from 'moment-duration-format'

// @ts-ignore there seems to be a weird issue here with the types
setup(moment)

export * from './apiFormat'
export * from './dateConverter'
export { format, formatters } from './dateFormat'
export * from './durationToMinutes'
export * from './emptyDates'
export * from './formatDateTime'
export * from './formatDeliveryTime'
export * from './minutesToHours'
export * from './setTimeNotation'
export * from './setTimezone'
export * from './Date'
export * from './DateFromNow'

moment.tz.setDefault('Europe/Amsterdam')
