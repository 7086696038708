import get from 'lodash/fp/get'

export interface IGroup<TValue> {
  Key: string
  Values: TValue[]
}

export const groupBy = <T extends object>(list: T[], key: string): Array<IGroup<T>> => {
  const getKey = get(key)

  const grouped = list.reduce((groups, item) => {
    const value = getKey(item)
    let group = groups.find(g => g.Key === value)
    if (!group) {
      group = {
        Key: value,
        Values: []
      }
      groups.push(group)
    }
    group.Values.push(item)
    return groups
  }, [] as IGroup<T>[])

  return grouped
}

export const groupByFunc = <T extends object>(list: T[], selector: (arg0: T) => string): Array<IGroup<T>> => {
  const grouped = list.reduce((groups, item) => {
    const value = selector(item)
    let group = groups.find(g => g.Key === value)
    if (!group) {
      group = {
        Key: value,
        Values: []
      }
      groups.push(group)
    }
    group.Values.push(item)
    return groups
  }, [] as IGroup<T>[])

  return grouped
}
