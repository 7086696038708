import { IAccount, IVehicle } from '../modules'
import { IPaginatedRequest } from '../request'
import { IPaymentMethod } from './paymentmethods'

interface IInvoice {
  CreatedAt: string
  InvoiceAccountUUID: string
  TotalVatExcluded: number
  WorkorderInvoiceUUID: string
}

export const getOpenInvoices = (accountUUID: string, params?: IPaginatedRequest) => ({
  method: 'GET',
  url: `modules/accounts/${accountUUID}/unpaid-invoices`,
  params
})

export const getUnpaidInvoicesSummary = (accountUUID: string) => ({
  method: 'GET',
  url: `modules/accounts/${accountUUID}/unpaid-invoices-summary`
})

export interface IOpenInvoicesListOutput {
  Account: IAccount
  Vehicle: IVehicle
  Invoice: IInvoice
}

export interface IUnpaidInvoicesSummary {
  PaymentAmountDue: number
  TotalVatExcluded: number
  TotalVatIncluded: number
}

export interface POSTGetPaymentBalanceOfSelectedInvoices {
  InvoiceUUIDS: string[]
}

interface IPaymentMethodWithBalance extends IPaymentMethod {
  CurrentBalance: number
}
export interface IPaymentBalanceOfSelectedInvoicesOutput {
  Balances: {
    InvoiceUUID: string
    PaymentMethods: IPaymentMethodWithBalance[]
    AmountPaid: number
    AmountRemaining: number
    AmountTotal: number
  }[]
  Totals: {
    AmountPaid: number
    AmountRemaining: number
    AmountTotal: number
    PaymentMethods: IPaymentMethodWithBalance[]
  }
}

export const getPaymentBalanceOfSelectedInvoices = (data: POSTGetPaymentBalanceOfSelectedInvoices) => ({
  method: 'GET',
  url: `modules/multiple-workorder-invoices/payment-balances`,
  params: data
})

export interface POSTPaySelectedInvoices {
  InvoiceUUIDS: string[]
  AmountPaid: number
  PaymentMethodUUID: string
  PaymentDate?: string
}

export const paySelectedInvoices = (data: POSTPaySelectedInvoices) => ({
  method: 'POST',
  url: `modules/multiple-workorder-invoices/multiple-payments`,
  data
})
