import { ArchiveType, GETResponse, ProductIDType, WorkorderLineType } from '@cbgms/api'
import { OdometerType } from '@carsys/enums/odometer-type'


export interface ArchiveLine {
  ID: string
  Date: string
  OdometerUnit: OdometerType
  OdometerValue: number | null
  Code: string
  Description: string
  Price: number
  SupplierProductID: string
  SupplierProductIDType: ProductIDType
  LineType: WorkorderLineType
  LineTypeUUID: string
  OrderAccountUUID: string
  Source: ArchiveType
  VehicleUUID: string
  Quantity: number
}

export type GETArchiveLines = GETResponse<ArchiveLine[]>

export const getArchiveLines = (vehicleUUID?: string, searchTerm?: string) => ({
  method: 'GET',
  url: '/modules/archive/lines',
  params: {
    VehicleUUID: vehicleUUID,
    SearchTerm: searchTerm
  }
})
