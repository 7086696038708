import { Link } from 'react-router-dom'
import MuiTab, { TabProps } from '@mui/material/Tab'

interface FormTabProps extends Omit<TabProps<typeof Link>, 'to'> {
  /**
   * This value property is needed for the internal MUI implementation.
   * This will take the hash used for this specific tab.
   */
  value: string
}

export function Tab(props: FormTabProps) {
  return <MuiTab {...props} component={Link} label={props.label} to={{ hash: props.value }} value={props.value} />
}
