import React from 'react'
import { Box } from '@mui/material'

export interface Props {
  /**
   * An element representing the main icon to display.
   */
  MainIcon: JSX.Element
  /**
   * An element representing the secondary icon to display. It'll be shown
   * at the top right.
   */
  SecondaryIcon: JSX.Element
  /**
   * In some cases the stroke width for the secondary icon
   * is a little off. In those cases use this property
   * to change the stroke width.
   */
  strokeWidth?: number
  /**
   * In some cases icons need to be bit bigger
   * Increasing the fontsize can be helpfull then
   */
  secondaryFontSize?: number
  /**
   * If the icon is placed on a button or a component with
   * a different color than the body color you should pass
   * the backgroundColor of the parent component
   */
  backgroundColor?: string

  secondaryIconPosition?: 'top-right' | 'bottom-right'
}

interface SecondaryIconStyles {
  fontSize: number
  left: number
  position: React.CSSProperties['position']
  top?: number
  bottom?: number
}

// Forward ref to make tooltips work.
// See: https://mui.com/material-ui/react-tooltip/#custom-child-element
export const StackedIcon = React.forwardRef<unknown, Props>(
  (
    {
      MainIcon,
      SecondaryIcon,
      strokeWidth = 6,
      secondaryFontSize,
      // ideally we would take the backgroundColor of the parent instead of white, hard to achive that considering transitions and such
      backgroundColor = 'background.default',
      secondaryIconPosition = 'top-right',
      ...forwardRefProps
    },
    ref
  ) => {
    const mainIconRef = React.useRef<SVGSVGElement>(null)

    const [secondaryIconStyles, setSecondaryIconStyles] = React.useState<SecondaryIconStyles>({
      ...calculatePositioning(mainIconRef.current, secondaryFontSize),
      position: 'absolute',
      top: secondaryIconPosition === 'top-right' ? 0 : undefined,
      bottom: secondaryIconPosition === 'bottom-right' ? 0 : undefined
    })

    React.useLayoutEffect(() => {
      setSecondaryIconStyles(previousState => ({
        ...previousState,
        ...calculatePositioning(mainIconRef.current, secondaryFontSize)
      }))
    }, [MainIcon])

    const mainIcon = React.cloneElement(MainIcon, { ref: mainIconRef })

    const secondaryIcon = React.cloneElement(SecondaryIcon, {
      sx: secondaryIconStyles
    })

    const secondaryIconOutline = React.cloneElement(SecondaryIcon, {
      sx: {
        color: backgroundColor,
        fill: 'currentcolor',
        stroke: 'currentcolor',
        strokeWidth,
        ...secondaryIconStyles
      }
    })

    return (
      <Box {...forwardRefProps} sx={{ display: 'inline-flex', position: 'relative' }} ref={ref}>
        {mainIcon}
        {secondaryIconOutline}
        {secondaryIcon}
      </Box>
    )
  }
)

function calculatePositioning(element: SVGSVGElement | null, secondaryFontSize?: number) {
  return {
    fontSize: secondaryFontSize || (element ? element.clientWidth / 2 : 12),
    left: secondaryFontSize || (element ? element.clientWidth / 2 : 12)
  }
}
