import { faker } from '@faker-js/faker/locale/en'
import { SentSurvey } from './models'
import { SurveyProviderType } from '@carsys/enums/survey-provider'

export const mockSentSurvey = (sentSurvey?: Partial<SentSurvey>): SentSurvey => ({
  CreatedAt: faker.date.recent().toISOString(),
  UpdatedAt: faker.date.between(faker.date.recent(), faker.date.soon()).toISOString(),
  UUID: faker.datatype.uuid(),
  WorkorderInvoiceUUID: faker.datatype.uuid(),
  SurveyProviderType: faker.helpers.objectValue(SurveyProviderType),
  SentAt: faker.date.soon().toISOString(),
  EmailAddress: faker.internet.email(),
  FirstName: faker.name.firstName(),
  LastName: faker.name.lastName(),
  Status: 'sent',
  StatusErrorKey: '',
  ...sentSurvey
})
