import { Reducer } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import * as types from 'app/auth/state/types'

interface ITokenReducerState {
  AccessToken: string
  ExpiresIn: number
  IdToken: string
  RefreshToken: string
  TokenType: string
}

export type TokenReducerState = Readonly<ITokenReducerState>

const defaultTokenState: TokenReducerState = {
  AccessToken: '',
  ExpiresIn: 0,
  IdToken: '',
  RefreshToken: '',
  TokenType: 'Bearer'
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type TokenReducerBehavior<S> = Record<typeof types.LOGIN_SUCCESS, Reducer<S, any>> &
  Record<typeof types.SET_ACCESS_TOKEN, Reducer<S, any>> &
  Record<typeof types.SET_ONBOARDING_TOKEN, Reducer<S, any>>

const tokens = createReducer<TokenReducerState, TokenReducerBehavior<TokenReducerState>>(defaultTokenState, {
  [types.LOGIN_SUCCESS]: (_, { payload }) => {
    const { IDToken, ...rest } = payload.data.Data.AuthenticationResult
    return {
      ...rest,
      IdToken: IDToken
    }
  },
  [types.SET_ACCESS_TOKEN]: (state, { payload }) => {
    const { AccessToken, IDToken } = payload.data.Data.AuthenticationResult

    return {
      ...defaultTokenState,
      ...state,
      AccessToken,
      IdToken: IDToken
    }
  },
  [types.SET_ONBOARDING_TOKEN]: (state, { payload }) => {
    // Onboarding tokens are temporary, they're not yet attached to a shop/user, but are still needed to call the api.
    // we give an onboarding token when the user proves he has valid order-account credentials
    const { AccessToken, IDToken } = payload.data.Data.AuthenticationResult

    return {
      ...defaultTokenState,
      ...state,
      AccessToken,
      IdToken: IDToken
    }
  }
})

export default tokens
