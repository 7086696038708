import * as React from 'react'
import { Box } from '@mui/material'
import { keyframes } from '@mui/system'

interface SnackBarUndoTimerBarprops {
  /**
   * Time in milliseconds for showing the undo time progress bar animation.
   */
  autoHideDuration?: number | undefined | null
}

const SnackBarUndoTimerBar: React.FC<SnackBarUndoTimerBarprops> = ({ autoHideDuration }) => {
  const shrink = keyframes`
    0% { width: 100% },
    100% { width: 0% }`

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: 10,
          width: '100%',
          backgroundColor: 'rgba(0,0,0,0.4)',
          animation: `${shrink} ${autoHideDuration}ms 1 linear`,
          animationFillMode: 'forwards'
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: 10,
          width: '100%',
          backgroundColor: 'rgba(0,0,0,0.1)'
        }}
      />
    </>
  )
}

export default SnackBarUndoTimerBar
