export const LOAD_EMPLOYEE_SKILLS = 'LOAD_EMPLOYEE_SKILLS'
export const LOAD_EMPLOYEE_SKILLS_SUCCESS = 'LOAD_EMPLOYEE_SKILLS_SUCCESS'
export const LOAD_EMPLOYEE_SKILLS_FAILED = 'LOAD_EMPLOYEE_SKILLS_FAILED'

export const LOAD_EMPLOYEE_SKILL = 'LOAD_EMPLOYEE_SKILL'
export const LOAD_EMPLOYEE_SKILL_SUCCESS = 'LOAD_EMPLOYEE_SKILL_SUCCESS'
export const LOAD_EMPLOYEE_SKILL_FAILED = 'LOAD_EMPLOYEE_SKILL_FAILED'

export const CREATE_EMPLOYEE_SKILL = 'CREATE_EMPLOYEE_SKILL'
export const CREATE_EMPLOYEE_SKILL_SUCCESS = 'CREATE_EMPLOYEE_SKILL_SUCCESS'
export const CREATE_EMPLOYEE_SKILL_FAILED = 'CREATE_EMPLOYEE_SKILL_FAILED'

export const UPDATE_EMPLOYEE_SKILL = 'UPDATE_EMPLOYEE_SKILL'
export const UPDATE_EMPLOYEE_SKILL_SUCCESS = 'UPDATE_EMPLOYEE_SKILL_SUCCESS'
export const UPDATE_EMPLOYEE_SKILL_FAILED = 'UPDATE_EMPLOYEE_SKILL_FAILED'

export const DELETE_EMPLOYEE_SKILL = 'DELETE_EMPLOYEE_SKILL'
export const DELETE_EMPLOYEE_SKILL_SUCCESS = 'DELETE_EMPLOYEE_SKILL_SUCCESS'
export const DELETE_EMPLOYEE_SKILL_FAILED = 'DELETE_EMPLOYEE_SKILL_FAILED'

export const RESET_EMPLOYEE_SKILL = 'RESET_EMPLOYEE_SKILL'

export const LOAD_ALL_EMPLOYEE_SKILLS = 'LOAD_ALL_EMPLOYEE_SKILLS'
export const LOAD_ALL_EMPLOYEE_SKILLS_SUCCESS = 'LOAD_ALL_EMPLOYEE_SKILLS_SUCCESS'
export const LOAD_ALL_EMPLOYEE_SKILLS_FAILED = 'LOAD_ALL_EMPLOYEE_SKILLS_FAILED'
