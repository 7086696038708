import { AnyAction } from 'redux'

import { SupportedCountryCode } from '@cbgms/api'

import { GET_ENVIRONMENT_COUNTRIES_SUCCESS } from 'core/widgets/CountriesDropdown/types'

type State = readonly SupportedCountryCode[]

const defaultState: State = []

export function environmentReducer(state: State = defaultState, action: AnyAction): State {
  switch (action.type) {
    case GET_ENVIRONMENT_COUNTRIES_SUCCESS:
      return action.payload.data.Data
    default:
      return state
  }
}
