import { ModifyNotificationSchedule } from './notificationSchedule'
import { HttpMethod } from '@cbgms/api/http-method'
import { UUIDResponse } from '@cbgms/api/helpers'

export type POSTNotificationScheduleRequest = ModifyNotificationSchedule
export type POSTNotificationScheduleResponse = UUIDResponse

export const createNotificationScheduleRequest = (notificationSchedule: POSTNotificationScheduleRequest) => ({
  method: HttpMethod.POST,
  url: 'modules/notification-schedules',
  data: notificationSchedule
})
