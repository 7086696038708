import React from 'react'
import FeaturesContext from './context'

interface Props {
  features: string[]
  children: React.ReactNode
}

const FeaturesContextProvider = ({ features, children }: Props) => {
  return <FeaturesContext.Provider value={features}>{children}</FeaturesContext.Provider>
}

export default FeaturesContextProvider
