import { IEmployee, IOrderAccountData, IShop, WorkorderLineState } from '@cbgms/api'
import {
  EcommerceBase,
  IAnalyticsCTX,
  IAnalyticsCTXData,
  IAnalyticsEmployeeData,
  IAnalyticsShopInfoData,
  IAnalyticsSupplierData,
  Item,
  Order,
  OrderLineCollection,
  Purchase
} from './Types'
import { ILogContextData } from 'contexts/Logging/useLogContextData'
import { countryCodeFromShopID } from 'app/auth/components/hooks/use-onboarding-setup'

export function orderToPurchase(ctx: ILogContextData, order: Order): Purchase {
  const countryCode = countryCodeFromShopID(ctx.shop.ID) || ''

  return {
    transaction_id: order.orderUUID,
    /*
      Calculating tax because we don't have this property.
    */
    tax: order.totals.totalVatIncl - order.totals.totalVatExcl,
    /*
      Docs state that currency is required when sending value.
      We we don't have this property, so it will be hardcoded for now.
    */
    currency: currencyByCountry(countryCode),
    value: order.totals.totalVatExcl,
    items: mapItems(ctx, order)
  }
}

function mapItems(ctx: ILogContextData, order: OrderLineCollection): Item[] {
  const countryCode = countryCodeFromShopID(ctx.shop.ID) || ''
  const affiliation = mapAffiliation(ctx)

  const flatLines = flattenOrderLines(order.orderLines)

  return flatLines.map((line, index) => ({
    item_id: line.SupplierProductID,
    item_name: line.LineCode,
    index, // don't use sortOrder as index, sort is not persisted between child/parent relations, we have duplicates
    item_brand: line.ExternalPart?.Brand || '',
    item_category: line.LineType,
    item_category2: line.LineDescription,
    item_category3: line.GenericArticleID || line.ForeignType || '',
    item_category4: line.GenericArticleDescription || line.LineTypeUUID || line.ForeignID || '',
    // price should be single unit price.
    // use the external part netPrice, if that's empty, fall back on lineCostPrice,
    // lineCostPrice is the total cost price (quantity*unitPrice), we should account for that
    price: line.ExternalPart?.NetPrice || line.LineCostPrice / line.Quantity,
    quantity: line.Quantity,
    affiliation,
    location_id: countryCode
  }))
}

// flattenOrderLines will recursively flatten the workorderlines in case there's child/lines
// order is persisted, childs are inserted in between parents
function flattenOrderLines(lines: WorkorderLineState[]): WorkorderLineState[] {
  return lines.reduce<WorkorderLineState[]>((acum, line) => {
    if (!line.IsPlaceholder) {
      // don't report place-holder on purchase events, they just mess up the stats
      acum.push(line)
    }
    if (line.childLines?.length) {
      acum.push(...flattenOrderLines(line.childLines))
    }
    return acum
  }, [])
}

function currencyByCountry(countryCode: string): string {
  switch ((countryCode || '').toUpperCase()) {
    case 'UK':
      return 'GBP'
    case 'CH':
      return 'CHF'
    case 'US':
      return 'USD'
    case 'PL':
      return 'PLN'
    case 'SE':
      return 'SEK'
    case 'DK':
      return 'DKK'
    default:
      return 'EUR'
  }
}

export function lineCollectionToAddToCart(ctx: ILogContextData, order: OrderLineCollection): EcommerceBase {
  const countryCode = countryCodeFromShopID(ctx.shop.ID) || ''

  return {
    currency: currencyByCountry(countryCode),
    value: 0, // value intentionally left empty for now, unsure if this is needed on addToCart events.
    items: mapItems(ctx, order)
  }
}

export function lineCollectionToRemoveFromCart(ctx: ILogContextData, order: OrderLineCollection): EcommerceBase {
  const countryCode = countryCodeFromShopID(ctx.shop.ID) || ''

  return {
    currency: currencyByCountry(countryCode),
    value: 0, // value intentionally left empty, might need to have the cost of the removed item
    items: mapItems(ctx, order)
  }
}

function mapAffiliation(ctx: ILogContextData): string {
  // affiliation is a mandatory field and should point to the category
  return ctx.orderAccount?.Wholesaler?.Name || 'unknown'
}

export function ctxToAnalyticsCTX(ctx: ILogContextData): IAnalyticsCTX {
  return {
    employee: employeeToAnalyticsEmployee(ctx.employee),
    shop: shopToAnalyticsShop(ctx.shop),
    supplier: orderAccountToSupplier(ctx.orderAccount),
    ctx: ctxToAnalyticsCTXData(ctx)
  }
}

export function normalizePath(path: string): string {
  // First replace all UUIDs
  let result = path.replaceAll(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g, ':uuid')
  // Replace non uuid variable url parts: prefix + non greedy capture until / or end of string
  result = result.replace(/(product-groups\/).*?(\/|$)/g, '$1:uuid$2')
  result = result.replace(/(product-details-modal\/.*?\/).*?(\/|$)/g, '$1:uuid$2')
  result = result.replace(/(haynes\/repair-times\/).*?(\/|$)/g, '$1:uuid$2')
  result = result.replace(/(haynes\/service-schedule\/).*?(\/|$)/g, '$1:uuid$2')
  result = result.replace(/(search-products\/:uuid\/).*?(\/|$)/g, '$1:searchTerm$2')
  return result
}

function employeeToAnalyticsEmployee(employee: IEmployee): IAnalyticsEmployeeData {
  return {
    CreatedAt: employee.CreatedAt,
    DateInService: employee.DateInService,
    Functions: employee.Functions,
    MOT: employee.MOT,
    ProfileColour: employee.ProfileColour,
    UUID: employee.UUID,
    EmployeeStatus: employee.EmployeeStatus,
    HasAcceptedGarageAdminTermsAndConditions: employee.HasAcceptedGarageAdminTermsAndConditions,
    HasAcceptedPortalTermsAndConditions: employee.HasAcceptedPortalTermsAndConditions,
    HasAcceptedGMSTermsAndConditions: employee.HasAcceptedPortalTermsAndConditions
  }
}

function shopToAnalyticsShop(shop: IShop): IAnalyticsShopInfoData {
  return {
    CompanyID: shop.CompanyUUID,
    ShopID: shop.ID,
    ShopName: shop.Name,
    ShopCountry: countryCodeFromShopID(shop.ID) || 'unknown',
    ModuleGMSEnabled: shop.ModuleGMSEnabled,
    ModulePortalEnabled: shop.ModulePortalEnabled,
    ModuleIsSupportEnabled: shop.ModuleIsSupportEnabled,
    ModuleTimeRegistrationEnabled: shop.ModuleTimeRegistrationEnabled,
    ModuleVehicleSalesEnabled: shop.ModuleVehicleSalesEnabled,
    ModuleTyreChangeEnabled: shop.ModuleTyreChangeEnabled,
    ModuleCourtesyVehiclesEnabled: shop.ModuleCourtesyVehiclesEnabled,
    ModuleRentalVehiclesEnabled: shop.ModuleRentalVehiclesEnabled,
    ModuleAddOrderAccount: shop.ModuleAddOrderAccount,
    ModuleFleetLeaseEnabled: shop.ModuleFleetLeaseEnabled,
    ModuleSwitchDefaultOrderAccount: shop.ModuleSwitchDefaultOrderAccount,
    ModuleCRMEnabled: shop.ModuleCRMEnabled,
    ModuleInternalInvoicingEnabled: shop.ModuleInternalInvoicingEnabled,
    ModuleFleetLeaseWhiteLabelEnabled: shop.ModuleFleetLeaseWhiteLabelEnabled
  }
}

function orderAccountToSupplier(oa: IOrderAccountData | null): IAnalyticsSupplierData {
  return {
    SupplierName: oa?.Wholesaler?.Name || 'unknown',
    OrderSystem: oa?.Wholesaler?.OrderSystem || 'unknown',
    CMSSystem: oa?.Wholesaler?.CMSSystem || 'unknown',
    CMSURL: oa?.Wholesaler?.CMSURL || 'unknown',
    TicketSystem: oa?.Wholesaler?.TicketSystem || 'unknown',
    Country: oa?.Wholesaler?.Country || 'unknown',
    SupplierAccountUserID: oa?.OrderAccount?.Credentials?.UserId || 'unknown',
    SupplierActiveServices: oa?.WholesalerOrderSystemServices?.filter(a => a.Active).map<string>(a => a.Service as string) || [],
    CatalogType: oa?.Catalog?.CatalogType || 'unknown'
  }
}

function ctxToAnalyticsCTXData(ctx: ILogContextData): IAnalyticsCTXData {
  const isPortal = !!(ctx.shop.ModulePortalEnabled && !ctx.shop.ModuleGMSEnabled)
  const isGarageAdmin = !ctx.shop.ModulePortalEnabled && !ctx.shop.ModuleGMSEnabled
  const isGMS = !!ctx.shop.ModuleGMSEnabled

  let name = ''
  if (!name && isPortal) name = 'B2B'
  if (!name && isGarageAdmin) name = 'GA'
  if (!name && isGMS) name = 'GMS'
  if (!name) name = 'unknown'

  return {
    ApplicationName: name,
    IsPortal: isPortal,
    IsGarageAdmin: isGarageAdmin,
    IsGMS: isGMS
  }
}
