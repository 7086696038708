import { useCallback } from 'react'
import { sendClickEvent } from 'utils/TagManager/tag-manager'
import { ILogContextData } from './useLogContextData'

export interface ClickEventParams {}
/**
 * useClickLoggerFunc will create the click logging function,
 * Calling Google analytics / tag manager and other logging platforms
 */
export type LogClickEvent = (event: React.MouseEvent<HTMLElement>, data: ClickEventParams) => void

export const useClickLoggerFunc = (ctx: ILogContextData): LogClickEvent => {
  const googleTagManagerClickLogger = useCallback(
    (event: React.MouseEvent<HTMLElement>, data: ClickEventParams) => sendClickEvent(event, ctx, data),
    [sendClickEvent, ctx]
  )

  return useCallback(
    (event: React.MouseEvent<HTMLElement>, data: ClickEventParams) => {
      try {
        googleTagManagerClickLogger(event, data)
      } catch (e) {
        console.error('unable to log click event', e)
      }
    },
    [googleTagManagerClickLogger]
  )
}
