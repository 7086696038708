import { Middleware } from 'redux'
import * as Sentry from '@sentry/react'
import { REHYDRATE } from 'redux-persist/lib/constants'

import { LOGIN_SUCCESS, LOGOUT } from 'app/auth/state/types'
import { getUserContext } from 'utils/sentry'

const sentryMiddleware: Middleware = store => next => action => {
  if (action.type === LOGIN_SUCCESS) {
    Sentry.configureScope(scope => {
      scope.setUser(getUserContext(action))
    })
  }

  if (action.type === REHYDRATE) {
    Sentry.configureScope(scope => {
      scope.setUser(getUserContext(store))
    })
  }

  if (action.type === LOGOUT) {
    Sentry.configureScope(scope => scope.clear())
  }

  return next(action)
}

export default sentryMiddleware
