import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'

import { HttpMethod } from '../../http-method'
import { GETArrayResponse } from '../../helpers'

import { MessageType } from './message-types'
import { Template } from './template'

export type GETTemplatesResponse = GETArrayResponse<Template>

export const getTemplatesRequest = (messageType: string) => ({
  method: HttpMethod.GET,
  params: {
    MessageType: messageType
  },
  url: 'modules/crm/templates'
})

export const getTemplates = (messageTypes: MessageType[] | MessageType) =>
  createAsyncApiAction<GETTemplatesResponse>('gms', {
    method: HttpMethod.GET,
    url: '/modules/crm/templates',
    params: {
      MessageType: messageTypes
    }
  })
