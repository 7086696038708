import { AnyAction, Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from '../types'

export type PermissionExtensionReducerState = Readonly<PermissionExtension>
interface PermissionExtension {
  modifiedPermissions: ModifiedPermissionCollection
}
export interface ModifiedPermissionCollection {
  [permissionKey: string]: ModifiedPermission
}

interface ModifiedPermission {
  Deactivated: boolean // Deactivated permissions should not be active anymore.
}

export interface PermissionExtensionAddDeactivationAction extends AnyAction {
  type: 'PERMISSION_EXTENSION_ADD_DEACTIVATION'
  payload?: { permissions: string[] }
}
export interface PermissionExtensionRemoveDeactivationAction extends AnyAction {
  type: 'PERMISSION_EXTENSION_REMOVE_DEACTIVATION'
  payload?: { permissions: string[] }
}

export const defaultState: PermissionExtensionReducerState = { modifiedPermissions: {} } as any

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type PermissionExtensionReducerBehavior<S = PermissionExtensionReducerState> = Record<
  typeof types.PERMISSION_EXTENSION_ADD_DEACTIVATION,
  Reducer<S, PermissionExtensionAddDeactivationAction>
> &
  Record<typeof types.PERMISSION_EXTENSION_REMOVE_DEACTIVATION, Reducer<S, PermissionExtensionRemoveDeactivationAction>>

/**
 * permissionExtension keep track of modifications to the users permissions, based on settings provided by the user
 */
// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
const permissionExtension = createReducer<PermissionExtensionReducerState, PermissionExtensionReducerBehavior>(defaultState, {
  [types.PERMISSION_EXTENSION_ADD_DEACTIVATION]: (state, { payload }) => {
    if (!state) {
      state = defaultState
    }

    // modify the state; add or overwrite any existing keys
    const newModifiedPermissions = payload!.permissions.reduce(
      (newState, permissionKey) => ({ ...newState, [permissionKey]: { Deactivated: true } }),
      state.modifiedPermissions
    )
    return { ...state, modifiedPermissions: newModifiedPermissions }
  },
  [types.PERMISSION_EXTENSION_REMOVE_DEACTIVATION]: (state, { payload }) => {
    if (!state) {
      state = defaultState
    }
    // modify the state; remove the supplied keys
    const newModifiedPermissions = Object.entries(state.modifiedPermissions).reduce((newState, keyAndVal) => {
      if (payload!.permissions.find(p => p === keyAndVal[0])) {
        // key is in change set, remove from new state
        return newState
      }
      // key not in change set, keep it in state.
      return { ...newState, [keyAndVal[0]]: keyAndVal[1] }
    }, {})

    return { ...state, modifiedPermissions: newModifiedPermissions }
  }
})

export default permissionExtension
