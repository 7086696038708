import * as types from '../types'
import * as checklistTypes from '../checklist/types'
import { AnyAction } from 'redux'
import { IChecklist } from '@cbgms/api/modules/checklists'

export const workorderChecklistsReducer = (state: IChecklist[] = [], action: AnyAction) => {
  switch (action.type) {
    case types.GET_WORKORDER_CHECKLISTS_SUCCESS:
      return action.payload.data.Data.map((item: any) => item.Checklist) as IChecklist[]
    case types.GET_WORKORDER_CHECKLISTS_FAILED:
    case checklistTypes.REMOVE_CHECKLIST_SUCCESS:
    case types.CLEAR_WORKORDER_CHECKLISTS:
      return [] as IChecklist[]
    default:
      return state
  }
}
