import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'
import { APIResponse } from '@cbgms/api'
import { PostSupportTicket, TicketMediaItemResponse } from './types'

export const getNewDefaultOrderAccount = () => ({
  method: 'GET',
  url: '/modules/wholesaler/order-account/settings'
})

export const postSupportTicketMediaItem = (file: File) => {
  return createAsyncApiAction<APIResponse<TicketMediaItemResponse>>('gms', {
    method: 'POST',
    url: `/media/private/ticket-images/${file.name}`,
    data: file
  })
}

export const postSupportTicket = ({
  Attachments,
  Question,
  Vehicle,
  WholesalerUUID,
  TicketType,
  Part,
  CallbackDetails
}: PostSupportTicket) => {
  return createAsyncApiAction<void>('gms', {
    method: 'POST',
    url: `/modules/wholesaler/order-account/ticket-submit`,
    data: { Attachments, Body: Question, Vehicle, WholesalerUUID, Type: TicketType, Part, CallbackDetails }
  })
}
