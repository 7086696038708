import { HttpMethod } from '@cbgms/api/http-method'
import { GETResponse } from '@cbgms/api/helpers'
import { SchedulerSettings } from './scheduler-settings'

export type GETSchedulerSettingsResponse = GETResponse<SchedulerSettings>

export const getSchedulerSettingsRequest = () => ({
  method: HttpMethod.GET,
  url: '/modules/workorder-appointments/agenda/settings'
})
