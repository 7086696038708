import React, { useMemo } from 'react'
import { StyledEngineProvider, ThemeOptions, ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'
import { StylesProvider } from '@mui/styles'
import { TempType, calculateCssVars, calculateLightAndDarkColor, getThemeColors } from 'utils/theme'
import { useSelector } from 'react-redux'
import { defaultColors, defaultThemeOptions } from '@carsys/ui'

const themeWithColours = (colours: TempType, themeOptions: ThemeOptions) => {
  const { themeColor, themeColorText, actionColor, actionColorText } = getThemeColors(colours)
  const { dark, extraDark, light } = calculateLightAndDarkColor(actionColor)

  const palette = {
    ...themeOptions.palette!,
    primary: {
      main: actionColor,
      light,
      dark,
      contrastText: actionColorText
    },
    primaryThemeColor: {
      main: themeColor,
      light: colours.mainLightenedColour,
      dark: colours.mainDarkenedColour,
      extraDark,
      contrastText: themeColorText
    }
  }

  return createTheme({ ...themeOptions, palette })
}

const defaultTheme = themeWithColours(defaultColors, defaultThemeOptions)

const useThemeColors = () => {
  const apiTheme = useSelector(state => state?.auth?.theme)

  return useMemo(() => apiTheme && calculateCssVars(apiTheme), [apiTheme])
}

export const ThemeContext: React.FC<{}> = ({ children }) => {
  const themeColours = useThemeColors()

  const theme = useMemo(() => {
    return themeColours && !!themeColours.mainColour ? themeWithColours(themeColours, defaultThemeOptions) : defaultTheme
  }, [themeColours])

  return (
    <StyledEngineProvider injectFirst>
      <StylesProvider injectFirst>
        <ThemeProvider theme={responsiveFontSizes(theme)}>{children}</ThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  )
}

// We should eventually refactor this away. The tests don't recognize the custom props we added to the theme without this.
export const MuiJestThemeWrapper = (props: React.PropsWithChildren<{}>) => (
  <ThemeProvider theme={defaultTheme}>{props.children}</ThemeProvider>
)

export default ThemeContext
