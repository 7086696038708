export enum Timezone {
  NL_Amsterdam = 'Europe/Amsterdam',
  DE_Busingen = 'Europe/Busingen',
  BE_Brussels = 'Europe/Brussels',
  CZ_Prague = 'Europe/Prague',
  DE_Berlin = 'Europe/Berlin',
  GB_London = 'Europe/London',
  FR_Paris = 'Europe/Paris',
  IT_Rome = 'Europe/Rome'
}
