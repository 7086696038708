import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'

interface IJournalEntryNumberState {
  NumberPattern: string
  NumberPatternExample: string
}

export type JournalEntryNumberState = Readonly<IJournalEntryNumberState>

const defaultState: JournalEntryNumberState = {
  NumberPattern: '',
  NumberPatternExample: ''
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type JournalEntryNumberBehavior<S = JournalEntryNumberState> = Record<typeof types.LOAD_JOURNAL_ENTRY_NUMBER_SUCCESS, Reducer<S, any>> &
  Record<typeof types.UPDATE_JOURNAL_ENTRY_NUMBER_SUCCESS, Reducer<S, any>> &
  Record<typeof types.LOAD_JOURNAL_ENTRY_NUMBER_PREVIEW_SUCCESS, Reducer<S, any>>

const journalEntryNumber = createReducer<JournalEntryNumberState, JournalEntryNumberBehavior>(defaultState, {
  [types.LOAD_JOURNAL_ENTRY_NUMBER_SUCCESS]: (state, { payload }) => ({
    ...(state || defaultState),
    ...payload.data.Data
  }),
  [types.UPDATE_JOURNAL_ENTRY_NUMBER_SUCCESS]: (state, { payload }) => ({
    ...(state || defaultState),
    ...payload.data.Data
  }),
  [types.LOAD_JOURNAL_ENTRY_NUMBER_PREVIEW_SUCCESS]: (state, { payload }) => ({
    ...(state || defaultState),
    NumberPatternExample: payload.data.Data.join('\n')
  })
})

export default journalEntryNumber
