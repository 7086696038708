import React from 'react'
import { useTranslation } from 'react-i18next'
import { setLocale } from 'yup'
import moment from 'moment'

type DateTimeFormats = 'date' | 'dateTime' | 'time'

function formatDateTimeValue(value: any, format: DateTimeFormats = 'date'): string {
  const formats: Record<DateTimeFormats, string> = {
    date: 'L',
    dateTime: 'L LT',
    time: 'LT'
  }
  const date = moment(value)
  return date.isValid() ? date.format(formats[format]) : value
}

/**
 * We set up global Yup internationalization with I18next and Yup's setLocale.
 *
 * Currently we support nicely formatting dates and regexes using
 * Yup's meta information. We are still missing similar functionality
 * for several other validations.
 *
 * - boolean.isValue
 * - mixed.notOneOf
 * - mixed.notType
 * - mixed.oneOf
 * - object.noUnknown
 *
 * If you want to override the global translation that's still possible. At any
 * point you can provide a message for the specific schema.
 *
 * ```ts
 * yup.matches(/^Cool(.+)?/, "Should start with \"Cool\".")
 * ```
 */
export function YupI18nProvider({ children }: React.PropsWithChildren<{}>) {
  const { t } = useTranslation()

  React.useEffect(() => {
    setLocale({
      array: {
        length: ({ length }) => t('Form.SchemaErrors.Array.Length', { length }),
        max: ({ max }) => t('Form.SchemaErrors.Array.Max', { max }),
        min: ({ min }) => t('Form.SchemaErrors.Array.Min', { min })
      },
      boolean: {
        isValue: ({ value }) => ({ key: t('Form.SchemaErrors.Boolean.IsValue', { value }) })
      },
      date: {
        max: ({ max, spec }) => t('Form.SchemaErrors.Date.Max', { max: formatDateTimeValue(max, spec.meta?.format) }),
        min: ({ min, spec }) => t('Form.SchemaErrors.Date.Min', { min: formatDateTimeValue(min, spec.meta?.format) })
      },
      mixed: {
        default: t('Form.SchemaErrors.Mixed.Default'),
        required: t('Form.SchemaErrors.Mixed.IsRequired'),
        defined: t('Form.SchemaErrors.Mixed.Defined'),
        notOneOf: ({ values }) => t('Form.SchemaErrors.Mixed.NotOneOf', { values }),
        notType: ({ type }) => t('Form.SchemaErrors.Mixed.NotType', { type }),
        oneOf: ({ values }) => t('Form.SchemaErrors.Mixed.OneOf', { values })
      },
      number: {
        max: ({ max }) => t('Form.SchemaErrors.Number.Max', { max }),
        min: ({ min }) => t('Form.SchemaErrors.Number.Min', { min }),
        integer: t('Form.SchemaErrors.Number.Integer'),
        lessThan: ({ less }) => t('Form.SchemaErrors.Number.LessThan', { less }),
        moreThan: ({ more }) => t('Form.SchemaErrors.Number.MoreThan', { more }),
        negative: ({ less }) => t('Form.SchemaErrors.Number.Negative', { less }),
        positive: ({ more }) => t('Form.SchemaErrors.Number.Positive', { more })
      },
      object: {
        noUnknown: ({ unknown }) => t('Form.SchemaErrors.Object.NoUnknown', { unknown })
      },
      string: {
        email: t('Form.SchemaErrors.String.Email'),
        length: ({ length }) => t('Form.SchemaErrors.String.Length', { length }),
        lowercase: t('Form.SchemaErrors.String.Lowercase'),
        matches: ({ regex, spec }) => t('Form.SchemaErrors.String.Matches', { regex: spec.meta?.format ?? regex }),
        max: ({ max }) => t('Form.SchemaErrors.String.Max', { max }),
        min: ({ min }) => t('Form.SchemaErrors.String.Min', { min }),
        trim: t('Form.SchemaErrors.String.Trim'),
        uppercase: t('Form.SchemaErrors.String.Uppercase'),
        url: t('Form.SchemaErrors.String.URL'),
        uuid: t('Form.SchemaErrors.String.UUID')
      }
    })
  }, [t])

  // To make the TypeScript compiler happy the fragment is required.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
