import { AnyAction } from 'redux'
import * as types from '../types'
import { EmptyUUID } from '@cbgms/base/utils/uuid/constants'
import { EmptyDate } from '@cbgms/base/utils/datetime/emptyDates'
import { IAppointment } from '@cbgms/api'
import { GET_ORDER_WITH_RELATED_SUCCESS } from 'app/order/state/types'
import { UPDATE_APPOINTMENT_SUCCESS } from 'app/workorderAppointments/state/types'

const defaultAppointmentState: Partial<IAppointment> = {
  UUID: '',
  StartingTime: '',
  Date: EmptyDate,
  EmployeeUUID: EmptyUUID,
  EmployeeName: '',
  EmployeeProfileColour: '#FFFFFF',
  PlannedDuration: 0
}

export const workorderFirstAppointmentReducer = (
  state: Partial<IAppointment> = defaultAppointmentState,
  action: AnyAction
): Partial<IAppointment> => {
  switch (action.type) {
    case types.GET_FIRST_APPOINTMENT_FAILED:
      return defaultAppointmentState
    case types.GET_FIRST_APPOINTMENT_SUCCESS:
      return action.payload.data?.Data || defaultAppointmentState
    case UPDATE_APPOINTMENT_SUCCESS:
      return {
        CustomerWaitsInShop: action.payload.data?.Data?.CustomerWaitsInShop,
        Date: action.payload.data?.Data?.Date,
        EmployeeName: action.payload.data?.Data?.EmployeeName,
        EmployeeProfileColour: action.payload.data?.Data?.EmployeeProfileColour,
        EmployeeUUID: action.payload.data?.Data?.EmployeeUUID,
        EndingTime: action.payload.data?.Data?.EndingTime,
        PlannedDuration: action.payload.data?.Data?.PlannedDuration,
        StartingTime: action.payload.data?.Data?.StartingTime,
        Status: action.payload.data?.Data?.Status,
        UUID: action.payload.data?.Data?.UUID,
        WorkstationName: action.payload.data?.Data?.WorkstationName,
        WorkstationUUID: action.payload.data?.Data?.WorkstationUUID
      }
    case types.GET_WORKORDER_WITH_RELATED_SUCCESS:
      return action.payload.data?.Data?.Appointment || defaultAppointmentState
    case GET_ORDER_WITH_RELATED_SUCCESS:
      return action.payload.data?.Data?.Appointment || defaultAppointmentState
    default:
      return state
  }
}
