import React from 'react'

const storageKey = 'carsys_chunk_failed'

// lazyWithReload is a wrapper around React.lazy which checks if the factory function
// throws a ChunkLoadError and triggers a reload via `window.location.reload()` if it does.
//
// A ChunkLoadError might for example be thrown after the app has been updated and the files have been replaced on the server.
// In that case the chunk cannot be found anymore, which causes a 404, which in turn causes the ChunkLoadError.
function lazyWithReload<T extends React.ComponentType<any>>(factory: () => Promise<{ default: T }>): React.LazyExoticComponent<T> {
  return React.lazy(async () => {
    try {
      return await factory()
    } catch (error: any) {
      if (error?.name !== 'ChunkLoadError') {
        throw error
      }

      // make sure we only reload once by adding a temporary key to local storage
      if (!getWithExpiry(storageKey)) {
        setWithExpiry(storageKey, true, 10 * 1000) // 10 seconds
        window.location.reload()
      }

      // React.lazy only supports default imports and requires the factory function to return a default function.
      // We return null, which doesn't render anything, because we're reloading the page anyway.
      return { default: () => null } as any
    }
  })
}

function setWithExpiry(key: string, value: {}, ttl: number) {
  const now = new Date()
  const item = {
    value,
    expiry: now.getTime() + ttl
  }
  localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  // If the item is expired, delete the item from storage
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

export default lazyWithReload
