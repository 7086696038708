import React from 'react'
import { OnboardingState } from '../../hooks/use-onboarding-setup'
import { DuplicationCheck, IOnboardingStep, OnboardingStepKey } from '@cbgms/api'
import { SetupWizardStepComponent } from 'app/menu/components/TopBar/setupWizard/SetupWizardStep'

export interface NormalStep<TStepProps> {
  step: OnboardingStepKey
  stepData: IOnboardingStep
  stepProps: TStepProps & {
    onSubmit: ((data: any) => void) | (() => void)
  }
  Component: React.FC<TStepProps & defaultStepFormProps>
  CTAComponent: SetupWizardStepComponent | null
  StepperText?: React.FC<{}>
  showInStepper: boolean
  showStepperHeader: boolean
  hideSkip: boolean
  isCompleted: boolean
  shouldExecuteStep: boolean
  shouldRunInBackground: boolean
  persister: IPersister
}

export interface CTAOnlyStep {
  step: OnboardingStepKey
  stepData: IOnboardingStep
  Component: null
  CTAComponent: SetupWizardStepComponent
  shouldExecuteStep: false
}

export type IStep<TStepProps> = NormalStep<TStepProps> | CTAOnlyStep

interface IPersister {
  persistOrder: number
  onPersist: () => Promise<void>
}

export interface BaseStepInputProps {
  basePath: string
  onSubmit?: () => void // submits are called whenever we finish a step
  onboardingInfo: OnboardingState
}

export type UseStepFunc<TStepProps, TUseStepInputProps = {}, TStepOutput = {}> = (
  props: BaseStepInputProps & TUseStepInputProps
) => NormalStep<TStepProps> & TStepOutput

export type UseCTAStepFunc<TUseStepInputProps = {}, TStepOutput = {}> = (
  props: BaseStepInputProps & TUseStepInputProps
) => CTAOnlyStep & TStepOutput

export enum StepPersistanceMode {
  PersistImmediately = 'PersistImmediately',
  PersistInOneTransaction = 'PersistInOneTransaction'
}

export enum OnboardingMode {
  OnboardingExistingShop = 'OnboardingExistingShop',
  OnboardingViaWholesaler = 'OnboardingViaWholesaler'
}
export interface useStepsProps {
  basePath: string
  themeUUID?: string
  onboardingInfo: OnboardingState
  persistMode: StepPersistanceMode
  clearOnboardingToken: () => void
}

export interface useStepsResult {
  steps: NormalStep<unknown>[]
  allSteps: IStep<unknown>[]
  isPersisting: boolean
}

export interface defaultStepFormProps<T = object> {
  isPersisting: boolean
  onSubmitSuccess: () => void
  prevStep: () => void
  isFirstStep: boolean
  hideSkip: boolean
  shouldRunInBackground: boolean
  initialData: Partial<T>
  duplicationCheck: DuplicationCheck[]
}

export interface StepNavigationProps {
  prevStep: () => void
  nextStep?: () => void
  hideBack?: boolean
  hideSkip: boolean
  disableNext?: boolean
  isLoading?: boolean
}
