import { dateConverter } from './dateConverter'
import { apiFormat } from './apiFormat'

export let format = {
  shortDateFull: 'L',
  longDateFormat: 'L - LT',
  longWeekdayDateTime: 'dddd L LT',
  longWeekdayDate: 'dddd L',
  dayDateTime: 'L LT',
  time: 'HH:mm',
  fullDateTime: 'L HH:mm:ss',
  fullTime: 'HH:mm:ss',
  date: 'L',
  monthShort: 'MMM',
  monthLong: 'MMMM',
  year: 'YYYY',
  weekday: 'dddd'
}

export const changeFormat = (newFormat: typeof format) => (format = newFormat)

export let formatters = {
  date: dateConverter(format.shortDateFull, apiFormat.yearMonthDay),
  datetime: dateConverter(format.longDateFormat, apiFormat.iso),
  time: dateConverter(format.time, apiFormat.time)
}

export const changeFormatters = (newFormatters: typeof formatters) => (formatters = newFormatters)
