import { PersistConfig, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import shop, { ShopReducerState } from './shop'
import permissionExtension, { PermissionExtensionReducerState } from './permissionExtension'
import tokens, { TokenReducerState } from './tokens'
import urls, { UrlReducerState } from './urls'
import user, { UserReducerState } from './user'
import challenge from './challenge'
import employee, { EmployeeReducerState } from 'app/auth/state/reducers/employee'
import theme, { ThemeReducerState } from 'app/auth/state/reducers/theme'
import localisation, { LocalisationReducerState } from './localisation'
import currentShop from './currentShop'

const config: PersistConfig<AuthReducerState> = {
  key: 'gms',
  storage,
  timeout: 100
}

interface IAuthReducerState {
  challenge: ReturnType<typeof challenge>
  currentShop: ReturnType<typeof currentShop>
  employee: EmployeeReducerState
  localisation: LocalisationReducerState
  theme: ThemeReducerState
  shop: ShopReducerState
  tokens: TokenReducerState
  urls: UrlReducerState
  user: UserReducerState
  permissionExtension: PermissionExtensionReducerState
}

export type AuthReducerState = Readonly<IAuthReducerState>

export const auth = persistCombineReducers<
  AuthReducerState,
  any // This any is required because the types of redux-perist are too constrained.
>(config, {
  challenge,
  currentShop,
  employee,
  localisation,
  theme,
  shop,
  tokens,
  urls,
  user,
  permissionExtension
})

export default auth
