import React, { Dispatch, SetStateAction } from 'react'

/* For more information about how and when you should use this please have a look at:
https://gist.github.com/AlpacaGoesCrazy/25e3a15fcd4e57fb8ccd408d488554d7 */
export function useSafeState<S = undefined>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] {
  const [state, dispatch] = React.useState(initialState)
  const mounted = React.useRef(false)

  React.useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const setState: Dispatch<SetStateAction<S>> = React.useCallback(
    (...args) => {
      if (mounted.current) dispatch(...args)
    },
    [dispatch]
  )

  return [state, setState]
}
