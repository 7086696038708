import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Checkbox } from './Checkbox'

// This is needed because Firefox says checkboxes are read only and we have
// global styling altering the cursor on read only inputs.
const inputProps = {
  style: {
    cursor: 'pointer'
  }
}

export interface LabeledCheckboxProps extends Omit<React.ComponentProps<typeof FormControlLabel>, 'control'> {
  checkboxProps?: Omit<React.ComponentProps<typeof Checkbox>, 'control'>
}

export function LabeledCheckbox(props: LabeledCheckboxProps) {
  const { checkboxProps, ...labelProps } = props
  return <FormControlLabel control={<Checkbox color='primary' inputProps={inputProps} {...checkboxProps} />} {...labelProps} />
}
