import moment from 'moment'
import { apiFormat } from './apiFormat'
import { EmptyDate } from './emptyDates'

export const dateConverter = (userFormat: string, apiFmt: string) => {
  const conv = {
    toUserFormat: (date: moment.Moment) => date.format(userFormat),
    toApiFormat: (date: moment.Moment) => (apiFmt === apiFormat.iso ? date.utc() : date).format(apiFmt),
    fromUserFormat: (input: string) => moment(input, userFormat),
    fromApiFormat: (input: string) => moment(input, apiFmt)
  }

  return {
    ...conv,
    format: (input: string) => {
      const momentValue = conv.fromApiFormat(input)

      if (!momentValue.isValid() || momentValue.year() <= 1) {
        return ''
      }

      return conv.toUserFormat(momentValue)
    },
    parse: (input: string) => {
      const momentValue = conv.fromUserFormat(input)

      return conv.toApiFormat(momentValue.isValid() ? momentValue : moment(EmptyDate))
    }
  }
}
