import { AnyAction } from 'redux'
import * as types from './types'

interface ITimeCorrectionReducerState {
  previousFilterDate: string
  filterDate: string
  employeeUUID: string
  employeeName: string
}

export type TimeCorrectionReducerState = Readonly<ITimeCorrectionReducerState>

const defaultState: TimeCorrectionReducerState = {
  previousFilterDate: '',
  filterDate: '',
  employeeUUID: '',
  employeeName: ''
}

const timeCorrection = (state: TimeCorrectionReducerState = { ...defaultState }, action: AnyAction) => {
  switch (action.type) {
    case types.UPDATE_PREVIOUS_FILTER_DATE:
      return { ...state, previousFilterDate: action.payload.previiousFilterDate }
    case types.UPDATE_FILTER_DATE:
      return { ...state, filterDate: action.payload.filterDate }
    case types.UPDATE_EMPLOYEE_UUID:
      return { ...state, employeeUUID: action.payload.employeeUUID }
    case types.RESET_EMPLOYEE_UUID:
      return { ...state, employeeUUID: '' }
    case types.RESET_STATE:
      return { ...state, filterDate: '', employeeUUID: '', employeeName: '' }
    default:
      return state
  }
}

export default timeCorrection
