import React from 'react'
import { FeaturesContextProvider } from '@cbgms/base/feature-flags'
import useGmsFeatures from './useGmsFeatures'

interface Props {
  children: React.ReactNode
}

const GmsFeaturesContextProvider = ({ children }: Props) => {
  const enabledGmsFeatures = useGmsFeatures()

  return <FeaturesContextProvider features={enabledGmsFeatures}>{children}</FeaturesContextProvider>
}

export default GmsFeaturesContextProvider
