import { GETLabourRatesResponse, IGetLabourRateResponse } from '@cbgms/api/modules/labourrates/'
import { IRootReducerState } from 'state/rootReducer'

export const getLabourRate = (labourRateUUID: string, state: IRootReducerState) => {
  if (labourRateUUID === 'new') {
    return {
      Code: '',
      Description: '',
      HourlyRate: 0
    }
  }

  return state.settings.labourRates.detail
}

export const getOptions = (response: GETLabourRatesResponse) => {
  return response.Data.map((item: IGetLabourRateResponse) => ({
    key: item.LabourRate.UUID,
    value: item.LabourRate.UUID,
    text: `${item.LabourRate.Code} - ${item.LabourRate.Description}`
  }))
}
