import * as types from './types'
import * as requests from '@cbgms/api/modules/dashboard/requests'
import * as employeeRequests from '@cbgms/api/modules/employees/requests'
import {
  GETChangeLogEvents,
  GETOnboardingStatusResponse,
  GETOpenRequests,
  GETRecentlyChangedWorkorders,
  GETWidgetSettings,
  IChangeLogInputParams,
  IVehicleType,
  IWidgetSettings
} from '@cbgms/api'
import { AsyncAction } from '@cbgms/base/redux/async-action'

export const createVehicle = (vehicle: IVehicleType) => ({
  type: [types.CREATE_VEHICLE, types.CREATE_VEHICLE_SUCCESS, types.CREATE_VEHICLE_FAILED],
  payload: {
    api: 'gms',
    request: {
      method: 'POST',
      url: '/modules/vehicles',
      data: vehicle
    }
  }
})

export const loadOnboardingStatus = () =>
  AsyncAction<GETOnboardingStatusResponse>({
    type: [types.GET_ONBOARDING_STATUS, types.GET_ONBOARDING_STATUS_SUCCESS, types.GET_ONBOARDING_STATUS_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url: '/modules/shops/onboarding-status'
      }
    }
  })

export const getDashboardChangeLog = (params: IChangeLogInputParams) =>
  AsyncAction<GETChangeLogEvents>({
    type: [types.GET_DASHBOARD_CHANGE_LOG, types.GET_DASHBOARD_CHANGE_LOG_SUCCESS, types.GET_DASHBOARD_CHANGE_LOG_FAILED],
    payload: {
      api: 'gms',
      request: requests.getDashboardChangeLog(params)
    }
  })

export const getWidgetSettings = () =>
  AsyncAction<GETWidgetSettings>({
    type: [types.GET_WIDGET_SETTINGS, types.GET_WIDGET_SETTINGS_SUCCESS, types.GET_WIDGET_SETTINGS_FAILED],
    payload: {
      api: 'gms',
      request: requests.getWidgetSettings()
    }
  })

export const putWidgetSettings = (data: IWidgetSettings) =>
  AsyncAction<GETWidgetSettings>({
    type: [types.PUT_WIDGET_SETTINGS, types.PUT_WIDGET_SETTINGS_SUCCESS, types.PUT_WIDGET_SETTINGS_FAILED],
    payload: {
      api: 'gms',
      request: requests.putWidgetSettings(data)
    }
  })

export const acceptTermsAndConditions = () => ({
  type: [
    types.EMPLOYEE_ACCEPT_TERMS_CONDITIONS,
    types.EMPLOYEE_ACCEPT_TERMS_CONDITIONS_SUCCESS,
    types.EMPLOYEE_ACCEPT_TERMS_CONDITIONS_FAILED
  ],
  payload: {
    api: 'gms',
    request: employeeRequests.employeeAcceptTermsAndConditions()
  }
})

export const getRecentlyModifiedWorkorders = (resultLimit = 25) =>
  AsyncAction<GETRecentlyChangedWorkorders>({
    type: ['', '', ''],
    payload: {
      api: 'gms',
      request: requests.getRecentlyModifiedWorkorders(resultLimit)
    }
  })

export const getOpenRequestWidget = () =>
  AsyncAction<GETOpenRequests>({
    type: [types.GET_OPEN_REQUESTS, types.GET_OPEN_REQUESTS_SUCCESS, types.GET_OPEN_REQUESTS_FAILED],
    payload: {
      api: 'gms',
      request: requests.getOpenRequestDetails()
    }
  })
