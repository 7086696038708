export const EmptyDate = '0001-01-01'
export const EmptyTime = '00:00'
export const EmptyDateTime = '0001-01-01T00:00:00Z'
export const EmptyListDateTime = '0001-01-01T00:00:00+00:00'

export function isEmptyDateTime(input = ''): boolean {
  return input === EmptyDateTime || input === ''
}

export function isEmptyDate(input = ''): boolean {
  return input === EmptyDate || input === ''
}
