import React from 'react'
import { useTranslation } from 'react-i18next'

type ToHumanReadableFilesizeFormatter = (
  /** File size in megabytes. */
  megabytes: number
) => string

/**
 * Returns a function that allows you to transform
 * a filesize in megabytes to a human readable format.
 */
export function useHumanReadableFilesize(): ToHumanReadableFilesizeFormatter {
  const { i18n } = useTranslation()

  return React.useCallback(
    (megabytes: number) => {
      return megabytes.toLocaleString(i18n.language, {
        style: 'unit',
        unit: 'megabyte',
        unitDisplay: 'narrow'
      })
    },
    [i18n.language]
  )
}
