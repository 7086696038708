import { useCallback } from 'react'
import { sendPurchaseEvent } from 'utils/TagManager/tag-manager'
import { ILogContextData } from './useLogContextData'
import { ICartTotals } from 'app/order/components/Cart/OrderCartContext'
import { IWorkorderLine } from '@cbgms/api'

export interface PurchaseEventParams {
  order: {
    orderUUID: string
    totals: ICartTotals
    orderLines: IWorkorderLine[]
  }
}

export type PurchaseEvent = (data: PurchaseEventParams) => void
/**
 * usePurchaseLoggerFunc will create the purchase logging function,
 * Calling Google analytics / tag manager and other logging platforms
 */
export const usePurchaseLoggerFunc = (ctx: ILogContextData) => {
  const googleTagManagerPurchaseLogger = useCallback(
    (data: PurchaseEventParams) => sendPurchaseEvent(ctx, data.order),
    [sendPurchaseEvent, ctx]
  )

  return useCallback(
    (data: PurchaseEventParams) => {
      try {
        googleTagManagerPurchaseLogger(data)
      } catch (e) {
        console.error('unable to log purchase event', e)
      }
    },
    [googleTagManagerPurchaseLogger]
  )
}
