import React, { memo, useMemo } from 'react'
import { ClickEventParams, useClickLoggerFunc } from './useClickLoggerFunc'
import { PurchaseEventParams, usePurchaseLoggerFunc } from './usePurchaseLoggerFunc'
import { ViewCartEventParams, useViewCartLoggerFunc } from './useViewCartLoggerFunc'
import { AddToCartEventParams, useAddToCartLoggerFunc } from './useAddToCartLoggerFunc'
import { RemoveFromCartEventParams, useRemoveFromCartLoggerFunc } from './useRemoveFromCartLoggerFunc'
import { NavigationEventParams, useNavigationLoggerFunc } from './useNavigationLoggerFunc'
import { LogContextValue, createEventLoggerContext, useAbstractEventLogger } from '@cbgms/base/context/LogContext'
import { useLogContextData } from './useLogContextData'

const LogContext = createEventLoggerContext<
  ClickEventParams,
  PurchaseEventParams,
  NavigationEventParams,
  ViewCartEventParams,
  AddToCartEventParams,
  RemoveFromCartEventParams
>()

const MemoLogContextProvider: React.FC<{ value: LogContextValue<any, any, any, any, any, any> }> = memo(({ value, children }) => {
  return <LogContext.Provider value={value}>{children}</LogContext.Provider>
})

/**
 * LogContextProvider will setup the default logging context
 */
export const LogContextProvider: React.FC<{}> = ({ children }) => {
  const ctx = useLogContextData()
  const clickLogger = useClickLoggerFunc(ctx)
  const purchaseLogger = usePurchaseLoggerFunc(ctx)
  const viewCartLogger = useViewCartLoggerFunc(ctx)
  const addToCartLogger = useAddToCartLoggerFunc(ctx)
  const removeFromCartLogger = useRemoveFromCartLoggerFunc(ctx)
  const navigationLogger = useNavigationLoggerFunc(ctx)

  const value = useMemo(
    () => ({
      clickEvent: clickLogger,
      purchaseEvent: purchaseLogger,
      navigationEvent: navigationLogger,
      viewCartEvent: viewCartLogger,
      addToCartEvent: addToCartLogger,
      removeFromCartEvent: removeFromCartLogger
    }),
    [clickLogger, purchaseLogger, navigationLogger, viewCartLogger, addToCartLogger, removeFromCartLogger]
  )

  return <MemoLogContextProvider value={value}>{children}</MemoLogContextProvider>
}

export const useEventLogger = () => {
  const v = useAbstractEventLogger<
    ClickEventParams,
    PurchaseEventParams,
    NavigationEventParams,
    ViewCartEventParams,
    AddToCartEventParams,
    RemoveFromCartEventParams
  >()
  return v
}
