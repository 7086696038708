import React from 'react'
import LoadingButton from './LoadingButton'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Dialog } from './Dialog'
import { useTranslation } from 'react-i18next'
import { InstanceProps, create } from 'react-modal-promise'

interface ScrollDialogProps {
  title?: string
  content: React.ReactNode
  cancelButtonContent: React.ReactNode
  confirmButtonContent: React.ReactNode
  closeOnClickOutside?: boolean
  closeOnConfirm?: boolean
  isLoading?: boolean
}

interface Props {
  onClose: () => void
  onConfirm: () => void
  open: boolean
}

export const ConfirmDialog = (props: ScrollDialogProps & Props) => {
  const { t } = useTranslation()

  const handleConfirm = async () => {
    await props.onConfirm()
    if (props.closeOnConfirm) {
      props.onClose()
    }
  }

  return (
    <Dialog open={props.open} onClose={props.closeOnClickOutside ? props.onClose : undefined} scroll='paper'>
      <DialogTitle data-testid='confirm-dialog-title'>{props.title ?? t('Form.SubmitConfirmation.Title')}</DialogTitle>
      <DialogContent dividers data-testid='confirm-dialog-content'>
        <DialogContentText component='div'>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.isLoading}
          color='primary'
          variant='outlined'
          data-testid='confirm-dialog-cancel-button'
        >
          {props.cancelButtonContent}
        </Button>
        <LoadingButton
          onClick={handleConfirm}
          loading={!!props.isLoading}
          disabled={props.isLoading}
          color='primary'
          variant='contained'
          data-testid='confirm-dialog-confirm-button'
        >
          {props.confirmButtonContent}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const confirmDialogPromiseHandler: React.FC<ScrollDialogProps & InstanceProps<unknown>> = ({ onResolve, onReject, isOpen, ...props }) => {
  return <ConfirmDialog {...props} onClose={onReject} onConfirm={onResolve} open={isOpen} />
}

export const ConfirmDialogPromise = create(confirmDialogPromiseHandler)
