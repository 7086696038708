import { Reducer } from 'redux'

import { ITheme } from '@cbgms/api/modules/themes'
import { emptyTheme } from '@cbgms/api/modules/themes/mocks'
import { createReducer } from '@cbgms/base/utils/redux'

import * as types from 'app/auth/state/types'
import logoURL from 'assets/svg/LogoCarsys.svg?url'
import logoSmallURL from 'assets/svg/LogoCarsysSmall.svg?url'

export type ThemeReducerState = Readonly<ITheme>

const defaultState: ThemeReducerState = emptyTheme({ LogoURL: logoURL, LogoSmallURL: logoSmallURL })

type ThemeReducerBehavior<S = ThemeReducerState> = Record<typeof types.GET_SHOP_THEME_SUCCESS, Reducer<S, any>>

const theme = createReducer<ThemeReducerState, ThemeReducerBehavior>(defaultState, {
  [types.GET_SHOP_THEME_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export default theme
