// ignores the error handling behaviour in defaultHandler and just throws the error, so it can be handeled in the consuming code.
import { retrieveError } from 'middlewares/errorHandler/utils'
import { IFailedRequest } from '../errorHandler'
import { toastErrorCode } from '../utils'
import onlyThrowErrorHandler from './onlyThrowErrorHandler'

// ignore the error handling behaviour in defaultHandler, show a toast with with the translated error code and throw the error, so the
// it can be handled in the consuming code.
function toastAndThrowErrorHandler(failedRequest: IFailedRequest) {
  const errorCode = retrieveError(failedRequest.error)
  toastErrorCode(errorCode)
  onlyThrowErrorHandler(failedRequest)
}

export default toastAndThrowErrorHandler
