/* eslint-disable prettier/prettier */
import { CSSProperties } from '@mui/styles'
import { Loader } from './Loader'

export const LoaderWithBackground = ({ isLoading }: { isLoading: boolean }) => {
  const backgroundStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.2)'
  }
  if (!isLoading) return null

  return (
    <div style={backgroundStyle}>
      <Loader loading={isLoading} size={38} floating absolute />
    </div>
  )
}
