import {
  GETLabourRateDefaultsResponse,
  GETLabourRateResponse,
  GETLabourRatesResponse,
  IGetLabourRateResponse,
  PUTLabourRateDefaultsResponse
} from '@cbgms/api/modules/labourrates'

import { getOptions } from './selectors'
import * as types from './types'
import { DropdownItemProps } from 'semantic-ui-react'

export interface IDefaults {
  Bodywork: string
  Electrical: string
  Mechanical: string
  TimeFactor: number
}

interface ILabourRatesReducerState {
  detail?: Pick<IGetLabourRateResponse['LabourRate'], 'Code' | 'Description' | 'HourlyRate'>
  options: DropdownItemProps[]
  defaults?: IDefaults
}

type LabourRatesReducerState = Readonly<ILabourRatesReducerState>

const defaultState: LabourRatesReducerState = {
  detail: {
    Code: '',
    Description: '',
    HourlyRate: 0
  },
  options: [],
  defaults: {
    Bodywork: '',
    Electrical: '',
    Mechanical: '',
    TimeFactor: 1
  }
}

interface LoaderLabourRate {
  type: typeof types.LOAD_LABOUR_RATE_SUCCESS
  payload: {
    data: GETLabourRateResponse
  }
}

interface LoadAllLabourRates {
  type: typeof types.LOAD_ALL_LABOUR_RATES_SUCCESS
  payload: {
    data: GETLabourRatesResponse
  }
}

interface LoadAllLabourRateDefaults {
  type: typeof types.LOAD_LABOUR_RATE_DEFAULTS_SUCCESS
  payload: {
    data: GETLabourRateDefaultsResponse
  }
}

interface UpdateAllLabourRateDefaults {
  type: typeof types.UPDATE_LABOUR_RATE_DEFAULTS_SUCCESS
  payload: {
    data: PUTLabourRateDefaultsResponse
  }
}

type LabourRateActions = LoaderLabourRate | LoadAllLabourRates | LoadAllLabourRateDefaults | UpdateAllLabourRateDefaults

export default function labourRates(state = defaultState, action: LabourRateActions): LabourRatesReducerState {
  switch (action.type) {
    case types.LOAD_LABOUR_RATE_SUCCESS: {
      return {
        ...state,
        detail: action.payload.data.Data
      }
    }
    case types.LOAD_ALL_LABOUR_RATES_SUCCESS: {
      return {
        ...state,
        options: getOptions(action.payload.data)
      }
    }
    case types.LOAD_LABOUR_RATE_DEFAULTS_SUCCESS: {
      return {
        ...state,
        defaults: action.payload.data.Data
      }
    }
    case types.UPDATE_LABOUR_RATE_DEFAULTS_SUCCESS: {
      return {
        ...state,
        defaults: action.payload.data.Data
      }
    }
    default: {
      return state
    }
  }
}
