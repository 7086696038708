export enum TyrePosition {
  Left = 'left',
  Right = 'right',
  Front = 'front',
  Rear = 'rear',
  Outside = 'outside',
  Inside = 'inside',
  Middle = 'middle',
  Spare = 'spare'
}
