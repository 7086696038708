import * as types from './types'
import * as workorderTypes from 'app/workorder/state/workorder/types'
import {
  GET_VEHICLE_WITH_RELATED_SUCCESS,
  LOAD_ODOMETER_LOG_SUCCESS,
  LOAD_VEHICLE_SUCCESS,
  RESET_VEHICLE_DETAIL
} from 'app/vehicles/state/types'
import { ICreateWorkorderLineRequest, ILatestOdometerLog, IProduct } from '@cbgms/api'
import { getLines, getLinesFromRepairTimes, getTotals } from 'app/workorder/state/workorder/selectors'
import { IOrderWithRelated, OrderLineState } from '@cbgms/api/modules/order/index'
import { WorkorderTotalsState } from 'app/workorder/state/workorder/reducers/WorkorderTotalsState'
import { AnyAction, combineReducers } from 'redux'
import { emptyWorkorder } from '@cbgms/api/modules/workorder/mocks'
import { GET_WORKORDER_WITH_RELATED_SUCCESS } from 'app/workorder/state/workorder/types'

export interface IOrderViewState {
  details: IOrderWithRelated
  lines: OrderLineState[]
  placeholderLines: ICreateWorkorderLineRequest[]
  totals: WorkorderTotalsState
  odometer: ILatestOdometerLog
  products: IProduct[]
}

const details = (state = { Workorder: emptyWorkorder() }, action: AnyAction) => {
  switch (action.type) {
    case LOAD_VEHICLE_SUCCESS:
      return {
        ...state,
        Vehicle: action.payload.data.Data
      }
    case GET_VEHICLE_WITH_RELATED_SUCCESS:
      return {
        ...state,
        Vehicle: action.payload.data.Data.Vehicle
      }
    case RESET_VEHICLE_DETAIL:
      return {
        ...state,
        Vehicle: undefined
      }
    case GET_WORKORDER_WITH_RELATED_SUCCESS:
      return action.payload.data.Data
    case types.GET_ORDER_WITH_RELATED_SUCCESS:
      return action.payload.data.Data
    case types.RESET_ORDER:
      return { Workorder: emptyWorkorder() }
    default:
      return state
  }
}

const lines = (state: OrderLineState[] = [], action: AnyAction): OrderLineState[] => {
  switch (action.type) {
    case types.GET_ORDER_LINES_SUCCESS:
    case types.PUT_ORDER_LINE_SUCCESS:
    case types.POST_ORDER_LINE_SUCCESS:
    case types.DELETE_ORDER_LINE_SUCCESS:
    case workorderTypes.GET_WORKORDER_LINES_SUCCESS:
    case workorderTypes.UPDATE_WORKORDER_LINES_SUCCESS:
    case workorderTypes.ADD_PRODUCT_SUCCESS:
    case workorderTypes.ADD_OFFLINE_WORKORDER_LINES_SUCCESS:
    case workorderTypes.DELETE_WORKORDER_LINES_SUCCESS:
    case workorderTypes.UPDATE_WORKORDER_LINES_SORT_SUCCESS:
    case workorderTypes.PLACE_ORDER_SUCCESS:
    case types.SET_ORDER_STATUS_SUCCESS:
    case types.SET_DISCOUNT_SUCCESS:
      return getLines(action.payload, true)
    case workorderTypes.PUT_REPAIR_TIMES_SUCCESS:
      return getLinesFromRepairTimes(action.payload, true) as OrderLineState[]
    case types.RESET_ORDER:
      return []
    default:
      return state
  }
}

const placeholderLines = (state: ICreateWorkorderLineRequest[] = [], action: AnyAction): ICreateWorkorderLineRequest[] => {
  let orderLines: OrderLineState[] = []

  switch (action.type) {
    case types.PRE_POST_ORDER_LINE:
      if (action.payload.orderLine.SupplierProductID) {
        return [...state, action.payload.orderLine]
      }
      return state
    case types.GET_ORDER_LINES_SUCCESS:
    case types.POST_ORDER_LINE_SUCCESS:
    case types.DELETE_ORDER_LINE_SUCCESS:
    case workorderTypes.ADD_PRODUCT_SUCCESS:
      orderLines = getLines(action.payload)
      return [...state].filter(line => !orderLines.find(ol => ol.SupplierProductID === line.SupplierProductID))
    default:
      return state
  }
}

const totals = (state: WorkorderTotalsState = getTotals(), action: AnyAction) => {
  switch (action.type) {
    case types.GET_ORDER_LINES_SUCCESS:
    case types.POST_ORDER_LINE_SUCCESS:
    case types.PUT_ORDER_LINE_SUCCESS:
    case types.DELETE_ORDER_LINE_SUCCESS:
    case workorderTypes.ADD_PRODUCT_SUCCESS:
    case workorderTypes.ADD_OFFLINE_WORKORDER_LINES_SUCCESS:
    case workorderTypes.UPDATE_WORKORDER_LINES_SUCCESS:
    case workorderTypes.PLACE_ORDER_SUCCESS:
    case types.SET_DISCOUNT_SUCCESS:
      return getTotals(action.payload.data.Data.Totals)
    case workorderTypes.PUT_REPAIR_TIMES_SUCCESS:
      return getTotals(action.payload.data.Data.WorkorderLines.Totals)
    case types.RESET_ORDER:
      return getTotals()
    default:
      return state
  }
}

const odometer = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case LOAD_ODOMETER_LOG_SUCCESS:
      return action.payload.data.Data
    case types.RESET_ORDER:
      return {}
    default:
      return state
  }
}

const products = (state: IProduct[] = [], action: AnyAction) => {
  switch (action.type) {
    case types.GET_PRODUCTS_BY_ORDER_SUCCESS:
      return action.payload.data.Data
    case types.ADD_PRODUCT_TO_ORDER:
      return [...state, action.payload.product]
    case types.REMOVE_PRODUCT_FROM_ORDER:
      return state.filter(v => v.ProductID !== action.payload.productId)
    case types.RESET_ORDER:
      return []
    default:
      return state
  }
}

export default combineReducers<IOrderViewState>({
  details,
  lines,
  placeholderLines,
  totals,
  odometer,
  products
})
