import React from 'react'
import { Checkbox } from './Checkbox'
import Tooltip from '@mui/material/Tooltip'

// This is needed because Firefox says checkboxes are read only and we have
// global styling altering the cursor on read only inputs.
const inputProps = {
  style: {
    cursor: 'pointer'
  }
}

export interface TooltipCheckboxProps {
  toolTipProps: Omit<React.ComponentProps<typeof Tooltip>, 'control' | 'children'>
  checkboxProps: Omit<React.ComponentProps<typeof Checkbox>, 'control'>
}

export function TooltipCheckbox(props: TooltipCheckboxProps) {
  return (
    <Tooltip {...props.toolTipProps}>
      <Checkbox color='primary' inputProps={inputProps} {...props.checkboxProps} />
    </Tooltip>
  )
}
