export enum UpholsteryType {
  Velour = 'velour',
  Alcantara = 'alcantara',
  HalfLeatherOrAlcantara = 'half_leather_or_alcantara',
  HalfLeatherOrCloth = 'half_leather_or_cloth',
  Leather = 'leather',
  Skai = 'skai',
  Cloth = 'cloth',
  Unknown = 'unknown'
}
