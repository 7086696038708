import { Reducer } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import { getWearPartsIntervals } from 'app/vehicles/state/selectors'

import * as types from 'app/vehicles/state/types'

export interface IArticle {
  ID: string
  Mandatory: boolean
  Description: string
}

export interface IWearpartsIntervals {
  Article: IArticle
  Interval: string
  AdditionalTime: number
  RepairNodes: any
}

interface IWearPartIntervalsCollection {
  [index: string]: ReadonlyArray<IWearpartsIntervals>
}

export type WearPartIntervalsReducerState = Readonly<IWearPartIntervalsCollection>

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type WearPartIntervalsReducerBehavior<S = WearPartIntervalsReducerState> = Record<
  typeof types.GET_WEAR_PART_INTERVALS_SUCCESS,
  Reducer<S, any>
> &
  Record<typeof types.RESET_WEAR_PART_INTERVALS, Reducer<S, any>>

const wearPartIntervals = createReducer<WearPartIntervalsReducerState, WearPartIntervalsReducerBehavior>(
  {},
  {
    [types.GET_WEAR_PART_INTERVALS_SUCCESS]: (state, { request, payload }) => {
      return {
        ...state,
        [request.maintenanceSystemID]: getWearPartsIntervals(payload)
      }
    },
    [types.RESET_WEAR_PART_INTERVALS]: () => {
      return {}
    }
  }
)

export default wearPartIntervals
