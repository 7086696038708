import { APIResponse } from '../../helpers'

export enum ImportSource {
  Css = 'css',
  Mamsoft = 'mamsoft'
}

export interface ImportError {
  Error: string
  RowInfo: string
}

interface ImportResult {
  TotalRecords: number
  ImportedRecords: number
  DuplicateRecords: number
  Errors: ImportError[]
  Warnings: ImportError[]
}

export enum ImportType {
  Vehicles = 'Vehicles',
  Accounts = 'Accounts',
  Archives = 'Archives',
  ArchiveLines = 'ArchiveLines',
  EnrichmentData = 'EnrichmentData'
}

export enum ImportStatus {
  InProgress = 'in_progress',
  Done = 'done',
  Error = 'error'
}
export interface ImportResults {
  [ImportType.Vehicles]: ImportResult
  [ImportType.Accounts]: ImportResult
  [ImportType.Archives]: ImportResult
  [ImportType.ArchiveLines]: ImportResult
  [ImportType.EnrichmentData]: ImportResult
}

export interface ImportStatusSummary extends ImportResults {
  Status: ImportStatus
  Started: string
  Finished: string
  Error: string
}

export type GETImportStatus = APIResponse<ImportStatusSummary>
