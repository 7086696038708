export enum TicketType {
  GeneralTicket = 'GeneralTicket',
  PartRelatedQuestion = 'PartRelatedQuestion',
  PartAvailability = 'PartAvailability',
  VehicleRelatedQuestion = 'VehicleRelatedQuestion',
  PartVehicleLinkageQuestion = 'PartVehicleLinkageQuestion',
  ITRelatedQuestion = 'ITRelatedQuestion',
  Suggestion = 'Suggestion',
  CallMeBackRequest = 'CallMeBackRequest'
}
