export const LOAD_JOURNAL_ENTRY_NUMBER = 'LOAD_JOURNAL_ENTRY_NUMBER'
export const LOAD_JOURNAL_ENTRY_NUMBER_SUCCESS = 'LOAD_JOURNAL_ENTRY_NUMBER_SUCCESS'
export const LOAD_JOURNAL_ENTRY_NUMBER_FAILED = 'LOAD_JOURNAL_ENTRY_NUMBER_FAILED'

export const UPDATE_JOURNAL_ENTRY_NUMBER = 'UPDATE_JOURNAL_ENTRY_NUMBER'
export const UPDATE_JOURNAL_ENTRY_NUMBER_SUCCESS = 'UPDATE_JOURNAL_ENTRY_NUMBER_SUCCESS'
export const UPDATE_JOURNAL_ENTRY_NUMBER_FAILED = 'UPDATE_JOURNAL_ENTRY_NUMBER_FAILED'

export const LOAD_JOURNAL_ENTRY_NUMBER_PREVIEW = 'LOAD_JOURNAL_ENTRY_NUMBER_PREVIEW'
export const LOAD_JOURNAL_ENTRY_NUMBER_PREVIEW_SUCCESS = 'LOAD_JOURNAL_ENTRY_NUMBER_PREVIEW_SUCCESS'
export const LOAD_JOURNAL_ENTRY_NUMBER_PREVIEW_FAILED = 'LOAD_JOURNAL_ENTRY_NUMBER_PREVIEW_FAILED'
