import { ModifyWorkstation } from './workstation'
import { HttpMethod } from '@cbgms/api/http-method'
import { UUIDResponse } from '@cbgms/api/helpers'

export type POSTWorkstationRequest = ModifyWorkstation
export type POSTWorkstationResponse = UUIDResponse

export const createWorkstationRequest = (workstation: POSTWorkstationRequest) => ({
  method: HttpMethod.POST,
  url: 'modules/workstations',
  data: workstation
})
