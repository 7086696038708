import * as types from './types'
import * as searchTypes from 'app/search/state/types'
import { IAsset, IFilter, IProductGroupCategory, PartialProduct, ProductFilterValueObject } from '@cbgms/api'
import { removeDuplicateFilters } from './helpers'
import { AnyAction, combineReducers } from 'redux'

export interface IProductsViewState {
  categories: IProductGroupCategory[]
  selectedProduct: PartialProduct
  productFilterValues: ProductFilterValueObject
  productsLoading: boolean
  products: PartialProduct[]
  filters: IFilter[]
  assets: IAsset[]
}

const categories = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case types.GET_PRODUCT_GROUPS_SUCCESS:
      return action.payload.data.Data?.Categories || []
    case types.RESET_PRODUCT_GROUPS:
      return {}
    default:
      return state
  }
}

const selectedProduct = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case types.GET_PRODUCT_SUCCESS:
      return action.payload.data.Data
    case types.RESET_PRODUCT:
      return {}
    default:
      return state
  }
}

const productFilterValues = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case types.SET_PRODUCT_FILTER:
      return {
        ...state,
        [action.payload.key]: action.payload.activeFilters
      }
    case types.VALIDATE_PRODUCT_FILTER:
      return removeDuplicateFilters(state, action.payload)
    case types.RESET_PRODUCT_FILTER:
      return {}
    default:
      return state
  }
}

const productsLoading = (state = true, action: AnyAction) => {
  switch (action.type) {
    case types.GET_PRODUCTS:
      return true
    case types.GET_PRODUCTS_SUCCESS:
      return false
    case types.GET_PRODUCTS_FAILED:
      return false
    case types.GET_GENERIC_PRODUCTS:
      return true
    case types.GET_GENERIC_PRODUCTS_SUCCESS:
      return false
    case types.GET_GENERIC_PRODUCTS_FAILED:
      return false
    default:
      return state
  }
}

const products = (state: PartialProduct[] = [], action: AnyAction) => {
  switch (action.type) {
    case types.GET_PRODUCTS_SUCCESS:
      return action.payload.data.Data?.Products || []
    case types.SEARCH_PRODUCTS_SUCCESS:
      return action.payload.data.Data?.Products || []
    case types.GET_GENERIC_PRODUCTS_SUCCESS:
      return action.payload.data.Data?.Products || []
    case types.RESET_PRODUCTS:
      return []
    default:
      return state
  }
}

const filters = (state: IFilter[] = [], action: AnyAction) => {
  switch (action.type) {
    case searchTypes.SEARCH_ALL_SUCCESS:
      return action.payload.data.Data?.Filters || []
    case searchTypes.SEARCH_SUCCESS:
      return action.payload.data.Data?.Filters || []
    case types.GET_PRODUCTS_SUCCESS:
      return action.payload.data.Data?.Filters || []
    case types.SEARCH_PRODUCTS_SUCCESS:
      return action.payload.data.Data?.Filters || []
    case types.GET_GENERIC_PRODUCTS_SUCCESS:
      return action.payload.data.Data?.Filters || []
    case types.RESET_PRODUCTS:
      return []
    default:
      return state
  }
}

const assets = (state: IAsset[] = [], action: AnyAction) => {
  switch (action.type) {
    case types.GET_PRODUCTS_SUCCESS:
      return action.payload.data.Data?.Assets || []
    case types.SEARCH_PRODUCTS_SUCCESS:
      return action.payload.data.Data?.Assets || []
    case types.GET_GENERIC_PRODUCTS_SUCCESS:
      return action.payload.data.Data?.Assets || []
    case types.RESET_PRODUCTS:
      return []
    default:
      return state
  }
}

export default combineReducers<IProductsViewState>({
  categories,
  selectedProduct,
  productFilterValues,
  productsLoading,
  products,
  filters,
  assets
})
