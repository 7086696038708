import { Reducer, combineReducers } from 'redux'

import { IOKRInvoiceRecord } from '@cbgms/api/modules/rdw'
import { createReducer } from '@cbgms/base/utils/redux'

import * as types from './types'

export type DetailReducerState = Readonly<IOKRInvoiceRecord>

type DetailReducerBehavior<S> = Record<typeof types.LOAD_OKR_HISTORY_ITEM_SUCCESS, Reducer<S, any>>

const detail = createReducer<DetailReducerState, DetailReducerBehavior<DetailReducerState>>({} as any, {
  [types.LOAD_OKR_HISTORY_ITEM_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export type UnsentReducerState = Readonly<number>

type UnsentReducerBehavior<S> = Record<typeof types.LOAD_UNSENT_OKR_RECORDS_SUCCESS, Reducer<S, any>>

const unsent = createReducer<UnsentReducerState, UnsentReducerBehavior<UnsentReducerState>>(0 as any, {
  [types.LOAD_UNSENT_OKR_RECORDS_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

interface IOKRHistoryReducerState {
  detail: DetailReducerState
  unsent: UnsentReducerState
}

export type OKRHistoryReducerState = Readonly<IOKRHistoryReducerState>

const OKRHistory = combineReducers<OKRHistoryReducerState>({
  detail,
  unsent
})

export default OKRHistory
