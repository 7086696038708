import { GETGenArtsResponse, GenericArticleList } from '@cbgms/api'
import * as types from './types'

export interface IGenArtReducerState {
  list: GenericArticleList
  options: any[]
  lastFetched: Date
  isFetching: boolean
}

type GenArtReducerState = Readonly<IGenArtReducerState>

const defaultState: GenArtReducerState = {
  list: [],
  options: [],
  lastFetched: new Date(0),
  isFetching: false
}

interface LoadGenArtsSuccess {
  type: typeof types.GET_GEN_ARTS_SUCCESS
  payload: {
    data: GETGenArtsResponse
  }
}

interface LockCachedGenArts {
  type: typeof types.LOCK_GET_CACHED_GEN_ARTS
}

type GenArtActions = LoadGenArtsSuccess | LockCachedGenArts

export function genArts(state: GenArtReducerState = defaultState, action: GenArtActions) {
  switch (action.type) {
    case types.GET_GEN_ARTS_SUCCESS:
      return {
        list: action.payload.data.Data,
        options: action.payload.data.Data.map(option => {
          return {
            key: option.GenericArticleID,
            value: `${option.GenericArticleID}`,
            text: `${option.Designation} (${option.GenericArticleID})`
          }
        }),
        lastFetched: new Date(),
        isFetching: false
      }
    case types.LOCK_GET_CACHED_GEN_ARTS:
      return {
        ...state,
        isFetching: true
      }
    default:
      return state
  }
}
