import { AnyAction, combineReducers } from 'redux'
import { IParsedRDWProviderResponse, IRDWAPKInspectVehicle, IRDWDefectPoint } from '@cbgms/api'

import { createReducer } from '@cbgms/base/utils/redux'

import * as types from './types'
import { EmptyUUID } from '@cbgms/base/utils/uuid'

const consultResponseDefaultState: IParsedRDWProviderResponse | null = null

function consultResponse(state = consultResponseDefaultState, action: AnyAction) {
  switch (action.type) {
    case types.PARSE_CONSULT_RESPONSE_SUCCESS:
      return action.payload.data.Data
    case types.RESET_CONSULT_RESPONSE:
      return consultResponseDefaultState
    default:
      return state
  }
}

const defectListDefaultState: IRDWDefectPoint[] = []

const defectList = createReducer(defectListDefaultState, {
  [types.GET_CACHED_DEFECT_LIST_SUCCESS](_, { payload }) {
    return payload.data.Data.RecognizedDefects
  },
  [types.CLEAR_DEFECT_LIST]() {
    return defectListDefaultState
  },
  [types.PARSE_DEFECT_LIST_RESPONSE_SUCCESS](_, { payload }) {
    return payload.data.Data.RecognizedDefects
  }
})

const inspectVehicleDefaultState: IRDWAPKInspectVehicle = {
  APK: null,
  ConsultVehicleExpired: true,
  ConsultVehicleTimestamp: '',
  Status: '',
  UUID: EmptyUUID
}

const inspectVehicle = createReducer(inspectVehicleDefaultState, {
  [types.GET_RDW_APK_RECORD_SUCCESS](_, { payload }) {
    return payload.data.Data
  }
})

const historyDefaultState: [] = []

const history = createReducer(historyDefaultState, {
  [types.GET_CACHED_DEFECT_LIST_SUCCESS](_, { payload }) {
    return payload.data.Data.RecognizedDefects
  },
  [types.PARSE_DEFECT_LIST_RESPONSE_SUCCESS](_, { payload }) {
    return payload.data.Data.RecognizedDefects
  }
})

export default combineReducers({
  consultResponse,
  defectList,
  history,
  inspectVehicle
})
