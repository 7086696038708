export const GET_WORKORDER_WITH_RELATED = 'GET_WORKORDER_WITH_RELATED'
export const GET_WORKORDER_WITH_RELATED_SUCCESS = 'GET_WORKORDER_WITH_RELATED_SUCCESS'
export const GET_WORKORDER_WITH_RELATED_FAILED = 'GET_WORKORDER_WITH_RELATED_FAILED'

export const GET_WORKORDER_WITH_RELATED_PUBLIC = 'GET_WORKORDER_WITH_RELATED_PUBLIC'
export const GET_WORKORDER_WITH_RELATED_PUBLIC_SUCCESS = 'GET_WORKORDER_WITH_RELATED_PUBLIC_SUCCESS'
export const GET_WORKORDER_WITH_RELATED_PUBLIC_FAILED = 'GET_WORKORDER_WITH_RELATED_PUBLIC_FAILED'

export const PUT_WORKORDER_LINE_APPROVAL_PUBLIC = 'PUT_WORKORDER_LINE_APPROVAL_PUBLIC'
export const PUT_WORKORDER_LINE_APPROVAL_PUBLIC_SUCCESS = 'PUT_WORKORDER_LINE_APPROVAL_PUBLIC_SUCCESS'
export const PUT_WORKORDER_LINE_APPROVAL_PUBLIC_FAILED = 'PUT_WORKORDER_LINE_APPROVAL_PUBLIC_FAILED'

export const CREATE_WORKORDER = 'CREATE_WORKORDER'
export const CREATE_WORKORDER_SUCCESS = 'CREATE_WORKORDER_SUCCESS'
export const CREATE_WORKORDER_FAILED = 'CREATE_WORKORDER_FAILED'

export const GET_SUNDRY = 'GET_SUNDRY'
export const GET_SUNDRY_SUCCESS = 'GET_SUNDRY_SUCCESS'
export const GET_SUNDRY_FAILED = 'GET_SUNDRY_FAILED'

export const GET_VEHICLE_PRODUCT_HISTORY = 'GET_VEHICLE_PRODUCT_HISTORY'
export const GET_VEHICLE_PRODUCT_HISTORY_SUCCESS = 'GET_VEHICLE_PRODUCT_HISTORY_SUCCESS'
export const GET_VEHICLE_PRODUCT_HISTORY_FAILED = 'GET_VEHICLE_PRODUCT_HISTORY_FAILED'

export const LOAD_WORKORDERS = 'LOAD_WORKORDERS'
export const LOAD_WORKORDERS_SUCCESS = 'LOAD_WORKORDERS_SUCCESS'
export const LOAD_WORKORDERS_FAILED = 'LOAD_WORKORDERS_FAILED'

export const DELETE_WORKORDER = 'DELETE_WORKORDER'
export const DELETE_WORKORDER_SUCCESS = 'DELETE_WORKORDER_SUCCESS'
export const DELETE_WORKORDER_FAILED = 'DELETE_WORKORDER_FAILED'

export const LOAD_WORKORDER = 'LOAD_WORKORDER'
export const LOAD_WORKORDER_SUCCESS = 'LOAD_WORKORDER_SUCCESS'
export const LOAD_WORKORDER_FAILED = 'LOAD_WORKORDER_FAILED'

export const UPDATE_WORKORDER = 'UPDATE_WORKORDER'
export const UPDATE_WORKORDER_SUCCESS = 'UPDATE_WORKORDER_SUCCESS'
export const UPDATE_WORKORDER_FAILED = 'UPDATE_WORKORDER_FAILED'

export const LINK_VEHICLE_FROM_WORKORDER = 'LINK_VEHICLE_FROM_WORKORDER'
export const LINK_VEHICLE_FROM_WORKORDER_SUCCESS = 'LINK_VEHICLE_FROM_WORKORDER_SUCCESS'
export const LINK_VEHICLE_FROM_WORKORDER_FAILED = 'LINK_VEHICLE_FROM_WORKORDER_FAILED'

export const UNLINK_VEHICLE_FROM_WORKORDER = 'UNLINK_VEHICLE_FROM_WORKORDER'
export const UNLINK_VEHICLE_FROM_WORKORDER_SUCCESS = 'UNLINK_VEHICLE_FROM_WORKORDER_SUCCESS'
export const UNLINK_VEHICLE_FROM_WORKORDER_FAILED = 'UNLINK_VEHICLE_FROM_WORKORDER_FAILED'

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED'

export const ADD_OFFLINE_WORKORDER_LINES = 'ADD_OFFLINE_WORKORDER_LINES'
export const ADD_OFFLINE_WORKORDER_LINES_SUCCESS = 'ADD_OFFLINE_WORKORDER_LINES_SUCCESS'
export const ADD_OFFLINE_WORKORDER_LINES_FAILED = 'ADD_OFFLINE_WORKORDER_LINES_FAILED'

export const DUPLICATE_PRODUCT = 'DUPLICATE_PRODUCT'
export const DUPLICATE_PRODUCT_SUCCESS = 'DUPLICATE_PRODUCT_SUCCESS'
export const DUPLICATE_PRODUCT_FAILED = 'DUPLICATE_PRODUCT_FAILED'

export const GET_WORKORDER_LINES = 'GET_WORKORDER_LINES'
export const GET_WORKORDER_LINES_SUCCESS = 'GET_WORKORDER_LINES_SUCCESS'
export const GET_WORKORDER_LINES_FAILED = 'GET_WORKORDER_LINES_FAILED'

export const DELETE_WORKORDER_LINES = 'DELETE_WORKORDER_LINES'
export const DELETE_WORKORDER_LINES_SUCCESS = 'DELETE_WORKORDER_LINES_SUCCESS'
export const DELETE_WORKORDER_LINES_FAILED = 'DELETE_WORKORDER_LINES_FAILED'

export const DELETE_WORKORDER_ALL_LINES = 'DELETE_WORKORDER_ALL_LINES'
export const DELETE_WORKORDER_ALL_LINES_SUCCESS = 'DELETE_WORKORDER_ALL_LINES_SUCCESS'
export const DELETE_WORKORDER_ALL_LINES_FAILED = 'DELETE_WORKORDER_ALL_LINES_FAILED'

export const RETURN_AND_DELETE_ORDERED_WORKORDER_LINE = 'RETURN_AND_DELETE_ORDERED_WORKORDER_LINE'
export const RETURN_AND_DELETE_ORDERED_WORKORDER_LINE_SUCCESS = 'RETURN_AND_DELETE_ORDERED_WORKORDER_LINE_SUCCESS'
export const RETURN_AND_DELETE_ORDERED_WORKORDER_LINE_FAILED = 'RETURN_AND_DELETE_ORDERED_WORKORDER_LINE_FAILED'

export const DELETE_ORDERED_WORKORDER_LINE = 'DELETE_ORDERED_WORKORDER_LINE'
export const DELETE_ORDERED_WORKORDER_LINE_SUCCESS = 'DELETE_ORDERED_WORKORDER_LINE_SUCCESS'
export const DELETE_ORDERED_WORKORDER_LINE_FAILED = 'DELETE_ORDERED_WORKORDER_LINE_FAILED'

export const UPDATE_WORKORDER_LINES = 'UPDATE_WORKORDER_LINES'
export const UPDATE_WORKORDER_LINES_SUCCESS = 'UPDATE_WORKORDER_LINES_SUCCESS'
export const UPDATE_WORKORDER_LINES_FAILED = 'UPDATE_WORKORDER_LINES_FAILED'

export const UPDATE_WORKORDER_LINES_SORT = 'UPDATE_WORKORDER_LINES_SORT'
export const UPDATE_WORKORDER_LINES_SORT_SUCCESS = 'UPDATE_WORKORDER_LINES_SORT_SUCCESS'
export const UPDATE_WORKORDER_LINES_SORT_FAILED = 'UPDATE_WORKORDER_LINES_SORT_FAILED'

export const UPDATE_WORKORDER_CHILD_LINES_SORT = 'UPDATE_WORKORDER_CHILD_LINES_SORT'
export const UPDATE_WORKORDER_CHILD_LINES_SORT_SUCCESS = 'UPDATE_WORKORDER_CHILD_LINES_SORT_SUCCESS'
export const UPDATE_WORKORDER_CHILD_LINES_SORT_FAILED = 'UPDATE_WORKORDER_CHILD_LINES_SORT_FAILED'

export const UPDATE_WORKORDER_CHILD_PARENT = 'UPDATE_WORKORDER_CHILD_PARENT'
export const UPDATE_WORKORDER_CHILD_PARENT_SUCCESS = 'UPDATE_WORKORDER_CHILD_PARENT_SUCCESS'
export const UPDATE_WORKORDER_CHILD_PARENT_FAILED = 'UPDATE_WORKORDER_CHILD_PARENT_FAILED'

export const UPDATE_WORKORDER_PARENT_CHILD = 'UPDATE_WORKORDER_PARENT_CHILD'
export const UPDATE_WORKORDER_PARENT_CHILD_SUCCESS = 'UPDATE_WORKORDER_PARENT_CHILD_SUCCESS'
export const UPDATE_WORKORDER_PARENT_CHILD_FAILED = 'UPDATE_WORKORDER_PARENT_CHILD_FAILED'

export const GET_WORKORDER_LINE = 'GET_WORKORDER_LINE'
export const GET_WORKORDER_LINE_SUCCESS = 'GET_WORKORDER_LINE_SUCCESS'
export const GET_WORKORDER_LINE_FAILED = 'GET_WORKORDER_LINE_FAILED'

export const GET_REPAIR_TIMES_CHAPTERS = 'GET_REPAIR_TIMES_CHAPTERS'
export const GET_REPAIR_TIMES_CHAPTERS_SUCCESS = 'GET_REPAIR_TIMES_CHAPTERS_SUCCESS'
export const GET_REPAIR_TIMES_CHAPTERS_FAILED = 'GET_REPAIR_TIMES_CHAPTERS_FAILED'

export const GET_REPAIR_TIMES_TOPICS = 'GET_REPAIR_TIMES_TOPICS'
export const GET_REPAIR_TIMES_TOPICS_SUCCESS = 'GET_REPAIR_TIMES_TOPICS_SUCCESS'
export const GET_REPAIR_TIMES_TOPICS_FAILED = 'GET_REPAIR_TIMES_TOPICS_FAILED'

export const GET_REPAIR_TIMES_NODES = 'GET_REPAIR_TIMES_NODES'
export const GET_REPAIR_TIMES_NODES_SUCCESS = 'GET_REPAIR_TIMES_NODES_SUCCESS'
export const GET_REPAIR_TIMES_NODES_FAILED = 'GET_REPAIR_TIMES_NODES_FAILED'

export const GET_REPAIR_TIMES = 'GET_REPAIR_TIMES'
export const GET_REPAIR_TIMES_SUCCESS = 'GET_REPAIR_TIMES_SUCCESS'
export const GET_REPAIR_TIMES_FAILED = 'GET_REPAIR_TIMES_FAILED'

export const PUT_REPAIR_TIMES = 'PUT_REPAIR_TIMES'
export const PUT_REPAIR_TIMES_SUCCESS = 'PUT_REPAIR_TIMES_SUCCESS'
export const PUT_REPAIR_TIMES_FAILED = 'PUT_REPAIR_TIMES_FAILED'

export const RESET_HAYNESPRO_REPAIR_TIMES = 'RESET_HAYNESPRO_REPAIR_TIMES'

export const RESET_REPAIR_TIMES = 'RESET_REPAIR_TIMES'

export const GET_ORDER_LINES = 'GET_ORDER_LINES'
export const GET_ORDER_LINES_SUCCESS = 'GET_ORDER_LINES_SUCCESS'
export const GET_ORDER_LINES_FAILED = 'GET_ORDER_LINES_FAILED'

export const GET_ORDER_ACCOUNTS = 'GET_ORDER_ACCOUNTS'
export const GET_ORDER_ACCOUNTS_SUCCESS = 'GET_ORDER_ACCOUNTS_SUCCESS'
export const GET_ORDER_ACCOUNTS_FAILED = 'GET_ORDER_ACCOUNTS_FAILED'

export const RESET_ORDER_ACCOUNTS = 'RESET_ORDER_ACCOUNTS'

export const GET_CUSTOMER_CONTACT_INFO = 'GET_CUSTOMER_CONTACT_INFO'
export const GET_CUSTOMER_CONTACT_INFO_SUCCESS = 'GET_CUSTOMER_CONTACT_INFO_SUCCESS'
export const GET_CUSTOMER_CONTACT_INFO_FAILED = 'GET_CUSTOMER_CONTACT_INFO_FAILED'

export const GET_SUPPLIER_CONTACT_INFO = 'GET_SUPPLIER_CONTACT_INFO'
export const GET_SUPPLIER_CONTACT_INFO_SUCCESS = 'GET_SUPPLIER_CONTACT_INFO_SUCCESS'
export const GET_SUPPLIER_CONTACT_INFO_FAILED = 'GET_SUPPLIER_CONTACT_INFO_FAILED'

export const GET_SUPPLIER_ORDER_PARTS = 'GET_SUPPLIER_ORDER_PARTS'
export const GET_SUPPLIER_ORDER_PARTS_SUCCESS = 'GET_SUPPLIER_ORDER_PARTS_SUCCESS'
export const GET_SUPPLIER_ORDER_PARTS_FAILED = 'GET_SUPPLIER_ORDER_PARTS_FAILED'
export const RESET_SUPPLIER_ORDER_PART = 'RESET_SUPPLIER_ORDER_PART'

export const INCREMENT_SUPPLIER_ORDER_PART = 'INCREMENT_SUPPLIER_ORDER_PART'
export const DECREMENT_SUPPLIER_ORDER_PART = 'DECREMENT_SUPPLIER_ORDER_PART'

export const GET_SUPPLIER_ORDER_PARAMS = 'GET_SUPPLIER_ORDER_PARAMS'
export const GET_SUPPLIER_ORDER_PARAMS_SUCCESS = 'GET_SUPPLIER_ORDER_PARAMS_SUCCESS'
export const GET_SUPPLIER_ORDER_PARAMS_FAILED = 'GET_SUPPLIER_ORDER_PARAMS_FAILED'

export const GET_ORDER_DELIVERY_TIMES = 'GET_ORDER_DELIVERY_TIMES'
export const GET_ORDER_DELIVERY_TIMES_SUCCESS = 'GET_ORDER_DELIVERY_TIMES_SUCCESS'
export const GET_ORDER_DELIVERY_TIMES_FAILED = 'GET_ORDER_DELIVERY_TIMES_FAILED'

export const GET_ORDER_PICKUP_TIMES = 'GET_ORDER_PICKUP_TIMES'
export const GET_ORDER_PICKUP_TIMES_SUCCESS = 'GET_ORDER_PICKUP_TIMES_SUCCESS'
export const GET_ORDER_PICKUP_TIMES_FAILED = 'GET_ORDER_PICKUP_TIMES_FAILED'

export const PLACE_ORDER = 'PLACE_ORDER'
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS'
export const PLACE_ORDER_FAILED = 'PLACE_ORDER_FAILED'

export const CREATE_INVOICE = 'CREATE_INVOICE'
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS'
export const CREATE_INVOICE_FAILED = 'CREATE_INVOICE_FAILED'

export const PRE_INVOICE_CHECK = 'PRE_INVOICE_CHECK'
export const PRE_INVOICE_CHECK_SUCCESS = 'PRE_INVOICE_CHECK_SUCCESS'
export const PRE_INVOICE_CHECK_FAILED = 'PRE_INVOICE_CHECK_FAILED'

export const ARCHIVE_WORKORDER = 'ARCHIVE_WORKORDER'
export const ARCHIVE_WORKORDER_SUCCESS = 'ARCHIVE_WORKORDER_SUCCESS'
export const ARCHIVE_WORKORDER_FAILED = 'ARCHIVE_WORKORDER_FAILED'

export const GET_FIRST_APPOINTMENT = 'GET_FIRST_APPOINTMENT'
export const GET_FIRST_APPOINTMENT_SUCCESS = 'GET_FIRST_APPOINTMENT_SUCCESS'
export const GET_FIRST_APPOINTMENT_FAILED = 'GET_FIRST_APPOINTMENT_FAILED'

export const EMAIL_APPROVAL = 'EMAIL_APPROVAL'
export const EMAIL_APPROVAL_SUCCESS = 'EMAIL_APPROVAL_SUCCESS'
export const EMAIL_APPROVAL_FAILED = 'EMAIL_APPROVAL_FAILED'

export const NOTIFY_MYKAR = 'NOTIFY_MYKAR'
export const NOTIFY_MYKAR_SUCCESS = 'NOTIFY_MYKAR_SUCCESS'
export const NOTIFY_MYKAR_FAILED = 'NOTIFY_MYKAR_FAILED'

export const FORMS = {
  order_products: 'order_products',
  add_line: 'add_line'
}

export const LOAD_VEHICLE_WORKORDERS = 'LOAD_VEHICLE_WORKORDERS'
export const LOAD_VEHICLE_WORKORDERS_SUCCESS = 'LOAD_VEHICLE_WORKORDERS_SUCCESS'
export const LOAD_VEHICLE_WORKORDERS_FAILED = 'LOAD_VEHICLE_WORKORDERS_FAILED'

export const LOAD_ACCOUNT_WORKORDERS = 'LOAD_ACCOUNT_WORKORDERS'
export const LOAD_ACCOUNT_WORKORDERS_SUCCESS = 'LOAD_ACCOUNT_WORKORDERS_SUCCESS'
export const LOAD_ACCOUNT_WORKORDERS_FAILED = 'LOAD_ACCOUNT_WORKORDERS_FAILED'

export const SEND_SMS_MESSAGE = 'SEND_SMS_MESSAGE'
export const SEND_SMS_MESSAGE_SUCCESS = 'SEND_SMS_MESSAGE_SUCCESS'
export const SEND_SMS_MESSAGE_FAILED = 'SEND_SMS_MESSAGE_FAILED'

export const UPDATE_ORDER_LINES = 'UPDATE_ORDER_LINES'
export const UPDATE_ORDER_LINES_SUCCESS = 'UPDATE_ORDER_LINES_SUCCESS'
export const UPDATE_ORDER_LINES_FAILED = 'UPDATE_ORDER_LINES_FAILED'

export const DELETE_ORDERPARTLINE = 'DELETE_ORDERPARTLINE'

export const ITEM_SEARCH = 'ITEM_SEARCH'
export const ITEM_SEARCH_SUCCESS = 'ITEM_SEARCH_SUCCESS'
export const ITEM_SEARCH_FAILED = 'ITEM_SEARCH_FAILED'

export const LOAD_CHANGED_LINES = 'LOAD_CHANGED_LINES'
export const LOAD_CHANGED_LINES_SUCCESS = 'LOAD_CHANGED_LINES_SUCCESS'
export const LOAD_CHANGED_LINES_FAILED = 'LOAD_CHANGED_LINES_FAILED'

export const RESTORE_WORKORDER_FROM_INVOICE = 'RESTORE_WORKORDER_FROM_INVOICE'
export const RESTORE_WORKORDER_FROM_INVOICE_SUCCESS = 'RESTORE_WORKORDER_FROM_INVOICE_SUCCESS'
export const RESTORE_WORKORDER_FROM_INVOICE_FAILED = 'RESTORE_WORKORDER_FROM_INVOICE_FAILED'

export const RESTORE_WORKORDER_FROM_QUOTATION = 'RESTORE_WORKORDER_FROM_QUOTATION'
export const RESTORE_WORKORDER_FROM_QUOTATION_SUCCESS = 'RESTORE_WORKORDER_FROM_QUOTATION_SUCCESS'
export const RESTORE_WORKORDER_FROM_QUOTATION_FAILED = 'RESTORE_WORKORDER_FROM_QUOTATION_FAILED'

export const GET_APPOINTMENT_AVAILABILITY = 'GET_APPOINTMENT_AVAILABILITY'
export const GET_APPOINTMENT_AVAILABILITY_SUCCESS = 'GET_APPOINTMENT_AVAILABILITY_SUCCESS'
export const GET_APPOINTMENT_AVAILABILITY_FAILED = 'GET_APPOINTMENT_AVAILABILITY_FAILED'

export const CREATE_APPOINTMENT_PROPOSAL = 'CREATE_APPOINTMENT_PROPOSAL'
export const CREATE_APPOINTMENT_PROPOSAL_SUCCESS = 'CREATE_APPOINTMENT_PROPOSAL_SUCCESS'
export const CREATE_APPOINTMENT_PROPOSAL_FAILED = 'CREATE_APPOINTMENT_PROPOSAL_FAILED'

export const GET_WORKORDER_CHECKLISTS = 'GET_WORKORDER_CHECKLISTS'
export const GET_WORKORDER_CHECKLISTS_SUCCESS = 'GET_WORKORDER_CHECKLISTS_SUCCESS'
export const GET_WORKORDER_CHECKLISTS_FAILED = 'GET_WORKORDER_CHECKLISTS_FAILED'

export const GET_SEARCH_QUICK_CREATE = 'GET_SEARCH_QUICK_CREATE'
export const GET_SEARCH_QUICK_CREATE_SUCCESS = 'GET_SEARCH_QUICK_CREATE_SUCCESS'
export const GET_SEARCH_QUICK_CREATE_FAILED = 'GET_SEARCH_QUICK_CREATE_FAILED'

export const GET_WORKORDER_WARNINGS = 'GET_WORKORDER_WARNINGS'
export const GET_WORKORDER_WARNINGS_SUCCESS = 'GET_WORKORDER_WARNINGS_SUCCESS'
export const GET_WORKORDER_WARNINGS_FAILED = 'GET_WORKORDER_WARNINGS_FAILED'

export const GET_WORKORDER_IMAGES = 'GET_WORKORDER_IMAGES'
export const GET_WORKORDER_IMAGES_SUCCESS = 'GET_WORKORDER_IMAGES_SUCCESS'
export const GET_WORKORDER_IMAGES_FAILED = 'GET_WORKORDER_IMAGES_FAILED'

export const GET_WORKORDERS_ASSIGNED_TO_EMPLOYEE = 'GET_WORKORDERS_ASSIGNED_TO_EMPLOYEE'
export const GET_WORKORDERS_ASSIGNED_TO_EMPLOYEE_SUCCESS = 'GET_WORKORDERS_ASSIGNED_TO_EMPLOYEE_SUCCESS'
export const GET_WORKORDERS_ASSIGNED_TO_EMPLOYEE_FAILED = 'GET_WORKORDERS_ASSIGNED_TO_EMPLOYEE_FAILED'

export const CLEAR_WORKORDER = 'CLEAR_WORKORDER'
export const CLEAR_WORKORDER_CHECKLISTS = 'CLEAR_WORKORDER_CHECKLISTS'

export const GET_WHOLESALER_IFRAME_URL = 'GET_WHOLESALER_IFRAME_URL'
export const GET_WHOLESALER_IFRAME_URL_SUCCESS = 'GET_WHOLESALER_IFRAME_URL_SUCCESS'
export const GET_WHOLESALER_IFRAME_URL_FAILED = 'GET_WHOLESALER_IFRAME_URL_FAILED'

export const PUT_WHOLESALER_IFRAME_CART_TO_WORKORDER = 'PUT_WHOLESALER_IFRAME_CART_TO_WORKORDER'
export const PUT_WHOLESALER_IFRAME_CART_TO_WORKORDER_SUCCESS = 'PUT_WHOLESALER_IFRAME_CART_TO_WORKORDER_SUCCESS'
export const PUT_WHOLESALER_IFRAME_CART_TO_WORKORDER_FAILED = 'PUT_WHOLESALER_IFRAME_CART_TO_WORKORDER_FAILED'

export const PROCESS_EXTERNAL_ORDER = 'PROCESS_EXTERNAL_ORDER'
export const PROCESS_EXTERNAL_ORDER_SUCCESS = 'PROCESS_EXTERNAL_ORDER_SUCCESS'
export const PROCESS_EXTERNAL_ORDER_FAILED = 'PROCESS_EXTERNAL_ORDER_FAILED'

export const SET_DISCOUNT = 'SET_DISCOUNT'
export const SET_DISCOUNT_SUCCESS = 'SET_DISCOUNT_SUCCESS'
export const SET_DISCOUNT_FAILED = 'SET_DISCOUNT_FAILED'

export const PUT_DEFAULT_INVOICE_TYPE = 'PUT_DEFAULT_INVOICE_TYPE'
export const PUT_DEFAULT_INVOICE_TYPE_SUCCESS = 'PUT_DEFAULT_INVOICE_TYPE_SUCCESS'
export const PUT_DEFAULT_INVOICE_TYPE_FAILED = 'PUT_DEFAULT_INVOICE_TYPE_FAILED'

export const GET_INVOICE_SUGGESTION = 'GET_INVOICE_SUGGESTION'
export const GET_INVOICE_SUGGESTION_SUCCESS = 'GET_INVOICE_SUGGESTION_SUCCESS'
export const GET_INVOICE_SUGGESTION_FAILED = 'GET_INVOICE_SUGGESTION_FAILED'
