import { Reducer } from 'redux'
import { UserStatus } from '@cbgms/api/modules/enums'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from 'app/settings/state/Employees/types'

interface IStatusReducerState {
  Username: string
  Status: UserStatus
  Enabled: boolean
  CreatedAt?: string
  UpdatedAt?: string
  Name: string
  NamePrefix: string
  FirstName: string
  EmailAddress: string
}

export type StatusReducerState = Readonly<IStatusReducerState>

const defaultState: StatusReducerState = {
  Username: '',
  Status: UserStatus.Unknown,
  Enabled: false,
  Name: '',
  NamePrefix: '',
  FirstName: '',
  EmailAddress: ''
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type StatusReducerBehavior<S = StatusReducerState> = Record<typeof types.LOAD_EMPLOYEE_STATUS_SUCCESS, Reducer<S, any>> &
  Record<typeof types.LOAD_EMPLOYEE_STATUS_FAILED, Reducer<S, any>>

const status = createReducer<StatusReducerState, StatusReducerBehavior>(defaultState, {
  [types.LOAD_EMPLOYEE_STATUS_SUCCESS]: (_, { payload }) => payload.data.Data,
  [types.LOAD_EMPLOYEE_STATUS_FAILED]: () => defaultState
})

export default status
