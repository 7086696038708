interface GoogleAnalytics {
  ga: UniversalAnalytics.ga
}

const setupAnalytics = (code: string, analytics: GoogleAnalytics) => {
  if (analytics.ga) {
    analytics.ga('create', code, 'auto')
    analytics.ga('set', 'anonymizeIp', true)
    analytics.ga('send', 'pageview')
  }
}

export default setupAnalytics
