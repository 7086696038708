import { IWorkorderTotals } from '@cbgms/api/modules/workorder'

export type WorkorderTotalsState = Readonly<IWorkorderTotals>

const defaultWorkorderTotalsParent = {
  DiscountAmountVatExcluded: 0,
  DiscountPercentage: 0,
  MarginAmount: 0,
  VatAmount: 0,
  VatExcluded: 0,
  VatIncluded: 0,
  VatTypes: [],
  CostPriceVatExcluded: 0
}

const defaultWorkorderTotals = {
  ...defaultWorkorderTotalsParent,
  LabourVatExcluded: 0,
  OtherVatExcluded: 0,
  PartsVatExcluded: 0
}

export const defaultState: WorkorderTotalsState = {
  ...defaultWorkorderTotalsParent,
  TotalIncludingRejected: {
    ...defaultWorkorderTotalsParent
  },
  TotalApproved: {
    ...defaultWorkorderTotals
  },
  TotalPending: {
    ...defaultWorkorderTotals
  },
  TotalQuote: {
    ...defaultWorkorderTotals
  },
  TotalRejected: {
    ...defaultWorkorderTotals
  },

  TotalWarranty: {
    ...defaultWorkorderTotals
  },
  TotalInternal: {
    ...defaultWorkorderTotals
  }
}
