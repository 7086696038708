import React, { useMemo } from 'react'
import { Timing } from '@cbgms/base/utils/timing'
import Box from '@mui/material/Box'
import { CircularProgress, CircularProgressProps } from '@mui/material'
import Fade from '@mui/material/Fade'
import LinearProgress from '@mui/material/LinearProgress'
import { SxProps } from '@mui/material/styles'

export interface LoaderProps {
  children?: React.ReactNode
  loading: boolean
  size?: string | number
  fading?: boolean // fading: hide the loader a short amount then show it
  linear?: boolean // linear: render as a loading bar instead of the default circular spinner
  overlayHeight?: string // css height
  floating?: boolean // allow the loader to float on the center of the screen
  absolute?: boolean // make floating loader position absolute instead of fixed
  color?: CircularProgressProps['color']
  className?: string
  sx?: SxProps<{}>
}

export const Loader = (props: LoaderProps) => {
  const { children, loading, size, fading, linear, overlayHeight, floating, absolute, color, ...rest } = props
  const wrapperStyling = useMemo((): SxProps<{}> => {
    if (floating) {
      return { position: absolute ? 'absolute' : 'fixed', top: '50%', left: '50%' }
    }
    return { position: 'relative' }
  }, [floating])

  // prevent any unnecessary jsx/html when we don't want the loader to show anything anymore
  if (!children && !loading) return null

  return (
    <Box sx={wrapperStyling} {...rest}>
      {loading && (
        <Fade
          in={loading}
          easing='ease-in'
          style={{
            transitionDelay: fading ? `${Timing.slowAnimation}ms` : '0ms',
            transitionDuration: fading ? `${Timing.slowAnimation}ms` : '0ms'
          }}
          unmountOnExit
        >
          {linear ? (
            <LinearProgress color={color} />
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999
              }}
            >
              <CircularProgress color={color} size={size} />
            </Box>
          )}
        </Fade>
      )}
      <Box
        sx={{
          pointerEvents: loading ? 'none' : 'initial',
          minHeight: linear ? 'initial' : overlayHeight || '50px'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

Loader.defaultProps = {
  fading: true,
  color: 'inherit'
}
