import { reducer as form } from 'redux-form'
import auth from 'app/auth/state/reducers'
import account from 'app/accounts/state/reducers'
import archive, { ArchiveReducerState } from 'app/archive/state/reducers'
import part, { IPartReducerState } from 'app/stock/state/reducers'
import calendar from 'app/workorderAppointments/state/reducers'
import searchReducer from 'app/search/state/reducers'
import enums from 'enums/state/reducers'
import { menu } from 'app/menu/state/reducers'
import orderHistory, { IOrderHistoryReducerState } from 'app/history/state/Order/reducers'
import vehicle from 'app/vehicles/state/reducers'
import vat from 'core/widgets/VatDropdown/reducers'
import countries from 'core/widgets/CountriesDropdown/reducers'
import workorder, { IWorkorderReducerState } from 'app/workorder/state/workorder/reducers'
import apk from 'app/workorder/state/apk/reducer'
import settings, { SettingsReducerState } from 'app/settings/state/reducers'
import { combineReducers } from 'redux'
import { IGenArtReducerState, genArts } from 'core/widgets/GenArtsDropdown/reducers'
import history, { HistoryReducerState } from 'app/history/state/reducers'
import timeRegistration from 'app/timeRegistration/state/reducer'
import environment from 'state/environment/reducers'
import cms, { CmsReducerState } from 'app/cms/state/reducers'
import order from 'app/order/state/reducers'
import products from 'app/products/state/reducers'
import replacementVehicleWithRelated from 'app/replacementVehicles/state/reducers'
import cbgmsComponentsProductFilters from '@cbgms/components/src/order-list/context/filters/ProductFiltersReducer'

export interface IRootReducerState {
  replacementVehicleWithRelated: ReturnType<typeof replacementVehicleWithRelated>
  auth: ReturnType<typeof auth>
  account: ReturnType<typeof account>
  apk: ReturnType<typeof apk>
  archive: ArchiveReducerState
  calendar: ReturnType<typeof calendar>
  countries: ReturnType<typeof countries>
  enums: ReturnType<typeof enums>
  environment: ReturnType<typeof environment>
  genArts: IGenArtReducerState
  orderHistory: IOrderHistoryReducerState
  search: ReturnType<typeof searchReducer>
  history: HistoryReducerState
  menu: ReturnType<typeof menu>
  part: IPartReducerState
  vehicle: ReturnType<typeof vehicle>
  workorder: IWorkorderReducerState
  settings: SettingsReducerState
  form: any
  timeRegistration: ReturnType<typeof timeRegistration>
  vat: ReturnType<typeof vat>
  cms: CmsReducerState
  order: ReturnType<typeof order>
  products: ReturnType<typeof products>
  cbgmsComponentsProductFilters: ReturnType<typeof cbgmsComponentsProductFilters>
}

export const reducers = combineReducers<IRootReducerState>({
  replacementVehicleWithRelated,
  auth,
  account,
  apk,
  archive,
  calendar,
  countries,
  enums,
  environment,
  genArts,
  search: searchReducer,
  history,
  menu,
  orderHistory,
  part,
  timeRegistration,
  vehicle,
  workorder,
  settings,
  form,
  vat,
  cms,
  order,
  products,
  cbgmsComponentsProductFilters
})
