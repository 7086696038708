export const LOAD_WORKORDER_NUMBER = 'LOAD_WORKORDER_NUMBER'
export const LOAD_WORKORDER_NUMBER_SUCCESS = 'LOAD_WORKORDER_NUMBER_SUCCESS'
export const LOAD_WORKORDER_NUMBER_FAILED = 'LOAD_WORKORDER_NUMBER_FAILED'

export const UPDATE_WORKORDER_NUMBER = 'UPDATE_WORKORDER_NUMBER'
export const UPDATE_WORKORDER_NUMBER_SUCCESS = 'UPDATE_WORKORDER_NUMBER_SUCCESS'
export const UPDATE_WORKORDER_NUMBER_FAILED = 'UPDATE_WORKORDER_NUMBER_FAILED'

export const LOAD_WORKORDER_NUMBER_PREVIEW = 'LOAD_WORKORDER_NUMBER_PREVIEW'
export const LOAD_WORKORDER_NUMBER_PREVIEW_SUCCESS = 'LOAD_WORKORDER_NUMBER_PREVIEW_SUCCESS'
export const LOAD_WORKORDER_NUMBER_PREVIEW_FAILED = 'LOAD_WORKORDER_NUMBER_PREVIEW_FAILED'
