import React from 'react'

export const XDomainContext = React.createContext('')

export const XDomainContextProvider: React.FC = ({ children }) => {
  const [xDomain, setXDomain] = React.useState('')

  React.useEffect(() => {
    fetchXDomain().then(setXDomain)
  }, [])

  if (!xDomain) return null

  return <XDomainContext.Provider value={xDomain}>{children}</XDomainContext.Provider>
}

const fetchXDomain = async () => {
  const response = await fetch(window.location.origin, { method: 'HEAD' })
  const XDomainHeader = response.headers.get('x-domain')

  if (!XDomainHeader) {
    console.error('failed to load x-domain')

    return ''
  }

  return XDomainHeader
}
