export enum ProductRelation {
  Alternative = 'alternative',
  Accessory = 'accessory',
  Upsell = 'upsell',
  CrossSell = 'crossSell',
  Supersede = 'supersede',
  Variant = 'variant',
  Tool = 'tool',
  Related = 'related',
  Contains = 'contains',
  Collateral = 'collateral'
}
