export const LOAD_ACCOUNTS = 'LOAD_ACCOUNTS'
export const LOAD_ACCOUNTS_SUCCESS = 'LOAD_ACCOUNTS_SUCCESS'
export const LOAD_ACCOUNTS_FAILED = 'LOAD_ACCOUNTS_FAILED'

export const LOAD_ACCOUNT = 'LOAD_ACCOUNT'
export const LOAD_ACCOUNT_SUCCESS = 'LOAD_ACCOUNT_SUCCESS'
export const LOAD_ACCOUNT_FAILED = 'LOAD_ACCOUNT_FAILED'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_ACCOUNT_FAILED = 'DELETE_ACCOUNT_FAILED'

export const LOAD_ACCOUNT_NUMBERS = 'LOAD_ACCOUNT_NUMBERS'
export const LOAD_ACCOUNT_NUMBERS_SUCCESS = 'LOAD_ACCOUNT_NUMBERS_SUCCESS'
export const LOAD_ACCOUNT_NUMBERS_FAILED = 'LOAD_ACCOUNT_NUMBERS_FAILED'

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED'

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS'
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED'

export const UPDATE_ACCOUNT_NUMBER = 'UPDATE_ACCOUNT_NUMBER'
export const UPDATE_ACCOUNT_NUMBER_SUCCESS = 'UPDATE_ACCOUNT_NUMBER_SUCCESS'
export const UPDATE_ACCOUNT_NUMBER_FAILED = 'UPDATE_ACCOUNT_NUMBER_FAILED'

export const CREATE_ACCOUNT_NUMBER = 'CREATE_ACCOUNT_NUMBER'
export const CREATE_ACCOUNT_NUMBER_SUCCESS = 'CREATE_ACCOUNT_NUMBER_SUCCESS'
export const CREATE_ACCOUNT_NUMBER_FAILED = 'CREATE_ACCOUNT_NUMBER_FAILED'

export const DELETE_ACCOUNT_NUMBER = 'DELETE_ACCOUNT_NUMBER'
export const DELETE_ACCOUNT_NUMBER_SUCCESS = 'DELETE_ACCOUNT_NUMBER_SUCCESS'
export const DELETE_ACCOUNT_NUMBER_FAILED = 'DELETE_ACCOUNT_NUMBER_FAILED'

export const RESET_ACCOUNT = 'RESET_ACCOUNT'

export const GET_ADDRESS_FROM_POSTAL_CODE = 'GET_ADDRESS_FROM_POSTAL_CODE'
export const GET_ADDRESS_FROM_POSTAL_CODE_SUCCESS = 'GET_ADDRESS_FROM_POSTAL_CODE_SUCCESS'
export const GET_ADDRESS_FROM_POSTAL_CODE_FAILED = 'GET_ADDRESS_FROM_POSTAL_CODE_FAILED'

export const UPDATE_SAGE_ACCOUNT_NUMBER = 'UPDATE_SAGE_ACCOUNT_NUMBER'
export const UPDATE_SAGE_ACCOUNT_NUMBER_SUCCESS = 'UPDATE_SAGE_ACCOUNT_NUMBER_SUCCESS'
export const UPDATE_SAGE_ACCOUNT_NUMBER_FAILED = 'UPDATE_SAGE_ACCOUNT_NUMBER_FAILED'

export const GET_COMMUNICATION_CHANNEL_DEFAULT_CONFIG = 'GET_COMMUNICATION_CHANNEL_DEFAULT_CONFIG'
export const GET_COMMUNICATION_CHANNEL_DEFAULT_CONFIG_SUCCESS = 'GET_COMMUNICATION_CHANNEL_DEFAULT_CONFIG_SUCCESS'
export const GET_COMMUNICATION_CHANNEL_DEFAULT_CONFIG_FAILED = 'GET_COMMUNICATION_CHANNEL_DEFAULT_CONFIG_FAILED'

export const GET_COMMUNICATION_CHANNEL_ACCOUNT_CONFIG = 'GET_COMMUNICATION_CHANNEL_ACCOUNT_CONFIG'
export const GET_COMMUNICATION_CHANNEL_ACCOUNT_CONFIG_SUCCESS = 'GET_COMMUNICATION_CHANNEL_ACCOUNT_CONFIG_SUCCESS'
export const GET_COMMUNICATION_CHANNEL_ACCOUNT_CONFIG_FAILED = 'GET_COMMUNICATION_CHANNEL_ACCOUNT_CONFIG_FAILED'
