import { generatePath } from 'react-router-dom'
import { OnboardingDuplicationCheckType, OnboardingStepKey } from '@cbgms/api'

export const pathTicketInvalid = (basePath?: string) => `${basePath}/invalid-ticket`
export const urlTicketInvalid = (basePath: string) => generatePath(pathTicketInvalid(basePath))

export const pathWholesalerCredentials = (basePath: string) => `${basePath}/${OnboardingStepKey.WholesalerCredentialsLogin}`
export const urlWholesalerCredentials = (basePath: string) => generatePath(pathWholesalerCredentials(basePath))

export const pathCheckVatNumber = (basePath: string) => `${basePath}/${OnboardingStepKey.VatDuplicateCheck}`
export const urlCheckVatNumber = (basePath: string) => generatePath(pathCheckVatNumber(basePath))
export const pathAlreadyRegisteredVAT = (basePath: string) => `${pathCheckVatNumber(basePath)}/already-registered`
export const urlAlreadyRegisteredVAT = (basePath: string) => generatePath(pathAlreadyRegisteredVAT(basePath))

export const pathVerifyContactInformation = (basePath: string) => `${basePath}/${OnboardingStepKey.ShopDetails}`
export const urlVerifyContactInformation = (basePath: string) => generatePath(pathVerifyContactInformation(basePath))

export const pathSetUpOrderAccounts = (basePath: string) => `${basePath}/${OnboardingStepKey.OrderAccounts}`
export const urlSetUpOrderAccounts = (basePath: string) => generatePath(pathSetUpOrderAccounts(basePath))
export const pathAddAdditionalOrderAccounts = (basePath: string) => `${pathSetUpOrderAccounts(basePath)}/add-additional-order-accounts`
export const urlAddAdditionalOrderAccounts = (basePath: string) => generatePath(pathAddAdditionalOrderAccounts(basePath))

export const pathSignUp = (basePath: string) => `${basePath}/${OnboardingStepKey.SignUpCredentials}`
export const urlSignUp = (basePath: string) => generatePath(pathSignUp(basePath))
export const pathAlreadyRegisteredUserEmail = (basePath: string) => `${pathSignUp(basePath)}/already-registered`
export const urlAlreadyRegisteredUserEmail = (basePath: string) => generatePath(pathAlreadyRegisteredUserEmail(basePath))

export const pathDuplicationError = (basePath: string) => `${basePath}/duplication`
export const urlDuplicationError = (basePath: string, checkType: OnboardingDuplicationCheckType) =>
  `${generatePath(pathDuplicationError(basePath))}?checkType=${checkType}`

export const pathInviteUserToOtherShop = (basePath: string) => `${basePath}/invite-to-other-shop`
export const urlInviteUserToOtherShop = (basePath: string, checkType: OnboardingDuplicationCheckType) =>
  `${generatePath(pathInviteUserToOtherShop(basePath))}?checkType=${checkType}`

export const pathAddMechanicsForm = (basePath: string) => `${basePath}/${OnboardingStepKey.Mechanic}`
export const urlAddMechanicsForm = (basePath: string) => generatePath(pathAddMechanicsForm(basePath))

export const pathSignUpFinished = (basePath: string) => `${basePath}/${OnboardingStepKey.SignupFinished}`
export const urlSignUpFinished = (basePath: string) => generatePath(pathSignUpFinished(basePath))

export const pathAcceptTermsAndConditionsForm = (basePath: string) => `${basePath}/${OnboardingStepKey.TermsAndConditions}`
export const urlAcceptTermsAndConditionsForm = (basePath: string) => generatePath(pathAcceptTermsAndConditionsForm(basePath))

export const pathLabourRateForm = (basePath: string) => `${basePath}/${OnboardingStepKey.LabourRate}`
export const urlLabourRateForm = (basePath: string) => generatePath(pathLabourRateForm(basePath))

export const pathOpeningHoursForm = (basePath: string) => `${basePath}/${OnboardingStepKey.OpeningHours}`
export const urlOpeningHoursForm = (basePath: string) => generatePath(pathOpeningHoursForm(basePath))

export const pathCaptureGMSSelection = (basePath: string) => `${basePath}/${OnboardingStepKey.ExternalGMS}`
export const urlCaptureGMSSelection = (basePath: string) => generatePath(pathCaptureGMSSelection(basePath))

export const pathWelcomeMessageForm = (basePath: string) => `${basePath}/${OnboardingStepKey.WelcomeScreen}`
export const urlWelcomeMessageForm = (basePath: string) => generatePath(pathWelcomeMessageForm(basePath))

export const pathPaymentMethodsStep = (basePath: string) => `${basePath}/${OnboardingStepKey.PaymentMethods}`
export const urlPaymentMethodsStep = (basePath: string) => generatePath(pathPaymentMethodsStep(basePath))

export const pathTyreChangeStep = (basePath: string) => `${basePath}/${OnboardingStepKey.TyreChange}`
export const urlTyreChangeStep = (basePath: string) => generatePath(pathTyreChangeStep(basePath))

export const pathInvoiceNumbersStep = (basePath: string) => `${basePath}/${OnboardingStepKey.InvoiceNumbers}`
export const urlInvoiceNumbersStep = (basePath: string) => generatePath(pathInvoiceNumbersStep(basePath))

export const pathInvoiceSequenceVehicleSales = (basePath: string) => `${basePath}/${OnboardingStepKey.InvoiceSequenceVehicleSales}`
export const urlInvoiceSequenceVehicleSales = (basePath: string) => generatePath(pathInvoiceSequenceVehicleSales(basePath))

export const pathInvoiceSequenceVehiclePurchase = (basePath: string) => `${basePath}/${OnboardingStepKey.InvoiceSequenceVehiclePurchase}`
export const urlInvoiceSequenceVehiclePurchase = (basePath: string) => generatePath(pathInvoiceSequenceVehiclePurchase(basePath))

export const pathRevenueGroupDefaults = (basePath: string) => `${basePath}/${OnboardingStepKey.RevenueGroupDefaults}`
export const urlRevenueGroupDefaults = (basePath: string) => generatePath(pathRevenueGroupDefaults(basePath))
