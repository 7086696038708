import { IFilter, IFilterValue } from '@cbgms/api'

export const APPLY_FILTER_SWITCH = 'APPLY_FILTER_SWITCH'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const SET_FILTER_BASELINE = 'SET_FILTER_BASELINE'

export type IFilterWithoutNestedValues = Omit<IFilter, 'Values'>
export interface IFilterDictionaryEntry {
  // The filter key
  filter: IFilterWithoutNestedValues
  values: IValueDictionary
}

export interface IFilterDictionary {
  [key: string]: IFilterDictionaryEntry | undefined
}

export interface IValueDictionaryEntry {
  // the filtervalue key
  filterKey: string
  filterValueKey: string
  filterValue: IFilterValue
}
export interface IValueDictionary {
  [key: string]: IValueDictionaryEntry | undefined
}

export interface IActiveFilterDictionaryEntry {
  // The filtervalue key
  [key: string]: IActiveValueDictionaryEntry | undefined
}

export interface IActiveValueDictionaryEntry {
  // the  key
  filterKey: string
  filterValueKey: string
  isOn: boolean
  uniqueIDS: string[]
}

export interface IActiveFilterDictionary {
  // The filter  key
  [key: string]: IActiveFilterDictionaryEntry | undefined
}

export interface IUniqueProductIDSDictionaryEntry {
  uniqueID: string
  isMatching: boolean
}

export interface IUniqueProductIDSDictionary {
  [key: string]: IUniqueProductIDSDictionaryEntry | undefined
}

export interface ICbgmsComponentsProductFiltersRoot {
  cbgmsComponentsProductFilters: IProductFiltersState
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
export type IProductFiltersState = {
  // each filter has a named context.
  [key: string]: INamedProductFilterState | undefined
} & {
  // safegaurd against incorrect usage,
  // prevent usages like `state.ununiqueProductIDS`, forces state[productListName].uniqueProductIDS
  hadStock?: never
  filterBaseLine?: never
  activeFilters?: never
  uniqueProductIDS?: never
}

export interface INamedProductFilterState {
  hadStock: boolean /* was this filter build with availability present */
  filterBaseLine: IFilterDictionary
  activeFilters: IActiveFilterDictionary
  // assetsRegions: string
  uniqueProductIDS: IUniqueProductIDSDictionary
}

export interface IBaselineOptions {
  includeAvailabilityFilter: boolean
  translations: IProductFilterTranslations
}

export interface IProductFilterTranslations {
  globalYes: string
  productFilterAvailable: string
}
