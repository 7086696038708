import React from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Button, Toolbar, Typography } from '@mui/material'
import { DefaultDialogProps, Dialog } from './Dialog'

export interface QuickViewDialogProps extends Omit<DefaultDialogProps, 'title'> {
  title: JSX.Element | string
  onNavigateBack?: () => void
}

export const QuickViewDialog: React.FC<QuickViewDialogProps> = ({
  title,
  open,
  onClose,
  children,
  onNavigateBack
}: QuickViewDialogProps) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <Toolbar>
        {!!onNavigateBack && (
          <Button startIcon={<ArrowBack />} onClick={onNavigateBack} sx={{ ml: -1, mr: 1 }}>
            {t('global.back')}
          </Button>
        )}
        <Typography flexGrow={1}>{title}</Typography>
        {onClose ? (
          <IconButton edge='end' aria-label='close' onClick={onClose} size='large'>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Toolbar>
      {children}
    </Dialog>
  )
}
