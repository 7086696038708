import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker'
import DateType from './date-helpers/date-type'
import useOpenOnFocus, { useOpenOnFocusInputProps } from './date-helpers/use-open-on-focus'
import { TextField } from './TextField'

export interface DatePickerProps extends DesktopDatePickerProps<DateType>, useOpenOnFocusInputProps {}

export function DatePicker(props: DatePickerProps) {
  const useOpenOnFocusProps = useOpenOnFocus(props.openOnFocus || false, props)

  return (
    <DesktopDatePicker
      {...props}
      slots={{
        textField: TextField,
        ...props.slots
      }}
      slotProps={{
        ...props.slotProps,
        textField: { ...props.slotProps?.textField, ...useOpenOnFocusProps.InputProps },
        openPickerButton: { ...props.slotProps?.openPickerButton, ...useOpenOnFocusProps.OpenPickerButtonProps }
      }}
      onClose={useOpenOnFocusProps.onClose}
      open={useOpenOnFocusProps.open}
    />
  )
}
