import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { IVatType } from '@cbgms/api'

interface IDefaultVATTypesState {
  VatTypes: IVatType[] // is not the correct interface
}

const defaultState: IDefaultVATTypesState = { VatTypes: [] }

const vat = createReducer(defaultState, {
  [types.GET_VAT_TYPES_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export default vat
