import React from 'react'

interface AccordionState {
  expanded: string[]
  registered: string[]
}

type AccordionActions =
  | { type: 'expand'; payload: string }
  | { type: 'collapse'; payload: string }
  | { type: 'register'; payload: string }
  | { type: 'unregister'; payload: string }
  | { type: 'expandAll' }
  | { type: 'collapseAll' }

function accordionReducer(state: AccordionState, action: AccordionActions): AccordionState {
  switch (action.type) {
    case 'expand': {
      return {
        ...state,
        expanded: [...state.expanded, action.payload]
      }
    }
    case 'collapse': {
      return {
        ...state,
        expanded: state.expanded.filter(accordion => accordion !== action.payload)
      }
    }
    case 'expandAll': {
      return {
        ...state,
        expanded: state.registered
      }
    }
    case 'collapseAll': {
      return {
        ...state,
        expanded: []
      }
    }
    case 'register': {
      return {
        ...state,
        expanded: [...state.expanded, action.payload],
        registered: [...state.registered, action.payload]
      }
    }
    case 'unregister': {
      return {
        ...state,
        expanded: state.expanded.filter(accordion => accordion !== action.payload),
        registered: state.registered.filter(accordion => accordion !== action.payload)
      }
    }
  }
}

const AccordionStateContext = React.createContext<AccordionState | null>(null)
const AccordionDispatchContext = React.createContext<React.Dispatch<AccordionActions> | null>(null)

export function AccordionContext({
  children,
  reducer = accordionReducer
}: {
  children: React.ReactNode
  reducer?: typeof accordionReducer
}) {
  const [state, dispatch] = React.useReducer(reducer, {
    expanded: [],
    registered: []
  })

  return (
    <AccordionStateContext.Provider value={state}>
      <AccordionDispatchContext.Provider value={dispatch}>{children}</AccordionDispatchContext.Provider>
    </AccordionStateContext.Provider>
  )
}

export function useAccordionState() {
  const state = React.useContext(AccordionStateContext)

  if (!state) throw new Error('useAccordionState must be used within an AccordionContext')

  return state
}

export function useAccordionDispatch() {
  const dispatch = React.useContext(AccordionDispatchContext)

  if (!dispatch) throw new Error('useAccordionDispatch must be used within an AccordionContext')

  return dispatch
}
