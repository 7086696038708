import React from 'react'
import once from 'lodash/once'

export type LogClickEvent<T> = (event: React.MouseEvent<HTMLElement>, data: T) => void
export type PurchaseEvent<T> = (data: T) => void
// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
export type NavigationEventParams<T> = T & {
  pathname: string
  search: string
  hash: string
}
export type NavigationEvent<T> = (data: NavigationEventParams<T>) => void
export type ViewCartEvent<T> = (data: T) => void
export type AddToCartEvent<T> = (data: T) => void
export type RemoveFromCartEvent<T> = (data: T) => void

export interface LogContextValue<C, P, N, VC, AC, RC> {
  clickEvent: LogClickEvent<C>
  purchaseEvent: PurchaseEvent<P>
  navigationEvent: NavigationEvent<N>
  viewCartEvent: ViewCartEvent<VC>
  addToCartEvent: AddToCartEvent<AC>
  removeFromCartEvent: RemoveFromCartEvent<RC>
}

const emptyLogContext = {
  clickEvent: () => {},
  purchaseEvent: () => {},
  navigationEvent: () => {},
  viewCartEvent: () => {},
  addToCartEvent: () => {},
  removeFromCartEvent: () => {}
}

/**
 * createEventLoggerContext will create a logging context
 * It takes types for each log event functions. it's intended to be called inside the application
 * It's ensured to be singleton,
 *
 * LogContext needs application specific providers.
 * Each application has their own provider wrapper,
 * the wrapper will create the actual logging functions
 * Wrappers can call createEventLoggerContext inside their render function and pass the expected types
 * IE const myProvider = ()=> { const CTX = createEventLoggerContext<T1,T2,T3>(); return <CTX.Provider />;}
 *
 * Shared packages like @carsys/ui can still access the context trigger log events
 * They might not know the exact types the logger function will receive, in that case unknowns are passed.
 */
export const createEventLoggerContext = once(<C, P, N, VC, AC, RC>() =>
  React.createContext<LogContextValue<C, P, N, VC, AC, RC> | undefined>(undefined)
)

/**
 * useAbstractEventLogger is a untyped event logger,
 * it will expose whatever the application has setup as logging functions
 * this function should be wrapped within the application to expose the correct types
 */
export function useAbstractEventLogger<C, P, N, VC, AC, RC>(): LogContextValue<C, P, N, VC, AC, RC> {
  const context = React.useContext(createEventLoggerContext<C, P, N, VC, AC, RC>())
  return context || emptyLogContext
}
