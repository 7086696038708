import Box, { BoxProps } from '@mui/material/Box'
import { getPanelId, getTabId, useTabContext } from '@mui/lab/TabContext'
import { ForwardedRef, forwardRef } from 'react'
interface TabPanelProps extends Omit<BoxProps, 'hidden'> {
  children: React.ReactNode
  value: string
}

export const TabPanel = forwardRef(({ children, value, ...props }: TabPanelProps, ref: ForwardedRef<HTMLDivElement>) => {
  const context = useTabContext()

  if (context === null) {
    throw new Error('TabPanel must be used within a TabContext')
  }

  const panelId = getPanelId(context, value)
  const tabId = getTabId(context, value)

  return (
    <Box aria-labelledby={tabId} id={panelId} hidden={context.value !== value} {...props} ref={ref} role='tabpanel'>
      {children}
    </Box>
  )
})
