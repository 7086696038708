import { Workstation } from './workstation'
import { IPaginatedRequest } from '../../request'
import { PaginatedResponse } from '../../helpers'

export interface WorkstationsRow {
  Workstation: Workstation
}

export type GETWorkstationsResponse = PaginatedResponse<WorkstationsRow>

export const getWorkstationsRequest = (params: IPaginatedRequest) => ({
  method: 'GET',
  params,
  url: 'modules/workstations'
})
