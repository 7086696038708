export enum InteriorColourType {
  Anthracite = 'anthracite',
  Beige = 'beige',
  DarkBlue = 'dark_blue',
  LightBlue = 'light_blue',
  Brown = 'brown',
  Yellow = 'yellow',
  DarkGray = 'dark_gray',
  LightGray = 'light_gray',
  Green = 'green',
  Ivory = 'ivory',
  Lilac = 'lilac',
  Orange = 'orange',
  Purple = 'purple',
  Red = 'red',
  Black = 'black',
  Unknown = 'unknown'
}
