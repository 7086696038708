import { Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import Delete from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'

import { useHumanReadableFilesize } from './use-human-readable-filesize'
import { bytesToMegabytes } from './utils'

export function FilePreview({
  file,
  onRemove
}: {
  /** The file to preview. */
  file: File
  /** A callback triggered when the delete button is clicked. */
  onRemove: () => void
}) {
  const { t } = useTranslation()

  return (
    <Paper variant='outlined'>
      <Grid container spacing={2} p={2} direction='row' alignItems='center'>
        <Grid item xs={8} md={10}>
          <Filename filename={file.name} />
          <Filesize filesize={file.size} />
        </Grid>
        <Grid item xs={4} md={2} sx={{ display: 'flex', justifyContent: 'right' }}>
          <IconButton onClick={onRemove} title={t('FileUploader.Buttons.Delete')} size='large'>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

function Filesize({ filesize }: { filesize: number }) {
  const formatter = useHumanReadableFilesize()
  const humanReadableFilesize = formatter(bytesToMegabytes(filesize))

  return <Typography variant='subtitle2'>{humanReadableFilesize}</Typography>
}

function Filename({ filename }: { filename: string }) {
  const fileExtensionSeparatorIndex = filename.lastIndexOf('.')
  const filenameWithoutExtension = filename.slice(0, fileExtensionSeparatorIndex)
  const fileExtension = filename.slice(fileExtensionSeparatorIndex)

  return (
    <Stack direction='row'>
      <Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' fontWeight='700'>
        {filenameWithoutExtension}
      </Typography>
      <Typography fontWeight='700'>{fileExtension}</Typography>
    </Stack>
  )
}
