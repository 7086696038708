import { createContext, useCallback, useRef, useState } from 'react'
import { Location } from 'history'
import NavigationPrompt, { ChildData } from 'react-router-navigation-prompt'

interface Subscriber {
  component: React.ComponentType<ChildData>
  when: (currentLocation: Location, nextLocation?: Location) => boolean
}

export const registerNavigationPromptContext = createContext<(key: string, subscriber: Subscriber) => void>(() => {})
export const unregisterNavigationPromptContext = createContext<(key: string) => void>(() => {})

export function NavigationPromptProvider({ children }: React.PropsWithChildren<{}>) {
  const subscribers = useRef<Map<string, Subscriber>>(new Map())

  const registerSubscriber = useCallback((key: string, subscriber: Subscriber) => {
    subscribers.current.set(key, subscriber)
  }, [])

  const unregisterSubscriber = useCallback((key: string) => {
    subscribers.current.delete(key)
  }, [])

  const [promptQueue, setPromptQueue] = useState<React.ComponentType<ChildData>[]>([])

  const whenQueue = useCallback((currentLocation: Location, nextLocation?: Location): boolean => {
    const matches = Array.from(subscribers.current.values())
      .filter(subscriber => {
        return subscriber.when(currentLocation, nextLocation)
      })
      .map(subscriber => subscriber.component)

    if (matches.length > 0) {
      setPromptQueue(matches)

      return true
    }

    return false
  }, [])

  return (
    <>
      <NavigationPrompt when={whenQueue}>
        {({ isActive, onCancel, onConfirm }) => {
          const [Component] = promptQueue
          return Component ? (
            <Component
              isActive={isActive}
              onCancel={() => {
                setPromptQueue(stack => {
                  const [, ...rest] = stack

                  if (!rest.length) {
                    onCancel()
                    return []
                  }

                  return rest
                })
              }}
              onConfirm={() => {
                setPromptQueue(stack => {
                  const [, ...rest] = stack

                  if (!rest.length) {
                    onConfirm()
                    return []
                  }

                  return rest
                })
              }}
            />
          ) : null
        }}
      </NavigationPrompt>
      <registerNavigationPromptContext.Provider value={registerSubscriber}>
        <unregisterNavigationPromptContext.Provider value={unregisterSubscriber}>{children}</unregisterNavigationPromptContext.Provider>
      </registerNavigationPromptContext.Provider>
    </>
  )
}
