import { duplicateVehicleModalQueryParam, duplicateVehicleUUIDQueryParam } from 'app/vehicles/components/DuplicateVehicleFound/const'
import navigation from 'utils/navigation'
import { IFailedRequest } from '../errorHandler'

const errVehicleRegistrationNotUniqueHandler = (failedRequest: IFailedRequest<{}>) => {
  const duplicateVehicleUUID = failedRequest.error.response.data.Data

  navigation.push(
    `${window.location.pathname}?${duplicateVehicleModalQueryParam}=1&${duplicateVehicleUUIDQueryParam}=${duplicateVehicleUUID}`
  )
}

export default errVehicleRegistrationNotUniqueHandler
