import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import 'assets/styles/main.less'
import 'assets/favicons'

import App from './app/Application'

if (__PRODUCTION__) {
  Sentry.init({
    dsn: 'https://7117eeb33f794603aad42d1d2448679d@sentry.io/1241459',
    environment: window.location.hostname,
    denyUrls: [
      /.*in.hotjar.com.*/, // ignore third party script
      /.*otBannerSdk.*/ // ignore third party script.
    ],
    ignoreErrors: [
      /bowser is not defined/, // ignore errors about the legacy browsers.
      /Loading.*chunk.*failed/, // network connection interrupts
      /.*NetworkError.*/, // network connection interrupts
      /.*Failed to fetch.*/, // network connection interrupts
      /.*\/modules\/auth\/refresh-token.*/, // refresh token expired.
      /SyntaxError: Identifier 'oneTrustId' has already been declared/, // oneTrust has some strange js
      /ResizeObserver loop/ // this can be caused by browser plugins and is generally harmless.
    ]
  })

  window['onunhandledrejection'] = (e: PromiseRejectionEvent) => {
    Sentry.captureException(e.reason)
  }
}

// Before React renders the application we need to include the MUI license.
// This removes the watermarks from the MUI Pro components and keeps
// us compliant with the license.
import './utils/mui-license'

// Quick hack to load the supported Moment languages in Vite.
// Necessary because The ES bundle of moment doesn't load every locale by default.
// This has no affects on the production bundle, and when using Webpack.
if (__VITE__) {
  // @ts-ignore
  import('moment/dist/locale/en-gb')
  // @ts-ignore
  import('moment/dist/locale/nl')
  // @ts-ignore
  import('moment/dist/locale/fr')
  // @ts-ignore
  import('moment/dist/locale/de')
}

// if the browser (the browser checker) could not be loaded. render the app anyway.
if (!bowser || bowser.isBowserSupportedByGMS()) {
  ReactDOM.render(<App />, document.getElementById('root'))
}
