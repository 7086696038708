import { Reducer } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import * as types from 'app/auth/state/types'
import { IEmployee } from '@cbgms/api/modules/employees'
import { emptyEmployee } from '@cbgms/api/modules/employees/mocks'

export type EmployeeReducerState = Readonly<IEmployee>

const defaultState: EmployeeReducerState = emptyEmployee()

type EmployeeReducerBehavior<S = EmployeeReducerState> = Record<typeof types.GET_USER_EMPLOYEE_SUCCESS, Reducer<S, any>>

const employee = createReducer<EmployeeReducerState, EmployeeReducerBehavior>(defaultState, {
  [types.GET_USER_EMPLOYEE_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export default employee
