import { selectorOrNestedKeyToKey } from './helpers'
import { NestedKeyOf, ResolveType, Selector, SelectorOrNestedKey } from './types'

enum ConjunctiveOperator {
  AND = ',',
  OR = ';'
}

enum GroupingOperator {
  OPEN = '(',
  CLOSE = ')'
}

export enum Operator {
  EQUAL = '=',
  NOT_EQUAL = '<>',

  IS_NULL = 'IS NULL',
  IS_NOT_NULL = 'IS NOT NULL',

  ILIKE = 'ILIKE',

  IN = 'IN',

  GREATER_THAN = '>',
  LESS_THAN = '<',
  GREATER_THAN_OR_EQUAL = '>=',
  LESS_THAN_OR_EQUAL = '<=',

  IS_ANY_OF = 'ANY'
}

type Primitive = number | string | boolean

type IsPrimitive<S extends SelectorOrNestedKey<any>, T extends object> = S extends Selector<any>
  ? ReturnType<S> extends Primitive
    ? ReturnType<S>
    : never
  : S extends NestedKeyOf<T>
  ? ResolveType<T, S, never>
  : never

type IsString<S extends SelectorOrNestedKey<any>, T extends object> = S extends Selector<any>
  ? ReturnType<S> extends string
    ? ReturnType<S>
    : never
  : S extends NestedKeyOf<T>
  ? ResolveType<T, S, never>
  : never

type IsEnumArray<S extends SelectorOrNestedKey<any>, T extends object> = S extends Selector<any>
  ? ReturnType<S> extends string[]
    ? ReturnType<S>[number][]
    : never
  : S extends NestedKeyOf<T>
  ? ResolveType<T, S, never> extends string[]
    ? string[]
    : number[]
  : never

type IsEnumOrEnumArray<S extends SelectorOrNestedKey<any>, T extends object> = S extends Selector<any>
  ? ReturnType<S> extends string[]
    ? ReturnType<S>[number][]
    : ReturnType<S> extends string
    ? ReturnType<S>[]
    : never
  : S extends NestedKeyOf<T>
  ? ResolveType<T, S, never> extends string[]
    ? string[]
    : number[]
  : never

export interface IQuery<T extends object> {
  and(): this
  or(): this
  groupStart(): this
  groupEnd(): this
  equals<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this
  notEquals<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this
  isNull<S extends SelectorOrNestedKey<T>>(selector: S): this
  notIsNull<S extends SelectorOrNestedKey<T>>(selector: S): this
  startsWith<S extends SelectorOrNestedKey<T>>(selector: S, value: IsString<S, T>): this
  endsWith<S extends SelectorOrNestedKey<T>>(selector: S, value: IsString<S, T>): this
  contains<S extends SelectorOrNestedKey<T>>(selector: S, value: IsString<S, T>): this
  notContains<S extends SelectorOrNestedKey<T>>(selector: S, value: IsString<S, T>): this
  containsAll<S extends SelectorOrNestedKey<T>>(selector: S, value: IsEnumArray<S, T>): this
  containsAny<S extends SelectorOrNestedKey<T>>(selector: S, value: IsEnumOrEnumArray<S, T>): this
  greaterThan<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this
  lessThan<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this
  greaterThanOrEqual<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this
  lessThanOrEqual<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this
  withQuery(query?: IQuery<T>): this
  withQueryStringNoValidation(queryString?: string): this
  toString(): string
}

class Query<T extends object> implements IQuery<T> {
  private query: string[] = []

  /**
   * Joins two queries for a `X AND Y` query.
   */
  and(): this {
    if (this.canAddConjunctiveOperator) {
      this.query.push(ConjunctiveOperator.AND)
    }

    return this
  }

  /**
   * Joins two queries for a `X OR Y` query.
   */
  or(): this {
    if (this.canAddConjunctiveOperator) {
      this.query.push(ConjunctiveOperator.OR)
    }

    return this
  }

  private get canAddConjunctiveOperator() {
    return !this.isEmptyQuery && !this.isPreviousQueryCommandGroupStart
  }

  private get isPreviousQueryCommandGroupStart() {
    return this.query[this.query.length - 1] === GroupingOperator.OPEN
  }

  private get isEmptyQuery() {
    return this.query.length === 0
  }

  /**
   * Starts a group for grouping queries for when you want to do
   * `X AND (Y OR Z)`
   */
  groupStart(): this {
    this.query.push(GroupingOperator.OPEN)

    return this
  }

  /**
   * Ends a group for grouping queries for when you want to do
   * `X AND (Y OR Z)`
   */
  groupEnd(): this {
    this.query.push(GroupingOperator.CLOSE)

    return this
  }

  private addQuery(selector: SelectorOrNestedKey<T>, operator: Operator, value: Primitive = ''): void {
    // selector is either a string with a prop-key, or a lambda pointing to the prop
    const entityKey = selectorOrNestedKeyToKey(selector)
    const cleanValue = typeof value === 'string' ? sanitizeValue(value) : value
    this.query.push(`${entityKey}|${operator}|${cleanValue}`)
  }

  /**
   * Creates an exact equals query.
   */
  equals<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this {
    this.addQuery(selector, Operator.EQUAL, value)

    return this
  }

  /**
   * Creates an exact not equals query.
   */
  notEquals<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this {
    this.addQuery(selector, Operator.NOT_EQUAL, value)

    return this
  }

  /**
   * Creates a query that checks if the property is null.
   */
  isNull<S extends SelectorOrNestedKey<T>>(selector: S): this {
    this.addQuery(selector, Operator.IS_NULL)

    return this
  }

  /**
   * Creates a query that checks if the property is not null.
   */
  notIsNull<S extends SelectorOrNestedKey<T>>(selector: S): this {
    this.addQuery(selector, Operator.IS_NOT_NULL)

    return this
  }

  /**
   * Creates a _`case insensitive`_ query that checks if the property starts with the given string.
   */
  startsWith<S extends SelectorOrNestedKey<T>>(selector: S, value: IsString<S, T>): this {
    this.addQuery(selector, Operator.ILIKE, `${value}%`)

    return this
  }

  /**
   * Creates a _`case insensitive`_ query that checks if the property ends with the given string.
   */
  endsWith<S extends SelectorOrNestedKey<T>>(selector: S, value: IsString<S, T>): this {
    this.addQuery(selector, Operator.ILIKE, `%${value}`)

    return this
  }

  /**
   * Creates a _`case insensitive`_ query that checks if the property contains the given string.
   */
  contains<S extends SelectorOrNestedKey<T>>(selector: S, value: IsString<S, T>): this {
    this.addQuery(selector, Operator.ILIKE, `%${value}%`)

    return this
  }

  /**
   * Creates a _`case insensitive`_ query that checks if the property contains the given string.
   */
  notContains<S extends SelectorOrNestedKey<T>>(selector: S, value: IsString<S, T>): this {
    this.addQuery(selector, Operator.ILIKE, `%${value}%`)

    return this
  }

  /**
   * Creates a query that checks if an array of enum values property contains all of the given values.
   */
  containsAll<S extends SelectorOrNestedKey<T>>(selector: S, values: IsEnumArray<S, T>): this {
    this.groupStart()
    values.forEach((value, index) => {
      this.addQuery(selector, Operator.IN, value)

      if (index < values.length - 1) this.and()
    })
    this.groupEnd()

    return this
  }

  /**
   * Creates a query that checks if an array of enum values property or an enum property contains
   * any of the given values.
   */
  containsAny<S extends SelectorOrNestedKey<T>>(selector: S, values: IsEnumOrEnumArray<S, T>): this {
    this.groupStart()
    // This currently uses `IN` and `OR` so that it works for both
    // enums and arrays of enums. This might not have the same performance
    // as using `ANY` or `IN` with multiple values.
    values.forEach((value: string | number, index: number) => {
      this.addQuery(selector, Operator.IN, value)

      if (index < values.length - 1) this.or()
    })
    this.groupEnd()

    return this
  }

  /**
   * Creates a greater than query.
   */
  greaterThan<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this {
    this.addQuery(selector, Operator.GREATER_THAN, value)

    return this
  }

  /**
   * Creates a less than query.
   */
  lessThan<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this {
    this.addQuery(selector, Operator.LESS_THAN, value)

    return this
  }

  /**
   * Creates a greater than or equals query.
   */
  greaterThanOrEqual<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this {
    this.addQuery(selector, Operator.GREATER_THAN_OR_EQUAL, value)

    return this
  }

  /**
   * Creates a less than or equals query.
   */
  lessThanOrEqual<S extends SelectorOrNestedKey<T>>(selector: S, value: IsPrimitive<S, T>): this {
    this.addQuery(selector, Operator.LESS_THAN_OR_EQUAL, value)

    return this
  }

  /**
   * Appends the given query to the current query.
   */
  withQuery(query?: IQuery<T>): this {
    if (query) {
      this.query.push(query.toString())
    }

    return this
  }

  /**
   * Appends the given query string to the current query.
   * No validation is ran to check if the queryString contains a valid query.
   */
  withQueryStringNoValidation(queryString?: string): this {
    if (queryString) {
      this.query.push(queryString)
    }

    return this
  }

  /**
   * Returns the stringified version of the query ready to be used as a search parameter.
   */
  toString(): string {
    return this.query.join('')
  }
}

/**
 * Query builder helper for List
 *
 * @example
 * interface AccountList {
 *   Account: {
 *     Name: string
 *     FirstName: string
 *   }
 * }
 *
 * // The result is a query that would look for any
 * // account where the first name contains "J"
 * // and name is exactly "Doe".
 * const q = query<AccountList>()
 *   .contains(o => o.Account.FirstName, 'J')
 *   .and()
 *   .equals(o => o.Account.Name, 'Doe')
 *   .toString()
 */
export function query<T extends object>(): IQuery<T> {
  return new Query<T>()
}

/**
 * sanitizeRegistration: makes sure the registration can be used with list queries.
 * @param reg full registration including country code
 * @returns a registration fit for the query
 * @deprecated use query() instead. query will also use sanitizeRegistration
 */
export function sanitizeRegistration(reg: string) {
  return reg.replace('|', '\\|')
}

// ReservedListChars contains all the characters that have to be escaped when used as value
interface charReplacer {
  replacer: RegExp
  replacee: string
}
const reservedListChars: charReplacer[] = [
  { replacer: /,/g, replacee: '\\,' },
  { replacer: /;/g, replacee: '\\;' },
  { replacer: /\|/g, replacee: '\\|' }
]
// sanitizeFilterValue will replace commas and semicolons, these are reserved characters in list
export const sanitizeValue = (input: string) => reservedListChars.reduce((val, a) => val.replace(a.replacer, a.replacee), input)
