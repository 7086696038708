import { Reducer } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import { PartGroup } from '@cbgms/api/modules/partgroups'

import { LOAD_PART_GROUP_SUCCESS } from 'app/settings/state/PartGroups/types'

export type PartGroupState = Readonly<Partial<PartGroup>>

const defaultState: PartGroupState = {}

type PartGroupBehavior<S = PartGroupState> = Record<typeof LOAD_PART_GROUP_SUCCESS, Reducer<S, any>>

const partGroup = createReducer<PartGroupState, PartGroupBehavior>(defaultState, {
  [LOAD_PART_GROUP_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export default partGroup
