import React, { useCallback, useMemo, useRef } from 'react'
import { SelectVehicleFunc } from './vehicles/components/SelectVehicleType/types.d'

interface IContextValue {
  setShowVehicleSelectionFunc: (f: SelectVehicleFunc) => void
  getShowVehicleSelectionFunc: () => SelectVehicleFunc
  hasBeenProvided: boolean
}

const defaultReduxRefsCtx: IContextValue = {
  setShowVehicleSelectionFunc: () => {},
  getShowVehicleSelectionFunc: (() => () => {}) as any,
  hasBeenProvided: false
}

const ReduxRefsCtx = React.createContext<IContextValue>(defaultReduxRefsCtx)

// ReduxRefsContext will expose functions that we can inject into the store / handlers
// the refs are set by child components
const ReduxRefsContext: React.FC<{}> = ({ children }) => {
  const GetShowVehicleSelectionRef = useRef<SelectVehicleFunc>(defaultReduxRefsCtx.getShowVehicleSelectionFunc())
  const setShowVehicleSelectionFunc = useCallback((f: SelectVehicleFunc) => {
    GetShowVehicleSelectionRef.current = f
  }, [])
  const getShowVehicleSelectionFunc = useCallback(() => {
    return GetShowVehicleSelectionRef.current
  }, [])

  const ctxValue = useMemo(
    () => ({
      setShowVehicleSelectionFunc,
      getShowVehicleSelectionFunc,
      hasBeenProvided: true
    }),
    [setShowVehicleSelectionFunc, getShowVehicleSelectionFunc]
  )

  return <ReduxRefsCtx.Provider value={ctxValue}>{children}</ReduxRefsCtx.Provider>
}

export const useReduxRefsContext = () => {
  const context = React.useContext(ReduxRefsCtx)
  if (context === undefined || !context.hasBeenProvided) {
    console.error('useReduxRefsContext must be used within an ReduxRefsContext')
  }
  return context
}

export default ReduxRefsContext
