export const LOAD_ADMINISTRATIONS = 'LOAD_ADMINISTRATIONS'
export const LOAD_ADMINISTRATIONS_SUCCESS = 'LOAD_ADMINISTRATIONS_SUCCESS'
export const LOAD_ADMINISTRATIONS_FAILED = 'LOAD_ADMINISTRATIONS_FAILED'

export const LOAD_ADMINISTRATION = 'LOAD_ADMINISTRATION'
export const LOAD_ADMINISTRATION_SUCCESS = 'LOAD_ADMINISTRATION_SUCCESS'
export const LOAD_ADMINISTRATION_FAILED = 'LOAD_ADMINISTRATION_FAILED'

export const LOAD_ADMINISTRATION_EXTERNAL = 'LOAD_ADMINISTRATION_EXTERNAL'
export const LOAD_ADMINISTRATION_EXTERNAL_SUCCESS = 'LOAD_ADMINISTRATION_EXTERNAL_SUCCESS'
export const LOAD_ADMINISTRATION_EXTERNAL_FAILED = 'LOAD_ADMINISTRATION_EXTERNAL_FAILED'

export const CREATE_ADMINISTRATION = 'CREATE_ADMINISTRATION'
export const CREATE_ADMINISTRATION_SUCCESS = 'CREATE_ADMINISTRATION_SUCCESS'
export const CREATE_ADMINISTRATION_FAILED = 'CREATE_ADMINISTRATION_FAILED'

export const UPDATE_ADMINISTRATION = 'UPDATE_ADMINISTRATION'
export const UPDATE_ADMINISTRATION_SUCCESS = 'UPDATE_ADMINISTRATION_SUCCESS'
export const UPDATE_ADMINISTRATION_FAILED = 'UPDATE_ADMINISTRATION_FAILED'

export const DELETE_ADMINISTRATION = 'DELETE_ADMINISTRATION'
export const DELETE_ADMINISTRATION_SUCCESS = 'DELETE_ADMINISTRATION_SUCCESS'
export const DELETE_ADMINISTRATION_FAILED = 'DELETE_ADMINISTRATION_FAILED'

export const LOAD_ADMINISTRATION_CUSTOM_SETTINGS = 'LOAD_ADMINISTRATION_CUSTOM_SETTINGS'
export const LOAD_ADMINISTRATION_CUSTOM_SETTINGS_SUCCESS = 'LOAD_ADMINISTRATION_CUSTOM_SETTINGS_SUCCESS'
export const LOAD_ADMINISTRATION_CUSTOM_SETTINGS_FAILED = 'LOAD_ADMINISTRATION_CUSTOM_SETTINGS_FAILED'

export const UPDATE_ADMINISTRATION_CUSTOM_SETTINGS = 'UPDATE_ADMINISTRATION_CUSTOM_SETTINGS'
export const UPDATE_ADMINISTRATION_CUSTOM_SETTINGS_SUCCESS = 'UPDATE_ADMINISTRATION_CUSTOM_SETTINGS_SUCCESS'
export const UPDATE_ADMINISTRATION_CUSTOM_SETTINGS_FAILED = 'UPDATE_ADMINISTRATION_CUSTOM_SETTINGS_FAILED'

export const LOAD_ADMINISTRATION_ADMINISTRATIONS = 'LOAD_ADMINISTRATION_ADMINISTRATIONS'
export const LOAD_ADMINISTRATION_ADMINISTRATIONS_SUCCESS = 'LOAD_ADMINISTRATION_ADMINISTRATIONS_SUCCESS'
export const LOAD_ADMINISTRATION_ADMINISTRATIONS_FAILED = 'LOAD_ADMINISTRATION_ADMINISTRATIONS_FAILED'

export const LOAD_ADMINISTRATION_VATCODES = 'LOAD_ADMINISTRATION_VATCODES'
export const LOAD_ADMINISTRATION_VATCODES_SUCCESS = 'LOAD_ADMINISTRATION_VATCODES_SUCCESS'
export const LOAD_ADMINISTRATION_VATCODES_FAILED = 'LOAD_ADMINISTRATION_VATCODES_FAILED'

export const LOAD_ADMINISTRATION_JOURNALS = 'LOAD_ADMINISTRATION_JOURNALS'
export const LOAD_ADMINISTRATION_JOURNALS_SUCCESS = 'LOAD_ADMINISTRATION_JOURNALS_SUCCESS'
export const LOAD_ADMINISTRATION_JOURNALS_FAILED = 'LOAD_ADMINISTRATION_JOURNALS_FAILED'

export const LOAD_ADMINISTRATION_LEDGERS = 'LOAD_ADMINISTRATION_LEDGERS'
export const LOAD_ADMINISTRATION_LEDGERS_SUCCESS = 'LOAD_ADMINISTRATION_LEDGERS_SUCCESS'
export const LOAD_ADMINISTRATION_LEDGERS_FAILED = 'LOAD_ADMINISTRATION_LEDGERS_FAILED'

export const RESET_ADMINISTRATION_OPTIONS = 'RESET_ADMINISTRATION_OPTIONS'

export const LOAD_CSV_EXPORTS = 'LOAD_CSV_EXPORTS'
export const LOAD_CSV_EXPORTS_SUCCESS = 'LOAD_CSV_EXPORTS_SUCCESS'
export const LOAD_CSV_EXPORTS_FAILED = 'LOAD_CSV_EXPORTS_FAILED'

export const LOAD_CSV_EXPORT = 'LOAD_CSV_EXPORT'
export const LOAD_CSV_EXPORT_SUCCESS = 'LOAD_CSV_EXPORT_SUCCESS'
export const LOAD_CSV_EXPORT_FAILED = 'LOAD_CSV_EXPORT_FAILED'

export const RESET_CSV_EXPORT_OPTIONS = 'RESET_CSV_EXPORT_OPTIONS'

export const LOAD_ENABLED_SOFTWARE = 'LOAD_ENABLED_SOFTWARE'
export const LOAD_ENABLED_SOFTWARE_SUCCESS = 'LOAD_ENABLED_SOFTWARE_SUCCESS'
export const LOAD_ENABLED_SOFTWARE_FAILED = 'LOAD_ENABLED_SOFTWARE_FAILED'
