import { IPreflight } from '@cbgms/api/modules/root'
import setupHotjar from './hotjar'
import setupAnalytics from './analytics'
import setupTagManager from './TagManager/tag-manager'
import parseInt from 'lodash/fp/parseInt'
import { ICookieConsent } from './use-cookie-consent-model'

export const setupThirdPartyScripts = (preflight: IPreflight, cookieConcent: ICookieConsent) => {
  // activate the performance cookies,
  // We can only start them when required, teardown is not possible,
  // we expect a page reload to be triggered when consent is revoked
  if (cookieConcent.Performance) {
    try {
      if (preflight.Analytics && preflight.Analytics.startsWith('UA')) {
        setupAnalytics(preflight.Analytics, window)
      }
    } catch (e) {
      console.warn(e)
    }

    try {
      if (preflight.TagManager && preflight.TagManager.startsWith('GTM')) {
        setupTagManager(preflight.TagManager)
      }
    } catch (e) {
      console.warn(e)
    }

    try {
      if (preflight.HotJar && parseInt(0, preflight.HotJar)) {
        setupHotjar(parseInt(0, preflight.HotJar), 6)
      }
    } catch (e) {
      console.warn(e)
    }
  }
}
