import { AccordionDetailsProps, default as MuiAccordionDetails } from '@mui/material/AccordionDetails'

interface Props extends AccordionDetailsProps {}

export function AccordionDetails({ children, ...props }: Props) {
  return (
    <MuiAccordionDetails sx={{ p: 2 }} {...props}>
      {children}
    </MuiAccordionDetails>
  )
}
