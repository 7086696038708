import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import MUILink, { LinkProps } from '@mui/material/Link'

export const Link = (p: RouterLinkProps & LinkProps) => {
  return (
    // pass react-router-dom link down to MUI
    <MUILink component={RouterLink} {...p}>
      {p.children}
    </MUILink>
  )
}

export default Link
