export enum Timing {
  fastKeyboardInput = 300,
  keyboardInput = 500,
  mouseOver = 200,
  longWait = 2000,
  ExtralongWait = 5000,
  fastAnimation = 200,
  mediumAnimation = 400,
  slowAnimation = 800,
  spinnerDelay = 500,
  asyncOperationWait = 5000
}
