export const SET_API_URLS = 'SET_API_URLS'
export const SET_REQUEST_HEADERS = 'SET_REQUEST_HEADERS'

export const LOGIN = 'USER_LOGIN'
export const LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const LOGIN_FAILED = 'USER_LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'

export const CREATE_ACCESS_TOKEN = 'CREATE_ACCESS_TOKEN'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_ONBOARDING_TOKEN = 'SET_ONBOARDING_TOKEN'

export const PASSWORDFORGOT = 'USER_PASSWORDFORGOT'
export const PASSWORDFORGOT_SUCCESS = 'USER_PASSWORDFORGOT_SUCCESS'
export const PASSWORDFORGOT_FAILED = 'USER_PASSWORDFORGOT_FAILED'

export const PASSWORDCHANGE = 'USER_PASSWORDCHANGE'
export const PASSWORDCHANGE_SUCCESS = 'USER_PASSWORDCHANGE_SUCCESS'
export const PASSWORDCHANGE_FAILED = 'USER_PASSWORDCHANGE_FAILED'

export const INVITEE_LOGIN = 'INVITEE_LOGIN'
export const INVITEE_LOGIN_SUCCESS = 'INVITEE_LOGIN_SUCCESS'
export const INVITEE_LOGIN_FAILED = 'INVITEE_LOGIN_FAILED'

export const INVITEE_CHANGE_PASSWORD = 'INVITEE_CHANGE_PASSWORD'
export const INVITEE_CHANGE_PASSWORD_SUCCESS = 'INVITEE_CHANGE_PASSWORD_SUCCESS'
export const INVITEE_CHANGE_PASSWORD_FAILED = 'INVITEE_CHANGE_PASSWORD_FAILED'

export const GET_USER_EMPLOYEE = 'GET_USER_EMPLOYEE'
export const GET_USER_EMPLOYEE_SUCCESS = 'GET_USER_EMPLOYEE_SUCCESS'
export const GET_USER_EMPLOYEE_FAILED = 'GET_USER_EMPLOYEE_FAILED'

export const GET_SHOP_THEME = 'GET_SHOP_THEME'
export const GET_SHOP_THEME_SUCCESS = 'GET_SHOP_THEME_SUCCESS'
export const GET_SHOP_THEME_FAILED = 'GET_SHOP_THEME_FAILED'

export const GET_LOCALISATION_SETTINGS = 'GET_LOCALISATION_SETTINGS'
export const GET_LOCALISATION_SETTINGS_SUCCESS = 'GET_LOCALISATION_SETTINGS_SUCCESS'
export const GET_LOCALISATION_SETTINGS_FAILED = 'GET_LOCALISATION_SETTINGS_FAILED'

export const GET_LOCALISATION_SETTINGS_GLOBAL = 'GET_LOCALISATION_SETTINGS_GLOBAL'
export const GET_LOCALISATION_SETTINGS_GLOBAL_SUCCESS = 'GET_LOCALISATION_SETTINGS_GLOBAL_SUCCESS'
export const GET_LOCALISATION_SETTINGS_GLOBAL_FAILED = 'GET_LOCALISATION_SETTINGS_GLOBAL_FAILED'

export const UPDATE_LOCALISATION_SETTINGS = 'UPDATE_LOCALISATION_SETTINGS'
export const UPDATE_LOCALISATION_SETTINGS_SUCCESS = 'UPDATE_LOCALISATION_SETTINGS_SUCCESS'
export const UPDATE_LOCALISATION_SETTINGS_FAILED = 'UPDATE_LOCALISATION_SETTINGS_FAILED'

export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'

export const SET_TEMP_LOCALE = 'SET_TEMP_LOCALE'

export const SET_CURRENT_SHOP = 'SET_CURRENT_SHOP'

export const CLEAR_CHALLENGES = 'CLEAR_CHALLENGES'

export const VALIDATE_WHOLESALER = 'VALIDATE_WHOLESALER'
export const VALIDATE_WHOLESALER_SUCCESS = 'VALIDATE_WHOLESALER_SUCCESS'
export const VALIDATE_WHOLESALER_FAILED = 'VALIDATE_WHOLESALER_FAILED'

export const ONBOARD_GET_WHOLESALER = 'ONBOARD_GET_WHOLESALER'
export const ONBOARD_GET_WHOLESALER_SUCCESS = 'ONBOARD_GET_WHOLESALER_SUCCESS'
export const ONBOARD_GET_WHOLESALER_FAILED = 'ONBOARD_GET_WHOLESALER_FAILED'

export const ONBOARD_GET_VAT_NUMBER_CHECK = 'ONBOARD_GET__VAT_NUMBER_CHECK'
export const ONBOARD_GET_VAT_NUMBER_CHECK_SUCCESS = 'ONBOARD_GET__VAT_NUMBER_CHECK_SUCCESS'
export const ONBOARD_GET_VAT_NUMBER_CHECK_FAILED = 'ONBOARD_GET__VAT_NUMBER_CHECK_FAILED'

export const PERMISSION_EXTENSION_ADD_DEACTIVATION = 'PERMISSION_EXTENSION_ADD_DEACTIVATION'
export const PERMISSION_EXTENSION_REMOVE_DEACTIVATION = 'PERMISSION_EXTENSION_REMOVE_DEACTIVATION'

export const ONBOARD_GET_THEME = 'ONBOARD_GET_THEME'
export const ONBOARD_GET_THEME_SUCCESS = 'ONBOARD_GET_THEME_SUCCESS'
export const ONBOARD_GET_THEME_FAILED = 'ONBOARD_GET_THEME_FAILED'
