import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import ReduxRefsContext from './ReduxRefsContext'

import useReduxStore from './useReduxStore'

const StoreWrapper: React.FC<{}> = ({ children }) => {
  const { store, persistor } = useReduxStore()

  useEffect(() => {
    // When using multiple shops in multiple tabs, we'd like to store the shop of last used/active tab in the local storage
    // This makes sure we don't end up in an unexpected shop when we refresh the page
    const onFocus = () => {
      persistor.persist()
    }
    window.addEventListener('focus', onFocus)
    return () => window.removeEventListener('focus', onFocus)
  }, [persistor])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  )
}

const ReduxWrapper: React.FC<{}> = ({ children }) => {
  return (
    <ReduxRefsContext>
      <StoreWrapper>{children}</StoreWrapper>
    </ReduxRefsContext>
  )
}

export default ReduxWrapper
