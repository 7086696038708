import { Reducer } from 'redux'
import * as types from 'app/workorder/state/workorder/types'
import { createReducer } from '@cbgms/base/utils/redux'
import { GenericArticle } from './nodes'
import { IMaintenanceSystem } from '@cbgms/api/modules/vehicles/maintenance'

export interface RepairTime {
  ID: string
  Description: string
  DurationInHours: number
  SubtaskKind: string
  GenericArticles: GenericArticle[]
  HasInfoGroups: boolean
  JobType: string
}

interface RepairJob {
  ID: string
  CalculatedDurationInHours: number
  Description: string
  GenericArticles: GenericArticle[]
  JobType: string
  RepairtimesInfo: {
    NodeID: string
    IncludedList: RepairTime[]
    FollowUpWorkList: RepairTime[]
    PreliminaryList: RepairTime[]
  }
}

export interface HaynesProMaintenanceAdditionalWork {
  Description: string
  Remark: string
  OECode: string
  DurationInHours: number
  SortOrder: number
  ID: string
}

export interface RepairTimesResponse {
  HaynesProRepairTasks: {
    Jobs: RepairJob[]
    TotalDurationInHours: number
  }
  UpdateHaynesproRepairtimesInput: UpdateHaynesproRepairTimesInput
  HaynesProMaintenanceSystems?: IMaintenanceSystem
  HaynesProMaintenanceAdditionalWork?: HaynesProMaintenanceAdditionalWork[]
}

export interface UpdateHaynesproRepairTimesInput {
  MaintenancePeriodIDs: string[]
  MaintenanceSystemID: string
  MaintenanceTasksIDs: string[]
  RepairTaskIDs: string[]
}

export type DetailsReducerState = Readonly<RepairTimesResponse>

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type DetailsReducerBehavior<S> = Record<typeof types.GET_REPAIR_TIMES_SUCCESS, Reducer<S, any>> &
  Record<typeof types.PUT_REPAIR_TIMES_SUCCESS, Reducer<S, any>> &
  Record<typeof types.RESET_HAYNESPRO_REPAIR_TIMES, Reducer<S, any>>

const defaultState: DetailsReducerState = {
  HaynesProRepairTasks: {
    Jobs: [],
    TotalDurationInHours: 0
  },
  UpdateHaynesproRepairtimesInput: {
    MaintenancePeriodIDs: [],
    MaintenanceSystemID: '',
    MaintenanceTasksIDs: [],
    RepairTaskIDs: []
  }
}

const details = createReducer<DetailsReducerState, DetailsReducerBehavior<DetailsReducerState>>(defaultState, {
  [types.GET_REPAIR_TIMES_SUCCESS]: (
    _,
    {
      payload: {
        data: { Data }
      }
    }
  ) => {
    return Data
  },
  [types.PUT_REPAIR_TIMES_SUCCESS]: (
    _,
    {
      payload: {
        data: { Data }
      }
    }
  ) => {
    return Data
  },
  [types.RESET_HAYNESPRO_REPAIR_TIMES]: () => {
    return defaultState
  }
})

export default details
