import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const MAX_HISTORY = 100

interface ILocalHistoryContext {
  history: Location[]
  goBackUntil: (filter: (location: Location) => boolean, fallback: string) => void
  hasBeenProvided: boolean
}

export const LocalHistoryContext = React.createContext<ILocalHistoryContext>({
  history: [],
  goBackUntil: () => false,
  hasBeenProvided: false
})

export const LocalHistoryContextProvider: React.FC = ({ children }) => {
  const { push } = useHistory()
  const [stack, setStack] = useState<Location[]>([])
  const location = useLocation()
  useEffect(() => {
    setStack(stack => [location, ...stack].slice(0, MAX_HISTORY) as Location[])
  }, [location])

  const goBackUntil = useCallback(
    (filter: (location: Location) => boolean, fallback: string) => {
      const match = stack.find(filter)
      push(match ?? fallback)
    },
    [stack]
  )

  const value = useMemo(() => {
    return { history: stack, goBackUntil, hasBeenProvided: true }
  }, [stack, goBackUntil])

  return <LocalHistoryContext.Provider value={value}>{children}</LocalHistoryContext.Provider>
}

export function useLocalHistory() {
  const context = React.useContext(LocalHistoryContext)
  if (context === undefined || !context.hasBeenProvided) {
    console.error('useLocalHistory must be used within a LocalHistoryContext')
  }

  return context as ILocalHistoryContext
}
