import { AnyAction } from 'redux'

import { getRepairTimes } from 'app/workorder/state/workorder/selectors'
import * as types from 'app/workorder/state/workorder/types'

export interface IChapter {
  ID: string
  Description: string
}

export type ChapterReducerState = ReadonlyArray<IChapter>

const initialState: ChapterReducerState = []

function chaptersReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case types.GET_REPAIR_TIMES_CHAPTERS:
      return state
    case types.GET_REPAIR_TIMES_CHAPTERS_SUCCESS:
      return getRepairTimes(action.payload)
    case types.GET_REPAIR_TIMES_CHAPTERS_FAILED:
      return initialState
    case types.RESET_REPAIR_TIMES:
      return initialState
    default:
      return state
  }
}

export default chaptersReducer
