import { useCallback } from 'react'

import { useAccordionDispatch } from './AccordionContext'

export function useExpandAll() {
  const dispatch = useAccordionDispatch()

  return useCallback(() => {
    dispatch({ type: 'expandAll' })
  }, [])
}
