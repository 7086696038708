export const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES'
export const LOAD_EMPLOYEES_SUCCESS = 'LOAD_EMPLOYEES_SUCCESS'
export const LOAD_EMPLOYEES_FAILED = 'LOAD_EMPLOYEES_FAILED'

export const LOAD_EMPLOYEE = 'LOAD_EMPLOYEE'
export const LOAD_EMPLOYEE_SUCCESS = 'LOAD_EMPLOYEE_SUCCESS'
export const LOAD_EMPLOYEE_FAILED = 'LOAD_EMPLOYEE_FAILED'

export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS'
export const CREATE_EMPLOYEE_FAILED = 'CREATE_EMPLOYEE_FAILED'

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS'
export const UPDATE_EMPLOYEE_FAILED = 'UPDATE_EMPLOYEE_FAILED'

export const INVITE_EMPLOYEE = 'INVITE_EMPLOYEE'
export const INVITE_EMPLOYEE_SUCCESS = 'INVITE_EMPLOYEE_SUCCESS'
export const INVITE_EMPLOYEE_FAILED = 'INVITE_EMPLOYEE_FAILED'

export const LOAD_EMPLOYEE_STATUS = 'LOAD_EMPLOYEE_STATUS'
export const LOAD_EMPLOYEE_STATUS_SUCCESS = 'LOAD_EMPLOYEE_STATUS_SUCCESS'
export const LOAD_EMPLOYEE_STATUS_FAILED = 'LOAD_EMPLOYEE_STATUS_FAILED'

export const TRIGGER_EMPLOYEE_PASSWORD_RESET = 'TRIGGER_EMPLOYEE_PASSWORD_RESET'
export const TRIGGER_EMPLOYEE_PASSWORD_RESET_SUCCESS = 'TRIGGER_EMPLOYEE_PASSWORD_RESET_SUCCESS'
export const TRIGGER_EMPLOYEE_PASSWORD_RESET_FAILED = 'TRIGGER_EMPLOYEE_PASSWORD_RESET_FAILED'

export const DISABLE_EMPLOYEE = 'DISABLE_EMPLOYEE'
export const DISABLE_EMPLOYEE_SUCCESS = 'DISABLE_EMPLOYEE_SUCCESS'
export const DISABLE_EMPLOYEE_FAILED = 'DISABLE_EMPLOYEE_FAILED'

export const ENABLE_EMPLOYEE = 'ENABLE_EMPLOYEE'
export const ENABLE_EMPLOYEE_SUCCESS = 'ENABLE_EMPLOYEE_SUCCESS'
export const ENABLE_EMPLOYEE_FAILED = 'ENABLE_EMPLOYEE_FAILED'

export const DELETE_EMPLOYEE_USER = 'DELETE_EMPLOYEE_USER'
export const DELETE_EMPLOYEE_USER_SUCCESS = 'DELETE_EMPLOYEE_USER_SUCCESS'
export const DELETE_EMPLOYEE_USER_FAILED = 'DELETE_EMPLOYEE_USER_FAILED'

export const UPDATE_WORK_HOURS = 'UPDATE_WORK_HOURS'
export const UPDATE_WORK_HOURS_SUCCESS = 'UPDATE_WORK_HOURS_SUCCESS'
export const UPDATE_WORK_HOURS_FAILED = 'UPDATE_WORK_HOURS_FAILED'

export const LOAD_WORK_HOURS = 'LOAD_WORK_HOURS'
export const LOAD_WORK_HOURS_SUCCESS = 'LOAD_WORK_HOURS_SUCCESS'
export const LOAD_WORK_HOURS_FAILED = 'LOAD_WORK_HOURS_FAILED'

export const DELETE_WORK_HOURS = 'DELETE_WORK_HOURS'
export const DELETE_WORK_HOURS_SUCCESS = 'DELETE_WORK_HOURS_SUCCESS'
export const DELETE_WORK_HOURS_FAILED = 'DELETE_WORK_HOURS_FAILED'

export const LOAD_MECHANICS = 'LOAD_MECHANICS'
export const LOAD_MECHANICS_SUCCESS = 'LOAD_MECHANICS_SUCCESS'
export const LOAD_MECHANICS_FAILED = 'LOAD_MECHANICS_FAILED'
