import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'
import { CatalogGroupNumbers } from '../products/index'
import { APIResponse } from '../../helpers'
import { LKQJobPostData, RepairJobs } from './types'
import { HttpMethod } from '../../http-method'
import { ForeignSystemType } from '../enums'
import { POSTWorkorderLineResponse } from '../workorder'

export const getRepairJobsByCatalogGroupNumbers = (vehicleUUID: string, catalogGroupNumbers: CatalogGroupNumbers) =>
  createAsyncApiAction<APIResponse<RepairJobs>>('gms', {
    method: HttpMethod.POST,
    url: `/modules/jobs/${vehicleUUID}`,
    data: catalogGroupNumbers
  })

export const postRepairJobsToWorkOrder = (workorderUUID: string, foreignType: ForeignSystemType, Data: LKQJobPostData) =>
  createAsyncApiAction<POSTWorkorderLineResponse>('POST_ORDER_LINE', 'gms', {
    method: HttpMethod.POST,
    url: `/modules/workorders/${workorderUUID}/foreign-system/${foreignType}`,
    data: Data
  })
