import {
  BodyType,
  DisplayType,
  EmissionClass,
  FuelType,
  InvoiceTypeWithVehicleSales,
  MDMSource,
  OdometerLogReason,
  RDWInspectionError,
  RDWInspectionResult,
  RecallCriteriaType,
  TransmissionType,
  VehicleAssignedAs,
  VehicleType
} from '../enums'
import { OdometerType } from '@carsys/enums/odometer-type'
import { UpholsteryType } from '@carsys/enums/upholstery-type'
import { InteriorColourType } from '@carsys/enums/interior-colour-type'
import { YesNoOptionType } from '@carsys/enums/yes-no-option-type'
import { MaintenanceBookletOptionType } from '@carsys/enums/maintenance-booklet-option-type'
import { PaintCodeType } from '@carsys/enums/paint-code-type'
import { PaintShadeCodeType } from '@carsys/enums/paint-shade-code-type'
import { OdometerDigitType } from '@carsys/enums/odometer-digit-type'
import { GETAccountsResponseRow } from '../accounts'
import { IArrayResponse, IPaginatedResponse, IResponse } from '../../response'
import { IRDWNotification } from '../rdw'
import { APIResponse, GETResponse, PUTRequestBody, PaginatedResponse } from '../../helpers'
import { OdometerMessage } from '../enums/odometer'

import { TimeType } from '@carsys/enums/time-type'
import { PeriodType } from '@carsys/enums/period-type'

export * from './mocks'

export interface IVehicle {
  AccountUUID: string
  CompanyAccountUUID: string
  LeaseCompanyAccountUUID: string
  AlarmCode: string
  Ascription: string
  BodyType: BodyType
  CapacityCC: string
  Colour: string
  ConstructionYear: string
  CountryFirstAdmission: string
  CreatedAt: string
  Cylinders: string
  DateOfFirstAdmission: string
  DateOfFirstAdmissionLocal: string
  EngineCode: string
  EngineSizeDescription: string
  FuelTypes: FuelType[]
  HaynesProID: string
  HbaseID: string
  Internal: string
  KeyCode: string
  Manufacturer: string
  Model: string
  MOTExpirationDate: string
  OdometerUnit: OdometerType
  OdometerValue: number
  PowerKw: number
  RadioCode: string
  Registration: string
  Remarks: string
  ShopID: string
  TecdocKtype: string
  TransmissionType: TransmissionType
  Type: string
  UpdatedAt: string
  UUID: string
  VehicleType: VehicleType
  Vin: string
  Weight: string
  MDMAttributes: { Attributes: IVehicleAttribute[] } | null
  TecDocPlusAttributes: { Attributes: IVehicleAttribute[] } | null
  GrossBPM: number
  LastEnrichedAt: string
  AssignedAs: VehicleAssignedAs
  NextServiceDate: string
  NextServiceOdometerValue: number
  NextServiceOdometerUnit: OdometerType
  NextServiceOdometerEstimatedDate: string
  KeyCodeTwo: string
  StartCode: string
  EngineOilType: string
  LastVisitDate: string
  SecondColour: string
  LastTimingBeltReplaceValue: number
  LastTimingBeltReplaceUnit: OdometerType
  LastTimingBeltReplaceDate: string
  CO2Emissions: number
  ManufacturerCode: string
  EngineSerialNumber: string
  NumberOfGears: number
  NumberOfWheels: number
  NumberOfSeats: number
  NumberOfCylinders: number
  EECVehicleCategory: string
  EECVersionAddition: string
  EECVehicleCategoryAddition: string
  PlaceChassisNumber: string
  NumberOfDoors: number
  Wheelbase: number
  DimensionsLength: number
  DimensionsWidth: number
  DimensionsHeight: number
  EmissionClass: EmissionClass
  TankCapacity: number
  CityConsumption: number
  HighwayConsumption: number
  AverageConsumption: number
  MaximumWeight: number
  MaximumLoad: number
  MaximumFrontAxle: number
  MaximumRearAxle: number
  NumberOfKeys: number
  SCMClass: number
  Upholstery?: UpholsteryType
  InteriorColour?: InteriorColourType
  DamagedVehicle?: YesNoOptionType
  NewVehicle?: YesNoOptionType
  MaintenanceBooklets?: MaintenanceBookletOptionType
  PaintTypeCode?: PaintCodeType
  PaintShadeCode?: PaintShadeCodeType
  RoadTax: number
  InsuranceCompany: string
  TypeOfInsurance: string
  PolicyNumber: string
  IsDeclarationOfConformityDate: boolean
  DeclarationOfConformityDate: string
  ListPriceDate: string
  CatalogPrice: number
  GrossBPMAmount: number
  BPMDate: string
  IsFullyMaintained: boolean
  HasBOVAGFortyPointsCheck: boolean
  HasBOVAGDelivery: boolean
  VehicleNumber: string
  VehicleLocation: string
  OdometerDigitType: OdometerDigitType
  DateOfLastVisit: string
  TachographExpiryDate: string
  DuplicateCode: string
  OneCylinderCapacity: number
  EngineOilCapacityLiter: number
  ManufacturerColourCode: string
  ManufacturerColourName: string
  ETAModelCode: string
  ExecutionCode: string
  VariantCode: string
  NextTimingBeltReplacementDueUnit: OdometerType
  NextTimingBeltReplacementDueValue: number
  NextTimingBeltReplacementDuePeriodUnit: PeriodType
  NextTimingBeltReplacementDuePeriodValue: number
  TimingBeltReplacementDurationUnit: TimeType
  TimingBeltReplacementDurationValue: number
}

export interface GETVehicleWithRelatedResponseRow extends GETVehicleResponseRow, GETAccountsResponseRow {}

export interface GETVehicleResponseRow {
  Vehicle: IVehicle
}

export interface ILatestOdometerLog {
  UUID: string
  VehicleUUID: string
  WorkorderInvoiceUUID: string
  ShopID: string
  Registration: string
  VIN: string
  OdometerUnit: OdometerType
  OdometerValue: number
  Reason: OdometerLogReason
  UpdateUserUUID: string
  UpdatePerformedByType: string
  UpdatePerformedByName: string
  CreatedAt: string
  UpdatedAt: string
}

export type GETLatestOdometerLogResponse = APIResponse<ILatestOdometerLog>
export type GETVehicleResponse = APIResponse<IVehicle>
export type GETVehiclesResponse = PaginatedResponse<GETVehicleResponseRow>
export type GETVehiclesWithRelatedResponse = PaginatedResponse<GETVehicleWithRelatedResponseRow>
export type GETVehicleWithRelatedResponse = APIResponse<GETVehicleWithRelatedResponseRow>
export type POSTVehicleResponse = APIResponse<{ UUID: string }>
export type PUTVehicle = PUTRequestBody<IVehicle>
export type GETVehicleTypesResponse = IArrayResponse<IVehicleType>

export interface IGetFinishInspectionRequestParams {
  vehicleUUID: string
  apkUUID: string
  inspectionResult: RDWInspectionResult
  inspectorPincode: string
}

export interface IParseFinishInspectionResponseParams {
  vehicleUUID: string
  apkUUID: string
  inspectionResult: RDWInspectionResult
  HTTPStatusCode?: number
  TransactionUUID: string
  WorkorderUUID?: string
  ResponseBody?: string
  ResponseTime?: string
}

export interface IParseFinishInspectionResponse {
  Error: string
  Notification: IRDWNotification
  ApkID: number
  TransactionID: string
  MOTExpiryDate: string
  HasRandomCheck: boolean
  RandomCheckExpiryTimestamp: string
  ErrorSpecification?: IRDWErrorSpecification
}

export interface IRDWErrorSpecification {
  ErrorType: RDWInspectionError
  ProviderAPKID: string
}

export interface IGetAPKReportRequestParams {
  vehicleUUID: string
  apkUUID: string
}

export interface IPostAPKReportResponseParams {
  vehicleUUID: string
  apkUUID: string
  HTTPStatusCode?: number
  TransactionUUID: string
  WorkorderUUID?: string
  ResponseBody?: string
  ResponseTime?: string
}

export interface IGetAPKHistoryResponseParams {
  vehicleUUID: string
}

export interface IGetApkConfirmRandomCheckParams {
  vehicleUUID: string
  apkUUID: string
}

export interface IGetApkConfirmRandomCheckWithoutVehicleParams {
  providerAccountUUID: string
  providerAPKID: string
}

export interface IPostApkConfirmRandomCheckWithoutVehicleParams {
  providerAccountUUID: string
  HTTPStatusCode?: number
  TransactionUUID: string
  WorkorderUUID?: string
  ResponseBody?: string
  ResponseTime?: string
}

export interface IPostApkConfirmRandomCheckParams {
  vehicleUUID: string
  apkUUID: string
  TransactionUUID: string
  WorkorderUUID?: string
  HTTPStatusCode?: number
  ResponseBody?: string
  ResponseTime?: string
}

export interface IApkConfirmRandomCheck {
  Notification: IRDWNotification
}

export interface IGenericVehicle {
  BodyType: string
  CilinderCount: number
  Colour: string
  ConstructionMonth: string
  ConstructionYear: string
  DateOfFirstAdmission: string
  DateOfFirstAdmissionLocal: string
  DateOfOwnership: string
  ETA: string
  EUType: string
  EUVariant: string
  EUVersion: string
  EngineCapacityCC: number
  EngineCodes: string[]
  FuelTypes: FuelType[]
  HaynesproID: string
  HbaseID: string
  ID: string
  Images: string[]
  MOTExpirationDate: string
  MadeFrom: string
  MadeUntil: string
  Manufacturer: string
  Model: string
  NumberOfDoors: number
  NumberOfWheels: number
  PowerKw: number
  PowerBHP: number
  Registration: string
  Remarks: string
  Source: string
  TecdocKtype: string
  TransmissionType: string
  Type: string
  VIN: string
  VehicleType: string
  Weight: number
  WeightRoadworthy: number
  MDMAttributes?: IVehicleAttribute[]
  TecDocPlusAttributes?: IVehicleAttribute[]
  FoundOn?: string[]
}

export interface IVehicleType extends IVehicle {
  GenericVehicle: IGenericVehicle
  ManufacturerID?: string
  Meta?: Partial<IPostVehicleDetailOptions>
  ModelID?: string
}

export interface IManufacturer {
  ManufacturerID: string
  ManufacturerName: string
}

export interface IModel {
  MadeFrom: string
  MadeUntil: string
  ModelID: string
  ModelName: string
  Remarks: string
}

export interface IMOTHistory {
  Registration: string
  Make: string
  Model: string
  FirstUsedDate: string
  FuelType: string
  PrimaryColour: string
  VehicleID: string
  RegistrationDate: string
  ManufactureDate: string
  EngineSize: string
  MOTTests: MOTTest[]
}

export type MOTHistoryResponse = GETResponse<IMOTHistory[]>

interface MOTTest {
  CompletedDate: string
  TestResult: boolean
  ExpiryDate?: string
  RfrAndComments?: RfrAndComments[]
  OdometerUnit: OdometerType | ''
  OdometerValue: string
}

export interface RfrAndComments {
  Text: string
  Type: string
  Dangerous: boolean
}

export interface RfrAndCommentsGroups {
  [key: string]: {
    Text: string
    Dangerous: boolean
  }[]
}

export type ManufacturerResponse = IPaginatedResponse<IManufacturer>
export type ModelResponse = IPaginatedResponse<IModel>
export type TypeResponse = IPaginatedResponse<IVehicleType>

export interface IVehicleDetailAttributes {
  Attributes: IVehicleAttribute[]
}

export interface IVehicleDetailData {
  Attributes: IVehicleAttribute[]
  Vehicle: IVehicleType
}

export type VehicleDetailsResponse = IResponse<IVehicleDetailData>

export interface IPostVehicleDetailOptions {
  Locale?: string
  Attributes: IVehicleAttribute[]
}

export type GETMDMAttributesResponse = GETResponse<IVehicleDetailAttributes>

export interface IVehicleModel {
  MadeFrom: string
  MadeUntil: string
  ModelID: string
  ModelName: string
  Remarks: string
}

export interface IVehicleAttribute {
  Name: string
  Value: string
  Source: MDMSource
  DisplayName: string
  DisplayValue: string
  DisplayType: DisplayType
  ResetOrder: number
  SortOrder: number
}

export enum AttributeName {
  TecDocPlusDateFrom = 'ModelYearFrom',
  TecDocPlusDateTo = 'ModelYearTo',
  EMDMDateFrom = 'DateFrom',
  EMDMDateTo = 'DateTo'
}

export enum VehicleValidationErrors {
  VehicleRegistrationNotUnique = 'ErrVehicleRegistrationNotUnique',
  VehicleVinNotUnique = 'ErrVehicleRegistrationNotUnique'
}

export type CheckOdometerResponse = IResponse<OdometerMessage[]>

export type GetInvoicesByVehicleResponse = IResponse<IVehicleInvoiceHistoryItem[]>

export interface IVehicleInvoiceHistoryItem {
  InvoiceUUID: string
  InvoiceNumber: string
  InvoiceDate: string
  TotalVatIncluded: number
  InvoiceType: InvoiceTypeWithVehicleSales
  AccountUUID: string
  Title: string
  FirstName: string
  NamePrefix: string
  Name: string
  Initials: string
  CompanyName: string
}

export type GetRecallsByVehicleResponse = IResponse<IRecall[]>

export interface IRecall {
  ID: number
  Category: string
  OeCode: string
  Description: string
  IssueDate: string
  RecallCriteria: IRecallCriteria[]
  Details: IRecallDetail[]
}

export interface IRecallCriteria {
  CriteriaType: RecallCriteriaType
  Value: string
}

export interface IRecallDetail {
  Description: string
  DetailNodes: IRecallDetailNode[]
}

export interface IRecallDetailNode {
  EOBDCode: string
  OECode: string
  Image: string
  Text: string
  TextRemark: string
  RepairTimeID: number
  TableContent?: ITableContent | null
  SpecialTools?: ISpecialTool | null
  SmartLinks?: ISmartLinks | null
}

export interface ITableContent {
  NumberOfRows: number
  NumberOfColumns: number
  TableCells: Array<{
    SortOrder: number
    Value: string
  }>
}

export interface ISmartLinks {
  IDOne: string
  IDTwo: string
  Operation: string
  Text: string[]
  Filter: string
}

export interface ISpecialTool {
  ID: string
  SortOrder: Number
  Code: string
  Dimensions: string
  Description: string
  MimeDataName: string
}

export type GetVehicleRegisteredInformationResponse = IResponse<IVehicleRegisteredInformation>

export interface IVehicleRegisteredInformation {
  SourceType: VehicleRegisteredInformationSourceType
  VehicleProperties: IVehicleProperty[]
}

export interface IVehicleProperty {
  Key: string
  Value: string
}

export enum VehicleRegisteredInformationSourceType {
  TripleAAA = 'TripleAAA',
  RDW = 'RDW',
  Traxio = 'Traxio'
}

export interface IVehicleManufacturerModel {
  Manufacturer: string
  Model: string
  Type: string
}

export type GETVehicleByRegistrationResponse = IResponse<IVehicleManufacturerModel>

export interface HaynesTechnicalData {
  ImageName: string
  Name: string
  Order: number
  Remark: string
  Unit: string
  Value: string
  SubAdjustments: HaynesTechnicalData[]
}
