export const GET_INVOICES = 'GET_INVOICES'
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS'
export const GET_INVOICES_FAILED = 'GET_INVOICES_FAILED'

export const GET_INVOICE_PDF = 'GET_INVOICE_PDF'
export const GET_INVOICE_PDF_SUCCESS = 'GET_INVOICE_PDF_SUCCESS'
export const GET_INVOICE_PDF_FAILED = 'GET_INVOICE_PDF_FAILED'

export const SEND_INVOICE_EMAIL = 'SEND_INVOICE_EMAIL'
export const SEND_INVOICE_EMAIL_SUCCESS = 'SEND_INVOICE_EMAIL_SUCCESS'
export const SEND_INVOICE_EMAIL_FAILED = 'SEND_INVOICE_EMAIL_FAILED'

export const GET_INVOICE = 'GET_INVOICE'
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS'
export const GET_INVOICE_FAILED = 'GET_INVOICE_FAILED'

export const CREATE_CREDIT_INVOICE = 'CREATE_CREDIT_INVOICE'
export const CREATE_CREDIT_INVOICE_SUCCESS = 'CREATE_CREDIT_INVOICE_SUCCESS'
export const CREATE_CREDIT_INVOICE_FAILED = 'CREATE_CREDIT_INVOICE_FAILED'

export const GET_INVOICE_PAYMENT_BALANCE = 'GET_INVOICE_PAYMENT_BALANCE'
export const GET_INVOICE_PAYMENT_BALANCE_SUCCESS = 'GET_INVOICE_PAYMENT_BALANCE_SUCCESS'
export const GET_INVOICE_PAYMENT_BALANCE_FAILED = 'GET_INVOICE_PAYMENT_BALANCE_FAILED'

export const POST_INVOICE_PAYMENT = 'POST_INVOICE_PAYMENT'
export const POST_INVOICE_PAYMENT_SUCCESS = 'POST_INVOICE_PAYMENT_SUCCESS'
export const POST_INVOICE_PAYMENT_FAILED = 'POST_INVOICE_PAYMENT_FAILED'

export const GET_INVOICE_PAYMENTS = 'GET_INVOICE_PAYMENTS'
export const GET_INVOICE_PAYMENTS_SUCCESS = 'GET_INVOICE_PAYMENTS_SUCCESS'
export const GET_INVOICE_PAYMENTS_FAILED = 'GET_INVOICE_PAYMENTS_FAILED'

export const REVERT_PAYMENT = 'REVERT_PAYMENT'
export const REVERT_PAYMENT_SUCCESS = 'REVERT_PAYMENT_SUCCESS'
export const REVERT_PAYMENT_FAILED = 'REVERT_PAYMENT_FAILED'

export const GET_INVOICES_BY_TYPE = 'GET_INVOICES_BY_TYPE'
export const GET_INVOICES_BY_TYPE_SUCCESS = 'GET_INVOICES_BY_TYPE_SUCCESS'
export const GET_INVOICES_BY_TYPE_FAILED = 'GET_INVOICES_BY_TYPE_FAILED'
