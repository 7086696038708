import propOr from 'lodash/fp/propOr'

import { GETOrderAccountResponse, IOrderAccountData } from '@cbgms/api/modules/wholesaler'

import * as types from './types'
import { GETDefaultOrderAccountReponse } from '@cbgms/api'

export interface DefaultOrderAccountReducerState {
  readonly defaultOrderAccountUUID: string
  readonly details?: IOrderAccountData
  readonly hasLoaded: boolean
}

const defaultOrderAccountState: DefaultOrderAccountReducerState = {
  defaultOrderAccountUUID: '',
  details: undefined,
  hasLoaded: false
}

interface SetDefaultOrderAccount {
  type: typeof types.SET_DEFAULT_ORDER_ACCOUNT_SUCCESS
  payload: {
    data: GETDefaultOrderAccountReponse
  }
}

interface GetDefaultOrderAccount {
  type: typeof types.GET_DEFAULT_ORDER_ACCOUNT_SUCCESS
  payload: {
    data: GETDefaultOrderAccountReponse
  }
}

interface GetDefaultOrderAccountDetails {
  type: typeof types.GET_DEFAULT_ORDER_ACCOUNT_DETAILS_SUCCESS
  payload: {
    data: GETOrderAccountResponse
  }
}

type DefaultOrderAccountAction = SetDefaultOrderAccount | GetDefaultOrderAccount | GetDefaultOrderAccountDetails

export default function defaultOrderAccount(
  state: DefaultOrderAccountReducerState = defaultOrderAccountState,
  action: DefaultOrderAccountAction
): DefaultOrderAccountReducerState {
  switch (action.type) {
    case types.SET_DEFAULT_ORDER_ACCOUNT_SUCCESS:
      return {
        ...state,
        defaultOrderAccountUUID: propOr('', 'data.Data.DefaultOrderAccountUUID', action.payload)
      }
    case types.GET_DEFAULT_ORDER_ACCOUNT_SUCCESS:
      return {
        ...state,
        defaultOrderAccountUUID: propOr('', 'data.Data.DefaultOrderAccountUUID', action.payload)
      }
    case types.GET_DEFAULT_ORDER_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data.Data,
        hasLoaded: true
      }
    default:
      return state
  }
}
