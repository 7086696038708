import * as types from '../types'
import { IAccount } from '@cbgms/api'
import { AnyAction } from 'redux'
import { GET_ORDER_WITH_RELATED_SUCCESS } from 'app/order/state/types'

export const workorderInvoiceAccountReducer = (state: IAccount | null = null, action: AnyAction): IAccount | null => {
  switch (action.type) {
    case types.GET_WORKORDER_WITH_RELATED_SUCCESS:
      return action.payload.data?.Data.InvoiceAccount
    case GET_ORDER_WITH_RELATED_SUCCESS:
      return action.payload.data?.Data.InvoiceAccount
    default:
      return state
  }
}
