import React, { useState } from 'react'
import { GridV1Context } from '@cbgms/components/grid/Contexts/GridV1Context'

export const GridV1ContextProvider: React.FC = ({ children }) => {
  const [topBar, setHeight] = useState(0)
  const [margin, setBodyMargin] = useState(0)
  const viewHeight = window.innerHeight

  const setTopBarHeight = (height: number) => {
    if (height !== 0) {
      setHeight(height)
    }
  }

  const setMargin = (margin: number) => {
    if (margin !== 0) {
      setBodyMargin(margin)
    }
  }

  const value = {
    topbarHeightPx: topBar,
    viewHeightPx: viewHeight,
    margin,
    setTopBarHeight,
    setMargin
  }

  return <GridV1Context.Provider value={value}>{children}</GridV1Context.Provider>
}
