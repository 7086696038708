import { IOnboardingStep, IShopResponse, IShopWholesaler, ModuleKeys } from '@cbgms/api'
import { Subscriptions } from '@carsys/enums/subscription'
import { OnboardingMode, StepPersistanceMode } from '../onboarding/steps/types.d'
import { useGetOnboardingExistingShop } from './use-get-onboarding-existing-shop'
import { useGetOnboardingWholesaler } from './use-get-onboarding-wholesaler'
import { useGetOnboardingStepData } from './use-get-onboarding-step-data'
import { getActiveModules } from 'app/auth/state/reducers/shop'

export interface useDetermineOnboardingModeInput {
  basePath: string
  existingShopOnboardingShopID?: string
  onboardUsingWholesalerUUID?: string
  thirdPartyOnboardingTicket?: string
}

export const countryCodeFromShopID = (shopID?: string) => {
  return shopID && shopID.length > 2 && shopID.substring(0, 2)
}

export interface useDetermineOnboardingModeOutput {
  persistMode: StepPersistanceMode
  isLoading: boolean
  onboardingInfo: OnboardingState
}

export interface OnboardingState {
  onboardingMode: OnboardingMode
  wholesaler: IShopWholesaler | undefined
  shop: IShopResponse | undefined
  country: string
  modules: Subscriptions[]
  stepData: IOnboardingStep[]
  onboardingModules: Partial<ModuleKeys>
}

export const useOnboardingSetup = ({
  basePath,
  existingShopOnboardingShopID,
  onboardUsingWholesalerUUID,
  thirdPartyOnboardingTicket
}: useDetermineOnboardingModeInput): useDetermineOnboardingModeOutput => {
  // coming in with a shopID:
  // coming in with a wholesalerUUID
  const { data: wholesaler, isLoading: isLoadingWholesaler } = useGetOnboardingWholesaler(onboardUsingWholesalerUUID || '')
  const { data: shopInfo, isLoading: isLoadingShop } = useGetOnboardingExistingShop(existingShopOnboardingShopID)
  const persistMode = onboardUsingWholesalerUUID ? StepPersistanceMode.PersistInOneTransaction : StepPersistanceMode.PersistImmediately
  const mode = existingShopOnboardingShopID ? OnboardingMode.OnboardingExistingShop : OnboardingMode.OnboardingViaWholesaler
  const country =
    mode === OnboardingMode.OnboardingViaWholesaler ? wholesaler?.Data.Country : countryCodeFromShopID(existingShopOnboardingShopID)

  const { data: stepData, isLoading: isLoadingStepData } = useGetOnboardingStepData({ mode, thirdPartyOnboardingTicket, basePath })
  const modules = shopInfo?.modules || (stepData ? getActiveModules(stepData.Modules) : [])

  return {
    persistMode,
    isLoading: isLoadingWholesaler || isLoadingShop || isLoadingStepData,
    onboardingInfo: {
      onboardingMode: mode,
      wholesaler: wholesaler?.Data,
      country: country || 'GB',
      shop: shopInfo?.shop,
      stepData: stepData?.Steps || [],
      modules,
      onboardingModules: stepData?.Modules || {}
    }
  }
}
