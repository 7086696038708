import { Locales } from '@carsys/enums/locales'
import { VehicleProvider } from '../enums'
import { IAPKRecord } from '../rdw'
import {
  IGetAPKHistoryResponseParams,
  IGetAPKReportRequestParams,
  IGetApkConfirmRandomCheckParams,
  IGetApkConfirmRandomCheckWithoutVehicleParams,
  IGetFinishInspectionRequestParams,
  IParseFinishInspectionResponseParams,
  IPostAPKReportResponseParams,
  IPostApkConfirmRandomCheckParams,
  IPostApkConfirmRandomCheckWithoutVehicleParams,
  IVehicle,
  IVehicleAttribute
} from './index'
import { VehicleIdType } from '@cbgms/api/modules/enums'
import { IPaginatedRequest } from '../../request'
import { sanitizeRegistration } from '@cbgms/components/list/query'

export const getVehicle = (vehicleUUID: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}`
})
export const getVehicles = (params: IPaginatedRequest) => ({
  params,
  method: 'GET',
  url: '/modules/vehicles'
})

const getVehiclesByIdentifierFields = [
  'Vehicle.Vin',
  'Vehicle.Model',
  'Vehicle.Manufacturer',
  'Vehicle.EngineCode',
  'Vehicle.Cylinders',
  'Vehicle.Colour',
  'Vehicle.ConstructionYear',
  'Vehicle.FuelTypes',
  'Vehicle.Registration'
]
export const getVehiclesByRegistration = (registration: string) => ({
  method: 'GET',
  url: '/modules/vehicles',
  params: {
    fields: getVehiclesByIdentifierFields.toString(),
    search: `Vehicle.Registration|=|${sanitizeRegistration(registration)}`
  }
})

export const postVehicle = (vehicle: Partial<IVehicle>) => ({
  method: 'POST',
  url: '/modules/vehicles',
  data: vehicle
})

export const getVehiclesByVin = (vin: string) => ({
  method: 'GET',
  url: '/modules/vehicles',
  params: {
    fields: getVehiclesByIdentifierFields.toString(),
    search: `Vehicle.Vin|ILIKE|${vin}`
  }
})

export const getVehicleWithRelated = (vehicleUUID: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}/with-related`
})

export const getVehicleExternalData = (vehicleUUID: string, provider: VehicleProvider = VehicleProvider.Rdw) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}/external-data`,
  params: { provider }
})

export const putRDWAPKRecord = (data: IAPKRecord) => ({
  method: 'PUT',
  url: `/modules/vehicles/${data.VehicleUUID}/rdw/apk/${data.UUID}`,
  data
})

export const postRDWAPKRecord = ({ VehicleUUID, WorkorderUUID, ...data }: IAPKRecord) => {
  return {
    method: 'POST',
    url: `/modules/vehicles/${VehicleUUID}/rdw/apk`,
    params: { WorkorderUUID },
    data
  }
}

export const getRDWCurrentAPKRecord = (vehicleUUID: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}/rdw/apk/current`
})

export const getRDWAPKRecordByUUID = (vehicleUUID: string, apkUUID: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}/rdw/apk/${apkUUID}`
})

export const deleteRDWAPKRecordByUUID = (vehicleUUID: string, apkUUID: string, dryRun: boolean) => ({
  method: 'DELETE',
  url: `/modules/vehicles/${vehicleUUID}/rdw/apk/${apkUUID}`,
  params: { DryRun: dryRun }
})

export const getFinishInspectionRequest = ({ vehicleUUID, apkUUID, ...params }: IGetFinishInspectionRequestParams) => {
  return {
    method: 'GET',
    url: `/modules/vehicles/${vehicleUUID}/rdw/apk/${apkUUID}/finish-inspection-request`,
    params
  }
}

export const postFinishInspectionResponse = ({
  vehicleUUID,
  apkUUID,
  inspectionResult,
  ...params
}: IParseFinishInspectionResponseParams) => {
  return {
    method: 'POST',
    url: `/modules/vehicles/${vehicleUUID}/rdw/apk/${apkUUID}/finish-inspection-response`,
    params: { inspectionResult },
    data: { ...params }
  }
}

export const getAPKReportRequest = ({ vehicleUUID, apkUUID }: IGetAPKReportRequestParams) => {
  return {
    method: 'GET',
    url: `/modules/vehicles/${vehicleUUID}/rdw/apk/${apkUUID}/apk-report-request`
  }
}

export const postAPKReportResponse = ({ vehicleUUID, apkUUID, ...params }: IPostAPKReportResponseParams) => {
  return {
    method: 'POST',
    url: `/modules/vehicles/${vehicleUUID}/rdw/apk/${apkUUID}/apk-report-response`,
    data: { ...params }
  }
}

export const getAPKHistoryOnVehicle = ({ vehicleUUID }: IGetAPKHistoryResponseParams) => {
  return {
    method: 'GET',
    url: `/modules/vehicles/${vehicleUUID}/rdw/transaction-history`
  }
}

export const getApkConfirmRandomCheckRequest = ({ vehicleUUID, apkUUID }: IGetApkConfirmRandomCheckParams) => {
  return {
    method: 'GET',
    url: `/modules/vehicles/${vehicleUUID}/rdw/apk/${apkUUID}/apk-confirm-random-check-request`
  }
}

export const postApkConfirmRandomCheckResponse = ({ vehicleUUID, apkUUID, ...params }: IPostApkConfirmRandomCheckParams) => {
  return {
    method: 'POST',
    url: `/modules/vehicles/${vehicleUUID}/rdw/apk/${apkUUID}/apk-confirm-random-check-response`,
    data: { ...params }
  }
}

export const getApkConfirmRandomCheckWithoutVehicleRequest = ({
  providerAccountUUID,
  providerAPKID
}: IGetApkConfirmRandomCheckWithoutVehicleParams) => {
  return {
    method: 'GET',
    url: `/modules/rdw/${providerAccountUUID}/apk-confirm-random-check-request`,
    params: { providerAPKID }
  }
}

export const postApkConfirmRandomCheckWithoutVehicleResponse = ({
  providerAccountUUID,
  ...params
}: IPostApkConfirmRandomCheckWithoutVehicleParams) => {
  return {
    method: 'POST',
    url: `/modules/rdw/${providerAccountUUID}/apk-confirm-random-check-response`,
    data: { ...params }
  }
}

export const getMOTHistoryOfVehicle = (vehicleUUID: string) => {
  return {
    method: 'GET',
    url: `/modules/vehicles/${vehicleUUID}/mot-history`
  }
}

export const checkOdometerOfVehicle = (vehicleUUID: string, newOdometerUnit: string, newOdometerValue: string) => {
  return {
    method: 'GET',
    url: `/modules/vehicles/${vehicleUUID}/odometer-check`,
    params: { newOdometerUnit, newOdometerValue }
  }
}

export const getOdometerLog = (vehicleUUID: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}/latest-odometer-log`
})

export const postGetVehicleDetails = (_locale?: Locales, vehicleCountry?: string, attributes?: IVehicleAttribute[]) => ({
  method: 'POST',
  url: 'modules/vehicletree/details',
  data: {
    locale: _locale,
    vehicleCountry,
    Attributes: attributes
  }
})

export const enrichVehicle = (vehicleUUID: string, target: VehicleIdType) => ({
  method: 'PUT',
  url: `/modules/vehicles/${vehicleUUID}/enrich`,
  data: {
    Target: target
  }
})

export const getInvoicesByVehicle = (vehicleUUID: string, query: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}/invoices`,
  params: {
    SearchString: query
  }
})

export const getRecallsByVehicle = (vehicleUUID: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}/haynespro/recalls`,
  params: {}
})

export const getVehicleRegisteredInformation = (vehicleUUID: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}/registered-information`,
  params: {}
})

export const getSuggestionsForVehicleWithProvider = (vehicleUUID: string, provider: VehicleProvider) => ({
  method: 'GET',
  url: `/modules/vehicles/${vehicleUUID}/external-data/suggestions`,
  params: {
    provider
  }
})

export const getVehicleByRegistration = (registration: string) => ({
  method: 'GET',
  url: `/modules/vehicles/by-registration/${registration}`
})

export const getVehicleActiveWarrantiesRequest = (uuid: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${uuid}/warranties`
})
