import MUIRadio from '@mui/material/Radio'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'

interface RadioOptionProps extends Omit<FormControlLabelProps, 'control'> {
  RadioControl?: React.ReactElement
}

export function RadioOption(props: RadioOptionProps) {
  const { RadioControl, ...rest } = props
  return <FormControlLabel control={RadioControl || <MUIRadio color='primary' />} {...rest} />
}
