import { APIResponse } from '../../helpers'
import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'
import { GmsOAuthScope } from './authorization-codes'

interface ValidateScopes {
  ValidatedScopes: GmsOAuthScope[]
}

export type ValidateScopesResponse = APIResponse<ValidateScopes>

export const validateScope = (scope: string) =>
  createAsyncApiAction<ValidateScopesResponse>('OAUTH_VALIDATE_SCOPE', 'gms', {
    method: 'GET',
    url: '/modules/gms-oauth/validate-scope',
    params: { scope }
  })
