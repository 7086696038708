import { Reducer } from 'redux'

import { IWorkHours } from '@cbgms/api/modules/opening-hours'
import { createReducer } from '@cbgms/base/utils/redux'

import { LOAD_OPENING_HOURS_SUCCESS } from 'app/settings/state/OpeningHours/types'

export type OpeningHoursState = Readonly<Partial<IWorkHours>>

const defaultState: OpeningHoursState = {}

type OpeningHoursBehavior<S = OpeningHoursState> = Record<typeof LOAD_OPENING_HOURS_SUCCESS, Reducer<S, any>>

const openingHours = createReducer<OpeningHoursState, OpeningHoursBehavior>(defaultState, {
  [LOAD_OPENING_HOURS_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export default openingHours
