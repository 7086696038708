import React, { useEffect, useMemo, useState } from 'react'
import { getCookie } from '@cbgms/base/utils/helpers'

export interface ICookieConsent {
  StrictlyNecessary: boolean // Cookies that the website can't  function without
  Performance: boolean // Performance, as in tracking the performance of the site. as in which pages are vistited
  Functional: boolean // Optional functionalities.
  Targeting: boolean // Ads
  SocialMedia: boolean // Social Media integrations (I don't think this will ever be relevant for us)
}

const noConsent = {
  StrictlyNecessary: true,
  Performance: false,
  Functional: false,
  Targeting: false,
  SocialMedia: false
}

const getInitialOneTrustConsent = (): ICookieConsent => {
  // we have to read the initial consent model from the cookie, if no cookie is available, we consider no consent.
  const currentOneTrustCookieConsent = getCookie('OptanonConsent')
  if (!currentOneTrustCookieConsent) {
    return noConsent
  }
  // Example Cookie value:
  // isGpcEnabled=0&datestamp=Wed+Dec+07+2022+15%3A21%3A44+GMT%2B0100+(Central+European+Standard+Time)&version=202211.1.0&isIABGlobal=false
  // &hosts=&genVendors=&consentId=555177a7-b064-45af-9d2a-85f158c5426f&interactionCount=8&landingPath=NotLandingPage
  // &groups=C0001%3A1%2CC0002%3A1%2CC0003%3A0&geolocation=NL%3BLI&AwaitingReconsent=false

  // try to find the 'group' part in the cookie
  const groupPart = currentOneTrustCookieConsent.split('&').find(cookiePart => cookiePart.indexOf('groups') === 0)
  // groupPart contains the raw cookie vaule=  groups=C0001%3A1%2CC0002%3A1%2CC0003%3A0
  if (!groupPart || groupPart?.indexOf('=') === -1) {
    return noConsent
  }

  const parsedGroups = decodeURIComponent(groupPart.split('=')[1]).split(',')
  // parsedGroups contains an array of categories and whether they're turned on: ['C0001:1','C0002:1','C0003:0']
  // a group is on when ':1'  is specified for that group, and off when ':0' is specified
  return {
    StrictlyNecessary: true,
    Performance: !!parsedGroups.find(c => c === 'C0002:1'),
    Functional: !!parsedGroups.find(c => c === 'C0003:1'),
    Targeting: !!parsedGroups.find(c => c === 'C0004:1'),
    SocialMedia: !!parsedGroups.find(c => c === 'C0005:1')
  }
}

const subscribeOneTrustCookieConsentChanges = (setOneTrustState: React.Dispatch<React.SetStateAction<ICookieConsent>>) => {
  // We will subscribe to oneTrust consent changes.
  // Onetrust will register itself as script on the window.
  //
  // one trust: https://developer.onetrust.com/onetrust/docs/when-consent-changes-ios#category-ids
  //  - C0001 =  Strictly Necessary
  //  - C0002 =  Performance
  //  - C0003 =  Functional
  //  - C0004 =  Targeting
  //  - C0005 =  Social Media

  // We manually start the third party tooling based on things that oneTrust exposes via the JS-sdk (window.OneTrust)
  // There's also a javascript type rewrite (text/plain -> text/javascript) specificly for google analytics
  // https://my.onetrust.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca?topicId=0TO1Q000000ItVuWAK

  if (window?.OneTrust && window?.OneTrust.OnConsentChanged) {
    // register the callback
    window?.OneTrust?.OnConsentChanged(args => {
      // calculate the new state by extracting information for the event.
      const newState = {
        StrictlyNecessary: true,
        Performance: !!args?.detail?.find(c => c === 'C0002'),
        Functional: !!args?.detail?.find(c => c === 'C0003'),
        Targeting: !!args?.detail?.find(c => c === 'C0004'),
        SocialMedia: !!args?.detail?.find(c => c === 'C0005')
      }

      // set the new state for react
      setOneTrustState((prevState: ICookieConsent) => {
        // side effect:  we can't turn off the tracking properly, force a refresh
        const revokedAnyConsent =
          (!newState.Functional && newState.Functional !== prevState.Functional) ||
          (!newState.Performance && newState.Performance !== prevState.Performance) ||
          (!newState.Targeting && newState.Targeting !== prevState.Targeting) ||
          (!newState.SocialMedia && newState.SocialMedia !== prevState.SocialMedia)
        if (revokedAnyConsent) {
          window.location.reload()
        }
        return newState
      })
    })
  }
}

export const useGetCookieConsentModel = (): ICookieConsent => {
  // OneTrust manages our tracking cookie acceptance.
  // the OneTrust script is injected as the first thing to run.
  // OneTrust will block scripts when they're not accepted automatically
  const initialCookieConsent = useMemo(getInitialOneTrustConsent, [])
  const [oneTrustState, setOneTrustState] = useState(initialCookieConsent)
  useEffect(() => {
    subscribeOneTrustCookieConsentChanges(setOneTrustState)
  }, [])

  return useMemo(() => oneTrustState, [oneTrustState])
}
