import { Reducer } from 'redux'

export interface IReducerBehavior<S> {
  [key: string]: Reducer<S>
}

type ReducerFactory = <S, B extends IReducerBehavior<S>>(defaultState: S, behaviors: B) => Reducer<S>

/**
 * Function for creating reducers.
 * @deprecated It's better to write a function with a switch statement!
 */
export const createReducer: ReducerFactory =
  (defaultState, behaviors) =>
  (state = defaultState, action) => {
    const behavior = behaviors[action.type]

    return behavior ? behavior(state, action) : state
  }
