import { ResultObjectType } from '../enums'
import { IChangeLogInputParams, IWidgetSettings } from '.'

export interface ISearchParams {
  Q: string
  ResultLimit?: number
  ResultTypes?: ResultObjectType[]
  SearchAllCountries?: boolean
  SearchExternalParts?: boolean
}

export const getSearch = (params: ISearchParams) => ({
  url: '/modules/dashboard/search',
  method: 'GET',
  params
})

export const getDashboardChangeLog = (params: IChangeLogInputParams) => ({
  url: '/modules/dashboard/change-log',
  method: 'GET',
  params
})

export const getWidgetSettings = () => ({
  url: '/modules/dashboard/widget-settings',
  method: 'GET'
})

export const putWidgetSettings = (data: IWidgetSettings) => ({
  url: '/modules/dashboard/widget-settings',
  method: 'PUT',
  data
})

export const getRecentlyModifiedWorkorders = (resultLimit: number) => ({
  url: `/modules/dashboard/workorders-with-recently-modified-lines?ResultLimit=${resultLimit}`,
  method: 'GET'
})

export const getOpenRequestDetails = () => ({
  url: `/modules/dashboard/open-requests`,
  method: 'GET'
})
