import { AnyAction, Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import { getTotals } from 'app/workorder/state/workorder/selectors'
import { WorkorderTotalsState, defaultState } from 'app/workorder/state/workorder/reducers/WorkorderTotalsState'

import * as types from '../../types'

type TotalsReducerBehavior<S> = Record<typeof types.LOAD_ARCHIVED_ORDER_LINES_SUCCESS, Reducer<S, AnyAction>>

const totals = createReducer<WorkorderTotalsState, TotalsReducerBehavior<WorkorderTotalsState>>(defaultState, {
  [types.LOAD_ARCHIVED_ORDER_LINES_SUCCESS]: (_: any, { payload }) => {
    return getTotals(payload.data.Data.Totals)
  }
})

export default totals
