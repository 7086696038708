import React from 'react'
import { DisplayRules, IOrderAccountData } from '@cbgms/api'
import { EmptyUUID } from '@cbgms/base/utils/uuid'
export interface OrderAccountData extends IOrderAccountData {
  UUID: IOrderAccountData['OrderAccount']['UUID']
  hasIFrameEnabled: boolean
  hasCatalogType: boolean
  fullName: string
}

export interface OrderAccountsContextValue {
  hasBeenProvided: boolean // we want to be sure that the context was provided, the default is effectively unusable
  defaultOrderAccount: OrderAccountData | null
  orderAccountsWholesalerIFrame: OrderAccountData[]
  orderAccountsWholesalerCatalog: OrderAccountData[]
  selectedOrderAccount: OrderAccountData
  setSelectedOrderAccountUUID: (UUID: string) => void
  orderAccountsLoading: boolean
  getOrderAccountInfo: (uuid: string | null) => IOrderAccountData | null
  displayRules?: DisplayRules
}

export const OrderAccountsContext = React.createContext<OrderAccountsContextValue>({
  hasBeenProvided: false,
  defaultOrderAccount: null,
  orderAccountsWholesalerIFrame: [],
  orderAccountsWholesalerCatalog: [],
  selectedOrderAccount: {} as OrderAccountData,
  setSelectedOrderAccountUUID: () => EmptyUUID,
  orderAccountsLoading: false,
  getOrderAccountInfo: () => null
})
