import MUIDialog, { DialogProps } from '@mui/material/Dialog'

export interface DefaultDialogProps extends DialogProps {
  enableBackdropClick?: boolean
  onClose?: () => void
}

export function Dialog({ onClose, enableBackdropClick, ...props }: DefaultDialogProps) {
  return (
    <MUIDialog
      {...props}
      onClose={(_, reason) => {
        if (onClose && (reason !== 'backdropClick' || enableBackdropClick)) {
          onClose()
        }
      }}
    />
  )
}
