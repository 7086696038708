import { useMemo } from 'react'
import { AnyAction, Store } from 'redux'
import { Persistor } from 'redux-persist/lib/types'
import { IRootReducerState } from 'state/rootReducer'
import { configureStore } from 'state/store'
import { useReduxRefsContext } from './ReduxRefsContext'
import { SelectVehicleFunc } from './vehicles/components/SelectVehicleType/types.d'

interface StoreBuildResult {
  store: Store<IRootReducerState, AnyAction> & { dispatch: unknown }
  persistor: Persistor
}

const setupStore = (getShowVehicleSelectionFunc: () => SelectVehicleFunc): StoreBuildResult => {
  const options = {
    injection: {
      getShowVehicleSelectionFunc
    },
    devTools: (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'],
    shouldPersist: true
  }
  return configureStore(options, undefined)
}

// store should be a singleton, but we still need to inject a bunch of stuff from react
let storeSingleton = setupStore((() => () => false) as any)

const useReduxStore = () => {
  const { getShowVehicleSelectionFunc } = useReduxRefsContext()

  return useMemo(() => {
    storeSingleton = setupStore(getShowVehicleSelectionFunc)
    return storeSingleton
  }, [getShowVehicleSelectionFunc])
}

export default useReduxStore
