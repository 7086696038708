import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router-dom'
import history from 'utils/history'
import i18next from 'utils/i18n'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import { XDomainContextProvider } from 'contexts/xDomainContext'
import AppThemeProvider from 'contexts/ThemeContext/ThemeContext'
import { EnvironmentContextProvider } from 'contexts/EnvironmentContext'
import { useSetFavicon } from './useSetFavicon'
import ReduxApiSetup from './ApiSetup/ReduxApiSetup'
import { Loader, LocalizationProvider } from '@carsys/ui'
import GmsFeaturesContextProvider from 'contexts/Features/GmsFeaturesContextProvider'
import ReduxWrapper from './ReduxWrapper'
import { usePageTitle } from 'hooks/use-page-title'
import { Box } from '@mui/material'
import { YupI18nProvider } from '@carsys/form/src/YupI18n'
import { LogContextProvider } from 'contexts/Logging/LogContext'
import SnackBarProvider from 'app/notifications/SnackBarProvider'
import { GridV1ContextProvider } from 'contexts/GridV1Context/GridV1Context'
import { LocalHistoryContextProvider } from '../contexts/LocalHistoryContext'
import { QueryClientProvider } from 'contexts/QueryClientProvider/QueryClientProvider'
import { NavigationPromptProvider } from '@cbgms/base/navigation-prompt/NavigationPromptProvider'

const App = () => {
  useSetFavicon()
  usePageTitle()
  // DndProvider is top level because if it re-renders
  // it can create another HTML5Backend which causes the application
  // to crash.
  return (
    <DndProvider backend={HTML5Backend}>
      <I18nextProvider i18n={i18next}>
        <XDomainContextProvider>
          <EnvironmentContextProvider>
            <ReduxWrapper>
              <ReduxApiSetup>
                <AppThemeProvider>
                  <LogContextProvider>
                    <GridV1ContextProvider>
                      <QueryClientProvider>
                        <GmsFeaturesContextProvider>
                          <React.Suspense
                            fallback={
                              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center' }}>
                                <Loader loading size='5rem' />
                              </Box>
                            }
                          >
                            <Router history={history}>
                              <LocalHistoryContextProvider>
                                <SnackBarProvider>
                                  <YupI18nProvider>
                                    <LocalizationProvider>
                                      <NavigationPromptProvider>
                                        <ApplicationRoutes />
                                      </NavigationPromptProvider>
                                    </LocalizationProvider>
                                  </YupI18nProvider>
                                </SnackBarProvider>
                              </LocalHistoryContextProvider>
                            </Router>
                          </React.Suspense>
                        </GmsFeaturesContextProvider>
                      </QueryClientProvider>
                    </GridV1ContextProvider>
                  </LogContextProvider>
                </AppThemeProvider>
              </ReduxApiSetup>
            </ReduxWrapper>
          </EnvironmentContextProvider>
        </XDomainContextProvider>
      </I18nextProvider>
    </DndProvider>
  )
}

export default App
