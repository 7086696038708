import { MockFn } from '../../../mock'
import { IDistributor, IDistributorResponseData, ILicense, IVoltCustomer, IVoltLicenseData } from './index'
import { LicenseType } from '@carsys/enums/license-type'
import { faker } from '@faker-js/faker/locale/en'

export const mockCreateDistributor: MockFn<Partial<IDistributor>> = (extend?) => ({
  UUID: 'fake-uuid',
  Name: 'HaynesPro',
  LicenseType: LicenseType.HaynesPro,
  ...extend
})

export const mockGetDistributors: MockFn<IDistributorResponseData> = () => ({
  Distributor: mockDistributor()
})

export const mockDistributor: MockFn<IDistributor> = (extend?) => ({
  CreatedAt: '2020-02-05T16:18:25.85314+00:00',
  LicenseType: LicenseType.HaynesPro,
  ModifiedByType: 'support',
  ModifiedByUUID: '40768507-c639-4db0-ace5-0073b4ced540',
  Name: 'Sator NL Licentie',
  UUID: '692ace9f-13af-c2e1-d878-0ec3bb962d85',
  UpdatedAt: '2020-02-20T10:37:18.787255+00:00',
  ...extend
})

export const mockGetLicense: MockFn<ILicense> = () => ({
  UUID: 'fake-license',
  CompanyUUID: 'fake-company',
  ShopID: 'XX000000',
  DistributorUUID: '692ace9f-13af-c2e1-d878-0ec3bb962d85',
  HaynesProLicenseSettings: { Username: 'username', Password: 'password' },
  LicenseExtension: {},
  ValidFrom: '2020-01-01T23:00:00Z',
  ValidTill: '2020-05-01T22:00:00Z',
  CreatedAt: '2020-02-05T16:18:25.85314+00:00',
  UpdatedAt: '2020-02-20T10:37:18.787255+00:00',
  ModifiedByType: '',
  ModifiedByUUID: ''
})

export const mockVoltCustomer: MockFn<IVoltCustomer> = (extend?) => ({
  Address1: faker.address.streetAddress(),
  Address2: faker.address.streetAddress(),
  City: faker.address.city(),
  ClientID: faker.datatype.number({ min: 10000, max: 99999 }).toString(),
  CompanyName: faker.random.words(),
  Contact: faker.random.words(),
  ContactEmail: faker.internet.email(),
  CountryCode: faker.address.countryCode(),
  CustomerID: faker.random.alphaNumeric(10),
  DistributorID: faker.datatype.number(100000),
  Email: faker.internet.email(),
  Languages: [faker.locale],
  Password: faker.random.alphaNumeric(16),
  ProductTypes: [],
  Telephone: faker.phone.number(),
  Username: faker.internet.userName(),
  Zipcode: faker.address.zipCode(),
  ...extend
})

export const mockVoltLicense: MockFn<IVoltLicenseData> = (extend?) => ({
  BeginDate: '',
  Customer: mockVoltCustomer(),
  CompanyUUID: faker.datatype.uuid(),
  CompanyDetails: faker.random.words(10),
  IsCompanyLicense: faker.datatype.boolean(),
  ShopDetails: faker.random.words(10),
  CustomerID: faker.random.alphaNumeric(10),
  EndDate: '',
  LicenseID: faker.random.alphaNumeric(10),
  LicenseUUID: faker.datatype.uuid(),
  ProductType: {
    Code: faker.random.alphaNumeric(5),
    ID: faker.random.alphaNumeric(10),
    Name: faker.random.word()
  },
  ShopID: '',
  Subjects: [],
  ...extend
})
