import { IReplacementVehicle, IReplacementVehicleWithRelated } from '@cbgms/api/modules/replacement-vehicles'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { EmptyUUID } from '@cbgms/base/utils/uuid'

const defaultReplacementVehicleState: IReplacementVehicle = {
  VehicleUUID: EmptyUUID,
  Location: '',
  PricePerDayCourtesy: 0,
  FreeDistancePerDayCourtesy: 0,
  DistanceUnitPriceCourtesy: 0,
  DeductibleCourtesy: 0,
  RevenueGroupUUIDCourtesy: EmptyUUID,
  PricePerDayRental: 0,
  FreeDistancePerDayRental: 0,
  DistanceUnitPriceRental: 0,
  DeductibleRental: 0,
  RevenueGroupUUIDRental: EmptyUUID,
  VehicleClassUUID: EmptyUUID,
  IsCourtesy: false,
  IsRental: false
}

const defaultReplacementVehicleWithRelatedState: IReplacementVehicleWithRelated = {
  ReplacementVehicle: defaultReplacementVehicleState
}

const replacementVehicleWithRelated = createReducer(defaultReplacementVehicleWithRelatedState, {
  [types.GET_REPLACEMENT_VEHICLE_WITH_RELATED_SUCCESS]: (_, { payload }) => {
    return (payload && payload.data && payload.data.Data && payload.data.Data) || defaultReplacementVehicleWithRelatedState
  },
  [types.GET_REPLACEMENT_VEHICLE_WITH_RELATED_FAILED]: () => {
    return defaultReplacementVehicleWithRelatedState
  }
})

export default replacementVehicleWithRelated
