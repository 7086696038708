import { Reducer } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'

interface IEmailHistoryReducerState {
  detail: any
}

export type EmailHistoryReducerState = Readonly<IEmailHistoryReducerState>

const defaultState: EmailHistoryReducerState = {
  detail: {}
}

type EmailHistoryReducerBehavior<S> = Record<typeof types.LOAD_EMAIL_HISTORY_ITEM_SUCCESS, Reducer<S, any>>

const emailHistory = createReducer<EmailHistoryReducerState, EmailHistoryReducerBehavior<EmailHistoryReducerState>>(defaultState, {
  [types.LOAD_EMAIL_HISTORY_ITEM_SUCCESS]: (_, { payload }) => {
    return {
      detail: payload.data.Data
    }
  }
})

export default emailHistory
