import { AccordionSummaryProps, default as MuiAccordionSummary, accordionSummaryClasses } from '@mui/material/AccordionSummary'

interface Props extends AccordionSummaryProps {}

export function AccordionSummary({ children, ...props }: Props) {
  return (
    <MuiAccordionSummary sx={{ [`&.${accordionSummaryClasses.expanded}`]: { borderBottom: 1, borderColor: 'divider' } }} {...props}>
      {children}
    </MuiAccordionSummary>
  )
}
