export const LOAD_WORKORDERACTIVITIES_LIST = 'LOAD_WORKORDERACTIVITIES_LIST'
export const LOAD_WORKORDERACTIVITIES_LIST_SUCCESS = 'LOAD_WORKORDERACTIVITIES_LIST_SUCCESS'
export const LOAD_WORKORDERACTIVITIES_LIST_FAILED = 'LOAD_WORKORDERACTIVITIES_LIST_FAILED'

export const LOAD_WORKORDERACTIVITY = 'LOAD_WORKORDERACTIVITY'
export const LOAD_WORKORDERACTIVITY_SUCCESS = 'LOAD_WORKORDERACTIVITY_SUCCESS'
export const LOAD_WORKORDERACTIVITY_FAILED = 'LOAD_WORKORDERACTIVITY_FAILED'

export const RESET_WORKORDERACTIVITY = 'RESET_WORKORDERACTIVITY'

export const UPDATE_WORKORDERACTIVITY = 'UPDATE_WORKORDERACTIVITY'
export const UPDATE_WORKORDERACTIVITY_SUCCESS = 'UPDATE_WORKORDERACTIVITY_SUCCESS'
export const UPDATE_WORKORDERACTIVITY_FAILED = 'UPDATE_WORKORDERACTIVITY_FAILED'

export const REMOVE_WORKORDERACTIVITY = 'REMOVE_WORKORDERACTIVITY'
export const REMOVE_WORKORDERACTIVITY_SUCCESS = 'REMOVE_WORKORDERACTIVITY_SUCCESS'
export const REMOVE_WORKORDERACTIVITY_FAILED = 'REMOVE_WORKORDERACTIVITY_FAILED'

export const CREATE_WORKORDERACTIVITY = 'CREATE_WORKORDERACTIVITY'
export const CREATE_WORKORDERACTIVITY_SUCCESS = 'CREATE_WORKORDERACTIVITY_SUCCESS'
export const CREATE_WORKORDERACTIVITY_FAILED = 'CREATE_WORKORDERACTIVITY_FAILED'
