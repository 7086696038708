export const LOAD_REPLACEMENT_VEHICLES = 'LOAD_REPLACEMENT_VEHICLES'
export const LOAD_REPLACEMENT_VEHICLES_SUCCESS = 'LOAD_REPLACEMENT_VEHICLES_SUCCESS'
export const LOAD_REPLACEMENT_VEHICLES_FAILED = 'LOAD_REPLACEMENT_VEHICLES_FAILED'

export const GET_REPLACEMENT_VEHICLE = 'GET_REPLACEMENT_VEHICLE'
export const GET_REPLACEMENT_VEHICLE_SUCCESS = 'GET_REPLACEMENT_VEHICLE_SUCCESS'
export const GET_REPLACEMENT_VEHICLE_FAILED = 'GET_REPLACEMENT_VEHICLE_FAILED'

export const GET_REPLACEMENT_VEHICLE_WITH_RELATED = 'GET_REPLACEMENT_VEHICLE_WITH_RELATED'
export const GET_REPLACEMENT_VEHICLE_WITH_RELATED_SUCCESS = 'GET_REPLACEMENT_VEHICLE_WITH_RELATED_SUCCESS'
export const GET_REPLACEMENT_VEHICLE_WITH_RELATED_FAILED = 'GET_REPLACEMENT_VEHICLE_WITH_RELATED_FAILED'

export const PUT_REPLACEMENT_VEHICLE = 'PUT_REPLACEMENT_VEHICLE'
export const PUT_REPLACEMENT_VEHICLE_SUCCESS = 'PUT_REPLACEMENT_VEHICLE_SUCCESS'
export const PUT_REPLACEMENT_VEHICLE_FAILED = 'PUT_REPLACEMENT_VEHICLE_FAILED'

export const DELETE_REPLACEMENT_VEHICLES = 'DELETE_REPLACEMENT_VEHICLES'
export const DELETE_REPLACEMENT_VEHICLES_SUCCESS = 'DELETE_REPLACEMENT_VEHICLES_SUCCESS'
export const DELETE_REPLACEMENT_VEHICLES_FAILED = 'DELETE_REPLACEMENT_VEHICLES_FAILED'

export const GET_REPLACEMENT_VEHICLE_SETTINGS = 'GET_REPLACEMENT_VEHICLE_SETTINGS'
export const GET_REPLACEMENT_VEHICLE_SETTINGS_SUCCESS = 'GET_REPLACEMENT_VEHICLE_SETTINGS_SUCCESS'
export const GET_REPLACEMENT_VEHICLE_SETTINGS_FAILED = 'GET_REPLACEMENT_VEHICLE_SETTINGS_FAILED'

export const UPDATE_REPLACEMENT_VEHICLE_SETTINGS = 'UPDATE_REPLACEMENT_VEHICLE_SETTINGS'
export const UPDATE_REPLACEMENT_VEHICLE_SETTINGS_SUCCESS = 'UPDATE_REPLACEMENT_VEHICLE_SETTINGS_SUCCESS'
export const UPDATE_REPLACEMENT_VEHICLE_SETTINGS_FAILED = 'UPDATE_REPLACEMENT_VEHICLE_SETTINGS_FAILED'
