import { IFetchResponse, IRequest } from './index'

export class FetchError extends Error {
  code: number
  request: IRequest
  response: IFetchResponse

  constructor(request: IRequest, response: IFetchResponse) {
    super(`${request.method} ${response.url} ${response.status} (${response.statusText})`)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError)
    }

    this.code = response.status
    this.request = request
    this.response = response
  }
}

export default FetchError
