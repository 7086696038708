import { AnyAction, Reducer, applyMiddleware, compose, createStore } from 'redux'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { DeepPartial } from 'utility-types'
import { IRootReducerState, reducers } from './rootReducer'
import httpMiddleware from 'middlewares/http'
import { FetchAdapter } from '@cbgms/base/http'
import { buildHandle } from 'middlewares/errorHandler'
import { LOGOUT } from 'app/auth/state/types'
import forEach from 'lodash/forEach'
import isEqual from 'lodash/isEqual'
import { APIConfig } from 'utils/apiConfig'
import rootSaga from 'state/rootSaga'
import { challengeMiddleware } from 'middlewares/challenge'
import sentryMiddleware from 'middlewares/sentry'
import { Timezone } from '@carsys/enums/timezone'
import { TimeNotation } from '@carsys/enums/time-notation'
import { setTimeNotation, setTimezone } from '@cbgms/base/utils/datetime'
import { IInjection } from './types'

const rootReducer = (reducer: Reducer<IRootReducerState, AnyAction>) => (state: IRootReducerState | undefined, action: AnyAction) => {
  const nextState =
    action.type === LOGOUT && state && state.auth
      ? ({
          auth: { _persist: { ...state.auth._persist }, urls: { ...state.auth.urls } },
          menu: { ...state.menu },
          environment: { ...state.environment }
        } as any)
      : state

  return reducer(nextState, action)
}

export const finalReducer = rootReducer(reducers)

export interface IApiUrlMap {
  [api: string]: string
}

interface IFetchAdapterDictionary {
  [api: string]: FetchAdapter
}

const getFetchAdapters = (): IFetchAdapterDictionary => {
  const adapters: IFetchAdapterDictionary = {}
  forEach(APIConfig, (_, key) => {
    adapters[key] = new FetchAdapter()
  })

  return adapters
}

export const configureStore = (
  options: { injection: IInjection; devTools?: any; shouldPersist?: any },
  preloadedState?: DeepPartial<IRootReducerState>
) => {
  const fetchAdapters = getFetchAdapters()
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [httpMiddleware(fetchAdapters, buildHandle(options.injection)), sentryMiddleware, challengeMiddleware, sagaMiddleware]

  const enhancers =
    __PRODUCTION__ || !options.devTools ? applyMiddleware(...middlewares) : compose(applyMiddleware(...middlewares), options.devTools())

  const store = createStore(finalReducer, preloadedState as IRootReducerState, enhancers)

  const createSubscription = () => {
    let locale: any
    let authentication: any
    let timezone: Timezone
    let timeNotation: TimeNotation

    const subscription = () => {
      const {
        auth,
        auth: { localisation }
      } = store.getState()

      if (!isEqual(authentication, auth)) {
        Object.keys(fetchAdapters).forEach(api => {
          fetchAdapters[api].setHeader('X-Current-Shop-ID', auth.currentShop)
          fetchAdapters[api].setHeader('Authorization', `${auth.tokens.TokenType} ${auth.tokens.IdToken}`)

          const urls = auth.urls as IApiUrlMap
          fetchAdapters[api].setBaseUrl(urls[api])
        })

        authentication = auth
      }

      if (localisation.Locale && localisation.Locale !== locale) {
        locale = localisation.Locale
      }

      // This is for resetting the value when the user logs out
      if (!localisation.Locale && locale) {
        locale = undefined
      }

      if (!!localisation.Timezone && localisation.Timezone !== timezone) {
        timezone = localisation.Timezone
        setTimezone(localisation.Timezone)
      }

      if (!!localisation.TimeNotation && localisation.TimeNotation !== timeNotation) {
        timeNotation = localisation.TimeNotation
        setTimeNotation(localisation.TimeNotation)
      }
    }

    return subscription
  }

  store.subscribe(createSubscription())

  let persistor = createFakePersistor()
  if (options.shouldPersist) {
    persistor = persistStore(store)
  }
  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}

const createFakePersistor = () => {
  return {
    pause: () => {},
    persist: () => {},
    purge: async () => {},
    flush: async () => {},
    dispatch: (_: any) => ({} as any),
    getState: () => ({} as any),
    subscribe: (_: any) => () => ({} as any)
  }
}
