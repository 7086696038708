import * as types from 'app/auth/state/types'

interface Challenge {
  readonly [key: string]: any
}

type State = Challenge | null

const defaultState: State = null

interface ClearChallengesAction {
  type: typeof types.CLEAR_CHALLENGES
}

interface NewPasswordRequireAction {
  type: typeof types.NEW_PASSWORD_REQUIRED
  payload: Challenge
}

type Actions = ClearChallengesAction | NewPasswordRequireAction

function challengeReducer(state: State = defaultState, action: Actions): State {
  switch (action.type) {
    case types.NEW_PASSWORD_REQUIRED:
      return action.payload
    case types.CLEAR_CHALLENGES:
      return defaultState
    default:
      return state
  }
}

export default challengeReducer
