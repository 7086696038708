/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button'
import MuiTooltip from '@mui/material/Tooltip'
import { useAbstractEventLogger } from '@cbgms/base/context/LogContext'

export interface OptionalTooltipProps {
  tooltip?: React.ReactElement | string /* optionally provide tooltip content. When provided it will wrap the button in a tooltip */
}
const OptionalTooltip: React.FC<OptionalTooltipProps> = ({ children, tooltip }) => {
  if (!tooltip) return <>{children}</>
  return (
    <MuiTooltip title={tooltip}>
      <span>{children}</span>
    </MuiTooltip>
  )
}

export interface ButtonProps extends MUIButtonProps, OptionalTooltipProps {}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ tooltip, children, onClick, ...props }, ref) => {
  const log = useAbstractEventLogger()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    log.clickEvent(e, props)
    onClick && onClick(e)
  }

  return (
    <OptionalTooltip tooltip={tooltip}>
      <MUIButton ref={ref} onClick={handleClick} {...props}>
        {children}
      </MUIButton>
    </OptionalTooltip>
  )
})

export default Button
