import { MockFn, referenceDate } from '../../mock'
import {
  GETWorkorderItemSearch,
  ISignal,
  IStatusTotal,
  IWorkorder,
  IWorkorderLine,
  IWorkorderLines,
  IWorkorderResponse,
  IWorkorderTotals,
  PublicWorkorderLine,
  SignalType,
  WholesalerIframeCart,
  WorkorderLineState
} from './'
import {
  OrderDeliveryStatus,
  OrderStatus,
  OrderType,
  ReplacementVehicleBookingStatus,
  VatType,
  WorkorderLineGroup,
  WorkorderLineStatus,
  WorkorderLineType,
  WorkorderStatus,
  WorkorderType
} from '../enums'
import { faker } from '@faker-js/faker/locale/en'
import { mockAccount } from '../accounts/mocks'
import { mockVehicle } from '../vehicles/mocks'
import { RecentlyModifiedWorkorderLine } from '../dashboard'
import { EmptyUUID } from '@cbgms/base/utils/uuid'
import { WorkorderInvoiceType } from '../enums/workorder-invoice-type'
import { mockWholesalerGMS } from '../wholesaler/mocks'
import { IExpenseOf } from '../expense-of'
import { IDepartment } from '../departments'
import { DepartmentType } from '../enums/department'
import { WorkorderInvoice } from 'modules/workorder-invoices'

export const mockWorkorder: MockFn<IWorkorder> = (extend?) => ({
  AccountUUID: '48234870-5389-445f-8b41-c61a52bf27dc', // just some random test account UUID
  InvoiceAccountUUID: faker.datatype.uuid(),
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  ID: faker.random.numeric(8),
  InternalRemark: faker.lorem.sentence(),
  InvoiceRemark: faker.lorem.sentence(),
  UseLeaseFlow: false,
  Notes: faker.lorem.sentence(),
  PreviousInternalRemark: faker.lorem.sentence(),
  PreviousInvoiceRemark: faker.lorem.sentence(),
  ROBAccountUUID: faker.datatype.uuid(),
  ShopID: faker.random.alphaNumeric(8),
  Status: WorkorderStatus.Workorder,
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  UUID: faker.datatype.uuid(),
  VehicleUUID: faker.datatype.uuid(),
  ProposalUUID: faker.datatype.uuid(),
  CreatedByUUID: faker.datatype.uuid(),
  CreatedByName: faker.datatype.uuid(),
  WorkorderType: WorkorderType.WorkShop,
  DefaultInvoiceType: WorkorderInvoiceType.Standard,
  DefaultDepartmentUUID: EmptyUUID,
  DefaultExpenseOfUUID: EmptyUUID,
  DefaultIsClaim: false,
  ...extend
})
export const mockResult = (): GETWorkorderItemSearch => ({
  Data: {
    OrderAccounts: [mockWholesalerGMS(), mockWholesalerGMS(), mockWholesalerGMS()],
    Results: [
      {
        LineType: WorkorderLineType.LabourRate,
        LineTypeUUID: '8b2a80cb-621f-51bd-c3ae-2d600b7a7d22',
        Code: 'WPL',
        Description: 'Werkplaatstarief',
        Brand: '',
        SalesPrice: 10,
        LocalStock: 1000,
        Quantity: 1,
        VatType: VatType.High,
        Divisible: true,
        SalesPercentage: 0,
        CostPrice: 10
      },
      {
        LineType: WorkorderLineType.WarehousePart,
        LineTypeUUID: '8fedba4e-621f-52cd-ffff-1234567890ab',
        Code: '123456',
        Description: 'OIL',
        Brand: 'Oil filter',
        SalesPrice: 15,
        LocalStock: 50,
        Quantity: 1,
        VatType: VatType.High,
        Divisible: false,
        SalesPercentage: 0,
        CostPrice: 10
      },
      {
        LineType: WorkorderLineType.Activity,
        LineTypeUUID: '8fedba4e-621f-52cd-fff1-1234567890ab',
        Code: 'APK',
        Description: 'APK beurt',
        Brand: 'RDW',
        SalesPrice: 15,
        LocalStock: 50,
        Quantity: 1,
        VatType: VatType.High,
        Divisible: false,
        SalesPercentage: 0,
        CostPrice: 10
      },
      {
        LineType: WorkorderLineType.Sundries,
        LineTypeUUID: '8fedba4e-621f-52cd-fff2-1234567890ab',
        Code: 'SUN',
        Description: 'Sundries',
        Brand: '',
        SalesPrice: 15,
        LocalStock: 50,
        Quantity: 1,
        VatType: VatType.High,
        Divisible: false,
        SalesPercentage: 0,
        CostPrice: 10
      }
    ]
  }
})

export const emptyWorkorder: MockFn<IWorkorder> = (extend?) => ({
  AccountUUID: '',
  InvoiceAccountUUID: '',
  CreatedAt: '',
  ID: '',
  InternalRemark: '',
  InvoiceRemark: '',
  UseLeaseFlow: false,
  Notes: '',
  PreviousInternalRemark: '',
  PreviousInvoiceRemark: '',
  ROBAccountUUID: '',
  ShopID: '',
  Status: WorkorderStatus.Workorder,
  UpdatedAt: '',
  UUID: '',
  VehicleUUID: '',
  ProposalUUID: '',
  CreatedByUUID: '',
  CreatedByName: '',
  WorkorderType: WorkorderType.WorkShop,
  DefaultInvoiceType: WorkorderInvoiceType.Standard,
  DefaultDepartmentUUID: '',
  DefaultExpenseOfUUID: '',
  DefaultIsClaim: false,
  ...extend
})

export const mockWorkorderLines: MockFn<IWorkorderLines> = (extend?) => ({
  WorkorderUUID: faker.datatype.uuid(),
  Lines: [mockWorkorderLine()],
  Totals: mockWorkorderTotals(),
  ...extend
})

export const mockWorkorderLine: MockFn<IWorkorderLine> = (extend?) => ({
  UUID: faker.datatype.uuid(),
  WorkorderUUID: faker.datatype.uuid(),
  ParentLineUUID: faker.datatype.uuid(),
  VatType: VatType.High,
  CatalogGroupNumbers: {
    HBaseArticleCodes: [],
    MDMComponentCodes: [],
    MDMComponentNumbers: [],
    TecdocGenarts: []
  },
  Quantity: faker.datatype.number(),
  Discount: faker.datatype.number(),
  DiscountIsAmount: faker.datatype.boolean(),
  UseSalesPriceCustom: faker.datatype.boolean(),
  SalesPriceCustom: faker.datatype.number(),
  CostPriceCustom: faker.datatype.number(),
  UseCodeCustom: faker.datatype.boolean(),
  CodeCustom: faker.random.alphaNumeric(10),
  UseDescriptionCustom: faker.datatype.boolean(),
  DescriptionCustom: faker.lorem.sentence(),
  SortOrder: faker.datatype.number(),
  LineStatus: WorkorderLineStatus.Approved,
  LineType: WorkorderLineType.Activity,
  LineTypeUUID: faker.datatype.uuid(),
  SupplierAccountUUID: '', // empty for all lines excluding custom line (WorkorderLineType.Custom)
  OrderAccountUUID: '4abdbe4f-2183-4ba2-8b9e-81a8d0c1808f', // Fictional Random Order Account UUID S1
  SupplierProductID: faker.random.alphaNumeric(10),
  SupplierProductGroupID: faker.random.alphaNumeric(10),
  ForeignType: '' as any,
  ForeignID: '',
  GenericArticleID: '',
  GenericArticleDescription: '',
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  LineCode: faker.random.alphaNumeric(3),
  LineDescription: faker.lorem.sentence(),
  LineUnitPrice: faker.datatype.number(),
  LineUnitPriceIncludingDiscount: faker.datatype.number(20),
  LineUnitDiscountAmount: faker.datatype.number(),
  LineUnitDiscountAmountVatIncl: faker.datatype.number(),
  LineUnitPriceVatIncl: faker.datatype.number(),
  LinePriceVatExcl: faker.datatype.number(),
  LinePriceVatIncl: faker.datatype.number(),
  TechnicalDataDuration: faker.datatype.number(),
  LinePriceWithoutDiscountVatExcl: faker.datatype.number(),
  LinePriceWithoutDiscountVatIncl: faker.datatype.number(),
  LineDiscountAmount: faker.datatype.number(),
  LineDiscountPercentage: faker.datatype.number(),
  LineQuantityDivisible: faker.datatype.boolean(),
  OrderStatus: OrderStatus.NA,
  OrderType: OrderType.NA,
  OrderedQuantity: faker.datatype.number(),
  OrderDeliveryStatus: OrderDeliveryStatus.NA,
  OrderDeliveredQuantity: 0,
  IsPlaceholder: false,
  TyreMutationUUID: EmptyUUID,
  ReplacementVehicleBookingStatus: ReplacementVehicleBookingStatus.CheckedOut,
  LineCostPrice: faker.datatype.number(),
  IgnoredInTotals: false,
  WarehouseStock: faker.datatype.number(),
  InvoiceType: WorkorderInvoiceType.Standard,
  DepartmentUUID: faker.datatype.uuid(),
  ExpenseOfUUID: faker.datatype.uuid(),
  IsClaim: false,
  IsService: faker.datatype.boolean(),
  HasOrderForm: false,
  ErrorCode: '',
  HasSummedParent: false,
  ...extend
})

export const mockDepartments: MockFn<IDepartment[]> = (extend?) => [
  {
    CreatedAt: '2022-06-21T10:14:00.494294+00:00',
    Module: DepartmentType.VehicleSales,
    Name: 'In- en verkoop',
    ShopID: 'NL999999',
    UUID: 'e948845d-d4ac-b87b-d489-52506916a927',
    UpdatedAt: '2022-06-21T10:14:00.494294+00:00',
    ...extend
  }
]

export const mockExpenseOf: MockFn<IExpenseOf[]> = (extend?) => [
  {
    UUID: '8c06a32e-8188-4f1d-96cc-bc81d614bab0',
    Name: 'Expense of vehicle sales',
    DepartmentUUID: 'e948845d-d4ac-b87b-d489-52506916a927',
    Type: WorkorderInvoiceType.Warranty,
    Ledgers: [
      {
        AdministrationUUID: '97bb6646-09b7-11ed-861d-0242ac120002',
        Code: '1234',
        Description: 'Description',
        IsRequired: true
      }
    ],
    ...extend
  }
]

export const mockStaticWorkorderLineState: MockFn<WorkorderLineState> = (extend?) => ({
  CodeCustom: 'pl2e70cxo7',
  CreatedAt: '1926-01-20T11:46:17.894Z',
  DescriptionCustom: 'Illum tempora expedita sit tempore animi iusto.',
  Discount: 18392,
  DiscountIsAmount: false,
  ForeignID: '',
  ForeignType: '' as any,
  GenericArticleDescription: '',
  GenericArticleID: '',
  CatalogGroupNumbers: {
    HBaseArticleCodes: [],
    MDMComponentCodes: [],
    MDMComponentNumbers: [],
    TecdocGenarts: []
  },
  LineCode: 'kks',
  LineDescription: 'Eius laudantium perferendis.',
  LineDiscountAmount: 7090,
  LineDiscountPercentage: 9419,
  LinePriceVatExcl: 23620,
  LinePriceVatIncl: 29629,
  LinePriceWithoutDiscountVatExcl: 53533,
  LinePriceWithoutDiscountVatIncl: 29627,
  LineStatus: WorkorderLineStatus.Approved,
  LineType: WorkorderLineType.Activity,
  LineTypeUUID: 'b5aee902-f18c-4ba5-acb2-90802d168d97',
  LineUnitPrice: 11190,
  LineUnitPriceIncludingDiscount: 11190 - 24214,
  LineUnitDiscountAmount: 24214,
  LineUnitDiscountAmountVatIncl: 5353,
  LineUnitPriceVatIncl: 11314,
  LineQuantityDivisible: true,
  OrderStatus: OrderStatus.NA,
  OrderType: OrderType.NA,
  SupplierAccountUUID: '', // empty for all lines excluding custom line (WorkorderLineType.Custom)
  OrderAccountUUID: '4abdbe4f-2183-4ba2-8b9e-81a8d0c1808f', // Fictional Random Order Account UUID S1
  OrderedQuantity: 71635,
  OrderDeliveryStatus: OrderDeliveryStatus.NA,
  OrderDeliveredQuantity: 0,
  ParentLineUUID: '16a2c3a9-2efb-4d10-8825-01199b0c3f2c',
  Quantity: 29911,
  SalesPriceCustom: 54773,
  CostPriceCustom: 45337,
  SortOrder: 44915,
  SupplierProductGroupID: 'sjddxlmy3f',
  SupplierProductID: '9c89pt4s7w',
  UUID: '8a176b4b-3a72-4af6-9f08-0b1f4a46f735',
  UpdatedAt: '1026-03-03T22:03:47.622Z',
  UseCodeCustom: true,
  UseDescriptionCustom: true,
  UseSalesPriceCustom: true,
  VatType: VatType.High,
  WorkorderUUID: 'fc8814e2-8ed5-4289-b1ae-8b8cd1cef139',
  childLines: [],
  isChildLine: false,
  IsPlaceholder: false,
  TyreMutationUUID: EmptyUUID,
  TechnicalDataDuration: faker.datatype.number(),
  ReplacementVehicleBookingStatus: ReplacementVehicleBookingStatus.CheckedOut,
  LineCostPrice: 2222,
  IgnoredInTotals: false,
  WarehouseStock: 99,
  InvoiceType: WorkorderInvoiceType.Standard,
  DepartmentUUID: faker.datatype.uuid(),
  ExpenseOfUUID: faker.datatype.uuid(),
  IsClaim: false,
  IsService: false,
  HasOrderForm: false,
  ErrorCode: '',
  HasSummedParent: false,
  ...extend
})

export const mockStaticPublicWorkorderLine: MockFn<PublicWorkorderLine> = (extend?: Partial<PublicWorkorderLine>) => ({
  LineCode: 'kks',
  LineDescription: 'Eius laudantium perferendis.',
  LinePriceVatIncl: 29629,
  LineStatus: WorkorderLineStatus.Approved,
  LineType: WorkorderLineType.Activity,
  ParentLineUUID: '16a2c3a9-2efb-4d10-8825-01199b0c3f2c',
  Quantity: 29911,
  IsPlaceholder: false,
  SortOrder: 44915,
  UUID: '8a176b4b-3a72-4af6-9f08-0b1f4a46f735',
  childLines: [],
  isChildLine: false,
  ShowPrice: true,
  ShowQuantity: true,
  ...extend
})

export const mockActiveWarrantyResponse = () => ({
  Data: [
    {
      Warranty: {
        UUID: 'ef705af9-ed0b-edd4-1450-b41a4e1346b6',
        Code: 'Warranty 2',
        Description: 'Description for Warranty 2',
        MonthsFrom: 'ascription_date',
        DurationMonths: 5,
        MileageType: 'mileage_since_purchase',
        Mileage: 2,
        UpdatedAt: '2022-05-05T12:47:45.662385Z',
        CreatedAt: '0001-01-01T00:00:00Z'
      },
      Period: {
        UUID: '62eaf23a-2ecb-dd8d-6fc5-fa9025428cea',
        ShopID: 'NL999999',
        WarrantyUUID: 'ef705af9-ed0b-edd4-1450-b41a4e1346b6',
        VehicleUUID: '903d5d03-f9df-227b-34e8-f9c80b4e3a70',
        AccountUUID: 'c718efb8-a8eb-283b-90ec-d1c328303ed2',
        InventoryVehicleInvoiceUUID: '8741c6de-3a24-74a4-7321-cf9f6228627b',
        StartDate: '0001-01-01',
        EndDate: '0001-01-01',
        StartMileage: 1532,
        EndMileage: 0,
        MileageUnit: 'kilometers',
        IsDeleted: false,
        UpdatedAt: '2022-05-05T12:35:05.344883Z',
        CreatedAt: '2022-05-05T12:35:05.344883Z'
      }
    }
  ]
})

export const mockRecentlyModifiedWorkorderLine: MockFn<RecentlyModifiedWorkorderLine> = (extend?) => ({
  WorkorderID: faker.lorem.word(),
  WorkorderUUID: faker.datatype.uuid(),
  WorkorderLineUUID: faker.datatype.uuid(),
  ModifierName: faker.lorem.word(),
  VehicleRegistration: faker.random.alphaNumeric(10),
  LineStatus: WorkorderLineStatus.Rejected,
  LineCode: faker.random.alphaNumeric(10),
  LineDescription: faker.lorem.sentence(),
  AuditCreatedAt: faker.date.past(2019, referenceDate).toISOString(),
  IsROBLine: false,
  ...extend
})

export const mockSignal: MockFn<ISignal> = (extend?) => ({
  Type: SignalType.Birthday,
  Date: '20-04-1996',
  ...extend
})

export const mockWorkorderWithRelated: MockFn<IWorkorderResponse> = (extend?) => ({
  Account: mockAccount(),
  Vehicle: mockVehicle(),
  Workorder: mockWorkorder(),
  ...extend
})

export const mockStatusTotal: MockFn<IStatusTotal> = extend => ({
  VatExcluded: faker.datatype.number(),
  VatIncluded: faker.datatype.number(),
  VatAmount: faker.datatype.number(),
  MarginAmount: faker.datatype.number(),
  VatTypes: Object.keys(VatType).map(key => ({
    Type: VatType[key as keyof typeof VatType],
    BaseAmount: faker.datatype.number({ max: 100 }),
    VatAmount: faker.datatype.number({ max: 100 }),
    Percentage: faker.datatype.number({ max: 100 }),
    LineGroup: WorkorderLineGroup.Part
  })),
  DiscountAmountVatExcluded: faker.datatype.number(),
  DiscountPercentage: faker.datatype.number(),
  PartsVatExcluded: faker.datatype.number(),
  LabourVatExcluded: faker.datatype.number(),
  OtherVatExcluded: faker.datatype.number(),
  CostPriceVatExcluded: faker.datatype.number(),
  ...extend
})

export const mockWorkorderTotals: MockFn<IWorkorderTotals> = (extend?) => ({
  ...mockStatusTotal(),
  TotalApproved: mockStatusTotal(extend ? extend.TotalApproved : undefined),
  TotalPending: mockStatusTotal(extend ? extend.TotalPending : undefined),
  TotalRejected: mockStatusTotal(extend ? extend.TotalRejected : undefined),
  TotalQuote: mockStatusTotal(extend ? extend.TotalQuote : undefined),
  TotalIncludingRejected: mockStatusTotal(),

  TotalWarranty: mockStatusTotal(),
  TotalInternal: mockStatusTotal(),
  ...extend
})

export const mockWholeSalerIframeCart: MockFn<WholesalerIframeCart> = (extend?) => ({
  SkippedItems: false,
  WorkorderUUID: faker.datatype.uuid(),
  Lines: [mockWorkorderLine()],
  Totals: mockWorkorderTotals(),
  Sundries: {
    UUID: faker.datatype.uuid(),
    Used: faker.datatype.boolean()
  },
  ...extend
})

export const mockSuppliersResponse = () => ({
  Data: [
    {
      Account: {
        CompanyName: 'First company',
        Name: 'First company',
        UUID: '215995f7-7611-0041-e8a4-8dc9e854aa92'
      },
      ExternalMetadata: {
        GmsKey: null
      }
    },
    {
      Account: {
        CompanyName: 'Second company',
        Name: 'Second company',
        UUID: '92dfb9a7-7cda-bdcf-f28f-a6ca51f56755'
      },
      ExternalMetadata: {
        GmsKey: null
      }
    },
    {
      Account: {
        CompanyName: 'Third company',
        Name: 'Third company',
        UUID: '68d785fc-497d-ba99-4c30-9563e1c1e280'
      },
      ExternalMetadata: {
        GmsKey: null
      }
    }
  ],
  Structure: {
    Account: {
      AccountNumber: 'string',
      BankBicNumber: 'string',
      BankIbanNumber: 'string',
      CocNumber: 'string',
      CompanyName: 'string',
      CreatedAt: 'DateTime',
      CreditorNumber: 'string',
      DateOfBirth: 'Date',
      DebtorNumber: 'string',
      Department: 'string',
      EmailAddress: 'EmailAddress',
      FirstName: 'string',
      Initials: 'string',
      InternalRemark: 'string',
      IsSupplier: 'bool',
      IsUseSageCashAccount: 'bool',
      MobileNumber: 'string',
      MobileNumberRemark: 'string',
      Name: 'string',
      NamePrefix: 'string',
      PhoneNumber: 'string',
      PhoneNumberRemark: 'string',
      PhoneNumberSecond: 'string',
      PhoneNumberSecondRemark: 'string',
      PhoneNumberThird: 'string',
      PhoneNumberThirdRemark: 'string',
      PostalAddress: 'string',
      PostalAddressExtra: 'string',
      PostalCity: 'string',
      PostalCountry: 'EnumCountryCode',
      PostalHouseNumber: 'string',
      PostalHouseNumberAddition: 'string',
      PostalPostalCode: 'string',
      PostalState: 'string',
      SystemAccountType: 'EnumSystemAccountType',
      Title: 'string',
      Type: 'EnumAccountType',
      UUID: 'UUID',
      UpdatedAt: 'DateTime',
      VatNumber: 'string',
      VisitorAddress: 'string',
      VisitorAddressExtra: 'string',
      VisitorCity: 'string',
      VisitorCountry: 'EnumCountryCode',
      VisitorHouseNumber: 'string',
      VisitorHouseNumberAddition: 'string',
      VisitorPostalCode: 'string',
      VisitorState: 'string'
    },
    ExternalMetadata: {
      CreatedAt: 'DateTime',
      ExternalKey: 'string',
      GmsKey: 'UUID',
      Source: 'string',
      UpdatedAt: 'DateTime'
    }
  },
  Paging: {
    Params: {
      OrderBy: null,
      Fields: [
        {
          Model: 'Account',
          Field: 'CompanyName'
        },
        {
          Model: 'Account',
          Field: 'Name'
        }
      ]
    },
    Page: 1,
    PerPage: 9999,
    TotalPages: 1,
    Total: 15
  }
})

export const mockInvoiceTypeSuggestionResponse = () => ({
  Data: {
    InvoiceType: 'internal',
    Department: 'vehicle_sales',
    Reason: 'vehicle-in-stock'
  }
})

export const mockCreateOfflineLineResponse = () => ({
  Data: {
    UUID: '2849a8f2-5195-494a-c2ce-078317d7ac55',
    WorkorderUUID: '7d94de6d-164a-9982-a3f6-9205456274cd',
    Lines: [
      {
        UUID: '2849a8f2-5195-494a-c2ce-078317d7ac55',
        WorkorderUUID: '7d94de6d-164a-9982-a3f6-9205456274cd',
        VatType: 'high',
        Quantity: 1,
        Discount: 0,
        DiscountIsAmount: false,
        UseSalesPriceCustom: true,
        SalesPriceCustom: 0,
        UseCostPriceCustom: true,
        CostPriceCustom: 0,
        UseCodeCustom: true,
        CodeCustom: '',
        UseDescriptionCustom: true,
        DescriptionCustom: 'Simple!',
        SupplierProductID: '',
        SupplierProductIDType: '',
        PriceType: 'quantity',
        InvoiceType: 'standard',
        SupplierAccountUUID: '00000000-0000-0000-0000-000000000000',
        OrderAccountUUID: '00000000-0000-0000-0000-000000000000',
        UpdatedAt: '2022-01-28T12:09:00.579849Z',
        LineStatus: 'quote',
        Duration: 0,
        TyreMutationUUID: '00000000-0000-0000-0000-000000000000',
        CustomActions: [],
        ManuallySet: false,
        ParentLineUUID: '00000000-0000-0000-0000-000000000000',
        GenericArticleID: '',
        GenericArticleDescription: '',
        LineType: 'offline_purchase',
        LineTypeUUID: '00000000-0000-0000-0000-000000000000',
        ForeignType: '',
        ForeignID: '',
        IsPlaceholder: false,
        OrderStatus: '',
        OrderedQuantity: 0,
        LineCode: '',
        LineDescription: 'Simple!',
        LineUnitPrice: 0,
        LineUnitPriceVatIncl: 0,
        LinePriceVatExcl: 0,
        LineUnitDiscountAmount: 0,
        LineUnitDiscountAmountVatIncl: 0,
        LineUnitPriceIncludingDiscount: 0,
        LineDiscountAmount: 0,
        LineDiscountPercentage: 0,
        LineQuantityDivisible: false,
        LineCostPrice: 0,
        LinePriceVatIncl: 0,
        LinePriceWithoutDiscountVatExcl: 0,
        LinePriceWithoutDiscountVatIncl: 0,
        ReplacementVehicleBookingStatus: '',
        WarehouseStock: 0,
        OrderDirect: false,
        Reference: '',
        StatusChanges: [
          {
            ChangedAt: '2022-01-28T12:09:00.614892Z',
            ChangedByType: 'employee',
            ChangedByName: 'Timo Test',
            NewStatus: 'quote',
            PreviousStatus: ''
          }
        ],
        FixedPriceDifference: 0,
        IgnoredInTotals: false,
        ExternalPart: null
      }
    ],
    Totals: {
      VatExcluded: 0,
      VatIncluded: 0,
      VatAmount: 0,
      MarginAmount: 0,
      VatTypes: [
        {
          Type: 'high',
          LineGroup: '',
          Percentage: 21,
          BaseAmount: 0,
          VatAmount: 0
        }
      ],
      DiscountAmountVatExcluded: 0,
      DiscountPercentage: 0,
      CostPriceVatExcluded: 0,
      TotalIncludingRejected: {
        VatExcluded: 0,
        VatIncluded: 0,
        VatAmount: 0,
        MarginAmount: 0,
        VatTypes: [
          {
            Type: 'high',
            LineGroup: '',
            Percentage: 21,
            BaseAmount: 0,
            VatAmount: 0
          }
        ],
        DiscountAmountVatExcluded: 0,
        DiscountPercentage: 0,
        CostPriceVatExcluded: 0
      },
      TotalApproved: {
        VatExcluded: 0,
        VatIncluded: 0,
        VatAmount: 0,
        MarginAmount: 0,
        VatTypes: null,
        DiscountAmountVatExcluded: 0,
        DiscountPercentage: 0,
        CostPriceVatExcluded: 0,
        PartsVatExcluded: 0,
        LabourVatExcluded: 0,
        OtherVatExcluded: 0
      },
      TotalPending: {
        VatExcluded: 0,
        VatIncluded: 0,
        VatAmount: 0,
        MarginAmount: 0,
        VatTypes: null,
        DiscountAmountVatExcluded: 0,
        DiscountPercentage: 0,
        CostPriceVatExcluded: 0,
        PartsVatExcluded: 0,
        LabourVatExcluded: 0,
        OtherVatExcluded: 0
      },
      TotalRejected: {
        VatExcluded: 0,
        VatIncluded: 0,
        VatAmount: 0,
        MarginAmount: 0,
        VatTypes: null,
        DiscountAmountVatExcluded: 0,
        DiscountPercentage: 0,
        CostPriceVatExcluded: 0,
        PartsVatExcluded: 0,
        LabourVatExcluded: 0,
        OtherVatExcluded: 0
      },
      TotalQuote: {
        VatExcluded: 0,
        VatIncluded: 0,
        VatAmount: 0,
        MarginAmount: 0,
        VatTypes: [
          {
            Type: 'high',
            LineGroup: '',
            Percentage: 21,
            BaseAmount: 0,
            VatAmount: 0
          }
        ],
        DiscountAmountVatExcluded: 0,
        DiscountPercentage: 0,
        CostPriceVatExcluded: 0,
        PartsVatExcluded: 0,
        LabourVatExcluded: 0,
        OtherVatExcluded: 0
      },
      TotalWarranty: {
        VatExcluded: 0,
        VatIncluded: 0,
        VatAmount: 0,
        MarginAmount: 0,
        VatTypes: null,
        DiscountAmountVatExcluded: 0,
        DiscountPercentage: 0,
        CostPriceVatExcluded: 0,
        PartsVatExcluded: 0,
        LabourVatExcluded: 0,
        OtherVatExcluded: 0
      },
      TotalInternal: {
        VatExcluded: 0,
        VatIncluded: 0,
        VatAmount: 0,
        MarginAmount: 0,
        VatTypes: null,
        DiscountAmountVatExcluded: 0,
        DiscountPercentage: 0,
        CostPriceVatExcluded: 0,
        PartsVatExcluded: 0,
        LabourVatExcluded: 0,
        OtherVatExcluded: 0
      }
    },
    Sundries: [
      {
        UUID: 'b7b60f35-43af-ccef-acd0-7e149cecb77b',
        Used: false
      }
    ]
  }
})

export const mockWorkorderInvoice: MockFn<WorkorderInvoice> = (extend?) => ({
  WorkorderInvoiceUUID: faker.datatype.uuid(),
  InvoiceCreatedAt: new Date(),
  TotalVatIncluded: 1,
  CreditInvoiceUUID: faker.datatype.uuid(),
  IsCredit: true,
  OriginalInvoiceUUID: faker.datatype.uuid(),
  ...mockWorkorder(),
  ...extend
})
