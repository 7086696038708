// This thingy gets the countrycode in the header
export const getXDomainCountry = (domain: string) => {
  // TODO remove the url condition when UK evironment returns the correct x-header
  const url = window.location.host

  const domainArray = domain!.split('.')
  const urlArray = url!.split('.')
  if (domainArray.length > 2 && urlArray.length > 2) {
    const xDomain = domainArray.reverse()[2].toLowerCase()
    const urlRegion = urlArray.reverse()[2].toLowerCase()
    if (xDomain !== urlRegion) {
      return urlRegion
    } else {
      return xDomain
    }
  } else {
    return 'develop'
  }
}
