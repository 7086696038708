import React, { useMemo } from 'react'
import MUIAlert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined'
export * from './Theme/types/mui-theme'
interface Props extends React.ComponentProps<typeof MUIAlert> {
  title?: string
}
export function Alert(props: Props) {
  const additionalProps = useMemo(() => {
    return {
      icon:
        !props.severity && !props.icon && props.color === 'muted' ? ( // when no icon was set and color is muted. apply different icon
          <HelpOutlineOutlined />
        ) : (
          props.icon
        )
    }
  }, [props.variant])
  return (
    <MUIAlert {...props} {...additionalProps}>
      {props.title && <AlertTitle>{props.title}</AlertTitle>}
      {props.children}
    </MUIAlert>
  )
}
Alert.defaultProps = {}
