export enum CognitoChallenge {
  SoftwareTokenMultiFactorAuthentication = 'SOFTWARE_TOKEN_MFA',
  SelectMultiFactorAuthenticationType = 'SELECT_MFA_TYPE',
  MultiFactorAuthenticationSetup = 'MFA_SETUP',
  CustomChallenge = 'CUSTOM_CHALLENGE',
  DeviceSecureRemotePasswordAuth = 'DEVICE_SRP_AUTH',
  DevicePasswordVerifier = 'DEVICE_PASSWORD_VERIFIER',
  AdminNoSecureRemotePasswordAuth = 'ADMIN_NO_SRP_AUTH',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  SMSMultiFactorAuthentication = 'SMS_MFA',
  PasswordVerifier = 'PASSWORD_VERIFIER'
}
