import { PUTVehicleDamage } from './index'

export const getVehicleDamageRequest = (uuid: string) => ({
  method: 'GET',
  url: `/modules/vehicles/${uuid}/damage`
})

export const putVehicleDamageRequest = (uuid: string, data: PUTVehicleDamage) => ({
  method: 'PUT',
  url: `/modules/vehicles/${uuid}/damage`,
  data
})
