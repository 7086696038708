import React from 'react'
import Paper, { PaperProps } from '@mui/material/Paper'
import Draggable from 'react-draggable'

// Helper to make papers draggable,
// For example :<Dialog PaperComponent={DraggablePaperGenerator("yourID")}
// then flag the draggable part somewhere like `<DialogTitle data-draggableID="yourID">`

export const DraggablePaperGenerator = (dataDraggableID: string) => {
  return React.useMemo(
    () => (props: PaperProps) => {
      return (
        <Draggable handle={`[data-draggableid="${dataDraggableID}"]`} cancel={'[class*="MuiDialogContent-root"]'}>
          <Paper {...props} />
        </Draggable>
      )
    },
    [dataDraggableID]
  )
}
