import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { IGetClosingStatementResponse, IGetClosingStatementSettings } from '@cbgms/api'
import { DropdownItemProps } from 'semantic-ui-react'

interface IClosingStatementsReducerState {
  detail: any
  settings: IGetClosingStatementSettings
  allList: DropdownItemProps[]
}

export type ClosingStatementsReducerState = Readonly<IClosingStatementsReducerState>

const defaultState: ClosingStatementsReducerState = {
  detail: {},
  settings: { DefaultInvoice: '', DefaultOnlineApproval: '', DefaultVehicleSalesQuotation: '', DefaultWorkorderQuotation: '' },
  allList: []
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type ClosingStatementsReducerBehavior<S> = Record<typeof types.LOAD_CLOSING_STATEMENT_SUCCESS, Reducer<S, any>> &
  Record<typeof types.LOAD_ALL_CLOSING_STATEMENTS_SUCCESS, Reducer<S, any>> &
  Record<typeof types.LOAD_CLOSING_STATEMENT_SETTINGS_SUCCESS, Reducer<S, any>> &
  Record<typeof types.UPDATE_CLOSING_STATEMENT_SETTINGS_SUCCESS, Reducer<S, any>>

const closingStatements = createReducer<ClosingStatementsReducerState, ClosingStatementsReducerBehavior<ClosingStatementsReducerState>>(
  defaultState,
  {
    [types.UPDATE_CLOSING_STATEMENT_SETTINGS_SUCCESS]: (state, { payload }) => {
      return {
        ...(state || defaultState),
        settings: payload.data.Data
      }
    },
    [types.LOAD_CLOSING_STATEMENT_SUCCESS]: (state, { payload }) => {
      return {
        ...(state || defaultState),
        detail: payload.data.Data
      }
    },
    [types.LOAD_CLOSING_STATEMENT_SETTINGS_SUCCESS]: (state, { payload }) => {
      return {
        ...(state || defaultState),
        settings: payload.data.Data
      }
    },
    [types.LOAD_ALL_CLOSING_STATEMENTS_SUCCESS]: (state, { payload }) => {
      return {
        ...(state || defaultState),
        allList: mapClosingStatementsToOptions(payload.data.Data)
      }
    }
  }
)

export const mapClosingStatementsToOptions = (closingStatements: IGetClosingStatementResponse[]) => {
  return closingStatements.map(item => ({
    key: item.ClosingStatement.UUID,
    value: item.ClosingStatement.UUID,
    text: `${item.ClosingStatement.Title}`
  }))
}

export default closingStatements
