import { Reducer, combineReducers } from 'redux'
import chapters, { ChapterReducerState } from 'app/workorder/state/workorder/repairtimes/chapter'
import topics, { TopicsReducerState } from 'app/workorder/state/workorder/repairtimes/topics'
import nodes, { NodesReducerState } from 'app/workorder/state/workorder/repairtimes/nodes'
import details, { DetailsReducerState } from 'app/workorder/state/workorder/repairtimes/details'

interface IHaynesReducerState {
  chapters: ChapterReducerState
  topics: TopicsReducerState
  nodes: NodesReducerState
  details: DetailsReducerState
}

export type RepairTimesReducerState = Readonly<IHaynesReducerState>

export const repairTimes: Reducer<RepairTimesReducerState> = combineReducers({
  chapters,
  topics,
  nodes,
  details
})

export default repairTimes
