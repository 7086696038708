import React from 'react'
import { XDomainContext } from './xDomainContext'
import { APIConfig } from 'utils/apiConfig'
import { setupThirdPartyScripts } from 'utils/thirdParty'
import { IPreflight, getPreflight } from '@cbgms/api/modules/root'
import { useGetCookieConsentModel } from 'utils/use-cookie-consent-model'
import { Environment } from '@carsys/enums/environment'

interface IEnvironmentContext {
  environment: IPreflight
  resolvedURLs: Record<string, string>
  hasBeenProvided: boolean
}

const emptyContextValue: IEnvironmentContext = {
  environment: { Analytics: '', Environment: Environment.Develop, HotJar: '', Sentry: '', TagManager: '', XDomain: '', FirebaseAppKey: '' },
  resolvedURLs: {},
  hasBeenProvided: false
}

export const EnvironmentContext = React.createContext<IEnvironmentContext>(emptyContextValue)

const resolveURLs = (domain: string): Record<string, string> =>
  Object.entries(APIConfig).reduce((urls, [key, api]) => {
    urls[key] = `https://${api}.${domain}`
    return urls
  }, {} as Record<string, string>)

const getPreflightData = async (apiUrls: Record<string, string>, xDomain: string): Promise<IPreflight | null> => {
  try {
    const { Data } = await getPreflight(apiUrls['gms'])
    return { ...Data, XDomain: xDomain }
  } catch (e) {
    console.error(e)
  }
  return null
}

export const EnvironmentContextProvider: React.FC = ({ children }) => {
  const xDomain = React.useContext(XDomainContext)
  const [environment, setEnvironment] = React.useState<IPreflight | null>(null)
  const [resolvedURLs, setResolvedURLs] = React.useState<Record<string, string> | null>(null)
  const consentModel = useGetCookieConsentModel()

  // Fetch the preflight info from backend
  React.useEffect(() => {
    const asyncFunc = async () => {
      try {
        const urls = resolveURLs(xDomain)
        setResolvedURLs(urls)
        const data = await getPreflightData(urls, xDomain)
        setEnvironment(data)
      } catch (e) {
        console.error(e)
      }
    }
    asyncFunc()
  }, [])

  // Trigger third party scripts whenever the consent model changes
  React.useEffect(() => {
    if (environment) {
      setupThirdPartyScripts(environment, consentModel)
    }
  }, [environment, consentModel])

  const value = React.useMemo(() => ({ environment, resolvedURLs, hasBeenProvided: true }), [environment, resolvedURLs])

  if (!value.environment || !value.resolvedURLs) return null

  return <EnvironmentContext.Provider value={value as IEnvironmentContext}>{children}</EnvironmentContext.Provider>
}

export function useEnvironmentContext() {
  const context = React.useContext(EnvironmentContext)
  if (context === undefined || !context.hasBeenProvided) {
    console.error('useEnvironmentContext must be used within an EnvironmentContext')
  }

  return context as IEnvironmentContext
}
