import propOr from 'lodash/fp/propOr'
import pipe from 'lodash/fp/pipe'
import map from 'lodash/fp/map'
import split from 'lodash/fp/split'
import sortBy from 'lodash/fp/sortBy'
import find from 'lodash/fp/find'
import { DropdownItemProps } from 'semantic-ui-react'
import forEach from 'lodash/forEach'
import { formatDeliveryTime } from '@cbgms/base/utils/datetime'
import { EmptyUUID } from '@cbgms/base/utils/uuid'
import {
  WorkorderLineState as ApiWorkorderLineState,
  ISupplierAddress,
  IWorkorderTotals,
  PublicWorkorderLine
} from '@cbgms/api/modules/workorder'
import { WorkorderTotalsState, defaultState } from 'app/workorder/state/workorder/reducers/WorkorderTotalsState'
import i18next from 'i18next'
import { IProductGroupCategory, PartialProduct } from '@cbgms/api/modules/products'
import { flattenRecursive, formatEnum } from '@cbgms/base/utils/helpers'
import { DeliveryOption } from '@cbgms/api/modules/enums'
import { IRootReducerState } from 'state/rootReducer'
import { OrderLineState } from '@cbgms/api/modules/order/index'
import { IDropdownOption } from '@carsys/ui'
import { IDeliveryOption } from '@cbgms/components/src/order-parts/place-order/DeliveryOptionsList'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { IOrderAccountData } from '@cbgms/api'

export type WorkorderLineState = ApiWorkorderLineState

export const getProductGroupName = (productGroups: IProductGroupCategory[], id: string) => {
  if (productGroups.length) {
    const pgs = find(
      pg => pg.ID === id,
      flattenRecursive(productGroups, pg => pg.Categories)
    )

    return pgs ? pgs.Name.toLowerCase() : ''
  } else {
    return ''
  }
}

export const getProductTitle = (product?: PartialProduct | null) => {
  if (!product) {
    return ''
  }
  // show the commercial name when it's available, otherwise show a bunch of other product information.
  if (product?.CommercialName) {
    return product?.CommercialName
  }
  return !product ? '' : `${product.Brand || ''} ${product.Description || ''} ${product.BaseID || ''}`.trim()
}

const lineDescriptionFallback = '...'

export const getLineDescription = (lines: WorkorderLineState[], uuid: string): string => {
  if (lines && lines.length) {
    const result = findLine(lines, uuid)

    if (result) {
      return result.UseDescriptionCustom ? result.DescriptionCustom : result.LineDescription
    }
  }
  return lineDescriptionFallback
}

export const getLineGenericArticleDescription = (lines: WorkorderLineState[] | OrderLineState[], uuid: string): string => {
  if (lines && lines.length) {
    const result = findLine(lines, uuid)

    if (result) {
      return result.GenericArticleDescription || result.DescriptionCustom
    }
  }
  return lineDescriptionFallback
}

const findLine = (lines: OrderLineState[], uuid: string): WorkorderLineState | OrderLineState | undefined => {
  let result = find(line => line.UUID === uuid, lines)
  if (!result) {
    lines.forEach(line => {
      const subLineResult = line.childLines && find(childLine => childLine.UUID === uuid, line.childLines)
      if (subLineResult) {
        result = subLineResult
      }
    })
  }
  return result
}

export function getLines<T extends PublicWorkorderLine | WorkorderLineState>(payload: any, removeChildrenFromRoot?: boolean): T[] {
  const lines: T[] = propOr([], 'data.Data.Lines')(payload)

  const outputLines = AddChildLinesToParentsAndFlagThem(lines)

  if (removeChildrenFromRoot) return outputLines.filter((line: T) => !line.isChildLine)
  return outputLines
}

export function AddChildLinesToParentsAndFlagThem<T extends PublicWorkorderLine | WorkorderLineState>(lines: T[]): T[] {
  // add all default properties to the lines
  const outputLines = lines.map((l: T) => {
    return { isChildLine: false, childLines: [] as T[], ...l }
  })

  // add child lines to their parents, and flag them.
  forEach(outputLines, (line: T) => {
    if (line.ParentLineUUID && line.ParentLineUUID !== EmptyUUID) {
      const parentLine = find((subLine: T) => subLine.UUID === line.ParentLineUUID, outputLines)
      if (parentLine) {
        line.isChildLine = true
        if (!find<T>(x => x.UUID === line.UUID, parentLine.childLines)) parentLine.childLines.push(line)
      }
    }
  })
  return outputLines
}

interface line {
  UUID: string
  ParentLineUUID?: string
}

// Check if a line is contained by a specific line UUID
export const isDescendantOf = (lines: line[], line: line | undefined, parentUUID: string): boolean => {
  if (!line) return false

  const lineIsParent = line.UUID === parentUUID
  const lineIsChild = line.ParentLineUUID === parentUUID

  return (
    lineIsParent ||
    lineIsChild ||
    isDescendantOf(
      lines,
      lines.find(l => l.UUID === line.ParentLineUUID),
      parentUUID
    )
  )
}

export const isOrderedLine = (line: WorkorderLineState) =>
  line.OrderedQuantity && line.OrderedQuantity > 0 && line.LineType === 'external_part'

export const getLinesFromRepairTimes = (payload: any, removeChildrenFromRoot?: boolean) => {
  const lines = propOr([], 'data.Data.WorkorderLines.Lines')(payload) as WorkorderLineState[]

  const outputLines = AddChildLinesToParentsAndFlagThem(lines)

  if (removeChildrenFromRoot) return outputLines.filter((line: WorkorderLineState) => !line.isChildLine)
  return outputLines
}

export const getTotals = (totals?: IWorkorderTotals): WorkorderTotalsState => {
  if (!totals) {
    return defaultState
  }

  return {
    ...totals,
    TotalPending: {
      ...totals.TotalPending,
      LabourVatExcluded: totals.TotalQuote.LabourVatExcluded + totals.TotalPending.LabourVatExcluded,
      PartsVatExcluded: totals.TotalQuote.PartsVatExcluded + totals.TotalPending.PartsVatExcluded,
      OtherVatExcluded: totals.TotalQuote.OtherVatExcluded + totals.TotalPending.OtherVatExcluded,
      DiscountAmountVatExcluded: totals.TotalQuote.DiscountAmountVatExcluded + totals.TotalPending.DiscountAmountVatExcluded,
      VatExcluded: totals.TotalQuote.VatExcluded + totals.TotalPending.VatExcluded,
      VatIncluded: totals.TotalQuote.VatIncluded + totals.TotalPending.VatIncluded,
      VatAmount: totals.TotalQuote.VatAmount + totals.TotalPending.VatAmount,
      MarginAmount: totals.TotalQuote.MarginAmount + totals.TotalPending.MarginAmount
    }
  }
}

export const getVehicleRegistrationNoCountry = pipe<any, any, any, string>(
  propOr('', 'vehicle.detail.Registration'),
  split('|'),
  ([_, registration]) => registration
)

export const getRepairTimes = propOr([], 'data.Data')

export const getWorkorderMaintenancePeriods = propOr([], 'data.Data.UpdateHaynesproRepairtimesInput.MaintenancePeriodIDs')

export const getRepairTimeChapterName = (chapters: any, uuid: any) => {
  if (chapters.length) {
    const chapter = find(item => uuid === item.ID, chapters)

    return (chapter && chapter.Description) || ''
  } else {
    return ''
  }
}

export const getSupplierOrderParts = propOr([], 'data.Data')

export const incrementSupplierOrderPart = (state: any, payload: any) => {
  const newState = [...state.PartLines]
  const part = newState.find(parts => {
    return parts.UUID === payload.supplierOrderPartsUUID
  })
  part.Quantity++

  return newState
}

export const decrementSupplierOrderPart = (state: any, payload: any) => {
  const newState = [...state.PartLines]
  const part = newState.find(parts => {
    return parts.UUID === payload.supplierOrderPartsUUID
  })
  if (part.Quantity > 0) {
    part.Quantity--
  }

  return newState
}

export const selectOptions = (selectedMethod: any, deliveryOptions: any, pickupOptions: any) =>
  selectedMethod === 'delivery' ? deliveryOptions : pickupOptions

export const getWorkorderLine = (state: IRootReducerState) => {
  if (!(state && state.workorder && state.workorder.line)) {
    return null
  }

  return state.workorder.line
}

export const getPaymentOptions = pipe<any, any, IDropdownOption[]>(
  propOr([], 'PaymentOptions'),
  map((option: any) => ({ key: option.ID, value: option.ID, text: formatEnum('EnumPaymentOption', option.PaymentType) }))
)

export const getDeliveryOptions = pipe<any, any, any, IDeliveryOption[]>(
  propOr([], 'DeliveryOptions'),
  map((option: any) => ({
    type: option.Type,
    timeOptions: option.Times.map((time: any) => ({
      value: time.ID,
      text: `${time.Description || formatDeliveryTime(time.Time) || i18next.t('product.delivery_unknown')}`
    }))
  })),
  sortBy((v: any) => v.type)
)

export const getPreferredDeliveryOptions = (options: IDeliveryOption[], cachedValue?: DeliveryOption): DeliveryOption | string => {
  if (!options || options.length === 0) {
    return ''
  }

  // select the cached delivery option if it's still in the list
  const validCachedOptions = options.find((opt: any) => opt.type === cachedValue)
  if (validCachedOptions) {
    return validCachedOptions.type
  }

  if (options && options.find((opt: any) => opt.type === DeliveryOption.DeliverJustInTime)) {
    // select the deliverJustInTime option when the api returned that option
    return DeliveryOption.DeliverJustInTime
  }

  // get the first option as second best default
  if (options && options.length !== 0) {
    return options[0].type
  }

  return '' // make sure no undefined is returned
}

export const getPreferredAddressOptions = (options: ISupplierAddress[], cachedValue: string): string => {
  if (!options || options.length === 0) {
    return ''
  }

  // select the cached value if it's still in the list
  const validCachedOptions = options.find((opt: ISupplierAddress) => opt.ID === cachedValue)
  if (validCachedOptions) {
    return validCachedOptions.ID
  }

  // select the first element if no cached value is available
  return options[0].ID
}

export const getPreferredPaymentOptions = (options: DropdownItemProps[], cachedValue: string): string => {
  if (!options || options.length === 0) {
    return ''
  }

  // select the cached value if it's still in the list
  const validCachedOptions = options.find((opt: DropdownItemProps) => opt.value === cachedValue)
  if (validCachedOptions) {
    return validCachedOptions.value as string
  }

  // select the first element if no cached value is available
  return options[0].value as string
}

export const useGetCurrentSelectedOrderAccount = () => {
  const orderAccountUUID = useSelector<IRootReducerState>(state => state.workorder?.supplierOrderParams?.orderAccountUUID || EmptyUUID)
  const allAccounts = useSelector<IRootReducerState, IOrderAccountData[]>(state => state.workorder?.orderAccounts || [])
  return useMemo(() => {
    const acc = allAccounts.find(account => account.OrderAccount.UUID === orderAccountUUID)
    if (!acc) {
      return { Wholesaler: { Name: '' } }
    }
    return acc
  }, [allAccounts, orderAccountUUID])
}

export const getFilterIsActive = (state: IRootReducerState, key: string, value: string) => {
  return (
    key &&
    value &&
    state &&
    state.products &&
    state.products.productFilterValues &&
    state.products.productFilterValues[key] &&
    state.products.productFilterValues[key].indexOf &&
    state.products.productFilterValues[key].indexOf(value) >= 0
  )
}
