import { Reducer, combineReducers } from 'redux'
import selectedPeriods, { SelectedPeriodsReducerState } from 'app/workorder/state/workorder/serviceSchedule/selectedPeriods'

interface IHaynesReducerState {
  selectedPeriods: SelectedPeriodsReducerState
}

export type ServiceScheduleReducerState = Readonly<IHaynesReducerState>

export const serviceSchedule: Reducer<ServiceScheduleReducerState> = combineReducers({
  selectedPeriods
})

export default serviceSchedule
