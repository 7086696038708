if (!__VITE__) {
  const faviconsContext = require.context(
    '!!file-loader?name=assets/favicons/[name].[ext]!.',
    true,
    /\.(svg|png|ico|xml|json|webmanifest)$/
  )
  faviconsContext.keys().forEach(faviconsContext)
}

export {}
