import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import { getRepairTimes } from 'app/workorder/state/workorder/selectors'
import * as types from 'app/workorder/state/workorder/types'

export interface GenericArticle {
  ID: number
  Mandatory: boolean
  Description: string
}

export interface RepairTimeNode {
  ID: string
  SortOrder: number
  Description: string
  DurationInHours: number
  HasSubnodes: boolean
  HasInfoGroups: boolean
  GenericArticles: GenericArticle[]
  JobType: string
}

interface RepairTimeNodeReducerState {
  [key: string]: RepairTimeNode[]
}

export type NodesReducerState = Readonly<RepairTimeNodeReducerState>

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type NodesReducerBehavior<S> = Record<typeof types.GET_REPAIR_TIMES_NODES_SUCCESS, Reducer<S, any>> &
  Record<typeof types.RESET_REPAIR_TIMES, Reducer<S, any>>

const nodes = createReducer<NodesReducerState, NodesReducerBehavior<NodesReducerState>>(
  {},
  {
    [types.GET_REPAIR_TIMES_NODES_SUCCESS]: (state, { request, payload }) => {
      return {
        ...state,
        [request.RootNode]: getRepairTimes(payload)
      }
    },
    [types.RESET_REPAIR_TIMES]: () => {
      return {}
    }
  }
)

export default nodes
