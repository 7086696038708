import * as originalReactQuery from '@tanstack/react-query'
import { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

// export everything from the original package and overwrite it below
export * from '@tanstack/react-query'

/**
 * useQuery is wrapper version of react-query
 * it will simply pass along all params and apply some default config
 */
export function useQuery<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  arg1: TQueryKey | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  arg2?: QueryFunction<TQueryFnData, TQueryKey> | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  arg3?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> {
  let options = parseQueryArgs(arg1, arg2, arg3)
  options = applyDefaults<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, TQueryKey>(options)
  return originalReactQuery.useQuery(options)
}

function parseQueryArgs<TOptions extends UseQueryOptions<any, any, any, TQueryKey>, TQueryKey extends QueryKey = QueryKey>(
  arg1: TQueryKey | TOptions,
  arg2?: QueryFunction<any, TQueryKey> | TOptions,
  arg3?: TOptions
): TOptions {
  if (!isQueryKey(arg1)) {
    return arg1 as TOptions
  }

  if (typeof arg2 === 'function') {
    return { ...arg3, queryKey: arg1, queryFn: arg2 } as TOptions
  }

  return { ...arg2, queryKey: arg1 } as TOptions
}

function isQueryKey(value: any): value is QueryKey {
  return typeof value === 'string' || Array.isArray(value)
}

function applyDefaults<TOptions extends UseQueryOptions<any, any, any, TQueryKey>, TQueryKey extends QueryKey = QueryKey>(
  options: TOptions
): TOptions {
  if (options.refetchOnReconnect === undefined) {
    options.refetchOnReconnect = false
  }

  return options
}
export default useQuery
