import React from 'react'
import moment from 'moment'

interface Props {
  value: moment.MomentInput
  formatFrom?: string
  withParentheses?: boolean
}

export const DateFromNow: React.FC<Props> = ({ value, formatFrom, withParentheses = false }) => {
  if (!value || !moment(value, formatFrom).isValid()) {
    return null
  }
  return (
    <>
      {withParentheses && '('}
      {moment(value, formatFrom).fromNow()}
      {withParentheses && ')'}
    </>
  )
}
