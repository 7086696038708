export const SEARCH = 'DASHBOARD_SEARCH'
export const SEARCH_SUCCESS = 'DASHBOARD_SEARCH_SUCCESS'
export const SEARCH_FAILED = 'DASHBOARD_SEARCH_FAILED'

export const SEARCH_ALL = 'SEARCH_ALL'
export const SEARCH_ALL_SUCCESS = 'SEARCH_ALL_SUCCESS'
export const SEARCH_ALL_FAILED = 'SEARCH_ALL_FAILED'

export const RESET_REDUCER = 'RESET_REDUCER'

export const SET_VALUE = 'SET_VALUE'

export const CREATE_VEHICLE = 'CREATE_VEHICLE'
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS'
export const CREATE_VEHICLE_FAILED = 'CREATE_VEHICLE_FAILED'

export const GET_SETUP_STATUS = 'GET_SETUP_STATUS'
export const GET_SETUP_STATUS_SUCCESS = 'GET_SETUP_STATUS_SUCCESS'
export const GET_SETUP_STATUS_FAILED = 'GET_SETUP_STATUS_FAILED'

export const GET_ONBOARDING_STATUS = 'GET_ONBOARDING_STATUS'
export const GET_ONBOARDING_STATUS_SUCCESS = 'GET_ONBOARDING_STATUS_SUCCESS'
export const GET_ONBOARDING_STATUS_FAILED = 'GET_ONBOARDING_STATUS_FAILED'

export const GET_DASHBOARD_CHANGE_LOG = 'GET_DASHBOARD_CHANGE_LOG'
export const GET_DASHBOARD_CHANGE_LOG_SUCCESS = 'GET_DASHBOARD_CHANGE_LOG_SUCCESS'
export const GET_DASHBOARD_CHANGE_LOG_FAILED = 'GET_DASHBOARD_CHANGE_LOG_FAILED'

export const GET_WIDGET_SETTINGS = 'GET_WIDGET_SETTINGS'
export const GET_WIDGET_SETTINGS_SUCCESS = 'GET_WIDGET_SETTINGS_SUCCESS'
export const GET_WIDGET_SETTINGS_FAILED = 'GET_WIDGET_SETTINGS_FAILED'

export const PUT_WIDGET_SETTINGS = 'PUT_WIDGET_SETTINGS'
export const PUT_WIDGET_SETTINGS_SUCCESS = 'PUT_WIDGET_SETTINGS_SUCCESS'
export const PUT_WIDGET_SETTINGS_FAILED = 'PUT_WIDGET_SETTINGS_FAILED'

export const EMPLOYEE_ACCEPT_TERMS_CONDITIONS = 'EMPLOYEE_ACCEPT_TERMS_CONDITIONS'
export const EMPLOYEE_ACCEPT_TERMS_CONDITIONS_SUCCESS = 'EMPLOYEE_ACCEPT_TERMS_CONDITIONS_SUCCESS'
export const EMPLOYEE_ACCEPT_TERMS_CONDITIONS_FAILED = 'EMPLOYEE_ACCEPT_TERMS_CONDITIONS_FAILED'

export const GET_OPEN_REQUESTS = 'GET_OPEN_REQUESTS'
export const GET_OPEN_REQUESTS_SUCCESS = 'GET_OPEN_REQUESTS_SUCCESS'
export const GET_OPEN_REQUESTS_FAILED = 'GET_OPEN_REQUESTS_FAILED'
