import { HttpMethod } from '../../http-method'
import { NotificationHistory, NotificationMessage } from './notification'
import { GETResponse } from '../../helpers'
import { NotificationStatus } from '../enums/notification'

export interface NotificationHistoryRequest {
  StartDate?: string
  EndDate?: string
  Status?: NotificationStatus
  Recipient?: string
  PerPage?: number
  Page?: number
}

export type GETNotificationHistoryRequest = NotificationHistoryRequest
export type GETNotificationHistoryResponse = GETResponse<NotificationHistory>

export const getNotificationHistoryRequest = (notificationHistoryRequest: GETNotificationHistoryRequest) => ({
  method: HttpMethod.GET,
  url: 'modules/crm/notification-history',
  params: notificationHistoryRequest
})

export type GETNotificationHistoryMessageResponse = GETResponse<NotificationMessage>

export const getNotificationHistoryMessageRequest = (messageID: string) => ({
  method: HttpMethod.GET,
  url: `modules/crm/notification-history/messages/${messageID}`
})
