export enum GmsSchedulerPages {
  MAIN = 'Main',
  DASHBOARD = 'Dashboard',
  PLANNING = 'Planning'
}

export enum GmsSchedulerViewModes {
  DEFAULT = 'Default',
  SHOP_WEEK = 'Week',
  SHOP_DAY = 'Day',
  MECHANICS = 'Mechanics',
  WORKSTATIONS = 'Workstations',
  MONTH = 'Month'
}

export enum SchedulerDaysToShowByViewSettings {
  Dashboard = 'Dashboard',
  MainMechanics = 'MainMechanics',
  MainWeek = 'MainWeek',
  MainWorkstations = 'MainWorkstations',
  PlanningMechanics = 'PlanningMechanics',
  PlanningWorkstations = 'PlanningWorkstations',
  PlanningWeek = 'PlanningWeek'
}

export interface ModifySchedulerSettings {
  WorkDays: number
  HidePreviousDayInOverview: boolean,
  DaysToShowByView: {
    [SchedulerDaysToShowByViewSettings.Dashboard]: number
    [SchedulerDaysToShowByViewSettings.MainMechanics]: number
    [SchedulerDaysToShowByViewSettings.MainWeek]: number
    [SchedulerDaysToShowByViewSettings.MainWorkstations]: number
    [SchedulerDaysToShowByViewSettings.PlanningMechanics]: number
    [SchedulerDaysToShowByViewSettings.PlanningWorkstations]: number
    [SchedulerDaysToShowByViewSettings.PlanningWeek]: number
  }
}

export interface SchedulerSettings extends ModifySchedulerSettings {
  LastVisitedViewByPage: {
    [GmsSchedulerPages.DASHBOARD]: GmsSchedulerViewModes
    [GmsSchedulerPages.MAIN]: GmsSchedulerViewModes
    [GmsSchedulerPages.PLANNING]: GmsSchedulerViewModes
  }
}
