import React from 'react'
import { EnvironmentContext } from 'contexts/EnvironmentContext'
import { APIConfig } from './apiConfig'

export const useApiUrl = (api: keyof typeof APIConfig) => {
  const context = React.useContext(EnvironmentContext)
  if (!context) {
    throw new Error('useApiUrl must be used within a EnvironmentContext component')
  }
  return context.resolvedURLs[api]
}
