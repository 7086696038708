import { GETArrayResponse } from '../../helpers'

export interface InvoicePayment {
  UUID: string
  PaymentMethodUUID: string
  PaymentMethodName: string
  Amount: number
  PaidAt: Date
  RevertedPaymentUUID: string
}

export type GETInvoicePaymentsResponse = GETArrayResponse<InvoicePayment>

export const getInvoicePaymentsRequest = (invoiceUUID: string) => ({
  method: 'GET',
  url: `/modules/workorder-invoices/${invoiceUUID}/payments`
})
