import { publicGetOnboardingStepData, publicGetThirdPartyProvidedOnboardingStepData } from '@cbgms/api'
import { useQuery } from '@cbgms/base/http/use-query'
import { loadOnboardingStatus } from 'app/dashboard/state/actions'
import { useDispatch } from 'react-redux'
import { OnboardingMode } from '../onboarding/steps/types.d'
import { useHistory } from 'react-router-dom'
import * as routing from '../onboarding/constants/routes'

export const ONBOARDING_CACHE_KEY = 'ONBOARDING'
export const STEP_DATA_CACHE_KEY = 'STEP-DATA'

export const getOnboardingStepDataCacheKey = (mode: OnboardingMode) => [ONBOARDING_CACHE_KEY, STEP_DATA_CACHE_KEY, mode]

interface UseGetOnboardingWholesalerProps {
  mode: OnboardingMode
  basePath: string
  thirdPartyOnboardingTicket?: string
}

const defaultModules = {
  ModulePortalEnabled: true,
  ModuleSwitchDefaultOrderAccount: true,
  ModuleAddOrderAccount: true
}

export const useGetOnboardingStepData = ({ mode, basePath, thirdPartyOnboardingTicket }: UseGetOnboardingWholesalerProps) => {
  const dispatch = useDispatch()
  const navigation = useHistory()
  const result = useQuery(getOnboardingStepDataCacheKey(mode), async () => {
    if (mode === OnboardingMode.OnboardingViaWholesaler && !thirdPartyOnboardingTicket) {
      const apiResult = await dispatch(publicGetOnboardingStepData())
      return { Steps: apiResult.Data, Modules: defaultModules }
    } else if (mode === OnboardingMode.OnboardingViaWholesaler && thirdPartyOnboardingTicket) {
      try {
        const apiResult = await dispatch(publicGetThirdPartyProvidedOnboardingStepData(thirdPartyOnboardingTicket))
        return apiResult.Data
      } catch (err) {
        navigation.push(routing.urlTicketInvalid(basePath))
      }
    } else {
      const apiResult = await dispatch(loadOnboardingStatus())
      return { Steps: apiResult.Data, Modules: defaultModules }
    }
  })

  return result
}
