import { logout } from 'app/auth/state/actions'
import { IFailedRequest } from '../errorHandler'
import navigation from 'utils/navigation'

function signOutAndRedirectToLogin(failedRequest: IFailedRequest, logoutReason: string) {
  failedRequest.store.dispatch(logout())
  navigation.push(`/auth/login?${new URLSearchParams({ logoutReason }).toString()}`)
}

export default signOutAndRedirectToLogin
