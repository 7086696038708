import { APIResponse, GETResponse, POSTRequestBody, PUTRequestBody } from '../../../helpers'
import { IPaginatedResponse } from '../../../response'
import { WorkorderInvoice } from '../../workorder-invoices'
import { IVehicle } from '../../vehicles'
import { OdometerType } from '@carsys/enums/odometer-type'
export enum OKRSource {
  ROB = 'rob',
  Schade = 'schade'
}

export enum OKRStatus {
  Sent = 'sent',
  NotSent = 'not_send',
  Warning = 'warning',
  Error = 'error'
}

export interface IOKRInvoiceRecord {
  ErrorMessage: string
  OdometerUnit: OdometerType
  OdometerValue: number
  RDWProviderAccountUUID: string
  SentAt: string
  ShopID: string
  Source: OKRSource
  Status: OKRStatus
  UUID: string
  WorkorderInvoiceUUID: string
}

export interface IGetOKRHistory {
  OKRHistory: IOKRInvoiceRecord
  Vehicle: IVehicle
  WorkorderInvoice: WorkorderInvoice
}

export interface IParseOKRRegistrationData {
  HTTPStatusCode: number
  TransactionUUID: string
  ResponseBody: string
  ResponseTime: string
}

export interface IParsedOKRRegistration {
  Notification: {
    Type: string
    Number: number
    Text: string
  }
  Error: string
}

export interface IOKRRegistrationMessage {
  TransactionUUID: string
  CreatedAt: string
  AdditionalHeaders: any
  RequestBody: string
  EndpointURL: string
  HTTPMethod: string
  ReturnParameters: {
    URL: string
    HTTPMethod: string
  }
}

export type GETUnsentOKRRecordsResponse = GETResponse<number>

export type GETOKRHistoryResponse = IPaginatedResponse<IGetOKRHistory>

export type GETOKRRegistrationMessageResponse = GETResponse<IOKRRegistrationMessage>

export type POSTParseOKRRegistrationBody = POSTRequestBody<IParseOKRRegistrationData, 'TransactionUUID' | 'ResponseBody' | 'HTTPStatusCode'>
export type POSTParseOKRRegistrationResponse = APIResponse<IParsedOKRRegistration>

export type PUTOKRHistoryRecordBody = PUTRequestBody<IOKRInvoiceRecord>
export type PUTOKRHistoryRecordResponse = APIResponse<{ UUID: string }>

export type GETOKRRecordByInvoiceResponse = GETResponse<IOKRInvoiceRecord>
