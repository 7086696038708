import * as types from './types'
import { GETGenArtsResponse } from '@cbgms/api'
import { AsyncAction } from '@cbgms/base/redux/async-action'

export const getGenArts = () =>
  AsyncAction<GETGenArtsResponse>({
    type: [types.GET_GEN_ARTS, types.GET_GEN_ARTS_SUCCESS, types.GET_GEN_ARTS_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url: '/miscellaneous/genarts'
      }
    }
  })

export const getCachedGenArts = () => ({
  type: types.GET_CACHED_GEN_ARTS
})

export const lockGetCachedGenArts = () => ({
  type: types.LOCK_GET_CACHED_GEN_ARTS
})
