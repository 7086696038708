import { CountryCode } from '@carsys/enums/country-code'
import { SupportedCountryCode } from '@cbgms/api'
import { AnyAction, combineReducers } from 'redux'
import * as types from './types'

type SearchableCountriesState = SupportedCountryCode[]

const defaultSearchableCountriesState: SearchableCountriesState = []

const searchableCountries = (
  state: SearchableCountriesState = defaultSearchableCountriesState,
  action: AnyAction
): SearchableCountriesState => {
  switch (action.type) {
    case types.LOAD_SEARCHABLE_COUNTRIES_SUCCESS:
      return action.payload.data.Data || defaultSearchableCountriesState
    default:
      return state
  }
}

type SelectedCountriesState = CountryCode[]

const defaultSelectedCountriesState: SelectedCountriesState = []

const selectedCountries = (state: SelectedCountriesState = defaultSelectedCountriesState, action: AnyAction): SelectedCountriesState => {
  switch (action.type) {
    case types.LOAD_VEHICLE_SEARCH_BY_COUNTRY_SUCCESS:
      return action.payload.data.Data.Countries
    default:
      return state
  }
}

export default combineReducers({
  searchableCountries,
  selectedCountries
})
