import { AnyAction, MiddlewareAPI } from 'redux'
import prop from 'lodash/fp/prop'
import decode from 'jwt-decode'

const defaultState = {
  'cognito:username': '',
  'custom:company_uuid': '',
  family_name: '',
  given_name: ''
}

function isAction(arg: any): arg is AnyAction {
  return arg.type !== undefined
}

export function getUserContext(store: MiddlewareAPI | AnyAction) {
  const token = isAction(store) ? getUserContextFromAction(store) : getUserContextFromStore(store)

  return {
    company: token['custom:company_uuid'],
    id: token['cognito:username'],
    username: `${token.given_name} ${token.family_name}`
  }
}

function getUserContextFromStore(store: MiddlewareAPI): typeof defaultState {
  const state = store.getState()

  return prop(['auth', 'user', 'IdToken'], state) || defaultState
}

function getUserContextFromAction(action: AnyAction): typeof defaultState {
  const token = prop(['payload', 'data', 'Data', 'AuthenticationResult', 'IDToken'], action) || ''

  try {
    return decode(token)
  } catch {
    return defaultState
  }
}
