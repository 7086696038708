import { Alert, Button } from '@mui/material'
import Refresh from '@mui/icons-material/Refresh'
import { useTranslation } from 'react-i18next'

export interface IRetryableErrorAlertProps {
  /**
   * The content of the component.
   */
  content?: string
  /**
   * The function to refetch the data.
   */
  onRetry: () => void
}

export const RetryableErrorAlert = ({ onRetry, content: contentText }: IRetryableErrorAlertProps) => {
  const { t } = useTranslation()
  const content = contentText ?? t('errorAlert.error_msg')

  return (
    <Alert icon={false} severity='error'>
      <Button color='inherit' onClick={onRetry} size='small' variant='outlined' sx={{ mr: 1 }}>
        <Refresh />
      </Button>
      {content}
    </Alert>
  )
}
