import { faker } from '@faker-js/faker/locale/en'
import {
  IGenericVehicle,
  ILatestOdometerLog,
  IMOTHistory,
  IManufacturer,
  IModel,
  IRecall,
  IVehicle,
  IVehicleAttribute,
  IVehicleManufacturerModel,
  IVehicleType
} from './index'
import { CountryCode } from '@carsys/enums/country-code'
import {
  BodyType,
  DisplayType,
  EmissionClass,
  FuelType,
  MDMSource,
  OdometerLogReason,
  RecallCriteriaType,
  TransmissionType,
  VehicleAssignedAs,
  VehicleType
} from '@cbgms/api/modules/enums'
import { OdometerType } from '@carsys/enums/odometer-type'
import { UpholsteryType } from '@carsys/enums/upholstery-type'
import { InteriorColourType } from '@carsys/enums/interior-colour-type'
import { YesNoOptionType } from '@carsys/enums/yes-no-option-type'
import { MaintenanceBookletOptionType } from '@carsys/enums/maintenance-booklet-option-type'
import { PaintCodeType } from '@carsys/enums/paint-code-type'
import { PaintShadeCodeType } from '@carsys/enums/paint-shade-code-type'
import { OdometerDigitType } from '@carsys/enums/odometer-digit-type'
import { MockFn, referenceDate } from '../../mock'
import { apiFormat } from '@cbgms/base/utils/datetime'
import moment from 'moment'
import { IActiveWarranty, IVehicleWarrantyPeriod } from '../vehicle-warranties'
import { mockVehicleWarranty } from '../vehicle-warranties/mocks'

import { TimeType } from '@carsys/enums/time-type'
import { PeriodType } from '@carsys/enums/period-type'
import { VehicleInvoice } from '../workorder-invoices'

export const mockVehicle: MockFn<IVehicle> = (extend?) => ({
  AccountUUID: faker.datatype.uuid(),
  CompanyAccountUUID: faker.datatype.uuid(),
  LeaseCompanyAccountUUID: faker.datatype.uuid(),
  AlarmCode: faker.random.alphaNumeric(10),
  Ascription: faker.date.past(50, referenceDate).toISOString(),
  BodyType: faker.helpers.arrayElement<BodyType>(Object.values(BodyType)),
  CapacityCC: faker.datatype.number().toString(),
  Colour: faker.color.human(),
  CountryFirstAdmission: faker.random.alphaNumeric(10),
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  Cylinders: faker.datatype.number().toString(),
  DateOfFirstAdmission: faker.date.past(50, referenceDate).toISOString(),
  DateOfFirstAdmissionLocal: faker.date.past(50, referenceDate).toISOString(),
  EngineCode: faker.random.alphaNumeric(10),
  FuelTypes: [FuelType.Diesel],
  HaynesProID: faker.random.alphaNumeric(10),
  HbaseID: faker.random.alphaNumeric(10),
  Internal: faker.random.alphaNumeric(10),
  KeyCode: faker.random.alphaNumeric(10),
  Manufacturer: faker.random.alphaNumeric(10),
  Model: faker.random.alphaNumeric(10),
  MOTExpirationDate: faker.date.future(2018, referenceDate).toISOString(),
  OdometerUnit: faker.helpers.arrayElement<OdometerType>(Object.values(OdometerType)),
  OdometerValue: faker.datatype.number(),
  PowerKw: faker.datatype.number(),
  RadioCode: faker.random.alphaNumeric(10),
  Registration: faker.random.alphaNumeric(10),
  Remarks: faker.random.words(10),
  ShopID: faker.random.alphaNumeric(10),
  TecdocKtype: faker.random.alphaNumeric(10),
  TransmissionType: faker.helpers.arrayElement<TransmissionType>(Object.values(TransmissionType)),
  Type: faker.random.alphaNumeric(10),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  UUID: faker.datatype.uuid(),
  VehicleType: VehicleType.Moped,
  Vin: faker.random.alphaNumeric(10),
  Weight: faker.datatype.number().toString(),
  EngineSizeDescription: faker.random.word(),
  ConstructionYear: faker.random.word(),
  MDMAttributes: { Attributes: [] },
  TecDocPlusAttributes: { Attributes: [] },
  GrossBPM: faker.datatype.number(),
  LastEnrichedAt: faker.date.future(2020, referenceDate).toISOString(),
  AssignedAs: VehicleAssignedAs.VehicleSale,
  NextServiceDate: faker.date.future(2020, referenceDate).toISOString(),
  NextServiceOdometerValue: faker.datatype.number(),
  NextServiceOdometerUnit: faker.helpers.arrayElement<OdometerType>(Object.values(OdometerType)),
  NextServiceOdometerEstimatedDate: moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonthDay),
  KeyCodeTwo: faker.random.alphaNumeric(10),
  StartCode: faker.random.alphaNumeric(10),
  EngineOilType: faker.random.word(),
  LastVisitDate: faker.date.future(2020, referenceDate).toISOString(),
  SecondColour: faker.random.word(),
  LastTimingBeltReplaceValue: faker.datatype.number(300000),
  LastTimingBeltReplaceUnit: faker.helpers.arrayElement<OdometerType>(Object.values(OdometerType)),
  LastTimingBeltReplaceDate: faker.date.future(2020, referenceDate).toISOString(),
  CO2Emissions: faker.datatype.number(),
  ManufacturerCode: faker.random.alphaNumeric(10),
  EngineSerialNumber: faker.random.alphaNumeric(10),
  NumberOfGears: faker.datatype.number(),
  NumberOfWheels: faker.datatype.number(),
  NumberOfSeats: faker.datatype.number(),
  NumberOfCylinders: faker.datatype.number(),
  EECVehicleCategory: faker.random.alphaNumeric(10),
  EECVersionAddition: faker.random.alphaNumeric(10),
  EECVehicleCategoryAddition: faker.random.alphaNumeric(10),
  PlaceChassisNumber: faker.random.alphaNumeric(10),
  NumberOfDoors: faker.datatype.number(),
  Wheelbase: faker.datatype.number(),
  DimensionsLength: faker.datatype.number(),
  DimensionsWidth: faker.datatype.number(),
  DimensionsHeight: faker.datatype.number(),
  EmissionClass: EmissionClass.Euro3,
  TankCapacity: faker.datatype.number(),
  CityConsumption: faker.datatype.number(),
  HighwayConsumption: faker.datatype.number(),
  AverageConsumption: faker.datatype.number(),
  MaximumWeight: faker.datatype.number(),
  MaximumLoad: faker.datatype.number(),
  MaximumFrontAxle: faker.datatype.number(),
  MaximumRearAxle: faker.datatype.number(),
  NumberOfKeys: faker.datatype.number(),
  SCMClass: faker.datatype.number(),
  Upholstery: UpholsteryType.Alcantara,
  InteriorColour: InteriorColourType.Anthracite,
  DamagedVehicle: YesNoOptionType.Yes,
  NewVehicle: YesNoOptionType.No,
  MaintenanceBooklets: MaintenanceBookletOptionType.No,
  PaintTypeCode: PaintCodeType.BasicOrUni,
  PaintShadeCode: PaintShadeCodeType.Light,
  RoadTax: faker.datatype.number(),
  InsuranceCompany: faker.random.alphaNumeric(10),
  TypeOfInsurance: faker.random.alphaNumeric(10),
  PolicyNumber: faker.random.alphaNumeric(10),
  IsDeclarationOfConformityDate: true,
  DeclarationOfConformityDate: faker.date.past(50, referenceDate).toISOString(),
  ListPriceDate: faker.date.future(2020, referenceDate).toISOString(),
  CatalogPrice: faker.datatype.number(),
  GrossBPMAmount: faker.datatype.number(),
  BPMDate: faker.date.future(2020, referenceDate).toISOString(),
  IsFullyMaintained: true,
  HasBOVAGFortyPointsCheck: true,
  HasBOVAGDelivery: true,
  VehicleNumber: 'ABC123XYZ',
  VehicleLocation: 'CityX',
  OdometerDigitType: OdometerDigitType.SevenDigit,
  DateOfLastVisit: '2023-01-19T12:00:00.000Z',
  TachographExpiryDate: '2023-12-31T23:59:59.999Z',
  DuplicateCode: 'DUPLICATE123',
  OneCylinderCapacity: 4,
  EngineOilCapacityLiter: 5,
  ManufacturerColourCode: 'COLOR123',
  ManufacturerColourName: 'Red',
  ETAModelCode: 'ETA567',
  ExecutionCode: 'EXECUTE890',
  VariantCode: 'VARIANT456',
  NextTimingBeltReplacementDueUnit: OdometerType.Kilometers,
  NextTimingBeltReplacementDueValue: 55422,
  NextTimingBeltReplacementDuePeriodUnit: PeriodType.Months,
  NextTimingBeltReplacementDuePeriodValue: 10,
  TimingBeltReplacementDurationUnit: TimeType.Hours,
  TimingBeltReplacementDurationValue: 10,
  ...extend
})

export const mockManufacturer: MockFn<IManufacturer> = (extend?) => ({
  ManufacturerID: faker.random.alphaNumeric() + faker.random.alphaNumeric() + faker.random.alphaNumeric(),
  ManufacturerName: faker.company.name(),
  ...extend
})

export const mockModel: MockFn<IModel> = (extend?) => ({
  MadeFrom: moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonthDay),
  MadeUntil: moment(faker.date.future(1, referenceDate)).format(apiFormat.yearMonthDay),
  ModelID: faker.random.alphaNumeric() + faker.random.alphaNumeric() + faker.random.alphaNumeric(),
  ModelName: faker.commerce.productName(),
  Remarks: faker.lorem.words(5),
  ...extend
})

export const mockModelOutlier: MockFn<IModel> = (extend?) => ({
  MadeFrom: moment(faker.date.past(10, referenceDate)).format(apiFormat.yearMonthDay),
  MadeUntil: moment(faker.date.past(9, referenceDate)).format(apiFormat.yearMonthDay),
  ModelID: faker.random.alphaNumeric() + faker.random.alphaNumeric() + faker.random.alphaNumeric(),
  ModelName: faker.commerce.productName(),
  Remarks: faker.lorem.words(5),
  ...extend
})

export const mockModelYearMonth: MockFn<IModel> = (extend?) => ({
  MadeFrom: moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonth),
  MadeUntil: moment(faker.date.future(1, referenceDate)).format(apiFormat.yearMonth),
  ModelID: faker.random.alphaNumeric() + faker.random.alphaNumeric() + faker.random.alphaNumeric(),
  ModelName: faker.commerce.productName(),
  Remarks: faker.lorem.words(5),
  ...extend
})

export const mockModelYearMonthOutlier: MockFn<IModel> = (extend?) => ({
  MadeFrom: moment(faker.date.past(10, referenceDate)).format(apiFormat.yearMonth),
  MadeUntil: moment(faker.date.past(9, referenceDate)).format(apiFormat.yearMonth),
  ModelID: faker.random.alphaNumeric() + faker.random.alphaNumeric() + faker.random.alphaNumeric(),
  ModelName: faker.commerce.productName(),
  Remarks: faker.lorem.words(5),
  ...extend
})

export const mockMOTHistory: MockFn<IMOTHistory> = (extend?) => {
  const passed = faker.datatype.boolean()

  return {
    Registration: faker.random.alphaNumeric(10),
    Make: faker.random.alphaNumeric(10),
    Model: faker.random.alphaNumeric(10),
    FirstUsedDate: moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonthDay),
    FuelType: FuelType.Petrol,
    PrimaryColour: faker.color.human(),
    VehicleID: faker.datatype.uuid(),
    RegistrationDate: moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonthDay),
    ManufactureDate: moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonthDay),
    EngineSize: faker.random.word(),
    MOTTests: [
      {
        CompletedDate: moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonthDay),
        TestResult: passed,
        ExpiryDate: !passed ? undefined : moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonthDay),
        RfrAndComments: [
          {
            Text: faker.random.words(Math.floor(Math.random() * 10 + 2)),
            Type: 'string',
            Dangerous: faker.datatype.boolean()
          }
        ],
        OdometerUnit: '',
        OdometerValue: ''
      }
    ],
    ...extend
  }
}

export const mockVehicleType: MockFn<IVehicleType> = (extend?) => ({
  AccountUUID: faker.datatype.uuid(),
  Ascription: faker.date.past(50, referenceDate).toISOString(),
  CompanyAccountUUID: faker.datatype.uuid(),
  LeaseCompanyAccountUUID: faker.datatype.uuid(),
  AlarmCode: faker.random.alphaNumeric(10),
  BodyType: faker.helpers.arrayElement<BodyType>(Object.values(BodyType)),
  CapacityCC: faker.datatype.number().toString(),
  Colour: faker.color.human(),
  CountryFirstAdmission: CountryCode.NL,
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  Cylinders: faker.datatype.number().toString(),
  DateOfFirstAdmission: faker.date.past(50, referenceDate).toISOString(),
  DateOfFirstAdmissionLocal: faker.date.past(50, referenceDate).toISOString(),
  EngineCode: faker.random.alphaNumeric(10),
  FuelTypes: [FuelType.Diesel],
  GenericVehicle: mockGenericVehicle(),
  HaynesProID: faker.random.alphaNumeric(10),
  HbaseID: faker.random.alphaNumeric(10),
  Internal: faker.random.alphaNumeric(10),
  KeyCode: faker.random.alphaNumeric(10),
  Manufacturer: faker.random.alphaNumeric(10),
  Model: faker.random.alphaNumeric(10),
  MOTExpirationDate: faker.date.future(2018, referenceDate).toISOString(),
  OdometerUnit: faker.helpers.arrayElement<OdometerType>(Object.values(OdometerType)),
  OdometerValue: faker.datatype.number(),
  PowerKw: faker.datatype.number(),
  RadioCode: faker.random.alphaNumeric(10),
  Registration: faker.random.alphaNumeric(10),
  Remarks: faker.random.words(10),
  ShopID: faker.random.alphaNumeric(10),
  TecdocKtype: faker.random.alphaNumeric(10),
  TransmissionType: faker.helpers.arrayElement<TransmissionType>(Object.values(TransmissionType)),
  Type: faker.random.alphaNumeric(10),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  UUID: faker.datatype.uuid(),
  VehicleType: VehicleType.Moped,
  Vin: faker.random.alphaNumeric(10),
  Weight: faker.datatype.number().toString(),
  EngineSizeDescription: faker.random.word(),
  ConstructionYear: faker.random.word(),
  MDMAttributes: { Attributes: [] },
  TecDocPlusAttributes: { Attributes: [] },
  GrossBPM: faker.datatype.number(),
  LastEnrichedAt: faker.date.future(2020, referenceDate).toISOString(),
  AssignedAs: VehicleAssignedAs.VehicleSale,
  NextServiceDate: moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonthDay),
  NextServiceOdometerEstimatedDate: moment(faker.date.past(1, referenceDate)).format(apiFormat.yearMonthDay),
  NextServiceOdometerValue: faker.datatype.number(),
  NextServiceOdometerUnit: faker.helpers.arrayElement<OdometerType>(Object.values(OdometerType)),
  KeyCodeTwo: faker.random.alphaNumeric(10),
  StartCode: faker.random.alphaNumeric(10),
  EngineOilType: faker.random.word(),
  LastVisitDate: faker.date.future(2020, referenceDate).toISOString(),
  SecondColour: faker.random.word(),
  LastTimingBeltReplaceValue: faker.datatype.number(300000),
  LastTimingBeltReplaceUnit: faker.helpers.arrayElement<OdometerType>(Object.values(OdometerType)),
  LastTimingBeltReplaceDate: faker.date.future(2020, referenceDate).toISOString(),
  CO2Emissions: faker.datatype.number(),
  ManufacturerCode: faker.random.alphaNumeric(10),
  EngineSerialNumber: faker.random.alphaNumeric(10),
  NumberOfGears: faker.datatype.number(),
  NumberOfWheels: faker.datatype.number(),
  NumberOfSeats: faker.datatype.number(),
  NumberOfCylinders: faker.datatype.number(),
  EECVehicleCategory: faker.random.alphaNumeric(10),
  EECVersionAddition: faker.random.alphaNumeric(10),
  EECVehicleCategoryAddition: faker.random.alphaNumeric(10),
  PlaceChassisNumber: faker.random.alphaNumeric(10),
  NumberOfDoors: faker.datatype.number(),
  Wheelbase: faker.datatype.number(),
  DimensionsLength: faker.datatype.number(),
  DimensionsWidth: faker.datatype.number(),
  DimensionsHeight: faker.datatype.number(),
  EmissionClass: EmissionClass.Euro3,
  TankCapacity: faker.datatype.number(),
  CityConsumption: faker.datatype.number(),
  HighwayConsumption: faker.datatype.number(),
  AverageConsumption: faker.datatype.number(),
  MaximumWeight: faker.datatype.number(),
  MaximumLoad: faker.datatype.number(),
  MaximumFrontAxle: faker.datatype.number(),
  MaximumRearAxle: faker.datatype.number(),
  NumberOfKeys: faker.datatype.number(),
  SCMClass: faker.datatype.number(),
  Upholstery: UpholsteryType.Alcantara,
  InteriorColour: InteriorColourType.Anthracite,
  DamagedVehicle: YesNoOptionType.Yes,
  NewVehicle: YesNoOptionType.No,
  MaintenanceBooklets: MaintenanceBookletOptionType.No,
  PaintTypeCode: PaintCodeType.BasicOrUni,
  PaintShadeCode: PaintShadeCodeType.Light,
  RoadTax: faker.datatype.number(),
  InsuranceCompany: faker.random.alphaNumeric(10),
  TypeOfInsurance: faker.random.alphaNumeric(10),
  PolicyNumber: faker.random.alphaNumeric(10),
  IsDeclarationOfConformityDate: true,
  DeclarationOfConformityDate: faker.date.past(50, referenceDate).toISOString(),
  ListPriceDate: faker.date.future(2020, referenceDate).toISOString(),
  CatalogPrice: faker.datatype.number(),
  GrossBPMAmount: faker.datatype.number(),
  BPMDate: faker.date.future(2020, referenceDate).toISOString(),
  IsFullyMaintained: true,
  HasBOVAGFortyPointsCheck: true,
  HasBOVAGDelivery: true,
  VehicleNumber: faker.random.alphaNumeric(10),
  VehicleLocation: faker.random.alphaNumeric(10),
  OdometerDigitType: OdometerDigitType.SevenDigit,
  DateOfLastVisit: faker.date.future(2020, referenceDate).toISOString(),
  TachographExpiryDate: faker.date.future(2020, referenceDate).toISOString(),
  DuplicateCode: faker.random.alphaNumeric(10),
  OneCylinderCapacity: faker.datatype.number(),
  EngineOilCapacityLiter: faker.datatype.number(),
  ManufacturerColourCode: faker.random.alphaNumeric(10),
  ManufacturerColourName: faker.random.alphaNumeric(10),
  ETAModelCode: faker.random.alphaNumeric(10),
  ExecutionCode: faker.random.alphaNumeric(10),
  VariantCode: faker.random.alphaNumeric(10),
  NextTimingBeltReplacementDueUnit: OdometerType.Kilometers,
  NextTimingBeltReplacementDueValue: 55422,
  NextTimingBeltReplacementDuePeriodUnit: PeriodType.Months,
  NextTimingBeltReplacementDuePeriodValue: 10,
  TimingBeltReplacementDurationUnit: TimeType.Hours,
  TimingBeltReplacementDurationValue: 12,
  ...extend
})
export const mockGenericVehicle: MockFn<IGenericVehicle> = extend => ({
  BodyType: faker.random.word(),
  CilinderCount: faker.datatype.number(),
  Colour: faker.color.human(),
  ConstructionMonth: faker.datatype.number().toString(),
  ConstructionYear: faker.datatype.number().toString(),
  DateOfFirstAdmission: faker.date.future(2018, referenceDate).toISOString(),
  DateOfFirstAdmissionLocal: faker.date.future(2018, referenceDate).toISOString(),
  DateOfOwnership: faker.date.future(2018, referenceDate).toISOString(),
  ETA: faker.random.word(),
  EUType: faker.random.word(),
  EUVariant: faker.random.word(),
  EUVersion: faker.random.word(),
  EngineCapacityCC: faker.datatype.number(),
  EngineCodes: [faker.random.word()],
  FuelTypes: [FuelType.Petrol, FuelType.Electricity],
  HaynesproID: faker.random.word(),
  HbaseID: faker.random.word(),
  ID: faker.random.word(),
  Images: [faker.random.word()],
  MOTExpirationDate: faker.random.word(),
  MadeFrom: faker.date.past(1, referenceDate).toISOString(),
  MadeUntil: faker.date.future(1, referenceDate).toISOString(),
  Manufacturer: faker.random.word(),
  Model: faker.random.word(),
  NumberOfDoors: faker.datatype.number(),
  NumberOfWheels: faker.datatype.number(),
  PowerKw: faker.datatype.number(),
  PowerBHP: faker.datatype.number(),
  Registration: faker.random.word(),
  Remarks: faker.random.word(),
  Source: faker.random.word(),
  TecdocKtype: faker.random.word(),
  TransmissionType: faker.random.word(),
  Type: faker.random.word(),
  VIN: faker.random.word(),
  VehicleType: faker.random.word(),
  Weight: faker.datatype.number(),
  WeightRoadworthy: faker.datatype.number(),
  ...extend
})

export const mockOdometerLog: MockFn<ILatestOdometerLog> = (extend?) => ({
  UUID: faker.datatype.uuid(),
  VehicleUUID: faker.datatype.uuid(),
  WorkorderInvoiceUUID: faker.datatype.uuid(),
  ShopID: faker.random.alphaNumeric(10),
  Registration: faker.random.alphaNumeric(10),
  VIN: faker.random.alphaNumeric(10),
  OdometerUnit: faker.helpers.arrayElement<OdometerType>(Object.values(OdometerType)),
  OdometerValue: faker.datatype.number(),
  Reason: faker.helpers.arrayElement<OdometerLogReason>(Object.values(OdometerLogReason)),
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  UpdateUserUUID: faker.datatype.uuid(),
  UpdatePerformedByType: faker.random.word(),
  UpdatePerformedByName: faker.name.firstName(),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  ...extend
})

export const mockMDMAttributesMakeList: MockFn<IVehicleAttribute[]> = () => [
  {
    Source: MDMSource.VDS,
    Name: 'Make',
    DisplayName: 'Manufacturer',
    DisplayValue: 'Porche',
    Value: 'Porche',
    ResetOrder: 1,
    SortOrder: 1,
    DisplayType: DisplayType.UserSelection
  },
  {
    Source: MDMSource.VDS,
    Name: 'Make',
    DisplayName: 'Manufacturer',
    DisplayValue: 'Fiat',
    Value: 'Fiat',
    ResetOrder: 1,
    SortOrder: 1,
    DisplayType: DisplayType.UserSelection
  }
]

export const mockMDMAttributesModelList: MockFn<IVehicleAttribute[]> = () => [
  {
    Source: MDMSource.VDS,
    Name: 'Make',
    DisplayName: 'Manufacturer',
    DisplayValue: 'Porche',
    Value: 'Porche',
    ResetOrder: 1,
    SortOrder: 1,
    DisplayType: DisplayType.UserSelection
  },
  {
    Source: MDMSource.VDS,
    Name: 'Model',
    DisplayName: 'Model',
    DisplayValue: '911',
    Value: '911',
    ResetOrder: 2,
    SortOrder: 2,
    DisplayType: DisplayType.UserSelection
  },
  {
    Source: MDMSource.VDS,
    Name: 'Model',
    DisplayName: 'Model',
    DisplayValue: 'Cayenne',
    Value: 'Cayenne',
    ResetOrder: 2,
    SortOrder: 2,
    DisplayType: DisplayType.UserSelection
  }
]

export const mockMDMAttributesID: MockFn<IVehicleAttribute[]> = () => [
  {
    Source: MDMSource.VDS,
    Name: 'ID',
    DisplayName: 'ID',
    DisplayValue: 'ID',
    Value: faker.datatype.uuid(),
    ResetOrder: 0,
    SortOrder: 0,
    DisplayType: DisplayType.Info
  }
]

export const mockMDMAttributesTypeSpecList: MockFn<IVehicleAttribute[]> = () => [
  {
    Source: MDMSource.VDS,
    Name: 'Make',
    DisplayName: 'Manufacturer',
    DisplayValue: 'Porche',
    Value: 'Porche',
    ResetOrder: 1,
    SortOrder: 1,
    DisplayType: DisplayType.UserSelection
  },
  {
    Source: MDMSource.VDS,
    Name: 'Model',
    DisplayName: 'Model',
    DisplayValue: '911',
    Value: '911',
    ResetOrder: 2,
    SortOrder: 2,
    DisplayType: DisplayType.UserSelection
  },
  {
    Source: MDMSource.VDS,
    Name: 'VehicleYear',
    DisplayName: 'VehicleYear',
    DisplayValue: '2012',
    Value: '2012',
    ResetOrder: 2,
    SortOrder: 2,
    DisplayType: DisplayType.UserSelection
  },
  {
    Source: MDMSource.VDS,
    Name: 'VehicleYear',
    DisplayName: 'VehicleYear',
    DisplayValue: '2013',
    Value: '2013',
    ResetOrder: 3,
    SortOrder: 3,
    DisplayType: DisplayType.UserSelection
  },
  {
    Source: MDMSource.VDS,
    Name: 'Fuel',
    DisplayName: 'Fuel',
    DisplayValue: 'Diesel',
    Value: 'Diesel',
    ResetOrder: 4,
    SortOrder: 4,
    DisplayType: DisplayType.UserSelection
  },
  {
    Source: MDMSource.VDS,
    Name: 'Fuel',
    DisplayName: 'Fuel',
    DisplayValue: 'Petrol',
    Value: 'Petrol',
    ResetOrder: 4,
    SortOrder: 4,
    DisplayType: DisplayType.UserSelection
  },
  {
    Source: MDMSource.VDS,
    Name: 'EngineSize',
    DisplayName: 'Engine Size',
    DisplayValue: '1.0',
    Value: '1.0',
    ResetOrder: 5,
    SortOrder: 5,
    DisplayType: DisplayType.UserSelection
  }
]

export const mockRecall: MockFn<IRecall> = (extend?) => {
  const item = `${faker.color.human()} ${faker.commerce.productMaterial()} ${faker.commerce.product()}`
  return {
    ID: faker.datatype.number(),
    Category: 'Recall',
    OeCode: 'R/2007/012',
    Description: `${item} overheats`,
    IssueDate: '2020-11-22',
    RecallCriteria: [
      { CriteriaType: RecallCriteriaType.VinFrom, Value: 'ZFA188****5352627' },
      { CriteriaType: RecallCriteriaType.VinTo, Value: 'ZFA188****5352999' }
    ],

    Details: [
      {
        Description: 'Defect',
        DetailNodes: [
          {
            EOBDCode: '1234',
            OECode: 'FRFR1232',
            Image: 'https://image.url',
            Text: `${item} overheating while gearbox turns 50 counter clockwise retrogrades`,
            TextRemark: '',
            RepairTimeID: 0,
            TableContent: null,
            SpecialTools: null,
            SmartLinks: null
          },
          {
            EOBDCode: '',
            OECode: '',
            Image: '',
            Text: 'Affected vehicles:',
            TextRemark: '12314',
            RepairTimeID: 0,
            TableContent: null,
            SpecialTools: null,
            SmartLinks: null
          }
        ]
      },
      {
        Description: 'Solution',
        DetailNodes: [
          {
            EOBDCode: '',
            OECode: '',
            Image: '',
            Text: 'Fiddle with the thingy',
            TextRemark: '',
            RepairTimeID: 0,
            TableContent: null,
            SpecialTools: null,
            SmartLinks: null
          }
        ]
      }
    ],
    ...extend
  }
}

export const mockVehicleManufacturerModel: MockFn<IVehicleManufacturerModel> = () => ({
  Manufacturer: faker.random.alphaNumeric(10),
  Model: faker.random.alphaNumeric(10),
  Type: faker.random.alphaNumeric(10)
})

export const mockVehicleActiveWarranty: MockFn<IActiveWarranty> = () => ({
  Period: mockVehicleWarrantyPeriod(),
  Warranty: mockVehicleWarranty()
})

export const mockVehicleWarrantyPeriod: MockFn<IVehicleWarrantyPeriod> = () => ({
  UUID: faker.datatype.uuid(),
  ShopID: faker.random.alphaNumeric(10),
  WarrantyUUID: faker.datatype.uuid(),
  VehicleUUID: faker.datatype.uuid(),
  AccountUUID: faker.datatype.uuid(),
  InventoryVehicleInvoiceUUID: faker.datatype.uuid(),
  StartDate: faker.date.past(2021, referenceDate).toISOString(),
  EndDate: faker.date.future(2021, referenceDate).toISOString(),
  StartMileage: faker.datatype.number(),
  EndMileage: faker.datatype.number(),
  MileageUnit: faker.helpers.arrayElement<OdometerType>(Object.values(OdometerType))
})

export const mockVehicleInvoice: MockFn<VehicleInvoice> = (extend?) => ({
  WorkorderInvoiceUUID: faker.datatype.uuid(),
  InvoiceCreatedAt: new Date(),
  ...mockVehicle(),
  ...extend
})
