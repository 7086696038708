import React from 'react'
import logoURL from 'assets/svg/LogoCarsys.svg?url'
import logoSmallURL from 'assets/svg/LogoCarsysSmall.svg?url'
import { emptyTheme } from '@cbgms/api/modules/themes/mocks'
import { ThemeReducerState } from 'app/auth/state/reducers/theme'
import tinyColor from 'tinycolor2'
import { ITheme } from '@cbgms/api'
import { getContrastRatio } from '@mui/material/styles'
import { defaultColors } from '@carsys/ui'

const { Consumer, Provider } = React.createContext<ThemeReducerState>(emptyTheme({ LogoURL: logoURL, LogoSmallURL: logoSmallURL }))

export const ThemeConsumer = Consumer
export const ThemeProvider = Provider

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ThemeColours = {
  mainColour: string
  offsetColour: string
  mainDarkenedColour: string
  mainLightenedColour: string
  mainVeryLightenedColour: string
  mainSuperLightenedColour: string
  mainColourText: string
}
export const calculateCssVars = (theme: ITheme): ThemeColours => ({
  mainColour: theme.MainColour,
  offsetColour: tinyColor(theme.MainColour).spin(-40).lighten(10).toString(),
  mainDarkenedColour: tinyColor(theme.MainColour).darken(10).toString(),
  mainLightenedColour: tinyColor(theme.MainColour).lighten(10).toString(),
  mainVeryLightenedColour: tinyColor({ ...tinyColor(theme.MainColour).toHsl(), l: 0.8 }).toString(),
  mainSuperLightenedColour: tinyColor({ ...tinyColor(theme.MainColour).toHsl(), l: 0.95 }).toString(),
  mainColourText: theme.MainColourText
})

export const CustomColors = { bodyColor: '#f0f0f0' }

export interface TempType {
  mainColour: string
  mainColourText: string
  mainDarkenedColour: string
  mainLightenedColour: string
}

export const getThemeColors = (colours: TempType) => {
  // Check for string value: 'white' because previous fallback theme contained this value.
  // This crashes the getContrastRatio function and prevents loading of the application.
  // Because the theme is synced into local storage users may have this value.
  const mainColourText = colours.mainColourText === 'white' ? '#fff' : colours.mainColourText

  const themeColor = colours.mainColour
  const themeColorText =
    getContrastRatio(themeColor, mainColourText) > 3 ? mainColourText : getContrastRatio(themeColor, '#fff') > 3 ? '#fff' : '#000'
  const actionColor =
    getContrastRatio(themeColor, CustomColors.bodyColor) > 3
      ? themeColor
      : getContrastRatio(mainColourText, CustomColors.bodyColor) > 3
      ? mainColourText
      : defaultColors.mainColour
  const actionColorText = getContrastRatio(actionColor, '#fff') > 3 ? '#fff' : '#000'

  return {
    themeColor,
    themeColorText,
    actionColor,
    actionColorText
  }
}

export const calculateLightAndDarkColor = (color: string) => ({
  light: tinyColor(color).lighten(10).toString(),
  dark: tinyColor(color).darken(10).toString(),
  extraDark: tinyColor(color).darken(15).toString()
})
