import i18next from 'i18next'
import { notifier } from '@cbgms/base/notifier/use-notifier'
import { IFailedRequest } from 'middlewares/errorHandler/errorHandler'
import { retrieveError } from 'middlewares/errorHandler/utils'

const gatewayTimeoutAPIError = (failedRequest: IFailedRequest) => {
  const { error } = failedRequest

  const errorMessage = retrieveError(error)

  if (i18next.exists(`Error.API.${errorMessage}`)) {
    notifier.error(i18next.t(`Error.API.${errorMessage}`))
    return
  }

  // when an error is unknown, we no longer just return the error, instead we show a generic translatable error
  notifier.error(i18next.t(`Error.API.ErrUnhandledExternalAPITimeout`))
}

export default gatewayTimeoutAPIError
