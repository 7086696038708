import React from 'react'
import MUIRadioGroup, { RadioGroupProps as MUIRadioGroupProps } from '@mui/material/RadioGroup'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText'

export interface RadioGroupProps extends Omit<FormControlProps<'fieldset'>, 'children' | 'color' | 'component' | 'onChange'> {
  children?: React.ReactNode
  helperText?: FormHelperTextProps['children']
  label?: FormLabelProps['children']
  name?: NonNullable<MUIRadioGroupProps['name']>
  onChange?: MUIRadioGroupProps['onChange']
  row?: MUIRadioGroupProps['row']
  value?: MUIRadioGroupProps['value']
}

export function RadioGroup({ children, helperText, label, name, onChange, row, value, ...props }: RadioGroupProps) {
  return (
    <FormControl component='fieldset' {...props}>
      {label ? <FormLabel component='legend'>{label}</FormLabel> : null}
      <MUIRadioGroup name={name} onChange={onChange} row={row} value={value} style={{ justifyContent: 'space-between' }}>
        {children}
      </MUIRadioGroup>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}
