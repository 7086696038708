import { AnyAction, Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'

import * as types from '../../types'
import { IArchiveOrderLine } from '@cbgms/api/modules/archive'

const defaultState: IArchiveOrderLine[] = []
export type ArchiveLinesState = IArchiveOrderLine[]
type TotalsReducerBehavior<S> = Record<typeof types.LOAD_ARCHIVED_ORDER_LINES_SUCCESS, Reducer<S, AnyAction>>

const lines = createReducer<ArchiveLinesState, TotalsReducerBehavior<ArchiveLinesState>>(defaultState, {
  [types.LOAD_ARCHIVED_ORDER_LINES_SUCCESS]: (_: any, { payload }) => {
    return payload.data.Data.Lines
  }
})

export default lines
