import decode from 'jwt-decode'
import { Reducer } from 'redux'
import { Locales } from '@carsys/enums/locales'
import { defaultLanguage } from '@cbgms/base/translate/locales'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from 'app/auth/state/types'

interface IUserReducerState {
  AccessToken: {
    [key: string]: any
  }
  IdToken: {
    [key: string]: any
  }
  authenticated: boolean
  tempLocale?: Locales
}

export type UserReducerState = Readonly<IUserReducerState>

const defaultState: UserReducerState = {
  AccessToken: {},
  IdToken: {},
  authenticated: false,
  tempLocale: defaultLanguage
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type UserReducerBehavior<S> = Record<typeof types.LOGIN_SUCCESS, Reducer<S, any>> &
  Record<typeof types.SET_ACCESS_TOKEN, Reducer<S, any>> &
  Record<typeof types.SET_TEMP_LOCALE, Reducer<S, any>>

const user = createReducer<UserReducerState, UserReducerBehavior<UserReducerState>>(defaultState, {
  [types.LOGIN_SUCCESS]: (_, { payload }) => {
    const { AccessToken, IDToken } = payload.data.Data.AuthenticationResult

    return {
      AccessToken: decode(AccessToken),
      IdToken: decode(IDToken),
      authenticated: true
    }
  },
  [types.SET_ACCESS_TOKEN]: (state, { payload }) => {
    const { AccessToken, IDToken } = payload.data.Data.AuthenticationResult

    return {
      ...state,
      AccessToken: decode(AccessToken),
      IdToken: decode(IDToken),
      authenticated: true
    }
  },
  [types.SET_TEMP_LOCALE]: (state, { locale }) => {
    return {
      ...(state as UserReducerState),
      tempLocale: locale
    }
  }
})

export default user
