import { IAccount, SystemAccountType } from '@cbgms/api/modules/accounts'
import { CountryCode } from '@carsys/enums/country-code'
import { AccountType } from '@cbgms/api/modules/enums'

import { LoadAccountSuccess, ResetAccount } from './actions'
import { LOAD_ACCOUNT_SUCCESS, RESET_ACCOUNT } from './types'

const defaultState = {
  BankBicNumber: '',
  BankIbanNumber: '',
  CocNumber: '',
  CompanyName: '',
  CreatedAt: '',
  DateOfBirth: '',
  Department: '',
  EmailAddress: '',
  FirstName: '',
  Initials: '',
  IsClient: true,
  IsSupplier: false,
  MobileNumber: '',
  Name: '',
  NamePrefix: '',
  PhoneNumber: '',
  PostalAddress: '',
  PostalAddressExtra: '',
  PostalCity: '',
  PostalCountry: CountryCode.ZZ,
  PostalHouseNumber: '',
  PostalHouseNumberAddition: '',
  PostalPostalCode: '',
  PostalState: '',
  SystemAccountType: SystemAccountType.Standard,
  Title: '',
  Type: AccountType.Private,
  UpdatedAt: '',
  UUID: '',
  VatNumber: '',
  VisitorAddress: '',
  VisitorAddressExtra: '',
  VisitorCity: '',
  VisitorCountry: CountryCode.ZZ,
  VisitorHouseNumber: '',
  VisitorHouseNumberAddition: '',
  VisitorPostalCode: '',
  VisitorState: '',
  IsUseSageCashAccount: false,
  AccountNumber: ''
}

type State = Readonly<IAccount>
type Actions = LoadAccountSuccess | ResetAccount

export const accountReducer = (state: State = defaultState, action: Actions): State => {
  switch (action.type) {
    case LOAD_ACCOUNT_SUCCESS:
      return action.payload.data.Data
    case RESET_ACCOUNT:
      return defaultState
    default:
      return state
  }
}
