import React from 'react'
import * as Yup from 'yup'
import { AcceptedFileTypes } from './types'
import { isAllowedFileTypes, isMultipleFiles, isWithinMaximumFilesize } from './utils'

export function createFileUploaderValidation(multiple: boolean, accept: AcceptedFileTypes[], maximumFilesize: number) {
  return Yup.mixed<File[]>()
    .test({
      name: 'MultipleFiles',
      message: 'FileUploader.Errors.MultipleFiles',
      test: (input: File[] = []) => !(!multiple && isMultipleFiles(input))
    })
    .test({
      name: 'UnsupportedFileType',
      message: 'FileUploader.Errors.UnsupportedFileType',
      test: (input: File[] = []) => input.every(file => isAllowedFileTypes(file, accept))
    })
    .test({
      name: 'FilesizeTooBig',
      message: 'FileUploader.Errors.FilesizeTooBig',
      test: (input: File[] = []) => input.every(file => isWithinMaximumFilesize(file, maximumFilesize))
    })
}

export function createFileUploaderReducer(validation: Yup.AnySchema) {
  return (state: FileUploaderState, action: ActionTypes) => {
    switch (action.type) {
      case FileUploaderAction.SET_FILE: {
        try {
          validation.validateSync(action.payload)
        } catch (error) {
          if (Yup.ValidationError.isError(error)) return { ...state, files: new Array(), error: error.message }
        }
        return { ...state, error: null, files: action.payload }
      }
      case FileUploaderAction.APPEND_FILES: {
        try {
          validation.validateSync(action.payload)
        } catch (error) {
          if (Yup.ValidationError.isError(error)) {
            return { ...state, error: error.message }
          }
        }
        return { ...state, error: null, files: [...state.files, ...action.payload] }
      }
      case FileUploaderAction.SET_ERROR: {
        return { ...state, files: new Array(), error: action.payload }
      }
    }
  }
}

export function useFileUploader(reducer: FileUploadReducer) {
  const [{ files, error }, dispatch] = React.useReducer(reducer, {
    files: new Array(),
    error: null
  })

  const setFiles = (files: File[]) => {
    dispatch({ type: FileUploaderAction.SET_FILE, payload: files })
  }

  const appendFiles = (files: File[]) => {
    dispatch({ type: FileUploaderAction.APPEND_FILES, payload: files })
  }

  const setError = (error: string | null) => {
    dispatch({ type: FileUploaderAction.SET_ERROR, payload: error })
  }

  return { files, error, setFiles, appendFiles, setError }
}

export interface FileUploaderState {
  files: File[]
  error: string | null
}

export enum FileUploaderAction {
  SET_FILE = 'SET_FILE',
  SET_ERROR = 'SET_ERROR',
  APPEND_FILES = 'APPEND_FILES'
}

interface SetFileAction {
  type: FileUploaderAction.SET_FILE
  payload: File[]
}
interface SetAppendFilesAction {
  type: FileUploaderAction.APPEND_FILES
  payload: File[]
}
interface SetErrorAction {
  type: FileUploaderAction.SET_ERROR
  payload: string | null
}

export type ActionTypes = SetFileAction | SetErrorAction | SetAppendFilesAction
export type FileUploadReducer = (state: FileUploaderState, action: ActionTypes) => FileUploaderState
