import { Grid } from '@mui/material'

import { FilePreview } from './FilePreview'

export function FileList({
  files,
  onRemove
}: {
  /** The list of files that should be displayed. */
  files: File[]
  /** A callback that is triggered when one of the files is deleted from the list. */
  onRemove: (file: File) => void
}) {
  return (
    <>
      {files.map(file => {
        return (
          <Grid item key={file.name} xs={12}>
            <FilePreview file={file} onRemove={() => onRemove(file)} />
          </Grid>
        )
      })}
    </>
  )
}
