import { APIResponse } from '../../helpers'
import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'

export enum OAuthResponseType {
  Code = 'code',
  Token = 'token'
}

export enum GmsOAuthScope {
  AccountsRead = 'gms.accounts:read'
}

export interface AuthorizationCodesBody {
  ResponseType: OAuthResponseType
  ClientID: string
  Scopes: GmsOAuthScope[]
  RedirectURI: string
  State?: string
}

type AuthorizationCodesResponse = APIResponse<{ RedirectURI: string }>

export const postAuthorizationCodes = (body: AuthorizationCodesBody) =>
  createAsyncApiAction<AuthorizationCodesResponse>('OAUTH_VALIDATE_SCOPE', 'gms', {
    method: 'POST',
    url: '/modules/gms-oauth/authorization-codes',
    data: body
  })
