import { AnyAction } from 'redux'
import * as types from '../types'
import { WorkorderLineState, getLines, getLinesFromRepairTimes } from '../selectors'
import { SET_DISCOUNT_SUCCESS } from 'app/order/state/types'

export const workorderLinesReducer = (state: WorkorderLineState[] = [], action: AnyAction) => {
  switch (action.type) {
    case types.ADD_PRODUCT_SUCCESS:
    case types.ADD_OFFLINE_WORKORDER_LINES_SUCCESS:
    case types.GET_WORKORDER_LINES_SUCCESS:
    case types.DELETE_WORKORDER_LINES_SUCCESS:
    case types.UPDATE_WORKORDER_LINES_SUCCESS:
    case types.UPDATE_WORKORDER_LINES_SORT_SUCCESS:
    case types.UPDATE_WORKORDER_CHILD_LINES_SORT_SUCCESS:
    case types.PLACE_ORDER_SUCCESS:
    case types.PUT_WHOLESALER_IFRAME_CART_TO_WORKORDER_SUCCESS:
    case types.UPDATE_WORKORDER_PARENT_CHILD_SUCCESS:
    case types.UPDATE_WORKORDER_CHILD_PARENT_SUCCESS:
    case SET_DISCOUNT_SUCCESS:
      return getLines<WorkorderLineState>(action.payload)
    case types.PUT_REPAIR_TIMES_SUCCESS:
      return getLinesFromRepairTimes(action.payload, false)
    default:
      return state
  }
}
