import { Reducer, combineReducers } from 'redux'

import { EmptyUUID } from '@cbgms/base/utils/uuid'
import { IOrderResponse } from '@cbgms/api/modules/order'
import { createReducer } from '@cbgms/base/utils/redux'

import * as types from './types'
import { DeliveryOption, OrderType } from '@cbgms/api'

export type DetailReducerState = Readonly<IOrderResponse>

const defaultState: DetailReducerState = {
  Orders: {
    CreatedAt: '',
    ID: '',
    InternalID: '',
    PlacedBy: '',
    Reference: '',
    Remark: '',
    ShopID: '',
    Timestamp: '',
    TotalPriceWhenOrdered: 0,
    Type: OrderType.Stock,
    UpdatedAt: '',
    UUID: EmptyUUID,
    OrderAccountUUID: EmptyUUID,
    WorkorderID: '',
    WarehouseUUID: EmptyUUID,
    WorkorderUUID: EmptyUUID,
    AccountUUID: EmptyUUID,
    DeliveryID: '',
    DeliveryOption: DeliveryOption.DefaultDelivery,
    DeliveryTime: '',
    DeliveryDateTime: '',
    DeliveryDescription: ''
  },
  Shop: {} as any,
  Account: {} as any,
  Invoice: {} as any,
  OrderAccount: {} as any,
  Wholesaler: {} as any,
  Workorder: {} as any
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type DetailReducerBehavior<S> = Record<typeof types.LOAD_ORDER_HISTORY_DETAILS_SUCCESS, Reducer<S, any>> &
  Record<typeof types.RESET_ORDER_HISTORY_ITEM, Reducer<S, any>>

const detail = createReducer<DetailReducerState, DetailReducerBehavior<DetailReducerState>>(defaultState, {
  [types.LOAD_ORDER_HISTORY_DETAILS_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [types.RESET_ORDER_HISTORY_ITEM]: () => defaultState
})

export interface IOrderHistoryRow {
  UUID: string
}

export type RowsReducerState = ReadonlyArray<IOrderHistoryRow>

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type RowsReducerBehavior<S> = Record<typeof types.LOAD_ORDER_HISTORY_DETAILS_LIST_SUCCESS, Reducer<S, any>> &
  Record<typeof types.RESET_ORDER_HISTORY_ITEM, Reducer<S, any>>

const rows = createReducer<RowsReducerState, RowsReducerBehavior<RowsReducerState>>([], {
  [types.LOAD_ORDER_HISTORY_DETAILS_LIST_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [types.RESET_ORDER_HISTORY_ITEM]: () => []
})

export interface IOrderHistoryReducerState {
  detail: DetailReducerState
  rows: RowsReducerState
}

type OrderHistoryState = Readonly<IOrderHistoryReducerState>

const orderHistory = combineReducers<OrderHistoryState>({
  detail,
  rows
})

export default orderHistory
