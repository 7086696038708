import { combineReducers } from 'redux'
import { allReducer } from './allReducer'
import { supportedReducer } from './supportedReducer'
import { environmentReducer } from './environmentReducer'

export type CountriesReducerState = ReturnType<typeof countries>

const countries = combineReducers({
  all: allReducer,
  supported: supportedReducer,
  environment: environmentReducer
})

export default countries
