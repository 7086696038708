import { CountryCode } from '@carsys/enums/country-code'
import { APIResponse } from '@cbgms/api'

export type GETAddressFromPostalCodeResponse = APIResponse<{
  Address: string
  HouseNumber: string
  PostalCode: string
  Country: CountryCode
  City: string
  State: string
  Latitude: number
  Longitude: number
}>

export const getAddressFromPostalCodeRequest = (CountryCode: string, HouseNumber: string, PostalCode: string) => ({
  api: 'gms',
  request: {
    params: {
      CountryCode,
      HouseNumber,
      PostalCode
    },
    method: 'GET',
    url: '/miscellaneous/address/from-postalcode'
  }
})
