import { capitalizeFirstCharacter } from './capitalizeFirstCharacter'

// Start each first word in a sentence with a capital letter. Doesn't lowercase the rest of the sentence (e.g. SMS stays SMS).
export const capitalizeSentence = (text: string) => {
  return text
    .split('. ')
    .filter(s => s) // filter empty strings
    .map(sentence => capitalizeFirstCharacter(sentence))
    .join('. ')
}
