import React from 'react'
import moment from 'moment'
import { formatDateTime } from '@cbgms/base/utils/datetime/formatDateTime'

interface Props {
  value: moment.MomentInput
  format?: string
}

export const Date: React.FC<Props> = ({ value, format }) => {
  return <>{formatDateTime(value, format)}</>
}
