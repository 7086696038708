export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS'
export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SUCCES = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SUCCES'
export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_FAILED = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_FAILED'

export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNT = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNT'
export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNT_SUCCES = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNT_SUCCES'
export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNT_FAILED = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNT_FAILED'

export const CREATE_RDW_SERVICE_PROVIDER_ACCOUNT = 'CREATE_RDW_SERVICE_PROVIDER_ACCOUNT'
export const CREATE_RDW_SERVICE_PROVIDER_ACCOUNT_SUCCES = 'CREATE_RDW_SERVICE_PROVIDER_ACCOUNT_SUCCES'
export const CREATE_RDW_SERVICE_PROVIDER_ACCOUNT_FAILED = 'CREATE_RDW_SERVICE_PROVIDER_ACCOUNT_FAILED'

export const UPDATE_RDW_SERVICE_PROVIDER_ACCOUNT = 'UPDATE_RDW_SERVICE_PROVIDER_ACCOUNT'
export const UPDATE_RDW_SERVICE_PROVIDER_ACCOUNT_SUCCES = 'UPDATE_RDW_SERVICE_PROVIDER_ACCOUNT_SUCCES'
export const UPDATE_RDW_SERVICE_PROVIDER_ACCOUNT_FAILED = 'UPDATE_RDW_SERVICE_PROVIDER_ACCOUNT_FAILED'

export const DELETE_RDW_SERVICE_PROVIDER_ACCOUNTS = 'DELETE_RDW_SERVICE_PROVIDER_ACCOUNTS'
export const DELETE_RDW_SERVICE_PROVIDER_ACCOUNTS_SUCCES = 'DELETE_RDW_SERVICE_PROVIDER_ACCOUNTS_SUCCES'
export const DELETE_RDW_SERVICE_PROVIDER_ACCOUNTS_FAILED = 'DELETE_RDW_SERVICE_PROVIDER_ACCOUNTS_FAILED'

export const LOAD_RDW_SUPPORTED_SERVICE_PROVIDER = 'LOAD_RDW_SUPPORTED_SERVICE_PROVIDER'
export const LOAD_RDW_SUPPORTED_SERVICE_PROVIDER_SUCCES = 'LOAD_RDW_SUPPORTED_SERVICE_PROVIDER_SUCCES'
export const LOAD_RDW_SUPPORTED_SERVICE_PROVIDER_FAILED = 'LOAD_RDW_SUPPORTED_SERVICE_PROVIDER_FAILED'

export const RESET_RDW_SERVICE_PROVIDER_ACCOUNT_STATE = 'RESET_RDW_SERVICE_PROVIDER_ACCOUNT_STATE'
export const SET_HAS_OKR_SERVICE_PROVIDER_STATE = 'SET_HAS_OKR_SERVICE_PROVIDER_STATE'

export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK'
export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK_SUCCESS = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK_SUCCESS'
export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK_FAILED = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK_FAILURE'

export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR'
export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR_SUCCESS = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR_SUCCESS'
export const LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR_FAILED = 'LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR_FAILURE'

export const UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK = 'UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK'
export const UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK_SUCCESS = 'UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK_SUCCESS'
export const UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK_FAILED = 'UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_APK_FAILURE'

export const UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR = 'UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR'
export const UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR_SUCCESS = 'UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR_SUCCESS'
export const UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR_FAILED = 'UPDATE_RDW_SERVICE_PROVIDER_ACCOUNTS_SETTINGS_OKR_FAILURE'
