import { VehicleProvider } from '@cbgms/api/modules/enums'
import { VehicleIdTypeVehicleProperty } from '@cbgms/api/modules/enums/vehicle-id-type'
import { enrichVehicle, loadVehicle, refreshMDMAttributes, updateVehicle } from 'app/vehicles/state/actions'
import { IFailedRequest } from '../errorHandler'
import updateVehicleFromProviderInfo from 'app/vehicles/components/Details/External/updateVehicleFromProviderInfo'
import { ApiActionPayload } from '@cbgms/base/redux/create-async-action'
import { IInjection } from 'state/types'
import { SelectVehicleFunc } from 'app/vehicles/components/SelectVehicleType/types.d'

interface FailedVehicleRequestPayload extends ApiActionPayload {
  skipVehicleSelection?: boolean
  vehicleUUID: string
}
export type FailedVehicleRequest = IFailedRequest<FailedVehicleRequestPayload>
const ErrVehicleIDEmpty = 'ErrVehicleIDEmpty'
// VehicleIdEmptyHandler will capture enrichement Errors from redux and trigger the vehicle enrich flows
// Enrich will attempt to fill in required vehicle ids, show user selections when required
//
// in general the flow is:
// initialze Application:
// setup refsContext to hold a function we can pass and use in the store/handler.
// Setup provider & store, pass the ref to the store
// render children, a child will use the refsContext and set the function that can be triggered from the handler
// Somewhere else: -> start API call
// -> API returns error
// -> Redux error handler middleware capture error
// -> start enrichment procedure, (only a single enrichment can run at the time. Other enrichments are queued)
// -> handle enrichment result, trigger a selection modal promise (using ref) or automaticly select
// -> finally: Either patch the original api call, retry it and pretend that the new result OR reject the enrichment
interface Enrichment {
  promise?: Promise<boolean>
  isSkippingVehicleSelection?: boolean
}
class VehicleIdEmptyHandler {
  private static instance?: VehicleIdEmptyHandler
  private runningEnrichments: { [target: string]: Enrichment }
  private retriedRequests: FailedVehicleRequest[]
  private getShowVehicleSelectionFunc: () => SelectVehicleFunc

  constructor(injection: IInjection) {
    this.runningEnrichments = {}
    this.retriedRequests = []
    this.getShowVehicleSelectionFunc = injection.getShowVehicleSelectionFunc
  }

  idTypeToProviderMap: { [key in VehicleIdTypeVehicleProperty]?: VehicleProvider } = {
    [VehicleIdTypeVehicleProperty.HbaseID]: VehicleProvider.Hbase,
    [VehicleIdTypeVehicleProperty.TecDocKtype]: VehicleProvider.TecDoc,
    [VehicleIdTypeVehicleProperty.HaynesProID]: VehicleProvider.Haynes,
    [VehicleIdTypeVehicleProperty.TecDocPlusAttributes]: VehicleProvider.TecDocPlus
  }

  async enrichVehicle({ action, store, error }: FailedVehicleRequest): Promise<boolean> {
    const errorResponse = error.response.data.Data
    // The VehicleUUID used to not be present on the error response. This was
    // added to support the TecRMI API calls. These API calls don't work with vehicles
    // but with work orders which is why we return the VehicleUUID in the error.
    const vehicleUUID = action.payload.vehicleUUID || errorResponse.VehicleUUID

    if (errorResponse.EnrichmentTarget === 'mdm_attributes') {
      try {
        // If the vehicle has no attributes due to not being created through the emdm catalog we can try to refresh them automatically
        const {
          Data: { Attributes }
        } = await store.dispatch(refreshMDMAttributes(vehicleUUID))
        // reload the vehicle so the refinement component can update too
        await store.dispatch(loadVehicle(vehicleUUID))
        return Attributes.length > 0
      } catch {
        // We can't handle enrichment on mdm_attributes. Shouldn't matter since the product list contains a component for vehicle refinement
        return false
      }
    }
    // Single results will automatically resolve on the api. No result will be returned.
    // When multiple types are returned, check if we should show the selection modal.
    let types
    // Vehicle uuid may be empty because some calls may or may not need a vehicle depending on catalog configuration
    if (vehicleUUID) {
      try {
        types = await store.dispatch(enrichVehicle(vehicleUUID, errorResponse.EnrichmentTarget, action.payload.skipVehicleSelection))
      } catch {
        // exception in enrichment, just show vehicle selection modal so user can do a mmt selection
      }
    }

    let selected = null

    const backendResolvedSelectVehicle = types?.Data?.length === 0
    if (backendResolvedSelectVehicle) {
      await store.dispatch(loadVehicle(vehicleUUID))
      return true
    }
    const provider = this.idTypeToProviderMap[errorResponse.VehicleProperty as VehicleIdTypeVehicleProperty] || VehicleProvider.Hbase
    const automaticallySelectVehicle = types?.Data?.length === 1
    if (automaticallySelectVehicle) {
      selected = types.Data[0]
    } else if (!action.payload.skipVehicleSelection) {
      selected = await this.getShowVehicleSelectionFunc()(types?.Data ?? [], provider)
    }

    if (!selected) {
      return false
    }

    // Check if we need to update more then just ID
    const currentVehicle = await store.dispatch(loadVehicle(vehicleUUID))
    const shouldUpdateInFull = !currentVehicle?.Data?.Manufacturer && !currentVehicle?.Data?.Model
    // store the new selected ID and optionally update other fields when the vehicle is mostly empty
    const updatedVehicle = updateVehicleFromProviderInfo(provider, selected, shouldUpdateInFull)
    await store.dispatch(updateVehicle(vehicleUUID, updatedVehicle))
    // if we got here, no errors where thrown,
    // But the selection might have been skipped,
    await store.dispatch(loadVehicle(vehicleUUID))

    return true
  }

  // Even if enrichment succeeds the request might still throw an error. So make sure to only retry the request _one time_.
  async retryRequestOnceUnlessDifferentEnrichError(f: FailedVehicleRequest) {
    const findMatch = (v: FailedVehicleRequest) =>
      v.action.payload.request === f.action.payload.request &&
      (v.error === f.error ||
        // If we get something else then an enrich error, check if that error was previously thrown
        (f.error?.response?.data?.Error !== ErrVehicleIDEmpty && v.error?.response?.data?.Error === f.error?.response?.data?.Error) ||
        // or when it is an enrich error, check if the EnrichmentTarget is the same. then that enrichtment failed.
        (f.error?.response?.data?.Error === ErrVehicleIDEmpty &&
          v.error?.response?.data?.Data.EnrichmentTarget === f.error?.response?.data?.Data.EnrichmentTarget))
    // check if this was already retried
    if (this.retriedRequests.find(findMatch)) {
      return
    }

    // we will retry this requests
    this.retriedRequests.push(f)
    try {
      return await f.request()
    } catch (e: any) {
      if (
        // another error popped up, check if it an different enrich.
        // we execute all enrich calls atlease once
        e?.response?.data?.Data?.EnrichmentTarget &&
        e?.response?.data?.Data?.EnrichmentTarget !== f.error.response.data.Data.EnrichmentTarget
      ) {
        // try again, keep track of the errors though
        await this.handleError({ ...f, error: e })
      }
    } finally {
      // we retried everything we wanted. safe to remove this from the list again.
      this.retriedRequests = this.retriedRequests.filter(v => !findMatch(v))
    }
  }

  async handleError(failedRequest: FailedVehicleRequest) {
    // Check it there is a enrichment going on already for the same target,
    //  it might be the case that the other running enrichment will skip the vehicle selection,
    //  we will then force another enrichment to start (with the vehicleSelection).
    // otherwise we will wait for the running enrichment to finish
    const target = failedRequest.error.response.data.Data.EnrichmentTarget
    const runningEnrichment = this.runningEnrichments[target]
    if (
      runningEnrichment?.promise && // if an enrichment is running, we can wait for that enrichment to finish
      // but make sure the running enrichment isn't skipping unless the new one is fine with skipping
      (!runningEnrichment.isSkippingVehicleSelection || failedRequest.action.payload.skipVehicleSelection)
    ) {
      const hasMadeSelection = await runningEnrichment.promise
      if (hasMadeSelection) {
        // only when a selection was made, retry the request.
        return this.retryRequestOnceUnlessDifferentEnrichError(failedRequest)
      }
      // break out of function when no selection was made
      return
    }

    try {
      this.runningEnrichments[target] = {
        isSkippingVehicleSelection: failedRequest.action.payload.skipVehicleSelection,
        promise: this.enrichVehicle(failedRequest)
      }
      const hasMadeSelection = await this.runningEnrichments[target].promise
      if (hasMadeSelection) {
        // only when a selection was made, retry the request.
        return this.retryRequestOnceUnlessDifferentEnrichError(failedRequest)
      }
    } catch {
      // Fallback to default error handling (Dispatch failed action)
      return
    } finally {
      delete this.runningEnrichments[target]
    }
  }

  static getInstance(injection: IInjection) {
    if (!VehicleIdEmptyHandler.instance) {
      VehicleIdEmptyHandler.instance = new VehicleIdEmptyHandler(injection)
    }

    return VehicleIdEmptyHandler.instance
  }
}
const buildVehicleIdEmptyHandler = (injection: IInjection) => async (failedRequest: FailedVehicleRequest) => {
  const handler = VehicleIdEmptyHandler.getInstance(injection)

  return handler.handleError(failedRequest)
}

export default buildVehicleIdEmptyHandler
