import React from 'react'
import FeaturesContext from './context'
import { Feature } from './types'

export const useFeature = (feature: Feature) => {
  return React.useContext(FeaturesContext).includes(feature)
}

export const useHasFeature = () => {
  const features = React.useContext(FeaturesContext)
  const hasFeature = (feature: Feature) => features.includes(feature) // provide function name for stack trace
  return hasFeature
}
