import { faker } from '@faker-js/faker/locale/en'
import { MockFn } from '../../mock'
import {
  TyreActivityType,
  TyreAxlePosition,
  TyrePosition,
  TyreProfileMeasurementSystem,
  TyreRimType,
  TyreSeasonChangeType,
  TyreSeasonType,
  TyreStatusType
} from '@cbgms/api/modules/enums'
import { mockTyreManufacturers } from '@cbgms/api/modules/miscellaneous/tyre-manufacturers/get'
import { GETResponse, POSTRequestBody } from '@cbgms/api/helpers'
import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'
import { HttpMethod } from '../../http-method'

export type POSTTyreChangeBody = POSTRequestBody<
  CreateTyreChange,
  'Fitted' | 'Unfitted' | 'WorkorderUUID' | 'MutationType' | 'SeasonIndicatorTypeFitted' | 'SeasonIndicatorTypeUnfitted'
>
export type POSTLeaseTyreChangeBody = POSTRequestBody<CreateTyreChange, 'Fitted' | 'Unfitted' | 'WorkorderUUID' | 'MutationType'>

export interface CreateTyreChange extends TyreChange {
  WorkorderUUID: string
}

export interface TyreChange {
  UUID: string
  MutationType: TyreActivityType
  Remark?: string
  Unfitted: TyreMutation[]
  Fitted: TyreMutation[]
  CreatedAt: string
  UpdatedAt: string
  SeasonIndicatorTypeFitted: TyreSeasonChangeType
  SeasonIndicatorTypeUnfitted: TyreSeasonChangeType
}

export interface TyreMutation {
  Tyre: Tyre
  Mutation: Mutation
}

interface Tyre {
  UUID?: string
  Make: string
  Model: string
  SeasonalType: TyreSeasonType
  TyreSpec: string
  IsRunFlat: boolean
}

export enum TyrePositionUnknown {
  Unknown = 'unknown'
}

export type TyrePositionWithUnknown = TyrePositionUnknown | TyrePosition

interface Mutation {
  UUID?: string
  TyreUUID?: string
  Axle: TyreAxlePosition
  RimType: TyreRimType
  FittingPosition: TyrePositionWithUnknown[]
  FittingRemark: string
  ProfileDepth: number
  ProfileMeasurementSystem: TyreProfileMeasurementSystem
  Status: TyreStatusType
  StorageUUID?: string
  StorageLocation: string
  StoredAtCustomer: boolean
}

export const postTyreChange = (data: POSTTyreChangeBody) => ({
  method: 'POST',
  url: '/modules/tyre-changes',
  data
})

export const postTyreChangeFromLease = (workOrderLineUUID: string, data: POSTLeaseTyreChangeBody) => ({
  method: 'POST',
  url: `/modules/tyre-changes/${workOrderLineUUID}`,
  data
})

export const mockMutation: MockFn<Mutation> = (extend?) => ({
  Axle: faker.helpers.arrayElement<TyreAxlePosition>(Object.values(TyreAxlePosition)),
  FittingPosition: [faker.helpers.arrayElement<TyrePosition>(Object.values(TyrePosition))],
  ProfileMeasurementSystem: TyreProfileMeasurementSystem.Millimeters,
  ProfileDepth: faker.datatype.number({ min: 1, max: 10, precision: 0.1 }),
  UUID: faker.datatype.uuid(),
  RimType: faker.helpers.arrayElement(Object.values(TyreRimType)),
  Status: TyreStatusType.Fitted,
  StorageLocation: faker.random.alphaNumeric(25),
  StoredAtCustomer: false,
  FittingRemark: faker.random.alphaNumeric(25),
  ...extend
})

export const mockTyre: MockFn<Tyre> = (extend?) => ({
  Make: faker.helpers.arrayElement(mockTyreManufacturers()),
  Model: faker.random.word(),
  SeasonalType: faker.helpers.arrayElement<TyreSeasonType>(Object.values(TyreSeasonType)),
  IsRunFlat: faker.datatype.boolean(),
  TyreSpec: `${faker.datatype.number({ min: 100, max: 400, precision: 5 })}${faker.datatype.number({
    min: 20,
    max: 70,
    precision: 5
  })}${faker.datatype.number({ min: 12, max: 25, precision: 1 })}`,
  ...extend
})

export const mockTyreChange: MockFn<TyreChange> = (extend?) => {
  const positions = [
    {
      Axle: TyreAxlePosition.FrontFirst,
      FittingPosition: [TyrePosition.Left]
    },
    {
      Axle: TyreAxlePosition.FrontFirst,
      FittingPosition: [TyrePosition.Right]
    },
    {
      Axle: TyreAxlePosition.RearFirst,
      FittingPosition: [TyrePosition.Left]
    },
    {
      Axle: TyreAxlePosition.RearFirst,
      FittingPosition: [TyrePosition.Right]
    }
  ]

  const winterTyre = {
    Make: 'Michelin',
    Model: 'Alpin',
    SeasonalType: TyreSeasonType.Winter,
    IsRunFlat: false,
    TyreSpec: '1954517'
  }

  const summerTyre = {
    Make: 'Bridgestone',
    Model: 'Potenza',
    SeasonalType: TyreSeasonType.Summer,
    IsRunFlat: true,
    TyreSpec: '2054517'
  }

  return {
    UUID: faker.datatype.uuid(),
    CreatedAt: '2020-05-15T16:34:11.146227Z',
    UpdatedAt: '2020-05-15T16:34:11.146227Z',
    Remark: faker.random.alphaNumeric(100),
    MutationType: TyreActivityType.Change,
    Fitted: positions.map(position => ({
      Mutation: mockMutation(position),
      Tyre: mockTyre(winterTyre)
    })),
    Unfitted: positions.map(position => ({
      Mutation: {
        ...mockMutation(position),
        Status: TyreStatusType.Reusable
      },
      Tyre: mockTyre(summerTyre)
    })),
    SeasonIndicatorTypeFitted: TyreSeasonChangeType.Winter,
    SeasonIndicatorTypeUnfitted: TyreSeasonChangeType.Summer,
    ...extend
  }
}

export const canPerformTyreChange = () => ({
  method: 'GET',
  url: '/modules/tyre-changes/prerequisites'
})

export type GETTyreChangeHistory = GETResponse<TyreChange[]>

export const getTyreChangeHistory = (vehicleUUID: string) => ({
  method: HttpMethod.GET,
  url: '/modules/tyre-changes',
  params: {
    VehicleUUID: vehicleUUID
  }
})

export const getTyreChangePdf = (vehicleUUID: string) =>
  createAsyncApiAction<Blob>('gms', {
    method: 'GET',
    url: `/modules/tyre-changes/${vehicleUUID}/labels`
  })
