import type { AcceptedFileTypes } from './types'

export function isMultipleFiles(files: File[]): boolean {
  return files.length > 1
}

export function isAllowedFileTypes(file: File, allowedFileTypes: AcceptedFileTypes[]): boolean {
  const extendedAllowedFileTypes: string[] = []
  allowedFileTypes.map(fileType => {
    if (fileType === 'docx' || fileType === 'doc') {
      extendedAllowedFileTypes.push('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
      extendedAllowedFileTypes.push('application/msword')
    } else if (fileType === 'xlsx' || fileType === 'xls') {
      extendedAllowedFileTypes.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      extendedAllowedFileTypes.push('application/vnd.ms-excel')
    } else {
      extendedAllowedFileTypes.push(fileType)
    }
  })

  return extendedAllowedFileTypes.some(fileType => file.type.includes(fileType))
}

export function isWithinMaximumFilesize(file: File, maximumFilesize: number): boolean {
  return bytesToMegabytes(file.size) <= maximumFilesize
}

export function bytesToMegabytes(bytes: number): number {
  const stepSize = 1000
  const stepsTillMegabytes = 2

  return bytes / stepSize ** stepsTillMegabytes
}

export function fileTypesToAcceptString(fileTypes: AcceptedFileTypes[]): string {
  return fileTypes.map(fileType => `.${fileType}`).join(', ')
}
