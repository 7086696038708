import { Reducer } from 'redux'

import { IDebitCreditNumbers } from '@cbgms/api/modules/bookkeeping-settings'
import { createReducer } from '@cbgms/base/utils/redux'

import { GET_DEBIT_CREDIT_SETTINGS_SUCCESS } from './types'

export type DebitCreditNumbersState = Readonly<Partial<IDebitCreditNumbers>>

const defaultDetailState: DebitCreditNumbersState = {
  MinimumDebtorNumber: 1,
  MinimumCreditorNumber: 1
}

type DebitCreditNumbersBehavior<S = DebitCreditNumbersState> = Record<typeof GET_DEBIT_CREDIT_SETTINGS_SUCCESS, Reducer<S, any>>

const debitCreditNumbers = createReducer<DebitCreditNumbersState, DebitCreditNumbersBehavior>(defaultDetailState, {
  [GET_DEBIT_CREDIT_SETTINGS_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export default debitCreditNumbers
