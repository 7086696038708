import React from 'react'
import { useFeature } from './hooks'
import { Feature as FeatureType } from './types'

interface Props {
  feature: FeatureType
  fallback?: React.ReactNode
  children: React.ReactNode
}

const Feature = ({ feature, children, fallback = null }: Props) => {
  const enabled = useFeature(feature)
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{enabled ? children : fallback}</>
}

export default Feature
