import MUITextField, { TextFieldProps } from '@mui/material/TextField'
import { IconButton, SelectProps, inputClasses } from '@mui/material'
import Clear from '@mui/icons-material/Clear'
import { DropdownOption } from './DropdownOption'

export interface IDropdownOption {
  key?: string | number | undefined
  value: string | number | undefined
  text?: string
  disabled?: boolean
  hidden?: boolean
}

export interface AdditionalOptions {
  onClear?: () => void
  options?: IDropdownOption[]
  noOptionsText?: string
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type Props = TextFieldProps & Pick<SelectProps, 'multiple' | 'renderValue'> & AdditionalOptions

const visible = { visibility: 'visible' }

export function Dropdown(props: Props) {
  const { multiple, renderValue, onClear, options, noOptionsText = 'No options', ...textFieldProps } = props

  const dropdownProps = {
    sx: {
      ':hover .dropdown_icon': visible,
      [`&.${inputClasses.focused} .dropdown_icon`]: visible
    },
    InputProps:
      onClear && !props.disabled
        ? {
            endAdornment: (
              <IconButton
                className='dropdown_icon'
                sx={{
                  position: 'relative',
                  marginRight: 2,
                  visibility: 'hidden'
                }}
                size='small'
                onClick={onClear}
              >
                <Clear />
              </IconButton>
            )
          }
        : undefined,
    SelectProps: { multiple, renderValue, ...props.SelectProps },
    ...textFieldProps,
    select: true
  }

  if (options && props.children) {
    throw new Error("<Dropdown> Use either options or children, don't use both at the same time.")
  }
  if (options) {
    return (
      <MUITextField {...dropdownProps}>
        {options?.length > 0 && !(options?.length === 1 && options[0].hidden) ? (
          options.map(option => (
            <DropdownOption
              key={option.key || option.value}
              value={option.value}
              disabled={option.disabled}
              style={{ display: option.hidden ? 'none' : undefined }}
            >
              {typeof option.text === 'string' ? option.text : option.value}
            </DropdownOption>
          ))
        ) : (
          <DropdownOption disabled>{noOptionsText}</DropdownOption>
        )}
      </MUITextField>
    )
  }
  return <MUITextField {...dropdownProps} />
}

Dropdown.defaultProps = {
  fullWidth: true,
  autoComplete: 'off',
  size: 'small',
  variant: 'outlined',
  multiple: false
}
