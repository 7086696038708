import { Reducer } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import * as types from 'app/auth/state/types'

interface IUrlReducerState {
  gms: string
  sso: string
}

export type UrlReducerState = Readonly<IUrlReducerState>

const defaultState: UrlReducerState = {
  gms: '',
  sso: ''
}

type UrlReducerBehavior<S> = Record<typeof types.SET_API_URLS, Reducer<S, any>>

const urls = createReducer<UrlReducerState, UrlReducerBehavior<UrlReducerState>>(defaultState, {
  [types.SET_API_URLS]: (_, { payload }) => {
    return payload
  }
})

export default urls
