import { WorkorderStatus, WorkorderType } from '@cbgms/api/modules/enums'
import { IWorkorder } from '@cbgms/api'
import { AnyAction } from 'redux'
import * as types from '../types'
import * as orderTypes from 'app/order/state/types'
import { WorkorderInvoiceType } from '@cbgms/api/modules/enums/workorder-invoice-type'

const defaultWorkorder: IWorkorder = {
  ID: '',
  UUID: '',
  ShopID: '',
  AccountUUID: '',
  ProposalUUID: '',
  InvoiceAccountUUID: '',
  VehicleUUID: '',
  Notes: '',
  InvoiceRemark: '',
  InternalRemark: '',
  CreatedAt: '',
  UpdatedAt: '',
  Status: WorkorderStatus.Quotation,
  PreviousInvoiceRemark: '',
  PreviousInternalRemark: '',
  UseLeaseFlow: false,
  CreatedByUUID: '',
  CreatedByName: '',
  WorkorderType: WorkorderType.WorkShop,
  DefaultInvoiceType: WorkorderInvoiceType.Standard,
  DefaultDepartmentUUID: '',
  DefaultExpenseOfUUID: '',
  DefaultIsClaim: false
}

export const workorderDetailsReducer = (state: IWorkorder = {} as IWorkorder, action: AnyAction): IWorkorder | null => {
  switch (action.type) {
    case types.CLEAR_WORKORDER:
      return { ...defaultWorkorder }
    case orderTypes.RESET_ORDER:
      return { ...defaultWorkorder }
    case types.LOAD_WORKORDER_SUCCESS:
      return action.payload.data?.Data || null
    case types.GET_WORKORDER_WITH_RELATED_SUCCESS:
      return action.payload.data?.Data?.Workorder || null
    default:
      return state
  }
}
