export const LOAD_INVOICE_SEQUENCES = 'LOAD_INVOICE_SEQUENCES'
export const LOAD_INVOICE_SEQUENCES_SUCCESS = 'LOAD_INVOICE_SEQUENCES_SUCCESS'
export const LOAD_INVOICE_SEQUENCES_FAILED = 'LOAD_INVOICE_SEQUENCES_FAILED'

export const LOAD_INVOICE_SEQUENCE = 'LOAD_INVOICE_SEQUENCE'
export const LOAD_INVOICE_SEQUENCE_SUCCESS = 'LOAD_INVOICE_SEQUENCE_SUCCESS'
export const LOAD_INVOICE_SEQUENCE_FAILED = 'LOAD_INVOICE_SEQUENCE_FAILED'

export const CREATE_INVOICE_SEQUENCE = 'CREATE_INVOICE_SEQUENCE'
export const CREATE_INVOICE_SEQUENCE_SUCCESS = 'CREATE_INVOICE_SEQUENCE_SUCCESS'
export const CREATE_INVOICE_SEQUENCE_FAILED = 'CREATE_INVOICE_SEQUENCE_FAILED'

export const UPDATE_INVOICE_SEQUENCE = 'UPDATE_INVOICE_SEQUENCE'
export const UPDATE_INVOICE_SEQUENCE_SUCCESS = 'UPDATE_INVOICE_SEQUENCE_SUCCESS'
export const UPDATE_INVOICE_SEQUENCE_FAILED = 'UPDATE_INVOICE_SEQUENCE_FAILED'

export const TEST_INVOICE_SEQUENCE = 'TEST_INVOICE_SEQUENCE'
export const TEST_INVOICE_SEQUENCE_SUCCESS = 'TEST_INVOICE_SEQUENCE_SUCCESS'
export const TEST_INVOICE_SEQUENCE_FAILED = 'TEST_INVOICE_SEQUENCE_FAILED'

export const DELETE_INVOICE_SEQUENCE = 'DELETE_INVOICE_SEQUENCE'
export const DELETE_INVOICE_SEQUENCE_SUCCESS = 'DELETE_INVOICE_SEQUENCE_SUCCESS'
export const DELETE_INVOICE_SEQUENCE_FAILED = 'DELETE_INVOICE_SEQUENCE_FAILED'
