import { Reducer } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import { ILocalizationSettings } from '@cbgms/api/modules/settings'
import * as types from 'app/auth/state/types'
import { Timezone } from '@carsys/enums/timezone'
import { TimeNotation } from '@carsys/enums/time-notation'

export type LocalisationReducerState = Readonly<ILocalizationSettings>

// Temp undefined, tests break
const defaultState: LocalisationReducerState = {
  Timezone: Timezone.NL_Amsterdam,
  DateNotation: '',
  TimeNotation: TimeNotation.Correct,
  Locale: undefined // Locales.en_GB
} as any

type LocalisationReducerBehavior<S = LocalisationReducerState> = Record<
  typeof types.GET_LOCALISATION_SETTINGS_GLOBAL_SUCCESS,
  Reducer<S, any>
>

const localisationReducer = createReducer<LocalisationReducerState, LocalisationReducerBehavior>(defaultState, {
  [types.GET_LOCALISATION_SETTINGS_GLOBAL_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  }
})

export default localisationReducer
