import i18next from 'i18next'
import { notifier } from '@cbgms/base/notifier/use-notifier'
import { Store } from 'redux'
import { IRootReducerState } from 'state/rootReducer'
import { ApiErrors } from './handlers/ApiErrors'

interface ErrorResponse<T> {
  response: {
    data: T
  }
}

interface ValidationError {
  ErrorMessage: string
  ErrorType: string
  FieldName: string
  OriginalValue: any
}

interface UnknownErrorResponse extends ErrorResponse<string> {}
interface GenericErrorResponse extends ErrorResponse<{ Error: string }> {}

export type ValidationErrors = ValidationError[]
export interface ValidationErrorResponse extends ErrorResponse<{ ValidationErrors: ValidationError[] }> {}

type ErrorResponses = UnknownErrorResponse | GenericErrorResponse | ValidationErrorResponse

export const retrieveError = (error: UnknownErrorResponse | GenericErrorResponse) => {
  const data = error?.response?.data

  if (typeof data === 'string') {
    return data
  }

  return data && data.Error
}

export const credentials = (store: Store<IRootReducerState>) => {
  const state = store.getState()

  const emailAddress = state?.auth?.user?.IdToken?.email
  const token = state?.auth?.tokens?.RefreshToken

  return { emailAddress, token }
}

export const isValidationError = (error: ErrorResponses): error is ValidationErrorResponse => {
  const data = error?.response?.data

  if (typeof data === 'string') {
    return false
  }

  return data && 'ValidationErrors' in data && data.ValidationErrors !== undefined
}

export const toastErrorCode = (errorCode: string) => {
  if (i18next.exists(`Error.API.${errorCode}`)) {
    if (errorCode === ApiErrors.ErrAllowedMaxFileSizeExceeded) {
      notifier.error(i18next.t(`Error.UploadFailed`), i18next.t(`Error.API.${errorCode}`))
    } else {
      notifier.error(i18next.t(`Error.API.${errorCode}`))
    }
    return
  }

  notifier.error(errorCode)
}
