import { BackendModule, ReadCallback } from 'i18next'

const languages: Record<string, () => Promise<any>> = {
  'de-DE': () => import('@cbgms/translations/locales/de-DE.json'),
  'en-GB': () => import('@cbgms/translations/locales/en-GB.json'),
  'fr-BE': () => import('@cbgms/translations/locales/fr-BE.json'),
  'fr-FR': () => import('@cbgms/translations/locales/fr-FR.json'),
  'nl-BE': () => import('@cbgms/translations/locales/nl-BE.json'),
  'nl-NL': () => import('@cbgms/translations/locales/nl-NL.json'),
  'it-IT': () => import('@cbgms/translations/locales/it-IT.json')
}

export class Backend implements Pick<BackendModule, 'type' | 'read'> {
  static type = 'backend'
  readonly type = 'backend' // this is required by the typedef

  async read(language: string, _namespace: string, callback: ReadCallback) {
    try {
      const loadJSON = languages[language]

      if (!loadJSON) {
        throw new Error(`Requested unsupported language: ${language}.`)
      }

      const translations = await loadJSON()

      callback(null, translations.default)

      return
    } catch (e) {
      console.error(e)
    }

    // in case the language can't be found
    callback(null, {})
  }
}

export default Backend
