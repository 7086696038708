export enum OnboardingStepKey {
  ShopDetails = 'shop_details',
  Mechanic = 'mechanic',
  LabourRate = 'labour_rate',
  OrderAccounts = 'order_accounts',
  OpeningHours = 'opening_hours',
  InvoiceNumbers = 'invoice_numbers',
  HaynesProEULA = 'haynespro_eula',
  InvoiceSequenceVehicleSales = 'invoice_sequence_vehicle_sales',
  InvoiceSequenceVehiclePurchase = 'invoice_sequence_vehicle_purchase',
  PaymentMethods = 'payment_methods',
  TyreChange = 'tyre_change',
  TermsAndConditions = 'terms_conditions',
  RevenueGroupDefaults = 'revenue_group_defaults',
  WelcomeScreen = 'welcome_screen',
  VatDuplicateCheck = 'vat_duplicate_check',
  ExternalGMS = 'external_gms',
  WholesalerCredentialsLogin = 'wholesaler_credentials_login',
  SignUpCredentials = 'sign_up_credentials',
  SignupFinished = 'sign_up_finished'
}

export enum OnboardingStepStatus {
  Off = 'off', // don't show this step
  Background = 'background', // this step will be completed in the background
  PreFill = 'pre_fill', // this step will be shown to user, with prefilled information
  Completed = 'completed', // this step is already done
  DelayedToWizard = 'delayed_to_wizard' // this step shouldnt show up in onboarding flow, only in wizard after
}

export enum OnboardingDuplicationCheckType {
  LoginEmail = 'login_email',
  OrderAccount = 'order_account',
  ShopAddress = 'shop_address',
  ShopPhoneNumber = 'shop_phone_number',
  ShopEmail = 'shop_email',
  ShopVat = 'shop_vat'
}

export enum OnboardingDuplicationActionType {
  InviteEmployeeToOtherShop = 'invite_employee_to_other_shop',
  RejectAndShowSupportDeskMessage = 'reject_and_show_support_desk_message'
}
