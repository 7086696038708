export enum RMAReturnReason {
  NA = '',
  PartDefective = 'ReasonPartDefective',
  PartDidNotFit = 'ReasonPartDidNotFit',
  PartIsWrong = 'ReasonPartIsWrong',
  OrderedTooMuch = 'ReasonOrderedTooMuch',
  PartNotNeeded = 'ReasonPartNotNeeded',
  OldPart = 'ReasonOldPart',
  Other = 'ReasonOther'
}
