import { useMemo } from 'react'
import { useShopLoggingData } from './useShopLoggingData'
import { useEmployeeLoggingData } from './useEmployeeLoggingData'
import { useOrderAccountsContext } from 'app/workorder/contexts/use-order-account-context'
import { IEmployee, IOrderAccountData, IShop } from '@cbgms/api'

export interface ILogContextData {
  shop: IShop
  orderAccount: IOrderAccountData | null
  employee: IEmployee
}

export const useLogContextData = (): ILogContextData => {
  const shopData = useShopLoggingData()
  const employeeData = useEmployeeLoggingData()
  const wholesalerData = useOrderAccountsContext({ optional: true })

  return useMemo(
    () => ({ shop: shopData, orderAccount: wholesalerData?.selectedOrderAccount || null, employee: employeeData }),
    [shopData, employeeData, wholesalerData.selectedOrderAccount]
  )
}
