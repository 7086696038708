import { formatEnum } from './formatEnum'

export function enumToOptions<T extends object>(enumerable: T, name: string, suffix?: string) {
  const keys = Object.keys(enumerable) as Array<keyof T>

  return keys.map(key => ({
    key: enumerable[key],
    text: formatEnum(name, enumerable[key], suffix),
    value: enumerable[key]
  }))
}
