import { combineReducers } from 'redux'

import emailHistory, { EmailHistoryReducerState } from 'app/history/state/Email/reducers'
import smsHistory, { SmsHistoryReducerState } from 'app/history/state/Sms/reducers'
import OKRHistory, { OKRHistoryReducerState } from './OKR/reducers'

interface IHistoryReducerState {
  email: EmailHistoryReducerState
  sms: SmsHistoryReducerState
  OKR: OKRHistoryReducerState
}

export type HistoryReducerState = Readonly<IHistoryReducerState>

export default combineReducers<HistoryReducerState>({
  email: emailHistory,
  sms: smsHistory,
  OKR: OKRHistory
})
