import { combineReducers } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { ISearchResponse } from '@cbgms/api'

const defaultResultState: ISearchResponse = {
  Errors: [],
  Results: [],
  Filters: [],
  TotalResultCount: 0,
  LimitedResultCount: 0
}

const results = createReducer(defaultResultState, {
  [types.SEARCH_SUCCESS]: (_, { payload }) => {
    return payload.data.Data === null ? [] : payload.data.Data
  },
  [types.SEARCH_FAILED]: () => defaultResultState
})

const allResults = createReducer(defaultResultState, {
  [types.SEARCH_ALL_SUCCESS]: (_, { payload }) => {
    return payload.data.Data === null ? [] : payload.data.Data
  },
  [types.SEARCH_ALL_FAILED]: () => defaultResultState
})

const searchIsLoading = createReducer(false, {
  [types.SEARCH]: () => true,
  [types.SEARCH_SUCCESS]: () => false,
  [types.SEARCH_FAILED]: () => false
})

const searchReducer = combineReducers({
  searchIsLoading,
  allResults,
  results
})

export default searchReducer
