import React from 'react'
import useTitleSuffix from './use-title-suffix'

/**
 *  usePageTitle will set a new title directly in the dom.
 *  The tab description will be more descriptive to the end user.
 *  ideally only 1 should ever be used at the time. otherwise the list running hook wil set the title.
 *
 *  when no title is provided. It will render the application name
 * */
export const usePageTitle = (title?: string) => {
  const suffix = useTitleSuffix()

  React.useEffect(() => {
    document.title = title || suffix
    return () => {
      // on un-render, reset, We expect another instance of usePageTitle to come in at another treeNode
      document.title = suffix
    }
  }, [title])
}
