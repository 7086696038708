import { useCallback } from 'react'
import { sendViewCartEvent } from 'utils/TagManager/tag-manager'
import { ILogContextData } from './useLogContextData'
import { ICartTotals } from 'app/order/components/Cart/OrderCartContext'
import { IWorkorderLine } from '@cbgms/api'

export interface ViewCartEventParams {
  order: {
    orderUUID: string
    totals: ICartTotals
    orderLines: IWorkorderLine[]
  }
}

export type ViewCartEvent = (data: ViewCartEventParams) => void
/**
 * useViewCartLoggerFunc will create the ViewCart logging function,
 * Calling Google analytics / tag manager and other logging platforms
 */
export const useViewCartLoggerFunc = (ctx: ILogContextData) => {
  const googleTagManagerViewCartLogger = useCallback(
    (data: ViewCartEventParams) => sendViewCartEvent(ctx, data.order),
    [sendViewCartEvent, ctx]
  )

  return useCallback(
    (data: ViewCartEventParams) => {
      try {
        googleTagManagerViewCartLogger(data)
      } catch (e) {
        console.error('unable to log ViewCart event', e)
      }
    },
    [googleTagManagerViewCartLogger]
  )
}
