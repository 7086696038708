export const INVITE_USER = 'INVITE_USER'
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS'
export const INVITE_USER_FAILED = 'INVITE_USER_FAILED'

export const GET_WHOLESALERS = 'GET_LIST_OF_WHOLESALERS'
export const GET_WHOLESALERS_SUCCESS = 'GET_LIST_OF_WHOLESALERS_SUCCESS'
export const GET_WHOLESALERS_FAILED = 'GET_LIST_OF_WHOLESALERS_FAILED'
export const SET_WHOLESALER = 'SET_SELECT_WHOLESALER'

export const STORE_WHOLESALER = 'STORE_WHOLESALER'
export const STORE_WHOLESALER_SUCCESS = 'STORE_WHOLESALER_SUCCESS'
export const STORE_WHOLESALER_FAILED = 'STORE_WHOLESALER_FAILED'

export const UPDATE_WHOLESALER = 'UPDATE_WHOLESALER'
export const UPDATE_WHOLESALER_SUCCESS = 'UPDATE_WHOLESALER_SUCCESS'
export const UPDATE_WHOLESALER_FAILED = 'UPDATE_WHOLESALER_FAILED'

export const OAUTH_CHECK = 'OAUTH_CHECK'
export const OAUTH_CHECK_SUCCESS = 'OAUTH_CHECK_SUCCESS'
export const OAUTH_CHECK_FAILED = 'OAUTH_CHECK_FAILED'

export const OAUTH_REVOKE = 'OAUTH_REVOKE'
export const OAUTH_REVOKE_SUCCESS = 'OAUTH_REVOKE_SUCCESS'
export const OAUTH_REVOKE_FAILED = 'OAUTH_REVOKE_FAILED'

export const DELETE_ORDER_ACCOUNT = 'DELETE_ORDER_ACCOUNT'
export const DELETE_ORDER_ACCOUNT_SUCCESS = 'DELETE_ORDER_ACCOUNT_SUCCESS_SC'
export const DELETE_ORDER_ACCOUNT_FAILED = 'DELETE_ORDER_ACCOUNT_FAILED'

export const GET_ORDER_ACCOUNT = 'GET_ORDER_ACCOUNT'
export const GET_ORDER_ACCOUNT_SUCCESS = 'GET_ORDER_ACCOUNT_SUCCESS'
export const GET_ORDER_ACCOUNT_FAILED = 'GET_ORDER_ACCOUNT_FAILED'
