import { GET_USER_EMPLOYEE_SUCCESS, SET_ACCESS_TOKEN, SET_CURRENT_SHOP, SET_ONBOARDING_TOKEN } from '../types'
import { IEmployee } from '@cbgms/api'

interface GetEmployeeSuccess {
  type: typeof GET_USER_EMPLOYEE_SUCCESS
  payload: {
    data: {
      Data: IEmployee
    }
  }
}

interface SetCurrentShop {
  type: typeof SET_CURRENT_SHOP
  payload: {
    currentShop: string
  }
}

interface SetAccessToken {
  type: typeof SET_ACCESS_TOKEN
}

interface SetOnboardingToken {
  type: typeof SET_ONBOARDING_TOKEN
}

type Actions = GetEmployeeSuccess | SetCurrentShop | SetAccessToken | SetOnboardingToken

const currentShop = (state = '', action: Actions) => {
  switch (action.type) {
    case GET_USER_EMPLOYEE_SUCCESS:
      // only use shop on employee as default, we don't want to override it when the user changed their selected shop
      return !state ? action.payload.data.Data.CurrentShop : state
    case SET_CURRENT_SHOP:
      return action.payload.currentShop
    case SET_ONBOARDING_TOKEN:
      return ''
    default:
      return state
  }
}

export default currentShop
