import { CountryCode } from '@carsys/enums/country-code'
import { AccountNumberType, AccountType } from '@cbgms/api/modules/enums'
import { DELETEResponse, GETArrayResponse, GETResponse, POSTRequestBody, PUTRequestBody, PaginatedResponse } from '@cbgms/api'

export * from './account-numbers'

export interface IVisitorAddressHolder {
  VisitorAddress: string
  VisitorAddressExtra: string
  VisitorCity: string
  VisitorCountry: CountryCode
  VisitorHouseNumber: string
  VisitorHouseNumberAddition: string
  VisitorPostalCode: string
  VisitorState: string
}

export interface IPostalAddressHolder {
  PostalAddress: string
  PostalAddressExtra: string
  PostalCity: string
  PostalCountry: CountryCode
  PostalHouseNumber: string
  PostalHouseNumberAddition: string
  PostalPostalCode: string
  PostalState: string
}

export interface IAccountName {
  /** Required if account type is business */
  CompanyName: string
  /** Required if account type is private */
  Name: string
  FirstName: string
  NamePrefix: string
  Title: string
}

export enum SystemAccountType {
  WalkIn = 'walk_in',
  Internal = 'internal',
  Standard = 'standard'
}

export interface IAccount extends IVisitorAddressHolder, IPostalAddressHolder, IAccountName {
  BankBicNumber: string
  BankIbanNumber: string
  CocNumber: string
  CreatedAt: string
  DateOfBirth: string
  Department: string
  EmailAddress: string
  Initials: string
  IsClient: boolean
  IsSupplier: boolean
  SystemAccountType: SystemAccountType
  MobileNumber: string
  PhoneNumber: string
  PhoneNumberRemark?: string
  PhoneNumberSecond?: string
  PhoneNumberSecondRemark?: string
  PhoneNumberThird?: string
  PhoneNumberThirdRemark?: string
  Type: AccountType
  UpdatedAt: string
  UUID: string
  VatNumber: string
  InternalRemark?: string
  IsUseSageCashAccount: boolean
  AccountNumber: string
}

export const IsEditableAccount = (account: Partial<IAccount>) =>
  account?.SystemAccountType !== SystemAccountType.Internal && account?.SystemAccountType !== SystemAccountType.WalkIn

export const IsUnlinkableAccount = (account: Partial<IAccount>) => account?.SystemAccountType !== SystemAccountType.Internal

export type DELETEAccountResponse = DELETEResponse

export type GETAccountResponse = GETResponse<IAccount>

export type GETAccountsResponse = PaginatedResponse<GETAccountsResponseRow>

export interface GETAccountsResponseRow {
  Account: IAccount
}

export type PUTAccountBody = PUTRequestBody<IAccount>

export type POSTAccountBody<TAccountType extends AccountType> = TAccountType extends AccountType.Business
  ? POSTRequestBody<IAccount, 'Type' | 'CompanyName'>
  : TAccountType extends AccountType.Dealer
  ? POSTRequestBody<IAccount, 'Type'>
  : POSTRequestBody<IAccount, 'Type' | 'Name'>

export interface IAccountNumber {
  AccountUUID: string
  Number: number
  NumberType: AccountNumberType
  CreatedAt: string
  UpdatedAt: string
}

export type GETAccountNumbersResponse = GETArrayResponse<IAccountNumber>

export type POSTAccountNumberBody = POSTRequestBody<IAccountNumber, 'NumberType'>

export type PUTAccountNumberBody = PUTRequestBody<IAccountNumber>

export type DELETEAccountNumberResponse = DELETEResponse<IAccountNumber>
