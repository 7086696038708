import { AnyAction } from 'redux'
import { LOAD_ENUMS_SUCCESS } from './types'
import { EnumValues } from '@cbgms/api'

type State = EnumValues
const defaultState: State = {}

function enumsReducer(state: State = defaultState, action: AnyAction): State {
  switch (action.type) {
    case LOAD_ENUMS_SUCCESS:
      return action.payload.data.Data
    default:
      return state
  }
}

export default enumsReducer
