export const GET_ORDER_WITH_RELATED = 'GET_ORDER_WITH_RELATED'
export const GET_ORDER_WITH_RELATED_SUCCESS = 'GET_ORDER_WITH_RELATED_SUCCESS'
export const GET_ORDER_WITH_RELATED_FAILED = 'GET_ORDER_WITH_RELATED_FAILED'

export const GET_ORDER_LINES = 'GET_ORDER_LINES'
export const GET_ORDER_LINES_SUCCESS = 'GET_ORDER_LINES_SUCCESS'
export const GET_ORDER_LINES_FAILED = 'GET_ORDER_LINES_FAILED'

export const GET_CHANGED_ORDER_LINES = 'GET_CHANGED_ORDER_LINES'
export const GET_CHANGED_ORDER_LINES_SUCCESS = 'GET_CHANGED_ORDER_LINES_SUCCESS'
export const GET_CHANGED_ORDER_LINES_FAILED = 'GET_CHANGED_ORDER_LINES_FAILED'

export const PUT_ORDER = 'PUT_ORDER'
export const PUT_ORDER_SUCCESS = 'PUT_ORDER_SUCCESS'
export const PUT_ORDER_FAILED = 'PUT_ORDER_FAILED'

export const PRE_POST_ORDER_LINE = 'PRE_POST_ORDER_LINE'
export const POST_ORDER_LINE = 'POST_ORDER_LINE'
export const POST_ORDER_LINE_SUCCESS = 'POST_ORDER_LINE_SUCCESS'
export const POST_ORDER_LINE_FAILED = 'POST_ORDER_LINE_FAILED'

export const PUT_ORDER_LINE = 'PUT_ORDER_LINE'
export const PUT_ORDER_LINE_SUCCESS = 'PUT_ORDER_LINE_SUCCESS'
export const PUT_ORDER_LINE_FAILED = 'PUT_ORDER_LINE_FAILED'

export const DELETE_ORDER_LINE = 'DELETE_ORDER_LINE'
export const DELETE_ORDER_LINE_SUCCESS = 'DELETE_ORDER_LINE_SUCCESS'
export const DELETE_ORDER_LINE_FAILED = 'DELETE_ORDER_LINE_FAILED'

export const DELETE_ALL_ORDER_LINES = 'DELETE_ALL_ORDER_LINES'
export const DELETE_ALL_ORDER_LINES_SUCCESS = 'DELETE_ALL_ORDER_LINES_SUCCESS'
export const DELETE_ALL_ORDER_LINES_FAILED = 'DELETE_ALL_ORDER_LINES_FAILED'

export const RESET_ORDER = 'RESET_ORDER'

export const GET_PRODUCTS_BY_ORDER = 'GET_PRODUCTS_BY_ORDER'
export const GET_PRODUCTS_BY_ORDER_SUCCESS = 'GET_PRODUCTS_BY_ORDER_SUCCESS'
export const GET_PRODUCTS_BY_ORDER_FAILED = 'GET_PRODUCTS_BY_ORDER_FAILED'

export const ADD_PRODUCT_TO_ORDER = 'ADD_PRODUCT_TO_ORDER'
export const REMOVE_PRODUCT_FROM_ORDER = 'REMOVE_PRODUCT_FROM_ORDER'

export const RECTIFY_ORDER_CART = 'RECTIFY_ORDER_CART'
export const RECTIFY_ORDER_CART_SUCCESS = 'RECTIFY_ORDER_CART_SUCCESS'
export const RECTIFY_ORDER_CART_FAILED = 'RECTIFY_ORDER_CART_FAILED'

export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'
export const SET_ORDER_STATUS_SUCCESS = 'SET_ORDER_STATUS_SUCCESS'
export const SET_ORDER_STATUS_FAILED = 'SET_ORDER_STATUS_FAILED'

export const SET_DISCOUNT = 'SET_DISCOUNT'
export const SET_DISCOUNT_SUCCESS = 'SET_DISCOUNT_SUCCESS'
export const SET_DISCOUNT_FAILED = 'SET_DISCOUNT_FAILED'
