import { Action, AnyAction } from 'redux'

export interface AsyncAction<R = any> extends Action<string[]>, AnyAction {
  __ReturnType: R
  type: string[]
}

export function AsyncAction<R>(action: Action<string[]> & AnyAction): AsyncAction<R> {
  return action as any
}

export type DispatchReturnValue<T extends AnyAction = AnyAction> = T extends AsyncAction ? Promise<T['__ReturnType']> : T
