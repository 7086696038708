import { AccordionProps, default as MuiAccordion } from '@mui/material/Accordion'

import { useCollapseAccordion } from './use-collapse-accordion'
import { useExpandAccordion } from './use-expand-accordion'
import { useIsExpanded } from './use-is-expanded'
import { useRegisterAccordion } from './use-register-accordion'

export interface Props extends AccordionProps {
  name: string
}

export function Accordion({ children, name, ...props }: Props) {
  const expanded = useIsExpanded(name)
  useRegisterAccordion(name)
  const collapse = useCollapseAccordion(name)
  const expand = useExpandAccordion(name)

  return (
    <MuiAccordion
      elevation={0}
      sx={{ borderRadius: 1, '&::before': { display: 'none' } }}
      variant='outlined'
      {...props}
      expanded={expanded}
      onChange={expanded ? collapse : expand}
    >
      {children}
    </MuiAccordion>
  )
}
