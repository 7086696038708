export const GET_DEFAULT_ORDER_ACCOUNT = 'GET_DEFAULT_ORDER_ACCOUNT'
export const GET_DEFAULT_ORDER_ACCOUNT_SUCCESS = 'GET_DEFAULT_ORDER_ACCOUNT_SUCCESS'
export const GET_DEFAULT_ORDER_ACCOUNT_FAILD = 'GET_DEFAULT_ORDER_ACCOUNT_FAILD'

export const SET_DEFAULT_ORDER_ACCOUNT = 'SET_DEFAULT_ORDER_ACCOUNT'
export const SET_DEFAULT_ORDER_ACCOUNT_SUCCESS = 'SET_DEFAULT_ORDER_ACCOUNT_SUCCESS'
export const SET_DEFAULT_ORDER_ACCOUNT_FAILD = 'SET_DEFAULT_ORDER_ACCOUNT_FAILD'

export const GET_DEFAULT_ORDER_ACCOUNT_DETAILS = 'GET_DEFAULT_ORDER_ACCOUNT_DETAILS'
export const GET_DEFAULT_ORDER_ACCOUNT_DETAILS_SUCCESS = 'GET_DEFAULT_ORDER_ACCOUNT_DETAILS_SUCCESS'
export const GET_DEFAULT_ORDER_ACCOUNT_DETAILS_FAILED = 'GET_DEFAULT_ORDER_ACCOUNT_DETAILS_FAILED'

export const GET_ORDER_ACCOUNT_DETAILS = 'GET_ORDER_ACCOUNT_DETAILS'
export const GET_ORDER_ACCOUNT_DETAILS_SUCCESS = 'GET_ORDER_ACCOUNT_DETAILS_SUCCESS'
export const GET_ORDER_ACCOUNT_DETAILS_FAILED = 'GET_ORDER_ACCOUNT_DETAILS_FAILED'
