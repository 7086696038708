import { ButtonProps, MenuItemProps } from '@mui/material'
import React from 'react'
import { useSplitButtonContext } from './SplitButtonContext'

interface AdditionalButtonProps {
  target?: string
  download?: boolean
}
export interface SplitButtonOptionProps {
  id: string
  MenuItemProps: MenuItemProps<'li', { button?: true }>
  ButtonProps: ButtonProps<'button', AdditionalButtonProps>
}

export function SplitButtonOption({ id, MenuItemProps, ButtonProps }: SplitButtonOptionProps) {
  const { registerOption, unregisterOption } = useSplitButtonContext()

  React.useEffect(() => {
    registerOption({ id, MenuItem: MenuItemProps, Button: ButtonProps })

    return () => unregisterOption(id)
  }, [id, registerOption, unregisterOption, ButtonProps, MenuItemProps])

  return null
}
