import { Box } from '@mui/material'
import { Loader } from './Loader'

export const ViewLoader = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Loader linear loading />
    </Box>
  )
}
