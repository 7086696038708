import { all, put, select, takeEvery } from 'redux-saga/effects'
import { getCountries, lockGetCachedCountries } from 'core/widgets/CountriesDropdown/actions'
import { IRootReducerState } from 'state/rootReducer'
import * as types from 'core/widgets/CountriesDropdown/types'
import * as genArtTypes from 'core/widgets/GenArtsDropdown/types'
import { IGenArtReducerState } from 'core/widgets/GenArtsDropdown/reducers'
import { getGenArts, lockGetCachedGenArts } from 'core/widgets/GenArtsDropdown/actions'
import { CountriesReducerState } from 'core/widgets/CountriesDropdown/reducers'
import { Locales } from '@carsys/enums/locales'

export default function* rootSaga() {
  yield all([watchGetCachedCountries(), watchGetCachedGenArts()])
}

function* getCachedCountries() {
  const cacheTime = 60 * 60 * 1000 // 1 hour
  const now = new Date()

  const { countries, lastFetched, lastLocale, isFetching }: CountriesReducerState['all'] = yield select(
    (state: IRootReducerState) => state.countries.all
  )
  const locale: Locales = yield select((state: IRootReducerState) => state.auth.localisation.Locale)

  const cacheExpired = now.getTime() - lastFetched.getTime() > cacheTime
  const localeChanged = locale !== lastLocale

  if (!isFetching && (!countries || !countries.length || cacheExpired || localeChanged)) {
    yield put(lockGetCachedCountries())
    yield put(getCountries(locale))
  }
}

function* watchGetCachedCountries() {
  yield takeEvery(types.GET_CACHED_COUNTRIES, getCachedCountries)
}

function* getCachedGenArts() {
  const cacheTime = 60 * 60 * 1000 // 1 hour
  const now = new Date()

  const { list, lastFetched, isFetching }: IGenArtReducerState = yield select((state: IRootReducerState) => state.genArts)

  const cacheExpired = now.getTime() - lastFetched.getTime() > cacheTime

  if (!isFetching && (!list || !list.length || cacheExpired)) {
    yield put(lockGetCachedGenArts())
    yield put(getGenArts())
  }
}

function* watchGetCachedGenArts() {
  yield takeEvery(genArtTypes.GET_CACHED_GEN_ARTS, getCachedGenArts)
}
