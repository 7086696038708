import { LocationState } from 'history'
import history from './history'
/**
 * @deprecated use `useHistory` instead.
 */
const navigation = {
  history: () => history,
  goBack: () => {
    return history.goBack()
  },
  push: (url: string, state?: LocationState) => {
    return history.push(url, state)
  },
  replace: (url: string) => {
    return history.replace(url)
  },
  reloadPage: () => {
    window.location.reload()
  },
  removeAnchor: () => {
    return history.replace(window.location.pathname)
  }
}

export default navigation
