import React from 'react'
import MUITextField from '@mui/material/TextField'
import { Typography } from '@mui/material'

type Props = React.ComponentProps<typeof MUITextField>

/**
 * VinTextField will render a vin field for the user to input a vin number.
 *  default styling is applied to render this like a VIN number
 * @param props
 */
export function VinTextField(props: Props) {
  return <MUITextField {...props} />
}

VinTextField.defaultProps = {
  fullWidth: true,
  autoComplete: 'off',
  size: 'small',
  variant: 'outlined',
  inputProps: { maxLength: 17 }
}

export interface textProps extends React.ComponentProps<typeof Typography> {
  value: string | React.ReactNode
}

/**
 * VinText will render static VIN number with default styling.
 * The user cannot change this static text, use a VinTextField for that instead
 * @param props
 */
export function VinText(props: textProps) {
  const { value, ...rest } = props
  return <Typography {...rest}>{value}</Typography>
}

VinText.defaultProps = {
  variant: 'caption',
  sx: {
    display: 'inline !important'
  }
}

// STYLING FOR VIN FONT THAT IS IMPLEMENTED
// VinText.defaultProps = {
//   sx: {
//     fontFamily: 'vin',
//     textTransform: 'uppercase',
//     display: 'inline !important',
//     fontSize: '70% !important' // the vin font is larger by default, use a smaller inherited size.
//   }
// }
