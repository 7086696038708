import { IGenericVehicle, IVehicle, VehicleProvider } from '@cbgms/api'
import { mapFromGenericVehicleToUpdateVehicle } from 'core/vehicles/mappings/mapFromGenericVehicleToIVehicle'

/**
 *
 * @param provider the provider of the field we're updating
 * @param vehicle the generic vehicle that was selected to use in the update
 * @param updateVehicleFieldsWithGenericVehicle whether or not
 * @returns
 */
const updateVehicleFromProviderInfo = (
  provider: VehicleProvider,
  vehicle: Partial<IGenericVehicle>,
  updateVehicleFieldsWithGenericVehicle: boolean
): Partial<IVehicle> => {
  let putData: Partial<IVehicle> = {}

  if (updateVehicleFieldsWithGenericVehicle) {
    putData = mapFromGenericVehicleToUpdateVehicle(vehicle)
  }

  switch (provider) {
    case VehicleProvider.Hbase:
      putData.HbaseID = vehicle.HbaseID
      break
    case VehicleProvider.Haynes:
      putData.HaynesProID = vehicle.HaynesproID
      break
    case VehicleProvider.TecDoc:
      putData.TecdocKtype = vehicle.TecdocKtype
      break
    case VehicleProvider.TecDocPlus:
      putData.TecDocPlusAttributes = { Attributes: vehicle.TecDocPlusAttributes || [] }
      break
  }

  return putData
}

export default updateVehicleFromProviderInfo
