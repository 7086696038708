export enum RecallCriteriaType {
  BodyStyle = 'body_style',
  DamFrom = 'dam_from',
  DamTo = 'dam_to',
  DateFrom = 'date_from',
  DateTo = 'date_to',
  EngineFrom = 'engine_from',
  EngineTo = 'engine_to',
  VinFrom = 'vin_from',
  VinTo = 'vin_to',
  TransmissionType = 'transmission_type',
  TransmissionCode = 'transmission_code',
  TransmissionFrom = 'transmission_from',
  TransmissionTo = 'transmission_to',
  Unknown = 'unknown'
}
