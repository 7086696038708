import pipe from 'lodash/fp/pipe'
import split from 'lodash/fp/split'
import propOr from 'lodash/fp/propOr'

export const getVehicleRegistrationNoCountry = pipe<any, string, any, string>(
  propOr('', 'vehicle.detail.Registration'),
  split('|'),
  ([_, registration]) => registration
)

export const getMaintenancePeriods = propOr([], 'data.Data')
export const getWearPartsIntervals = propOr([], 'data.Data')
