export enum TyreAxlePosition {
  FrontFirst = 'front_first',
  FrontSecond = 'front_second',
  FrontThird = 'front_third',
  RearFirst = 'rear_first',
  RearSecond = 'rear_second',
  RearThird = 'rear_third',
  RearFourth = 'rear_fourth',
  RearFifth = 'rear_fifth',
  RearSixth = 'rear_sixth',
  None = 'none'
}
