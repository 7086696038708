import { APIResponse } from '../../helpers'
import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'
import { GmsOAuthScope } from './authorization-codes'

export type TokenStatus = 'expired' | 'approved' | 'revoked'

export interface UserAccessToken {
  AccessToken: string
  ShopID: string
  EmployeeUUID: string
  Scopes: GmsOAuthScope[]
  Status: TokenStatus
  IssuedAt: string
  ExpiresAt: string
}

export interface IAccessToken {
  App: {
    AppID: string
    Name: string
    Description: string
    CallbackURL: string
  }
  AccessToken: UserAccessToken
  RefreshToken: {
    ExpiresAt: string
    IssuedAt: string
    Status: TokenStatus
  }
}

export interface IAccessTokenList {
  List: Array<IAccessToken>
  Cursor: string
}

export type AccessTokensResponse = APIResponse<IAccessTokenList>

export const getAccessTokens = () =>
  createAsyncApiAction<AccessTokensResponse>('OAUTH_GET_ACCESS_TOKENS', 'gms', {
    method: 'GET',
    url: `/modules/gms-oauth/access-tokens`
  })

export const deleteAccessTokens = (accessToken: any) =>
  createAsyncApiAction<APIResponse<any>>('OAUTH_REVOKE_ACCESS_TOKENS', 'gms', {
    method: 'DELETE',
    url: `/modules/gms-oauth/access-tokens/${accessToken}`
  })
