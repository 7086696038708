import { APIResponse, PUTRequestBody } from '../../helpers'

export interface MyKarSettings {
  Enabled: boolean
}

export interface MyKarPage {
  URL: string
}

export type GETMyKarSettingsResponse = APIResponse<MyKarSettings>

export type PUTMyKarSettingsRequest = PUTRequestBody<MyKarSettings>
export type PUTMyKarSettingsResponse = APIResponse<MyKarSettings>

export type GETMyKarLinkResponse = APIResponse<MyKarPage>

export const getMyKarSettingsRequest = () => ({
  method: 'GET',
  url: '/settings/mykar'
})

export const updateMyKarSettingsRequest = (data: PUTMyKarSettingsRequest) => ({
  method: 'PUT',
  url: '/settings/mykar',
  data
})
