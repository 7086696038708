export enum Subscriptions {
  Module_0 = 'module0',
  Module_GMS = 'moduleGMS',
  Module_Portal = 'modulePortal',
  Module_FleetLeaseWhiteLabel = 'moduleFleetLeaseWhiteLabel',
  Module_Support = 'moduleSupport',
  Module_TimeRegistration = 'moduleTimeRegistration',
  Module_VehicleSales = 'moduleVehicleSales',
  Module_TyreChange = 'moduleTyreChange',
  Module_CourtesyVehicles = 'moduleCourtesyVehicles',
  Module_RentalVehicles = 'moduleRentalVehicles',
  Module_CRM = 'moduleCRM',
  Module_InternalInvoicing = 'moduleInternalInvoicing',
  Module_AddOrderAccount = 'moduleAddOrderAccount',
  Module_FleetLease = 'moduleFleetLease',
  Module_SwitchDefaultOrderAccount = 'moduleSwitchDefaultOrderAccount',
  Module_Counter = 'moduleCounter'
}
