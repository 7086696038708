import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import { getRepairTimes } from 'app/workorder/state/workorder/selectors'
import * as types from 'app/workorder/state/workorder/types'

interface ITopic {
  ID: string
  Description: string
}

interface ITopicsReducerState {
  [key: string]: ReadonlyArray<ITopic>
}

export type TopicsReducerState = Readonly<ITopicsReducerState>

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type ChapterReducerBehavior<S> = Record<typeof types.GET_REPAIR_TIMES_TOPICS_SUCCESS, Reducer<S, any>> &
  Record<typeof types.RESET_REPAIR_TIMES, Reducer<S, any>>

const topics = createReducer<TopicsReducerState, ChapterReducerBehavior<TopicsReducerState>>(
  {},
  {
    [types.GET_REPAIR_TIMES_TOPICS_SUCCESS]: (state, { request, payload }) => {
      return {
        ...state,
        [request.RootNode]: getRepairTimes(payload)
      }
    },
    [types.RESET_REPAIR_TIMES]: () => {
      return {}
    }
  }
)

export default topics
