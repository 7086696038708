import { Reducer } from 'redux'
import { IShop, ModuleKeys } from '@cbgms/api/modules/shops'
import { Subscriptions } from '@carsys/enums/subscription'

import { createReducer } from '@cbgms/base/utils/redux'
import * as types from 'app/shops/state/types'
import * as workorderTypes from 'app/workorder/state/workorder/types'

export type ShopReducerState = Readonly<IShop>

const defaultState: ShopReducerState = { Modules: [Subscriptions.Module_0] } as any

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type ShopReducerBehavior<S = ShopReducerState> = Record<typeof types.GET_SHOP_SUCCESS, Reducer<S, any>> &
  Record<typeof workorderTypes.GET_WORKORDER_WITH_RELATED_PUBLIC_SUCCESS, Reducer<S, any>>

const shop = createReducer<ShopReducerState, ShopReducerBehavior>(defaultState, {
  [types.GET_SHOP_SUCCESS]: (_, { payload }) => {
    payload.data.Data.Modules = getActiveModules(payload.data.Data)
    return payload.data.Data
  },
  [workorderTypes.GET_WORKORDER_WITH_RELATED_PUBLIC_SUCCESS]: (_: any, { payload }: any) => {
    return payload.data.Data.Shop
  }
})

const moduleMap: Record<keyof ModuleKeys, Subscriptions> = {
  ModuleGMSEnabled: Subscriptions.Module_GMS,
  ModulePortalEnabled: Subscriptions.Module_Portal,
  ModuleTimeRegistrationEnabled: Subscriptions.Module_TimeRegistration,
  ModuleVehicleSalesEnabled: Subscriptions.Module_VehicleSales,
  ModuleCourtesyVehiclesEnabled: Subscriptions.Module_CourtesyVehicles,
  ModuleRentalVehiclesEnabled: Subscriptions.Module_RentalVehicles,
  ModuleTyreChangeEnabled: Subscriptions.Module_TyreChange,
  ModuleCRMEnabled: Subscriptions.Module_CRM,
  ModuleInternalInvoicingEnabled: Subscriptions.Module_InternalInvoicing,
  ModuleAddOrderAccount: Subscriptions.Module_AddOrderAccount,
  ModuleFleetLeaseEnabled: Subscriptions.Module_FleetLease,
  ModuleSwitchDefaultOrderAccount: Subscriptions.Module_SwitchDefaultOrderAccount,
  ModuleIsSupportEnabled: Subscriptions.Module_Support,
  ModuleFleetLeaseWhiteLabelEnabled: Subscriptions.Module_FleetLeaseWhiteLabel
}

export const getActiveModules = (shop: Partial<ModuleKeys>) => {
  // Support users don't get access to anything else but support
  if (shop.ModuleIsSupportEnabled) {
    return [Subscriptions.Module_Support]
  }

  return Object.entries(moduleMap)
    .filter(([moduleKey]) => shop[moduleKey as keyof ModuleKeys])
    .map(([, subscription]) => subscription)
    .concat(Subscriptions.Module_0) // module_0 should always be enabled for regular users
}

export default shop
