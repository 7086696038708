import { Environment } from '@carsys/enums/environment'

export interface IPreflight {
  Analytics: string
  Environment: Environment
  TagManager: string
  HotJar: string
  Sentry: string
  FirebaseAppKey: string
  XDomain: string
}

export interface IPreflightResponse {
  Data: IPreflight
}

export const getPreflight = async (apiUrl: string) => {
  const result = await fetch(`${apiUrl}/preflight`)
  const data = (await result.json()) as IPreflightResponse

  return data
}
