export const LOAD_VEHICLES = 'LOAD_VEHICLES'
export const LOAD_VEHICLES_SUCCESS = 'LOAD_VEHICLES_SUCCESS'
export const LOAD_VEHICLES_FAILED = 'LOAD_VEHICLES_FAILED'

export const GET_VEHICLES_BY_REGISTRATION = 'GET_VEHICLES_BY_REGISTRATION'
export const GET_VEHICLES_BY_REGISTRATION_SUCCESS = 'GET_VEHICLES_BY_REGISTRATION_SUCCESS'
export const GET_VEHICLES_BY_REGISTRATION_FAILED = 'GET_VEHICLES_BY_REGISTRATION_FAILED'

export const GET_VEHICLES_BY_VIN = 'GET_VEHICLES_BY_VIN'
export const GET_VEHICLES_BY_VIN_SUCCESS = 'GET_VEHICLES_BY_VIN_SUCCESS'
export const GET_VEHICLES_BY_VIN_FAILED = 'GET_VEHICLES_BY_VIN_FAILED'

export const GET_VEHICLE_WITH_RELATED = 'GET_VEHICLE_WITH_RELATED'
export const GET_VEHICLE_WITH_RELATED_SUCCESS = 'GET_VEHICLE_WITH_RELATED_SUCCESS'
export const GET_VEHICLE_WITH_RELATED_FAILED = 'GET_VEHICLE_WITH_RELATED_FAILED'

export const GET_VEHICLE = 'GET_VEHICLE'
export const GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS'
export const GET_VEHICLE_FAILED = 'GET_VEHICLE_FAILED'

export const LOAD_VEHICLE = 'LOAD_VEHICLE'
export const LOAD_VEHICLE_SUCCESS = 'LOAD_VEHICLE_SUCCESS'
export const LOAD_VEHICLE_FAILED = 'LOAD_VEHICLE_FAILED'

export const RESET_VEHICLE_DETAIL = 'RESET_VEHICLE_DETAIL'
export const SET_VEHICLE_DETAIL = 'SET_VEHICLE_DETAIL'

export const LOAD_ODOMETER_LOG = 'LOAD_ODOMETER_LOG'
export const LOAD_ODOMETER_LOG_SUCCESS = 'LOAD_ODOMETER_LOG_SUCCESS'
export const LOAD_ODOMETER_LOG_FAILED = 'LOAD_ODOMETER_LOG_FAILED'

export const RESET_ODOMETER_LOG = 'RESET_ODOMETER_LOG'

export const DELETE_VEHICLE = 'DELETE_VEHICLE'
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS'
export const DELETE_VEHICLE_FAILED = 'DELETE_VEHICLE_FAILED'

export const LOAD_VEHICLES_WITH_RELATED = 'LOAD_VEHICLES_WITH_RELATED'
export const LOAD_VEHICLES_WITH_RELATED_SUCCESS = 'LOAD_VEHICLES_WITH_RELATED_SUCCESS'
export const LOAD_VEHICLES_WITH_RELATED_FAILED = 'LOAD_VEHICLES_WITH_RELATED_FAILED'

export const CREATE_VEHICLE = 'CREATE_VEHICLE'
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS'
export const CREATE_VEHICLE_FAILED = 'CREATE_VEHICLE_FAILED'

export const GET_MDM_ATTRIBUTES = 'GET_MDM_ATTRIBUTES'
export const GET_MDM_ATTRIBUTES_SUCCESS = 'GET_MDM_ATTRIBUTES_SUCCESS'
export const GET_MDM_ATTRIBUTES_FAILED = 'GET_MDM_ATTRIBUTES_FAILED'

export const UPDATE_MDM_ATTRIBUTES = 'UPDATE_MDM_ATTRIBUTES'
export const UPDATE_MDM_ATTRIBUTES_SUCCESS = 'UPDATE_MDM_ATTRIBUTES_SUCCESS'
export const UPDATE_MDM_ATTRIBUTES_FAILED = 'UPDATE_MDM_ATTRIBUTES_FAILED'

export const REFRESH_MDM_ATTRIBUTES = 'REFRESH_MDM_ATTRIBUTES'
export const REFRESH_MDM_ATTRIBUTES_SUCCESS = 'REFRESH_MDM_ATTRIBUTES_SUCCESS'
export const REFRESH_MDM_ATTRIBUTES_FAILED = 'REFRESH_MDM_ATTRIBUTES_FAILED'

export const LOAD_HAYNESPRO_ADJUSTMENTDATA = 'LOAD_HAYNESPRO_ADJUSTMENTDATA'
export const LOAD_HAYNESPRO_ADJUSTMENTDATA_SUCCESS = 'LOAD_HAYNESPRO_ADJUSTMENTDATA_SUCCESS'
export const LOAD_HAYNESPRO_ADJUSTMENTDATA_FAILED = 'LOAD_HAYNESPRO_ADJUSTMENTDATA_FAILED'

export const LOAD_HAYNESPRO_TECHNICALMANUALS = 'LOAD_HAYNESPRO_TECHNICALMANUALS'
export const LOAD_HAYNESPRO_TECHNICALMANUALS_SUCCESS = 'LOAD_HAYNESPRO_TECHNICALMANUALS_SUCCESS'
export const LOAD_HAYNESPRO_TECHNICALMANUALS_FAILED = 'LOAD_HAYNESPRO_TECHNICALMANUALS_FAILED'

export const LOAD_HAYNESPRO_TECHNICALMANUAL = 'LOAD_HAYNESPRO_TECHNICALMANUAL'
export const LOAD_HAYNESPRO_TECHNICALMANUAL_SUCCESS = 'LOAD_HAYNESPRO_TECHNICALMANUAL_SUCCESS'
export const LOAD_HAYNESPRO_TECHNICALMANUAL_FAILED = 'LOAD_HAYNESPRO_TECHNICALMANUAL_FAILED'

export const RESET_HAYNESPRO_DATA = 'RESET_HAYNESPRO_DATA'

export const ENRICH_VEHICLE = 'ENRICH_VEHICLE'
export const ENRICH_VEHICLE_SUCCESS = 'ENRICH_VEHICLE_SUCCESS'
export const ENRICH_VEHICLE_FAILED = 'ENRICH_VEHICLE_FAILED'

export const LOAD_DETAILS = 'LOAD_DETAILS'
export const LOAD_DETAILS_SUCCESS = 'LOAD_DETAILS_SUCCESS'
export const LOAD_DETAILS_FAILED = 'LOAD_DETAILS_FAILED'

export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS'
export const UPDATE_VEHICLE_FAILED = 'UPDATE_VEHICLE_FAILED'

export const SHOW_SELECT_VEHICLE_TYPE = 'SHOW_SELECT_VEHICLE_TYPE'
export const CLOSE_SELECT_VEHICLE_TYPE = 'CLOSE_SELECT_VEHICLE_TYPE'

export const LOAD_BRANDS = 'LOAD_BRANDS'
export const LOAD_BRANDS_SUCCESS = 'LOAD_BRANDS_SUCCESS'
export const LOAD_BRANDS_FAILED = 'LOAD_BRANDS_FAILED'

export const LOAD_MODELS = 'LOAD_MODELS'
export const LOAD_MODELS_SUCCESS = 'LOAD_MODELS_SUCCESS'
export const LOAD_MODELS_FAILED = 'LOAD_MODELS_FAILED'

export const LOAD_TYPES = 'LOAD_TYPES'
export const LOAD_TYPES_SUCCESS = 'LOAD_TYPES_SUCCESS'
export const LOAD_TYPES_FAILED = 'LOAD_TYPES_FAILED'

export const RESET_VEHICLE_ACCOUNT = 'RESET_VEHICLE_ACCOUNT'

export const GET_MAINTENANCE_SYSTEMS = 'GET_MAINTENANCE_SYSTEMS'
export const GET_MAINTENANCE_SYSTEMS_SUCCESS = 'GET_MAINTENANCE_SYSTEMS_SUCCESS'
export const GET_MAINTENANCE_SYSTEMS_FAILED = 'GET_MAINTENANCE_SYSTEMS_FAILED'
export const RESET_MAINTENANCE_SYSTEMS = 'RESET_MAINTENANCE_SYSTEMS'

export const GET_WEAR_PART_INTERVALS = 'GET_WEAR_PART_INTERVALS'
export const GET_WEAR_PART_INTERVALS_SUCCESS = 'GET_WEAR_PART_INTERVALS_SUCCESS'
export const GET_WEAR_PART_INTERVALS_FAILED = 'GET_WEAR_PART_INTERVALS_FAILED'
export const RESET_WEAR_PART_INTERVALS = 'RESET_WEAR_PART_INTERVALS'

export const LOAD_REFRESH_DATA_VEHICLE = 'LOAD_VEHICLE_REFRESH_DATA'
export const LOAD_REFRESH_DATA_VEHICLE_SUCCESS = 'LOAD_VEHICLE_REFRESH_DATA_SUCCESS'
export const LOAD_REFRESH_DATA_VEHICLE_FAILED = 'LOAD_VEHICLE_REFRESH_DATA_FAILED'

export const GET_APK_HISTORY = 'GET_APK_HISTORY'
export const GET_APK_HISTORY_SUCCESS = 'GET_APK_HISTORY_SUCCESS'
export const GET_APK_HISTORY_FAILED = 'GET_APK_HISTORY_FAILED'

export const GET_MOT_HISTORY = 'GET_MOT_HISTORY'
export const GET_MOT_HISTORY_SUCCESS = 'GET_MOT_HISTORY_SUCCESS'
export const GET_MOT_HISTORY_FAILED = 'GET_MOT_HISTORY_FAILED'

export const CHECK_ODOMETER = 'CHECK_ODOMETER'
export const CHECK_ODOMETER_SUCCESS = 'CHECK_ODOMETER_SUCCESS'
export const CHECK_ODOMETER_FAILED = 'CHECK_ODOMETER_FAILED'

export const GET_INVOICES_BY_VEHICLE = 'GET_INVOICES_BY_VEHICLE'
export const GET_INVOICES_BY_VEHICLE_SUCCESS = 'GET_INVOICES_BY_VEHICLE_SUCCESS'
export const GET_INVOICES_BY_VEHICLE_FAILED = 'GET_INVOICES_BY_VEHICLE_FAILED'

export const GET_REPLACEMENT_VEHICLE = 'GET_REPLACEMENT_VEHICLE'
export const GET_REPLACEMENT_VEHICLE_SUCCESS = 'GET_REPLACEMENT_VEHICLE_SUCCESS'
export const GET_REPLACEMENT_VEHICLE_FAILED = 'GET_REPLACEMENT_VEHICLE_FAILED'

export const GET_RECALLS_BY_VEHICLE = 'GET_RECALLS_BY_VEHICLE'
export const GET_RECALLS_BY_VEHICLE_SUCCESS = 'GET_RECALLS_BY_VEHICLE_SUCCESS'
export const GET_RECALLS_BY_VEHICLE_FAILED = 'GET_RECALLS_BY_VEHICLE_FAILED'

export const GET_VEHICLE_REGISTERED_INFORMATION = 'GET_VEHICLE_REGISTERED_INFORMATION'
export const GET_VEHICLE_REGISTERED_INFORMATION_SUCCESS = 'GET_VEHICLE_REGISTERED_INFORMATION_SUCCESS'
export const GET_VEHICLE_REGISTERED_INFORMATION_FAILED = 'GET_VEHICLE_REGISTERED_INFORMATION_FAILED'

export const GET_VEHICLE_SUGGESTIONS_WITH_PROVIDER = 'GET_VEHICLE_SUGGESTIONS_WITH_PROVIDER'
export const GET_VEHICLE_SUGGESTIONS_WITH_PROVIDER_SUCCESS = 'GET_VEHICLE_SUGGESTIONS_WITH_PROVIDER_SUCCESS'
export const GET_VEHICLE_SUGGESTIONS_WITH_PROVIDER_FAILED = 'GET_VEHICLE_SUGGESTIONS_WITH_PROVIDER_FAILED'

export const GET_VEHICLE_DAMAGE = 'GET_VEHICLE_DAMAGE'
export const GET_VEHICLE_DAMAGE_SUCCESS = 'GET_VEHICLE_DAMAGE_SUCCESS'
export const GET_VEHICLE_DAMAGE_FAILED = 'GET_VEHICLE_DAMAGE_FAILED'

export const UPDATE_VEHICLE_DAMAGE = 'UPDATE_VEHICLE_DAMAGE'
export const UPDATE_VEHICLE_DAMAGE_SUCCESS = 'UPDATE_VEHICLE_DAMAGE_SUCCESS'
export const UPDATE_VEHICLE_DAMAGE_FAILED = 'UPDATE_VEHICLE_DAMAGE_FAILED'

export const GET_VEHICLE_ACTIVE_WARRANTIES = 'GET_VEHICLE_ACTIVE_WARRANTIES'
export const GET_VEHICLE_ACTIVE_WARRANTIES_SUCCESS = 'GET_VEHICLE_ACTIVE_WARRANTIES_SUCCESS'
export const GET_VEHICLE_ACTIVE_WARRANTIES_FAILED = 'GET_VEHICLE_ACTIVE_WARRANTIES_FAILED'

export const GET_VEHICLE_DATA_BY_REGISTRATION = 'GET_VEHICLE_DATA_BY_REGISTRATION'
export const GET_VEHICLE_DATA_BY_REGISTRATION_SUCCESS = 'GET_VEHICLE_DATA_BY_REGISTRATION_SUCCESS'
export const GET_VEHICLE_DATA_BY_REGISTRATION_FAILED = 'GET_VEHICLE_DATA_BY_REGISTRATION_FAILED'
