import { AnyAction } from 'redux'
import * as invoiceTypes from '../../invoice/types'
import propOr from 'lodash/fp/propOr'
import { EmptyUUID } from '@cbgms/base/utils/uuid/constants'

const defaultInvoiceState = {
  Account: {},
  AccountNumbers: {},
  FirstAppointment: {},
  HTML: '',
  Lines: [],
  Shop: {},
  VatTotals: [],
  Vehicle: {},
  Workorder: {
    AccountUUID: EmptyUUID,
    CreditInvoiceUUID: EmptyUUID,
    InvoiceDate: '',
    InvoiceNumber: '',
    IsCredit: false,
    WorkorderInvoiceUUID: EmptyUUID
  }
}

export const workorderInvoiceReducer = (state: any = defaultInvoiceState, action: AnyAction) => {
  switch (action.type) {
    case invoiceTypes.GET_INVOICE_SUCCESS:
    case invoiceTypes.CREATE_CREDIT_INVOICE_SUCCESS:
      return propOr(defaultInvoiceState, 'data.Data', action.payload)
    default:
      return state
  }
}
