import React, { useState } from 'react'
import { SketchPicker, SketchPickerProps } from 'react-color'
import Popper from '@mui/material/Popper'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  colorPicker: {},
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '0.28571429rem',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)',
    display: 'inline-block',
    cursor: 'pointer'
  },
  sketch: {
    boxShadow: 'none !important',
    padding: '0 !important'
  },
  color: {
    width: '36px',
    height: '28px'
  },
  popper: {
    zIndex: 10000
  }
}))

export interface ColorPickerProps extends Omit<SketchPickerProps, 'onChange' | 'ref'> {
  /**
   *  The id of this element. Use this prop to make label and helperText accessible for screen readers.
   */
  id?: string
  /**
   * The label content.
   */
  label?: string
  /**
   * The helper text content.
   */
  helperText?: string
  /**
   * If true, the label is displayed in an error state.
   * @default false
   */
  error?: boolean
  /**
   * If true, the label is displayed as required and the input element is required.
   * @default false
   */
  required?: boolean
  /**
   * The value of this element, required for a controlled component.
   */
  value?: string
  /**
   * If true, the component is disabled.
   * @default false
   */
  disabled?: boolean
  /**
   * Callback fired when the value is changed.
   */
  onChange?: (e: React.ChangeEvent<string>) => void
  /**
   * Name attribute of this element.
   */
  name: string
}

export const ColorPicker: React.FC<ColorPickerProps> = props => {
  const classes = useStyles()
  const [ownValue, setOwnValue] = useState(props.value || '#8c8787')
  const value = props.value || ownValue
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const clickAwayHandler = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const { required, label, helperText, onChange, ...rest } = props

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div className={classNames(classes.colorPicker, props.className)}>
        {label && (
          <Typography gutterBottom color={rest.error ? 'error.main' : 'inherit'}>
            {label}
            {required && '*'}
          </Typography>
        )}
        <Swatch data-testid='colorpicker-' color={value} onClick={handleClick} disabled={props.disabled} />
        <Popper data-testid='colorpicker-popup' open={open} anchorEl={anchorEl} className={classes.popper}>
          <Paper elevation={1}>
            <Box p={1}>
              <SketchPicker
                className={classes.sketch}
                color={value}
                onChange={(color, e) => {
                  setOwnValue(color.hex)
                  onChange?.({ ...e, target: { ...e.target, name: rest.name, value: color.hex } } as any)
                }}
                {...rest}
              />
            </Box>
          </Paper>
        </Popper>
        {!label && required && '*'}
        {helperText && (
          <Typography variant='caption' display='block'>
            {helperText}
          </Typography>
        )}
      </div>
    </ClickAwayListener>
  )
}
interface ISwatchProps {
  color: string
  onClick: React.MouseEventHandler<HTMLDivElement>
  disabled?: boolean
}

export const Swatch: React.FC<ISwatchProps> = ({ color, onClick, disabled }) => {
  const classes = useStyles()
  return (
    <div className={classes.swatch} onClick={!disabled ? onClick : undefined}>
      <div className={classes.color} style={{ backgroundColor: color }} />
    </div>
  )
}

export default React.memo(ColorPicker)
