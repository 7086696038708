import { useCallback } from 'react'
import { sendAddToCartEvent } from 'utils/TagManager/tag-manager'
import { ILogContextData } from './useLogContextData'
import { IWorkorderLine } from '@cbgms/api'

export interface AddToCartEventParams {
  order: {
    orderUUID: string
    orderLines: IWorkorderLine[]
  }
}

export type AddToCartEvent = (data: AddToCartEventParams) => void
/**
 * usePurchaseLoggerFunc will create the purchase logging function,
 * Calling Google analytics / tag manager and other logging platforms
 */
export const useAddToCartLoggerFunc = (ctx: ILogContextData) => {
  const googleTagManagerAddToCartLogger = useCallback(
    (data: AddToCartEventParams) => sendAddToCartEvent(ctx, data.order),
    [sendAddToCartEvent, ctx]
  )

  return useCallback(
    (data: AddToCartEventParams) => {
      try {
        googleTagManagerAddToCartLogger(data)
      } catch (e) {
        console.error('unable to log AddToCart event', e)
      }
    },
    [googleTagManagerAddToCartLogger]
  )
}
