import { GETArrayResponse } from '@cbgms/api'
import { MockFn } from '../../../mock'
import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'

export type GETTyreManufacturersResponse = GETArrayResponse<string>

export const getTyreManufacturers = () =>
  createAsyncApiAction<GETTyreManufacturersResponse>('gms', {
    method: 'GET',
    url: '/miscellaneous/tyre-manufacturers'
  })

export const mockTyreManufacturers: MockFn<string[]> = () => ['Michelin', 'Pirelli', 'Bridgestone']
