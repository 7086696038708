export const EmptyUUID = '00000000-0000-0000-0000-000000000000'

export const UUIDPathRegex = '[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}'

export const UUIDRegex = `^${UUIDPathRegex}$`

export const newRandomUUID = () => {
  // http://www.ietf.org/rfc/rfc4122.txt
  const s = [] as any
  const hexDigits = '0123456789abcdef' as any
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  // eslint-disable-next-line no-bitwise
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  // eslint-disable-next-line no-multi-assign
  s[8] = s[13] = s[18] = s[23] = '-'

  const uuid = s.join('')
  return uuid as string
}
