import { Reducer, combineReducers } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { IDetailPart, IPartStock } from '@cbgms/api/modules/warehouse'
import { EmptyUUID } from '@cbgms/base/utils/uuid'
import { TyreLabel, TyreType, VatType } from '@cbgms/api'

export type DetailReducerState = Readonly<IDetailPart>

const defaultState: DetailReducerState = {
  Part: {
    UUID: EmptyUUID,
    PartgroupUUID: EmptyUUID,
    Code: '',
    Description: '',
    Brand: '',
    Barcode: '',
    TecdocArtno: '',
    TecdocBrand: '',
    TecdocGenarts: [],
    EanCodes: [],
    OeNumbers: [],
    Searchcodes: [],
    VatType: VatType.High,
    Divisible: false,
    EnvironmentalTax: 0.0,
    RemovalFee: 0.0,
    RecommendedSalesPrice: 0.0,
    AccountancyRevenueGroupUUID: '',
    IsTyre: false,
    TyreWidth: 0,
    TyreHeight: 0,
    TyreDiameter: 0.0,
    TyreBrand: '',
    TyreLoadindex: 0,
    TyreSpeedindex: '',
    TyreType: TyreType.None,
    TyreIsRunFlat: false,
    TyreLabelEfficiency: TyreLabel.Unknown,
    TyreLabelGripWet: TyreLabel.Unknown,
    TyreLabelSoundDba: 0,
    TyreLabelSoundIndex: 0,
    CreatedAt: '0001-01-01T00:00:00Z',
    UpdatedAt: '0001-01-01T00:00:00Z'
  },
  PartShop: {
    PartUUID: EmptyUUID,
    ShopID: '',
    SalesPrice: 0.0,
    FixedStockPrice: 0.0,
    CreatedAt: '0001-01-01T00:00:00Z',
    UpdatedAt: '0001-01-01T00:00:00Z'
  }
}

export { defaultState as partsDefaultState }

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type DetailReducerBehavior<S> = Record<typeof types.LOAD_PART_SUCCESS, Reducer<S, any>> &
  Record<typeof types.CREATE_PART_SUCCESS, Reducer<S, any>> &
  Record<typeof types.RESET_PART, Reducer<S, any>>

const detail = createReducer<DetailReducerState, DetailReducerBehavior<DetailReducerState>>(defaultState, {
  [types.LOAD_PART_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [types.CREATE_PART_SUCCESS]: (state, { payload }) => {
    state = state || defaultState
    return {
      Part: {
        ...state.Part,
        ...payload.data.Data
      },
      PartShop: {
        ...state.PartShop
      }
    }
  },
  [types.RESET_PART]: () => defaultState
})

export type StockReducerState = Readonly<IPartStock>

const defaultStockState: StockReducerState = {
  TotalStock: 0,
  Warehouses: []
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type StockReducerBehavior<S> = Record<typeof types.LOAD_PART_STOCK_SUCCESS, Reducer<S, any>> &
  Record<typeof types.RESET_PART, Reducer<S, any>>

const stock = createReducer<StockReducerState, StockReducerBehavior<StockReducerState>>(defaultStockState, {
  [types.LOAD_PART_STOCK_SUCCESS]: (_, { payload }) => {
    return payload.data.Data
  },
  [types.RESET_PART]: () => defaultStockState
})

export interface IPartReducerState {
  detail: DetailReducerState
  stock: StockReducerState
}

type PartReducerState = Readonly<IPartReducerState>

const part = combineReducers<PartReducerState>({
  stock,
  detail
})

export default part
