import { ModifyWorkstation } from './workstation'
import { HttpMethod } from '@cbgms/api/http-method'
import { PUTRequestBody, UUIDResponse } from '@cbgms/api/helpers'

export type PUTWorkstationRequest = PUTRequestBody<ModifyWorkstation>
export type PUTWorkstationResponse = UUIDResponse

export const updateWorkstationRequest = (workstationUUID: string, workstation: PUTWorkstationRequest) => ({
  method: HttpMethod.PUT,
  url: `modules/workstations/${workstationUUID}`,
  data: workstation
})
