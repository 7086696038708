import { Button, ButtonGroup } from '@mui/material'
import ExpandIcon from '@mui/icons-material/Expand'
import CollapseIcon from '@mui/icons-material/VerticalAlignCenter'
import { useTranslation } from 'react-i18next'

import { useCollapseAll } from './use-collapse-all'
import { useExpandAll } from './use-expand-all'

export function ExpandCollapseAllButton() {
  const { t } = useTranslation()
  const collapseAll = useCollapseAll()
  const expandAll = useExpandAll()

  return (
    <ButtonGroup variant='outlined'>
      <Button startIcon={<ExpandIcon />} onClick={expandAll} variant='outlined'>
        {t('Accordion.ExpandAll')}
      </Button>
      <Button startIcon={<CollapseIcon />} onClick={collapseAll} variant='outlined'>
        {t('Accordion.CollapseAll')}
      </Button>
    </ButtonGroup>
  )
}
