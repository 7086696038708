import React from 'react'
import { Skeleton } from '@mui/material'
import { UseQueryResult } from '@cbgms/base/http/use-query'

export interface DeferredTextProps<T> {
  data: UseQueryResult<T, unknown>
  content?: string // either pass content or children
}

/**
 * DeferredText will show a skeleton placeholder until the text is loaded
 * you can either pass a content prop or pass children
 * @param props data is the react-query object to hold the loading state
 * @returns react element
 */
export const DeferredText: React.FC<DeferredTextProps<unknown>> = props => {
  if (props.data.isLoading) {
    return <Skeleton variant='text' />
  }
  if (props.content) {
    // need to wrap this in a fragment to satisfy typing, since string!==React.Element
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.content}</>
  }
  // need to wrap this in a fragment to satisfy typing, since React.ReactNode!==React.Element
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{props.children}</>
}

export default DeferredText
