export enum VehicleIdType {
  TecDocKtype = 'tecdoc_ktype',
  TecDocPlusAttributes = 'tecdocplus_attributes',
  EMDMAttributes = 'emdm_attributes',
  HbaseID = 'hbase_id',
  HaynesProID = 'haynespro_id',
  ETA = 'eta',
  Registration = 'registration',
  VIN = 'vin'
}

export default VehicleIdType

// this is used to create a put that will save a new vehicle id type in the db
export enum VehicleIdTypeVehicleProperty {
  TecDocKtype = 'TecdocKtype',
  TecDocPlusAttributes = 'TecDocPlusAttributes',
  HbaseID = 'HbaseID',
  HaynesProID = 'HaynesProID',
  ETA = 'ETA',
  Registration = 'Registration',
  VIN = 'Vin'
}
