import { logout, setOnboardingToken } from 'app/auth/state/actions'
import { IFailedRequest } from '../errorHandler'
import navigation from 'utils/navigation'

/**
 * signOutTemporaryToken   will reset the token and sign out
 *  during onboarding we might generate a self signed temporary token to we call certain onboarding backend routes.
 *  when you enter the gms token, we should reset your token and send you to the login page
 */
function signOutTemporaryToken(failedRequest: IFailedRequest) {
  failedRequest.store.dispatch(setOnboardingToken({ AccessToken: '', ExpiresIn: 0, IDToken: '', RefreshToken: '', TokenType: 'Bearer' }))
  failedRequest.store.dispatch(logout())
  console.warn('Executed reset of token. User attempted to access application with temporary (onboarding) token')
  navigation.push('/auth/login')
}

export default signOutTemporaryToken
