import React from 'react'
import { CircularProgress, InputAdornment, InputBaseProps, TextField, TextFieldProps } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type SearchInputProps = TextFieldProps & {
  placeholder: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  inputProps?: InputBaseProps
  loading?: boolean
  'data-testid'?: string
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onChange, placeholder, value, inputProps, loading, ...textFieldProps }, ref) => {
    return (
      <TextField
        {...textFieldProps}
        type='search'
        placeholder={placeholder}
        onChange={event => onChange(event)}
        value={value}
        inputRef={ref}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: loading && (
            <InputAdornment position='start' data-testid='loading-input-state'>
              <CircularProgress color='inherit' size={16} />
            </InputAdornment>
          ),
          ...inputProps
        }}
      />
    )
  }
)

SearchInput.defaultProps = {
  variant: 'outlined',
  'data-testid': 'search-input-base'
}
