import { IAccountCommunicationChannelConfig } from '.'

export const getCommunicationChannelsDefaultAccountConfig = () => ({
  method: 'GET',
  url: '/modules/communication-channels/default-account-config'
})

export const getCommunicationChannelsAccountConfig = (accountUUID: string) => ({
  method: 'GET',
  url: `/modules/accounts/${accountUUID}/communication-channels/config`
})

export const putCommunicationChannelsAccountConfig = (accountUUID: string, config: IAccountCommunicationChannelConfig) => ({
  method: 'PUT',
  url: `/modules/accounts/${accountUUID}/communication-channels/config`,
  data: config
})
