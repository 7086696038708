import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'

export interface IPostRoadAssistance {
  Name: string
  CountryCode: string
  ServiceProvider: string
  APIKey: string
  ImageURL: string
  Active: boolean
  HasDefaultKey: boolean
}
export interface IPostRoadAssistanceResponse extends IPostRoadAssistance {
  UUID: string
}

export interface APIKeyModal {
  Code: boolean
  Description: string
}

export interface APIKeyResponseModal {
  Data: APIKeyModal
}

export interface Response {
  Data: IPostRoadAssistance
}

export const createRoadAssistance = (values: IPostRoadAssistance) =>
  createAsyncApiAction<IPostRoadAssistanceResponse>('support', {
    method: 'POST',
    url: '/modules/road-assistance-gms',
    data: values
  })

export const getRoadAssistanceByUUID = (roadAssistanceProviderUUID: string) =>
  createAsyncApiAction<Response>('support', {
    method: 'GET',
    url: `/modules/road-assistance/${roadAssistanceProviderUUID}`
  })

export const updateRoadAssistance = (roadAssistanceProviderUUID: string, values: IPostRoadAssistance) =>
  createAsyncApiAction<IPostRoadAssistanceResponse>('support', {
    method: 'PUT',
    url: `/modules/road-assistance/${roadAssistanceProviderUUID}`,
    data: values
  })

export const validateAPIRoadAssistanceProvider = (provider: IPostRoadAssistance) =>
  createAsyncApiAction<APIKeyResponseModal>('support', {
    method: 'POST',
    url: '/modules/road-assistance/request-products',
    data: provider
  })
