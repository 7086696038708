import { formatEnum } from './formatEnum'

export function formatSliceOfEnum(enumName: string, enumValues: string[]): string {
  const translationKey = enumName.replace('SliceOfEnum', 'Enum')

  if (enumValues !== null) {
    return enumValues.map(enumValue => formatEnum(translationKey, enumValue)).join(', ')
  } else {
    return ''
  }
}
