import React from 'react'
import MUIToggleButtonGroup, { ToggleButtonGroupProps as MUIToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText'

export interface ToggleButtonGroupProps extends Omit<FormControlProps<'fieldset'>, 'children' | 'color' | 'component' | 'onChange'> {
  children?: React.ReactNode
  helperText?: FormHelperTextProps['children']
  label?: FormLabelProps['children']
  onChange?: MUIToggleButtonGroupProps['onChange']
  value?: MUIToggleButtonGroupProps['value']
  exclusive?: MUIToggleButtonGroupProps['exclusive']
  color?: MUIToggleButtonGroupProps['color']
}

export function ToggleButtonGroup({
  children,
  helperText,
  label,
  name,
  onChange,
  value,
  color,
  exclusive,
  ...props
}: ToggleButtonGroupProps) {
  return (
    <FormControl component='fieldset' {...props}>
      {label ? <FormLabel component='legend'>{label}</FormLabel> : null}
      <MUIToggleButtonGroup id={name} color={color} exclusive={exclusive} onChange={onChange} value={value}>
        {children}
      </MUIToggleButtonGroup>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}
