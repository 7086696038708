import * as types from '../types'
import { AnyAction } from 'redux'
import { IAccount } from '@cbgms/api'
import { GET_ORDER_WITH_RELATED_SUCCESS } from 'app/order/state/types'

const defaultAccountState: IAccount | null = null

export const workorderAccountReducer = (state: IAccount | null = defaultAccountState, action: AnyAction): IAccount | null => {
  switch (action.type) {
    case types.GET_WORKORDER_WITH_RELATED_SUCCESS:
      return action.payload.data?.Data.Account
    case GET_ORDER_WITH_RELATED_SUCCESS:
      return action.payload.data?.Data.Account
    default:
      return state
  }
}
