export const GET_CHECKLISTS = 'GET_CHECKLISTS'
export const GET_CHECKLISTS_SUCCESS = 'GET_CHECKLISTS_SUCCESS'
export const GET_CHECKLISTS_FAILED = 'GET_CHECKLISTS_FAILED'

export const GET_CHECKLIST = 'GET_CHECKLIST'
export const GET_CHECKLIST_SUCCESS = 'GET_CHECKLIST_SUCCESS'
export const GET_CHECKLIST_FAILED = 'GET_CHECKLIST_FAILED'

export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST'
export const UPDATE_CHECKLIST_SUCCESS = 'UPDATE_CHECKLIST_SUCCESS'
export const UPDATE_CHECKLIST_FAILED = 'UPDATE_CHECKLIST_FAILED'

export const REGENERATE_CHECKLIST = 'UPDATE_CHECKLIST'
export const REGENERATE_CHECKLIST_SUCCESS = 'UPDATE_CHECKLIST_SUCCESS'
export const REGENERATE_CHECKLIST_FAILED = 'UPDATE_CHECKLIST_FAILED'

export const CREATE_CHECKLIST = 'CREATE_CHECKLIST'
export const CREATE_CHECKLIST_SUCCESS = 'CREATE_CHECKLIST_SUCCESS'
export const CREATE_CHECKLIST_FAILED = 'CREATE_CHECKLIST_FAILED'

export const REMOVE_CHECKLIST = 'REMOVE_CHECKLIST'
export const REMOVE_CHECKLIST_SUCCESS = 'REMOVE_CHECKLIST_SUCCESS'
export const REMOVE_CHECKLIST_FAILED = 'REMOVE_CHECKLIST_FAILED'

export const GET_CHECKLIST_TASKS = 'GET_CHECKLIST_TASKS'
export const GET_CHECKLIST_TASKS_SUCCESS = 'GET_CHECKLIST_TASKS_SUCCESS'
export const GET_CHECKLIST_TASKS_FAILED = 'GET_CHECKLIST_TASKS_FAILED'

export const UPDATE_CHECKLIST_TASK = 'UPDATE_CHECKLIST_TASK'
export const UPDATE_CHECKLIST_TASK_SUCCESS = 'UPDATE_CHECKLIST_TASK_SUCCESS'
export const UPDATE_CHECKLIST_TASK_FAILED = 'UPDATE_CHECKLIST_TASK_FAILED'

export const CREATE_HAYNES_CHECKLIST = 'CREATE_HAYNES_CHECKLIST'
export const CREATE_HAYNES_CHECKLIST_SUCCESS = 'CREATE_HAYNES_CHECKLIST_SUCCESS'
export const CREATE_HAYNES_CHECKLIST_FAILED = 'CREATE_HAYNES_CHECKLIST_FAILED'
