import { IGenericVehicle, IVehicle } from '@cbgms/api'
import {
  BodyType as BodyTypeEnum,
  TransmissionType as TransmissionTypeEnum,
  VehicleType as VehicleTypeEnum
} from '@cbgms/api/modules/enums'

/**
 * mapFromGenericVehicleToUpdateVehicle will take a generic vehicle and map all the fields with values to an update object
 * @param inputVehicle The generic vehicle to use for the update
 * @returns a partial vehicle  that can be used to call the update
 */
export const mapFromGenericVehicleToUpdateVehicle = (inputVehicle: Partial<IGenericVehicle>): Partial<IVehicle> => {
  const {
    // properties we don't want to overwrite
    VIN,
    Registration,
    Remarks, // we don't overwrite Remarks, these are different for DBVehicle and GenericVehicle
    HaynesproID,
    TecdocKtype,
    HbaseID,
    MDMAttributes,
    TecDocPlusAttributes,

    // properties we need to transform
    Weight,
    EngineCapacityCC,
    CilinderCount,
    EngineCodes,
    DateOfOwnership,
    BodyType,
    TransmissionType,
    VehicleType,

    // properties we can simply consume
    ...spreadableProps
  } = inputVehicle

  const resultVehicle: Partial<IVehicle> = { ...spreadableProps }

  // exactly 1 engine code provided. we should overwrite with empty otherwise
  Object.assign(resultVehicle, {
    EngineCode: EngineCodes?.length === 1 ? EngineCodes[0] : '',
    CapacityCC: EngineCapacityCC?.toString() || '',
    Weight: Weight?.toString() || '',
    Cylinders: CilinderCount?.toString() || '',
    Ascription: DateOfOwnership
  })

  // don't blindly reset HaynesProdID
  if (HaynesproID) {
    Object.assign(resultVehicle, { HaynesProID: HaynesproID })
  }
  // don't blindly reset Hbase id
  if (HbaseID) {
    Object.assign(resultVehicle, { HbaseID })
  }
  // don't blindly overwrite TecdocKtype
  if (TecdocKtype) {
    Object.assign(resultVehicle, { TecdocKtype })
  }

  if (MDMAttributes && MDMAttributes.length) {
    resultVehicle.MDMAttributes = { Attributes: MDMAttributes }
  }
  if (TecDocPlusAttributes && TecDocPlusAttributes.length) {
    resultVehicle.TecDocPlusAttributes = { Attributes: TecDocPlusAttributes }
  }

  // reset enums to unknown, otherwise the api will give back errors
  if (BodyType === '') {
    resultVehicle.BodyType = BodyTypeEnum.Unknown
  }
  if (TransmissionType === '') {
    resultVehicle.TransmissionType = TransmissionTypeEnum.Unknown
  }
  if (VehicleType === '') {
    resultVehicle.VehicleType = VehicleTypeEnum.Unknown
  }

  return resultVehicle as Partial<IVehicle>
}
