import { Environment } from '@carsys/enums/environment'
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'

const sidebarOpen = createReducer(false, {
  [types.TOGGLE_SIDEBAR]: state => {
    return !state
  }
})

const sidebarVisible = createReducer(true, {
  [types.SIDEBAR_VISIBLE]: (_, { payload: { value } }) => {
    return value as boolean
  }
})

const iframeTopMenu = createReducer(false, {
  [types.IFRAME_TOP_MENU]: (_, { payload: { value } }) => {
    return value as boolean
  }
})

const simulatedEnvironment = createReducer(null as Environment | null, {
  [types.SIMULATED_ENVIROMENT]: (_, { payload: { value } }) => {
    return value as Environment | null
  }
})

const config = {
  key: 'menu',
  storage,
  // Only store the sidebar open state to local storage. Other reducers are only valid for current session.
  whitelist: ['sidebarOpen']
}

export const menu = persistCombineReducers(config, {
  sidebarOpen,
  sidebarVisible,
  iframeTopMenu,
  simulatedEnvironment
})
