import React from 'react'
import MUIToggleButton, { ToggleButtonProps as MUIToggleButtonProps } from '@mui/material/ToggleButton'
import MuiTooltip from '@mui/material/Tooltip'

interface OptionalTooltipProps {
  tooltip?: React.ReactElement | string /* optionally provide tooltip content. When provided it will wrap the ToggleButton in a tooltip */
}
const OptionalTooltip: React.FC<OptionalTooltipProps> = ({ children, tooltip }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!tooltip) return <>{children}</>
  return (
    <MuiTooltip title={tooltip}>
      <span>{children}</span>
    </MuiTooltip>
  )
}

export interface ToggleButtonProps extends MUIToggleButtonProps, OptionalTooltipProps {}

export const ToggleButton = React.forwardRef<HTMLButtonElement, ToggleButtonProps>(({ tooltip, children, ...props }, ref) => {
  return (
    <OptionalTooltip tooltip={tooltip}>
      <MUIToggleButton ref={ref} {...props}>
        {children}
      </MUIToggleButton>
    </OptionalTooltip>
  )
})

export default ToggleButton
