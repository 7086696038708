import { IListSettings, PUTLocalisationSettingsBody } from './'
import { HttpMethod } from '../../http-method'

export const getSettings = (moduleName: string, type: string) => ({
  method: HttpMethod.GET,
  url: `/settings/global/list/${moduleName}/${type}`
})

export const setSettings = (moduleName: string, type: string, body: IListSettings) => ({
  method: HttpMethod.POST,
  url: `/settings/global/list/${moduleName}/${type}`,
  data: body
})

export const removeSettings = (moduleName: string, type: string) => ({
  method: 'DELETE',
  url: `/settings/global/list/${moduleName}/${type}`
})

export const supportGetSettings = (moduleName: string, type: string) => ({
  method: 'GET',
  url: `/support/settings/global/list/${moduleName}/${type}`
})

export const supportSetSettings = (moduleName: string, type: string, body: IListSettings) => ({
  method: 'POST',
  url: `/support/settings/global/list/${moduleName}/${type}`,
  data: body
})

export const supportRemoveSettings = (moduleName: string, type: string) => ({
  method: 'DELETE',
  url: `/support/settings/global/list/${moduleName}/${type}`
})

export const getLocalisationSettings = (level?: string) => ({
  method: 'GET',
  url: '/settings/localisation',
  params: {
    level
  }
})

export const updateLocalisationSettings = (data: PUTLocalisationSettingsBody, level?: string) => ({
  method: 'PUT',
  url: '/settings/localisation',
  params: {
    level
  },
  data
})

export const removeLocalisationSettings = (level?: string) => ({
  method: 'DELETE',
  url: '/settings/localisation',
  params: {
    level
  }
})
