import React from 'react'
import { OrderAccountsContext } from './OrderAccountsContext'

export interface IOptions {
  // optional: for flexibility some components might have the OrderAccountContext as optional.
  // When set to true will not warn about missng provider
  optional: boolean
}

export function useOrderAccountsContext(options?: IOptions) {
  const context = React.useContext(OrderAccountsContext)

  if ((context === undefined || !context.hasBeenProvided) && !options?.optional) {
    console.error('useOrderAccountsContext must be used within an OrderAccountsContextProvider')
  }

  return context
}
