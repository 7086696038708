import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'

export const PostSendTestPushNotification = () =>
  createAsyncApiAction<{}>('gms', {
    method: 'POST',
    url: `/modules/push-notifications/test/send-test-message`,
    data: {}
  })

interface IRegisterDeviceInput {
  DeviceToken: string
  Platform: string
  DeviceName: string
}

export const PostRegisterDevice = (data: IRegisterDeviceInput) =>
  createAsyncApiAction<{}>('gms', {
    method: 'POST',
    url: `/modules/push-notifications/device/register-device`,
    data
  })

interface IPushPreferences {
  Preferences: IPushPreferencesInput[]
}
interface IPushPreferencesInput {
  FilterName: string
  FilterValue: string
}

export const PatchPushNotificationFilter = (data: IPushPreferences) =>
  createAsyncApiAction<{}>('gms', {
    method: 'PATCH',
    url: `/modules/push-notifications/patch-filters`,
    data
  })
