import { HttpMethod } from '@cbgms/api/http-method'
import { GETResponse } from '@cbgms/api/helpers'
import { Workstation } from './workstation'

export type GETWorkstationResponse = GETResponse<Workstation>

export const getWorkstationRequest = (workstationUUID: string) => ({
  method: HttpMethod.GET,
  url: `modules/workstations/${workstationUUID}`
})
