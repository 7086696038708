import { Reducer } from 'redux'

import { createReducer } from '@cbgms/base/utils/redux'
import { IEmployee } from '@cbgms/api/modules/employees'

import * as types from 'app/settings/state/Employees/types'

export type DetailReducerState = Readonly<Partial<IEmployee>>

const defaultState: DetailReducerState = {}

type DetailReducerBehavior<S = DetailReducerState> = Record<typeof types.LOAD_EMPLOYEE_SUCCESS, Reducer<S, any>>

const detail = createReducer<DetailReducerState, DetailReducerBehavior>(defaultState, {
  [types.LOAD_EMPLOYEE_SUCCESS]: (_, { payload }) => payload.data.Data
})

export default detail
