import { ModifyNotificationSchedule } from './notificationSchedule'
import { HttpMethod } from '@cbgms/api/http-method'
import { UUIDResponse } from '@cbgms/api/helpers'

export type PUTNotificationScheduleRequest = ModifyNotificationSchedule
export type PUTNotificationScheduleResponse = UUIDResponse

export const updateNotificationScheduleRequest = (uuid: string, notificationSchedule: PUTNotificationScheduleRequest) => ({
  method: HttpMethod.PUT,
  url: `modules/notification-schedules/${uuid}`,
  data: notificationSchedule
})
