import { FetchError } from './FetchError'
import { IFetchResponse, IRequest } from './index'

export class FetchAdapter {
  private headers = new Headers()

  constructor(private baseURL = '') {
    // These headers are required to prevent
    // ajax caching issues in IE11.
    this.headers.append('Cache-Control', 'no-cache')
    this.headers.append('Cache-Control', 'no-store')
    this.headers.append('Pragma', 'no-cache')
    this.headers.append('Expires', '0')
  }

  setBaseUrl(url: string) {
    this.baseURL = url
  }

  setHeader(name: string, value: string) {
    this.headers.set(name, value)
  }

  async request(request: IRequest): Promise<any> {
    const { data, method, mode = 'cors', params, url } = request

    const urlWithParams = this.createUrl(url, params)

    const body = this.createBody(data)

    const headers = request.headers ? this.mergeHeaders(request.headers) : this.headers

    const response = await fetch(urlWithParams.toString(), {
      body,
      headers,
      method,
      mode,
      redirect: 'error',
      credentials: request.credentials
    })

    const getter = this.getter(response)

    if (response.ok) {
      return response[getter]()
    }

    const fetchResponse = response as IFetchResponse
    fetchResponse.data = await response[getter]()
    throw new FetchError(request, fetchResponse)
  }

  private mergeHeaders(headers: Headers) {
    const mergedHeaders = new Headers()

    this.headers.forEach((value, key) => {
      mergedHeaders.set(key, value)
    })

    headers.forEach((value, key) => {
      mergedHeaders.set(key, value)
    })

    return mergedHeaders
  }

  private createUrl(path: string, params?: URLSearchParams): URL {
    let url: URL
    const baseURL = this.baseURL

    try {
      url = new URL(path, baseURL)
    } catch {
      throw new Error(`failed to create URL using baseURL '${baseURL}' and path '${path}'`)
    }
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (!key || !value) {
          // Skip undefined params, IE11 will crash on undefined params
          return
        }
        if (!Array.isArray(value)) {
          url.searchParams.append(key, value)
        } else {
          // for arrays we want to add each value separately
          // so the result would look like: ?foo=bar1&foo=bar2.
          // the backend will parse this as an array
          value.forEach(arrValue => {
            url.searchParams.append(key, arrValue)
          })
        }
      })
    }

    return url
  }

  private createBody(data?: any) {
    return data && (data.name || data instanceof FormData) ? data : JSON.stringify(data)
  }

  private getter(response: Response): 'json' | 'text' | 'blob' {
    const contentType = response.headers.get('Content-Type') || ''

    const [type] = contentType.split(';')

    switch (type) {
      case 'application/json':
        return 'json'
      case 'text/plain':
      case 'text/html':
        return 'text'
      default:
        return 'blob'
    }
  }
}

export default FetchAdapter
