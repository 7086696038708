import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import { getWorkorderMaintenancePeriods } from 'app/workorder/state/workorder/selectors'
import * as types from 'app/workorder/state/workorder/types'

export type SelectedPeriodsReducerState = ReadonlyArray<string>

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type SelectedReducerBehavior<S> = Record<typeof types.GET_REPAIR_TIMES_SUCCESS, Reducer<S, any>> &
  Record<typeof types.PUT_REPAIR_TIMES_SUCCESS, Reducer<S, any>>

const selectedPeriods = createReducer<SelectedPeriodsReducerState, SelectedReducerBehavior<SelectedPeriodsReducerState>>([], {
  [types.GET_REPAIR_TIMES_SUCCESS]: (_, { payload }) => {
    return getWorkorderMaintenancePeriods(payload)
  },
  [types.PUT_REPAIR_TIMES_SUCCESS]: (_, { payload }) => {
    return getWorkorderMaintenancePeriods(payload)
  }
})

export default selectedPeriods
