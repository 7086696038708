export const LOAD_LEDGER_ADMINISTRATION = 'LOAD_LEDGER_ADMINISTRATION'
export const LOAD_LEDGER_ADMINISTRATION_SUCCESS = 'LOAD_LEDGER_ADMINISTRATION_SUCCESS'
export const LOAD_LEDGER_ADMINISTRATION_FAILED = 'LOAD_LEDGER_ADMINISTRATION_FAILED'

export const LOAD_LEDGER_ADMINISTRATIONS = 'LOAD_LEDGER_ADMINISTRATIONS'
export const LOAD_LEDGER_ADMINISTRATIONS_SUCCESS = 'LOAD_LEDGER_ADMINISTRATIONS_SUCCESS'
export const LOAD_LEDGER_ADMINISTRATIONS_FAILED = 'LOAD_LEDGER_ADMINISTRATIONS_FAILED'

export const LOAD_LEDGER_REVENUE_GROUPS = 'LOAD_LEDGER_REVENUE_GROUPS'
export const LOAD_LEDGER_REVENUE_GROUPS_SUCCESS = 'LOAD_LEDGER_REVENUE_GROUPS_SUCCESS'
export const LOAD_LEDGER_REVENUE_GROUPS_FAILED = 'LOAD_LEDGER_REVENUE_GROUPS_FAILED'

export const LOAD_LEDGER_ADMINISTRATIONS_LEDGERS = 'LOAD_LEDGER_ADMINISTRATIONS_LEDGERS'
export const LOAD_LEDGER_ADMINISTRATIONS_LEDGERS_SUCCESS = 'LOAD_LEDGER_ADMINISTRATIONS_LEDGERS_SUCCESS'
export const LOAD_LEDGER_ADMINISTRATIONS_LEDGERS_FAILED = 'LOAD_LEDGER_ADMINISTRATIONS_LEDGERS_FAILED'

export const LOAD_LEDGER = 'LOAD_LEDGER'
export const LOAD_LEDGER_SUCCESS = 'LOAD_LEDGER_SUCCESS'
export const LOAD_LEDGER_FAILED = 'LOAD_LEDGER_FAILED'

export const CREATE_LEDGER = 'CREATE_LEDGER'
export const CREATE_LEDGER_SUCCESS = 'CREATE_LEDGER_SUCCESS'
export const CREATE_LEDGER_FAILED = 'CREATE_LEDGER_FAILED'

export const UPDATE_LEDGER = 'UPDATE_LEDGER'
export const UPDATE_LEDGER_SUCCESS = 'UPDATE_LEDGER_SUCCESS'
export const UPDATE_LEDGER_FAILED = 'UPDATE_LEDGER_FAILED'

export const RESET_LEDGER_OPTIONS = 'RESET_LEDGER_OPTIONS'

export const LOAD_REVENUE_LEDGERS = 'LOAD_REVENUE_LEDGERS'
export const LOAD_REVENUE_LEDGERS_SUCCESS = 'LOAD_REVENUE_LEDGERS_SUCCESS'
export const LOAD_REVENUE_LEDGERS_FAILED = 'LOAD_REVENUE_LEDGERS_FAILED'

export const REMOVE_REVENUE_LEDGER = 'REMOVE_REVENUE_LEDGER'
export const REMOVE_REVENUE_LEDGER_SUCCESS = 'REMOVE_REVENUE_LEDGER_SUCCESS'
export const REMOVE_REVENUE_LEDGER_FAILED = 'REMOVE_REVENUE_LEDGER_FAILED'
