export const LOAD_PART_GROUPS = 'LOAD_PART_GROUPS'
export const LOAD_PART_GROUPS_SUCCESS = 'LOAD_PART_GROUPS_SUCCESS'
export const LOAD_PART_GROUPS_FAILED = 'LOAD_PART_GROUPS_FAILED'

export const LOAD_PART_GROUP = 'LOAD_PART_GROUP'
export const LOAD_PART_GROUP_SUCCESS = 'LOAD_PART_GROUP_SUCCESS'
export const LOAD_PART_GROUP_FAILED = 'LOAD_PART_GROUP_FAILED'

export const CREATE_PART_GROUP = 'CREATE_PART_GROUP'
export const CREATE_PART_GROUP_SUCCESS = 'CREATE_PART_GROUP_SUCCESS'
export const CREATE_PART_GROUP_FAILED = 'CREATE_PART_GROUP_FAILED'

export const UPDATE_PART_GROUP = 'UPDATE_PART_GROUP'
export const UPDATE_PART_GROUP_SUCCESS = 'UPDATE_PART_GROUP_SUCCESS'
export const UPDATE_PART_GROUP_FAILED = 'UPDATE_PART_GROUP_FAILED'

export const DELETE_PART_GROUP = 'DELETE_PART_GROUP'
export const DELETE_PART_GROUP_SUCCESS = 'DELETE_PART_GROUP_SUCCESS'
export const DELETE_PART_GROUP_FAILED = 'DELETE_PART_GROUP_FAILED'
