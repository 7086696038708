import { FormatFunction } from 'i18next'

export const i18nInterpolationFormat: FormatFunction = (value, format) => {
  switch (format) {
    case 'toLowerCase':
      return value.toLowerCase()
    default:
      return value
  }
}
