import {
  GETPublicOnboardingStepDataResponse,
  GETPublicThirdPartyOnboardingStepDataResponse,
  IDuplicationCheckInput,
  POSTInviteUserToExistingShopRequest,
  POSTOnboardingSignUp
} from '@cbgms/api'
import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'
import { HttpMethod } from '../../http-method'

export const publicGetWholesalerByUUID = (uuid: string) => ({
  method: HttpMethod.GET,
  url: `/public/onboarding/wholesalers/${uuid}`
})

export const publicPostDuplicationCheck = (data: IDuplicationCheckInput) => ({
  method: HttpMethod.POST,
  url: `/miscellaneous/onboarding/duplication-check`,
  data
})

export const publicPostInviteUserToExistingShop = (data: POSTInviteUserToExistingShopRequest) => ({
  method: HttpMethod.POST,
  url: `/miscellaneous/onboarding/add-user-existing-shop`,
  data
})

export const publicGetHelpdeskParams = () => ({
  method: HttpMethod.GET,
  url: `/miscellaneous/onboarding/helpdesk-params`
})

export const onboardingSelfSignUp = (data: POSTOnboardingSignUp) => ({
  method: HttpMethod.POST,
  url: `/miscellaneous/onboarding/create-company`,
  data
})

export const getOnboardingExternalGMSList = () => ({
  method: HttpMethod.GET,
  url: `/miscellaneous/external-gms`
})

export const getOnboardingRegions = () => ({
  method: HttpMethod.GET,
  url: `/miscellaneous/onboarding/regions`
})

export const onboardingLinkHaynesLicenses = () => ({
  method: HttpMethod.POST,
  url: `/modules/third-party-licenses/haynes`
})

export const publicGetThemeByUUID = (uuid: string) => ({
  method: HttpMethod.GET,
  url: `/public/onboarding/theme/${uuid}`
})

export const publicGetOnboardingStepData = () =>
  createAsyncApiAction<GETPublicOnboardingStepDataResponse>('gms', {
    method: HttpMethod.GET,
    url: `/public/onboarding/steps`
  })

export const publicGetThirdPartyProvidedOnboardingStepData = (ticket: string) =>
  createAsyncApiAction<GETPublicThirdPartyOnboardingStepDataResponse>('gms', {
    method: HttpMethod.GET,
    url: `public/onboarding/ticket/${ticket}`
  })
