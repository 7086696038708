import { faker } from '@faker-js/faker/locale/en'
import { IAccount, SystemAccountType } from './index'
import { CountryCode } from '@carsys/enums/country-code'
import { AccountType } from '../enums'
import { MockFn, referenceDate } from '../../mock'
import { AccountInvoice } from 'modules/workorder-invoices'

export const mockAccount: MockFn<IAccount> = (extend?) => ({
  BankBicNumber: '',
  BankIbanNumber: '',
  CocNumber: faker.datatype.number().toString(),
  CompanyName: faker.company.name(),
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  DateOfBirth: faker.date.past(50, referenceDate).toISOString(),
  Department: faker.commerce.department(),
  EmailAddress: faker.internet.email(),
  FirstName: faker.name.firstName(),
  IsClient: faker.datatype.boolean(),
  IsSupplier: faker.datatype.boolean(),
  Initials: '',
  MobileNumber: faker.phone.number(),
  PhoneNumber: faker.phone.number(),
  Name: faker.name.lastName(),
  NamePrefix: faker.name.prefix(),
  SystemAccountType:
    SystemAccountType[faker.helpers.arrayElement(Object.getOwnPropertyNames(SystemAccountType)) as keyof typeof SystemAccountType],
  Title: faker.name.prefix(),
  Type: AccountType.Private,
  VatNumber: faker.datatype.number().toString(),
  PostalAddress: faker.address.streetAddress(),
  PostalAddressExtra: faker.address.street(),
  PostalHouseNumber: faker.datatype.number(1000).toString(),
  PostalHouseNumberAddition: faker.random.alphaNumeric(1),
  PostalPostalCode: faker.address.zipCode(),
  PostalCountry: CountryCode.NL,
  PostalCity: faker.address.city(),
  PostalState: faker.address.state(),
  VisitorAddress: faker.address.streetAddress(),
  VisitorAddressExtra: faker.address.street(),
  VisitorHouseNumber: faker.datatype.number(1000).toString(),
  VisitorHouseNumberAddition: faker.random.alphaNumeric(1),
  VisitorPostalCode: faker.address.zipCode(),
  VisitorCountry: CountryCode.NL,
  VisitorCity: faker.address.city(),
  VisitorState: faker.address.state(),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  UUID: faker.datatype.uuid(),
  IsUseSageCashAccount: faker.datatype.boolean(),
  AccountNumber: faker.datatype.number().toString(),
  ...extend
})

export const mockSupplierAccount: MockFn<IAccount> = (extend?) =>
  mockAccount({
    Type: AccountType.Business,
    IsSupplier: true,
    ...extend
  })

export const mockAccountInvoice: MockFn<AccountInvoice> = (extend?) => ({
  WorkorderInvoiceUUID: faker.datatype.uuid(),
  InvoiceCreatedAt: new Date(),
  ...mockAccount(),
  ...extend
})
