import { Box, IconButton, Paper, Tooltip } from '@mui/material'
import FileCopy from '@mui/icons-material/FileCopy'
import CheckIcon from '@mui/icons-material/Check'
import { Optional } from 'utility-types'

import { useSafeState } from '@cbgms/base/hooks/use-safe-state'
import { useClipboardCopy } from '@cbgms/base/hooks/use-clipboard-copy'

interface CopyButtonProps {
  textToCopy: string
  tooltipTitle: string
}

const CopyButton = (props: CopyButtonProps) => {
  const copy = useClipboardCopy()
  const [hasCopied, setHasCopied] = useSafeState(false)

  const onCopy = async () => {
    if (hasCopied) return

    await copy(props.textToCopy)
    setHasCopied(true)
    setTimeout(() => setHasCopied(false), 2000)
  }

  return (
    <Tooltip title={props.tooltipTitle}>
      <IconButton
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 1,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: 0,
          border: 1,
          borderBottom: 0,
          borderRight: 0,
          borderColor: 'divider'
        }}
        centerRipple={false}
        onClick={onCopy}
        size='large'
      >
        {hasCopied ? <CheckIcon /> : <FileCopy />}
      </IconButton>
    </Tooltip>
  )
}

export interface CodeSnippetProps {
  children: string
  CopyButtonProps: Optional<CopyButtonProps, 'textToCopy'>
  copyable?: boolean
}

export const CodeSnippet = (props: CodeSnippetProps) => {
  return (
    <Paper
      component='code'
      variant='outlined'
      sx={{
        backgroundColor: 'grey.50',
        color: 'grey.700',
        display: 'block',
        p: 2,
        pr: props.copyable ? 7 : undefined,
        position: 'relative'
      }}
    >
      {props.children}
      {props.copyable ? (
        <Box position='absolute' bottom={0} right={0}>
          <CopyButton textToCopy={props.children} {...props.CopyButtonProps} />
        </Box>
      ) : null}
    </Paper>
  )
}
