import { IFailedRequest } from 'middlewares/errorHandler/errorHandler'

import tokenExpired from 'middlewares/errorHandler/handlers/tokenExpired'
import validationError from 'middlewares/errorHandler/handlers/validationError'
import pageNotFoundHandler from 'middlewares/errorHandler/handlers/pageNotFoundHandler'
import shopNotFound from 'middlewares/errorHandler/handlers/tenderShopNotFoundHandler'
import defaultHandler from 'middlewares/errorHandler/handlers/defaultHandler'
import buildVehicleIdEmptyHandler from 'middlewares/errorHandler/handlers/vehicleIdEmptyHandler'
import onlyThrowErrorHandler from './onlyThrowErrorHandler'
import toastAndThrowErrorHandler from './toastAndThrowErrorHandler'
import { ApiErrors } from './ApiErrors'
import { getGenericVehicleByRegistrationErrors } from '@cbgms/api/modules/generic-vehicle/errors'
import signOutTemporaryToken from './signOutTemporaryToken'
import errVehicleRegistrationNotUniqueHandler from './ErrVehicleRegistrationNotUniqueHandler'
import internalServerAPIError from 'middlewares/errorHandler/handlers/internalServerAPIError'
import badGatewayAPIError from 'middlewares/errorHandler/handlers/badGatewayAPIError'
import gatewayTimeoutAPIError from 'middlewares/errorHandler/handlers/gatewayTimeoutAPIError'
import { StatusCode } from './StatusCode'
import { IInjection } from 'state/types'
import signOutAndRedirectToLogin from './signOutAndRedirectToLogin'

type Handler = (failedRequest: IFailedRequest) => any

interface IHandlers {
  [index: string]: Handler
}

const buildHandlers: (injection: IInjection) => IHandlers = injection => {
  return {
    ErrOdometerCannotBeZero() {},
    ErrNoAddressFound() {},
    ErrOrderAccountNotFound() {},
    ErrInvalidPostalCodeFormat() {},
    ErrWorkorderExpenseOfDepartmentsNotFound: defaultHandler,
    ErrNoCrossResultsFound: defaultHandler,
    ErrAccountNotFound: defaultHandler,
    ErrTenderNotFound: defaultHandler, // When tender search on id doesn't return a value
    ErrNoAppointmentFoundForUUID() {},
    ErrReplacementVehicleNotFound() {},
    ErrInvalidUUID: pageNotFoundHandler,
    ErrWorkorderNumberFormat() {},
    ErrInvalidToken() {}, // MyWorkorder token invalid
    ErrPublicWorkorderNotFound() {},
    ErrNoLatestOdometerLogFound() {},
    ErrUserNotFound() {},
    ErrRevenueGroupVatTypeInUse() {},
    ErrDefectListNotCached() {},
    ErrSundriesNotFound404() {},
    // Delete on checklist when it already has checked items
    ErrDeleteRejected() {},
    // Regenerate on checklist when it is closed
    ErrChecklistClosed() {},
    // This error happens when the registration is not found when you try refreshing it on the products page
    ErrVehicleRegistrationNotFound() {},
    // This error happens when you enter the products page and the MDM attributes are empty
    ErrNoVehicleTypesGiven() {},
    // We show these error messages in SetupWholesalersForm, so we don't want another toast with the same error message.
    ErrInvalidWholesalerCredentials() {},
    ErrAXBEConversionUserNotFound() {},
    // This error is used for haynespro, since we can't render the frame, a error message is already shown.
    ErrNoActiveLicense() {},
    ErrVehicleNotFound() {},
    ErrPolicyNoNotGenerated: defaultHandler,
    ErrPolicyNoAlreadyExist: defaultHandler,
    ErrNoProviderIdMatchWithShopId() {},
    ErrHaynesPRONoLicenseConfigured() {},
    ErrHaynesPROInsufficientPermissions() {},
    ErrRDWTransactionLogMissing: defaultHandler,
    ErrRDWTransactionLogAlreadyHasResponse: defaultHandler,
    ErrVehicleIDEmpty: buildVehicleIdEmptyHandler(injection) as Handler,
    ErrVehicleRegistrationNotUnique: errVehicleRegistrationNotUniqueHandler,
    ErrTokenExpired: tokenExpired,
    ErrTicketExpired() {},
    ErrWorkshopNotFound: shopNotFound,
    ErrLastTyreMutationForVehicleNotFound: defaultHandler,
    // These errors can be thrown by tyre module because not all settings have been set
    ErrTyreSettingsNotFound() {},
    ErrTyreChangeActivityNotLinked() {},
    ErrCannotPickDefaultOrderParameters: toastAndThrowErrorHandler,
    ErrRegistrationNotFound: onlyThrowErrorHandler,
    ErrReplacementVehicleBookingNotFound: toastAndThrowErrorHandler,
    [ApiErrors.HasOrderLinesError]() {},
    [getGenericVehicleByRegistrationErrors.ErrCountryNotSupported]() {},
    [getGenericVehicleByRegistrationErrors.ErrInvalidRegistrationFormat]() {},
    [getGenericVehicleByRegistrationErrors.ErrLicensePlateNotSupported]() {},
    ErrVehicleCouldNotBeEnriched: onlyThrowErrorHandler, // This error is handled in vehicleIdEmptyHandler, so we don't need a toast.
    ErrInvoicePaymentLinkCreationFailed: onlyThrowErrorHandler, // We still need the error to display the alert while creating the invoice
    'email address is unverified': signOutTemporaryToken,
    ErrBearerMalformed: signOutTemporaryToken,
    ErrEmployeeNotInService: failedRequest => signOutAndRedirectToLogin(failedRequest, 'ErrEmployeeNotInService'),
    ErrEmployeeOutOfService: failedRequest => signOutAndRedirectToLogin(failedRequest, 'ErrEmployeeOutOfService'),
    ErrShopNotFound: failedRequest => signOutAndRedirectToLogin(failedRequest, 'ErrShopNotFound'),
    ErrImportStatusNotFound() {},

    ErrNoContractFoundForRegistration() {},

    // These errors can be thrown by lease company
    ErrROBNoDefaultAccountFound() {},
    ErrNoAssociatedServiceOrderLineForRemoteComponent() {},
    ErrServiceOrderNotFound() {},
    ErrUserAccountValidationFailed() {},
    ErrServiceOrderAlreadyInvoiced() {},
    ErrServiceOrderLineCausesConflict() {},
    ErrServiceOrderLineNotFound() {},
    ErrServiceOrderLineUpdateFailed() {},
    ErrServiceOrderInconsistent() {},
    ErrServiceOrderNotReadyToBeInvoiced() {},
    ErrServiceOrderReferenceNotUnique() {},
    ErrServiceOrderUnexpectedInvoiceAmount() {},
    ErrDefaultUserCannotBeRemoved() {},
    ErrFixedPriceCouldNotBeApplied() {},
    ErrDefaultUserNotFound() {},
    ErrInvoiceReferenceCouldNotBeAdded() {},
    ErrLeaseUserAccountNotFound() {},
    ErrNoComponentIDProvided() {},
    ErrNonInvoiceableServiceOrder() {},
    ErrTyreIDCannotBeEmpty() {},
    ErrTyreChangeNotFound() {},
    ErrUnableToDeleteServiceOrder() {},
    ErrUnableToDeleteServiceOrderLine() {},
    ErrUnableToUpdateServiceOrder() {},
    ErrUpdateDefaultUserFailed() {},
    ErrUserNotActive() {},
    ErrUserUpdateAdapterTypeNotAllowed() {},
    ErrUserWithLinkedServiceOrderCannotBeRemoved() {},
    ErrWDLServiceOrderCannotBeInvoiced() {},
    ErrEmployeeNotFound() {},
    ErrCMSContentUnavailable() {}, // ignore, not critical

    ErrFormNotFound() {}, // Workorder form with data does not yet exist, an empty form will be fetched
    ErrNoOrderLinesFound() {}, // Sometimes happens when deleting lines while refreshing order params

    validationError,
    [StatusCode.NotFoundError]: pageNotFoundHandler,
    [StatusCode.InternalServerError]: internalServerAPIError,
    [StatusCode.BadGatewayError]: badGatewayAPIError,
    [StatusCode.GatewayTimeoutError]: gatewayTimeoutAPIError,
    default: defaultHandler
  }
}

export default buildHandlers
