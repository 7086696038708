import { HttpMethod } from '@cbgms/api/http-method'
import { PUTRequestBody, UUIDResponse } from '@cbgms/api/helpers'
import { SchedulerSettings } from './scheduler-settings'

export type PUTSchedulerSettingsRequest = PUTRequestBody<SchedulerSettings>
export type PUTSchedulerSettingsResponse = UUIDResponse

export const updateSchedulerSettingsRequest = (schedulerSettings: PUTSchedulerSettingsRequest) => ({
  method: HttpMethod.PUT,
  url: '/modules/workorder-appointments/agenda/settings',
  data: schedulerSettings
})
