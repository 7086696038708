import { AnyAction } from 'redux'
import { Environment } from '@carsys/enums/environment'
import { IPreflight } from '@cbgms/api/modules/root'

import { SET_ENVIRONMENT_VARIABLES } from './types'

type State = Readonly<IPreflight>

const defaultState: State = {
  Analytics: '',
  TagManager: '',
  Environment: Environment.Production, // Default as production for now because this is the safest option.
  HotJar: '',
  Sentry: '',
  XDomain: '',
  FirebaseAppKey: ''
}

function environmentReducer(state: State = defaultState, action: AnyAction): State {
  switch (action.type) {
    case SET_ENVIRONMENT_VARIABLES:
      return action.payload || defaultState
    default:
      return state
  }
}

export default environmentReducer
