import { combineReducers } from 'redux'

import detail, { DetailReducerState } from 'app/settings/state/Employees/reducers/detail'
import isLoading, { LoadingReducerState } from 'app/settings/state/Employees/reducers/isLoading'
import status, { StatusReducerState } from 'app/settings/state/Employees/reducers/status'
import workHours, { WorkHoursReducerState } from 'app/settings/state/Employees/reducers/workHours'

export interface IEmployeesReducerState {
  detail: DetailReducerState
  isLoading: LoadingReducerState
  status: StatusReducerState
  workHours: WorkHoursReducerState
}

export type EmployeesReducerState = Readonly<IEmployeesReducerState>

export default combineReducers<EmployeesReducerState>({
  detail,
  isLoading,
  status,
  workHours
})
