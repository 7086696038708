export const LOAD_LABOUR_RATES = 'LOAD_LABOUR_RATES'
export const LOAD_LABOUR_RATES_SUCCESS = 'LOAD_LABOUR_RATES_SUCCESS'
export const LOAD_LABOUR_RATES_FAILED = 'LOAD_LABOUR_RATES_FAILED'

export const LOAD_LABOUR_RATE = 'LOAD_LABOUR_RATE'
export const LOAD_LABOUR_RATE_SUCCESS = 'LOAD_LABOUR_RATE_SUCCESS'
export const LOAD_LABOUR_RATE_FAILED = 'LOAD_LABOUR_RATE_FAILED'

export const CREATE_LABOUR_RATE = 'CREATE_LABOUR_RATE'
export const CREATE_LABOUR_RATE_SUCCESS = 'CREATE_LABOUR_RATE_SUCCESS'
export const CREATE_LABOUR_RATE_FAILED = 'CREATE_LABOUR_RATE_FAILED'

export const UPDATE_LABOUR_RATE = 'UPDATE_LABOUR_RATE'
export const UPDATE_LABOUR_RATE_SUCCESS = 'UPDATE_LABOUR_RATE_SUCCESS'
export const UPDATE_LABOUR_RATE_FAILED = 'UPDATE_LABOUR_RATE_FAILED'

export const DELETE_LABOUR_RATE = 'DELETE_LABOUR_RATE'
export const DELETE_LABOUR_RATE_SUCCESS = 'DELETE_LABOUR_RATE_SUCCESS'
export const DELETE_LABOUR_RATE_FAILED = 'DELETE_LABOUR_RATE_FAILED'

export const LOAD_ALL_LABOUR_RATES = 'LOAD_ALL_LABOUR_RATES'
export const LOAD_ALL_LABOUR_RATES_SUCCESS = 'LOAD_ALL_LABOUR_RATES_SUCCESS'
export const LOAD_ALL_LABOUR_RATES_FAILED = 'LOAD_ALL_LABOUR_RATES_FAILED'

export const LOAD_LABOUR_RATE_DEFAULTS = 'LOAD_LABOUR_RATE_DEFAULTS'
export const LOAD_LABOUR_RATE_DEFAULTS_SUCCESS = 'LOAD_LABOUR_RATE_DEFAULTS_SUCCESS'
export const LOAD_LABOUR_RATE_DEFAULTS_FAILED = 'LOAD_LABOUR_RATE_DEFAULTS_FAILED'

export const UPDATE_LABOUR_RATE_DEFAULTS = 'UPDATE_LABOUR_RATE_DEFAULTS'
export const UPDATE_LABOUR_RATE_DEFAULTS_SUCCESS = 'UPDATE_LABOUR_RATE_DEFAULTS_SUCCESS'
export const UPDATE_LABOUR_RATE_DEFAULTS_FAILED = 'UPDATE_LABOUR_RATE_DEFAULTS_FAILED'

export const LOAD_LIST_OF_SMS_PROVIDERS = 'LOAD_LIST_OF_SMS_PROVIDERS'
export const LOAD_LIST_OF_SMS_PROVIDERS_SUCCESS = 'LOAD_LIST_OF_SMS_PROVIDERS_SUCCESS'
export const LOAD_LIST_OF_SMS_PROVIDERS_FAILED = 'LOAD_LIST_OF_SMS_PROVIDERS_FAILED'

export const LOAD_SMS_PROVIDER = 'LOAD_SMS_PROVIDER'
export const LOAD_SMS_PROVIDER_SUCCESS = 'LOAD_SMS_PROVIDER_SUCCESS'
export const LOAD_SMS_PROVIDER_FAILED = 'LOAD_SMS_PROVIDER_FAILED'

export const UPDATE_SMS_PROVIDER = 'UPDATE_SMS_PROVIDER'
export const UPDATE_SMS_PROVIDER_SUCCESS = 'UPDATE_SMS_PROVIDER_SUCCESS'
export const UPDATE_SMS_PROVIDER_FAILED = 'UPDATE_SMS_PROVIDER_FAILED'

export const VALIDATE_SMS_PROVIDER = 'VALIDATE_SMS_PROVIDER'
export const VALIDATE_SMS_PROVIDER_SUCCESS = 'VALIDATE_SMS_PROVIDER_SUCCESS'
export const VALIDATE_SMS_PROVIDER_FAILED = 'VALIDATE_SMS_PROVIDER_FAILED'

export const LOAD_SMS_BALANCE = 'LOAD_SMS_BALANCE'
export const LOAD_SMS_BALANCE_SUCCESS = 'LOAD_SMS_BALANCE_SUCCESS'
export const LOAD_SMS_BALANCE_FAILED = 'LOAD_SMS_BALANCE_FAILED'
