import { IShopWholesaler } from '@cbgms/api/modules/wholesaler'

import { SET_WHOLESALER } from './types'
import { SetWholesaler } from './actions'

type State = Readonly<IShopWholesaler> | null
type Actions = SetWholesaler

export const wholesalerReducer = (state: State = null, action: Actions): State => {
  switch (action.type) {
    case SET_WHOLESALER:
      return action.payload
    default:
      return state
  }
}
