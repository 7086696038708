export const LOAD_ORDER_HISTORY_LIST = 'LOAD_ORDER_HISTORY_LIST'
export const LOAD_ORDER_HISTORY_LIST_SUCCESS = 'LOAD_ORDER_HISTORY_LIST_SUCCESS'
export const LOAD_ORDER_HISTORY_LIST_FAILED = 'LOAD_ORDER_HISTORY_LIST_FAILED'

export const LOAD_ORDER_HISTORY_ITEM = 'LOAD_ORDER_HISTORY_ITEM'
export const LOAD_ORDER_HISTORY_ITEM_SUCCESS = 'LOAD_ORDER_HISTORY_ITEM_SUCCESS'
export const LOAD_ORDER_HISTORY_ITEM_FAILED = 'LOAD_ORDER_HISTORY_ITEM_FAILED'

export const RESET_ORDER_HISTORY_ITEM = 'RESET_ORDER_HISTORY_ITEM'

export const LOAD_ORDER_HISTORY_DETAILS_LIST = 'LOAD_ORDER_HISTORY_DETAILS_LIST'
export const LOAD_ORDER_HISTORY_DETAILS_LIST_SUCCESS = 'LOAD_ORDER_HISTORY_DETAILS_LIST_SUCCESS'
export const LOAD_ORDER_HISTORY_DETAILS_LIST_FAILED = 'LOAD_ORDER_HISTORY_DETAILS_LIST_FAILED'

export const LOAD_ORDER_HISTORY_DETAILS = 'LOAD_ORDER_HISTORY_DETAILS'
export const LOAD_ORDER_HISTORY_DETAILS_SUCCESS = 'LOAD_ORDER_HISTORY_DETAILS_SUCCESS'
export const LOAD_ORDER_HISTORY_DETAILS_FAILED = 'LOAD_ORDER_HISTORY_DETAILS_FAILED'

export const CANCEL_ORDER_LINE = 'CANCEL_ORDER_LINE'
export const CANCEL_ORDER_LINE_SUCCESS = 'CANCEL_ORDER_LINE_SUCCESS'
export const CANCEL_ORDER_LINE_FAILED = 'CANCEL_ORDER_LINE_FAILED'

export const CREAT_ORDER_INVOICE = 'CREAT_ORDER_INVOICE'
export const CREAT_ORDER_INVOICE_SUCCESS = 'CREAT_ORDER_INVOICE_SUCCESS'
export const CREAT_ORDER_INVOICE_FAILED = 'CREAT_ORDER_INVOICE_FAILED'

export const CREAT_PACKING_SLIP_INVOICE = 'CREAT_PACKING_SLIP_INVOICE'
export const CREAT_PACKING_SLIP_INVOICE_SUCCESS = 'CREAT_PACKING_SLIP_INVOICE_SUCCESS'
export const CREAT_PACKING_SLIP_INVOICE_FAILED = 'CREAT_PACKING_SLIP_INVOICE_FAILED'

export const LOAD_CHECK_PACKINGSLIP_EXIST = 'LOAD_CHECK_PACKINGSLIP_EXIST'
export const LOAD_CHECK_PACKINGSLIP_EXIST_SUCCESS = 'LOAD_CHECK_PACKINGSLIP_EXIST_SUCCESS'
export const LOAD_CHECK_PACKINGSLIP_EXIST_FAILED = 'LOAD_CHECK_PACKINGSLIP_EXIST_FAILED'
