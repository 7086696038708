import { useQuery } from '@cbgms/base/http/use-query'
import { getActiveModules } from 'app/auth/state/reducers/shop'
import { getCurrentShop } from 'app/shops/state/actions'
import { useDispatch } from 'react-redux'

export const ONBOARDING_CACHE_KEY = 'ONBOARDING'
export const WHOLESALER_CACHE_KEY = 'WHOLESALER'

const getExistingShopCacheKey = (shopID: string) => [ONBOARDING_CACHE_KEY, WHOLESALER_CACHE_KEY, shopID]

export const useGetOnboardingExistingShop = (shopID?: string) => {
  const dispatch = useDispatch()
  const result = useQuery(
    getExistingShopCacheKey(shopID || ''),
    async () => {
      if (!shopID) {
        return null
      }
      const shopData = await dispatch(getCurrentShop())

      const modules = getActiveModules(shopData.Data)
      return { modules, shop: shopData }
    },
    // don't garbage collect for 4 hours after useQuery becomes inactive
    { refetchOnWindowFocus: false, cacheTime: 240 * 60 * 1000 }
  )

  return result
}
