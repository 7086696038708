import { ApiAction, ApiActionPayload } from '@cbgms/base/redux/create-async-action'
import buildHandlers from 'middlewares/errorHandler/handlers'
import { isValidationError, retrieveError } from 'middlewares/errorHandler/utils'
import { IInjection } from 'state/types'

export interface FailedRequestAction<TPayload = ApiActionPayload> extends ApiAction<TPayload> {
  shouldIgnoreError: boolean
}

export interface IFailedRequest<TPayload = ApiActionPayload> {
  action: FailedRequestAction<TPayload>
  error: any
  request: () => Promise<any>
  store: any
}

export const buildHandle = (injection: IInjection) => {
  const handlers = buildHandlers(injection)

  const handle = (failedRequest: IFailedRequest) => {
    const { error } = failedRequest

    if (isValidationError(error)) {
      return handlers.validationError(failedRequest)
    }

    const errorMessage = retrieveError(error)

    if (!errorMessage) {
      // Decide on what to do with errors that aren't using our format.
      return
    }

    const handler = handlers[errorMessage] ? handlers[errorMessage] : handlers[error.code]

    if (!handler) {
      return handlers.default(failedRequest)
    }

    return handler(failedRequest)
  }
  return handle
}

export default buildHandle
