import React from 'react'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useTranslation } from 'react-i18next'

export const LocalizationProvider: React.FC<{}> = ({ children }) => {
  const { i18n } = useTranslation()
  return (
    <MuiLocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
      {children}
    </MuiLocalizationProvider>
  )
}
