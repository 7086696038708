import { Reducer, combineReducers } from 'redux'
import { IRDWServiceProviderAccount, IRDWServiceProviderAccountsResponse, IRDWSupportedServiceProvider } from '@cbgms/api'
import { emptyRDWServiceProviderAccount } from '@cbgms/api/modules/rdw/mocks'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { RDWCapability, RDWProviderSystem } from '@cbgms/api/modules/enums'

export interface IRDWServiceProviderState {
  detail: ServiceProviderDetailState
  hasOKRServiceProvider: HasOKRServiceProviderState
  supportedServiceProviders: SupportedServiceProvidersState
}

type HasOKRServiceProviderState = Readonly<boolean>
const defaultHasOKRServiceProviderState: HasOKRServiceProviderState = false

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type HasOKRServiceProviderBehavior<S = HasOKRServiceProviderState> = Record<
  typeof types.LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SUCCES,
  Reducer<S, any>
> &
  Record<typeof types.SET_HAS_OKR_SERVICE_PROVIDER_STATE, Reducer<S, any>>

const hasOKRServiceProvider = createReducer<HasOKRServiceProviderState, HasOKRServiceProviderBehavior>(defaultHasOKRServiceProviderState, {
  // check to see if any providers are set up to do OKR
  [types.LOAD_RDW_SERVICE_PROVIDER_ACCOUNTS_SUCCES]: (_, { payload }) => {
    return !!payload.data.Data.map((provider: IRDWServiceProviderAccountsResponse) => provider.RDWProviderAccount).filter(
      (rdwAccount: Partial<IRDWServiceProviderAccount>) =>
        rdwAccount.System === RDWProviderSystem.A2SP && rdwAccount.RDWCapabilities && rdwAccount.RDWCapabilities.includes(RDWCapability.OKR)
    ).length
  },
  [types.SET_HAS_OKR_SERVICE_PROVIDER_STATE]: (_, { payload }) => {
    return payload
  }
})

export type SupportedServiceProvidersState = ReadonlyArray<IRDWSupportedServiceProvider>

const defaultSupportedServiceProvidersState: SupportedServiceProvidersState = []

type SupportedServiceProvidersBehavior<S = SupportedServiceProvidersState> = Record<
  typeof types.LOAD_RDW_SUPPORTED_SERVICE_PROVIDER_SUCCES,
  Reducer<S, any>
>

const supportedServiceProviders = createReducer<SupportedServiceProvidersState, SupportedServiceProvidersBehavior>(
  defaultSupportedServiceProvidersState,
  {
    [types.LOAD_RDW_SUPPORTED_SERVICE_PROVIDER_SUCCES]: (_, { payload }) => {
      return payload.data.Data as IRDWSupportedServiceProvider[]
    }
  }
)

type ServiceProviderDetailState = Readonly<IRDWServiceProviderAccount>

const defaultServiceProviderDetailState: ServiceProviderDetailState = emptyRDWServiceProviderAccount()

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type SupportServicedProvidersBehavior<S = ServiceProviderDetailState> = Record<
  typeof types.LOAD_RDW_SERVICE_PROVIDER_ACCOUNT_SUCCES,
  Reducer<S, any>
> &
  Record<typeof types.RESET_RDW_SERVICE_PROVIDER_ACCOUNT_STATE, Reducer<S, any>>

const detail = createReducer<ServiceProviderDetailState, SupportServicedProvidersBehavior>(defaultServiceProviderDetailState, {
  [types.LOAD_RDW_SERVICE_PROVIDER_ACCOUNT_SUCCES]: (_, { payload }) => {
    return payload.data.Data as IRDWServiceProviderAccount
  },
  [types.RESET_RDW_SERVICE_PROVIDER_ACCOUNT_STATE]: () => {
    return defaultServiceProviderDetailState
  }
})

export default combineReducers<IRDWServiceProviderState>({
  detail,
  hasOKRServiceProvider,
  supportedServiceProviders
})
