export const SEARCH = 'SEARCH'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILED = 'SEARCH_FAILED'

export const SEARCH_ALL = 'SEARCH_ALL'
export const SEARCH_ALL_SUCCESS = 'SEARCH_ALL_SUCCESS'
export const SEARCH_ALL_FAILED = 'SEARCH_ALL_FAILED'

export const CREATE_VEHICLE = 'CREATE_VEHICLE'
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS'
export const CREATE_VEHICLE_FAILED = 'CREATE_VEHICLE_FAILED'

export const GET_SEARCH = 'GET_SEARCH'
export const GET_SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS'
export const GET_SEARCH_FAILED = 'GET_SEARCH_FAILED'

export const GET_GENERIC_VEHICLE_BY_REGISTRATION_VIA_CARDNA = 'GET_GENERIC_VEHICLE_BY_REGISTRATION_VIA_CARDNA'
export const GET_GENERIC_VEHICLE_BY_REGISTRATION_VIA_CARDNA_SUCCESS = 'GET_GENERIC_VEHICLE_BY_REGISTRATION_VIA_CARDNA_SUCCESS'
export const GET_GENERIC_VEHICLE_BY_REGISTRATION_VIA_CARDNA_FAILED = 'GET_GENERIC_VEHICLE_BY_REGISTRATION_VIA_CARDNA_FAILED'
