export const LOAD_SEARCHABLE_COUNTRIES = 'LOAD_SEARCHABLE_COUNTRIES'
export const LOAD_SEARCHABLE_COUNTRIES_SUCCESS = 'LOAD_SEARCHABLE_COUNTRIES_SUCCESS'
export const LOAD_SEARCHABLE_COUNTRIES_FAILED = 'LOAD_SEARCHABLE_COUNTRIES_FAILED'

export const LOAD_VEHICLE_SEARCH_BY_COUNTRY = 'LOAD_VEHICLE_SEARCH_BY_COUNTRY'
export const LOAD_VEHICLE_SEARCH_BY_COUNTRY_SUCCESS = 'LOAD_VEHICLE_SEARCH_BY_COUNTRY_SUCCESS'
export const LOAD_VEHICLE_SEARCH_BY_COUNTRY_FAILED = 'LOAD_VEHICLE_SEARCH_BY_COUNTRY_FAILED'

export const UPDATE_VEHICLE_SEARCH_BY_COUNTRY = 'UPDATE_VEHICLE_SEARCH_BY_COUNTRY'
export const UPDATE_VEHICLE_SEARCH_BY_COUNTRY_SUCCESS = 'UPDATE_VEHICLE_SEARCH_BY_COUNTRY_SUCCESS'
export const UPDATE_VEHICLE_SEARCH_BY_COUNTRY_FAILED = 'UPDATE_VEHICLE_SEARCH_BY_COUNTRY_FAILED'
