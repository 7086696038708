import { combineReducers } from 'redux'

import {
  GETWorkorderActivitiesResponse,
  GETWorkorderActivitiesRow,
  GETWorkorderActivityResponse,
  IWorkorderActivity
} from '@cbgms/api/modules/workorder-activities'

import { LOAD_WORKORDERACTIVITIES_LIST_SUCCESS, LOAD_WORKORDERACTIVITY_SUCCESS, RESET_WORKORDERACTIVITY } from './types'

interface LoadWorkorderActivitiesSuccess {
  type: typeof LOAD_WORKORDERACTIVITIES_LIST_SUCCESS
  payload: {
    data: GETWorkorderActivitiesResponse
  }
}

const defaultListState: GETWorkorderActivitiesRow[] = []

function list(state: GETWorkorderActivitiesRow[] = defaultListState, action: LoadWorkorderActivitiesSuccess) {
  switch (action.type) {
    case LOAD_WORKORDERACTIVITIES_LIST_SUCCESS:
      return action.payload.data.Data
    default:
      return state
  }
}

interface LoadWorkorderActivitySuccess {
  type: typeof LOAD_WORKORDERACTIVITY_SUCCESS
  payload: {
    data: GETWorkorderActivityResponse
  }
}

interface ResetWorkorderActivity {
  type: typeof RESET_WORKORDERACTIVITY
}

type ActivityDetailActions = LoadWorkorderActivitySuccess | ResetWorkorderActivity

type State = IWorkorderActivity

const defaultDetailState: State | null = null

function detail(state: State | null = defaultDetailState, action: ActivityDetailActions): State | null {
  switch (action.type) {
    case LOAD_WORKORDERACTIVITY_SUCCESS: {
      return action.payload.data.Data
    }
    case RESET_WORKORDERACTIVITY:
      return defaultDetailState
    default:
      return state
  }
}

export default combineReducers({
  list,
  detail
})
