import TagManager from 'react-gtm-module'
import {
  ctxToAnalyticsCTX,
  lineCollectionToAddToCart,
  lineCollectionToRemoveFromCart,
  normalizePath,
  orderToPurchase
} from './tag-data-transformer'
import { CommerceActions, NavigationEvent, Order, OrderLineCollection } from './Types'
import { ILogContextData } from 'contexts/Logging/useLogContextData'

const DATA_LAYER_NAME = 'pathDataLayer'

const getDataLayerJSONReplacer = () => {
  const maxDepth = 6 // failsafe, stop recursive depth at 6 layers
  const ignoredKeys = ['__reactFiber']
  const m = new Map() // map for holding onto paths
  const seen = new WeakSet() // set to prune circular objects
  // always skip the window object, trying to stringify the window will break entire app
  // You'll get an  DOMException: Failed to read a named property 'toJSON' from 'Window': Blocked a frame with origin
  seen.add(window)
  seen.add(document) // skip document, just to be safe
  return function (this: object, key: string, value: any) {
    // build the path
    const path = key === '' ? 'root' : `${m.get(this)}.${key}`
    if (value === Object(value)) m.set(value, path)

    // check max depth
    if ((path.match(/\./g) || []).length > maxDepth) return // exceeds max depth

    if (ignoredKeys.find(ignoredKey => key.indexOf(ignoredKey) !== -1)) return // skip any of the ignoredKeys from showing up

    // check for circular references
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) return //  value already seen. stop circles
      seen.add(value)
    }
    return value
  }
}

// datalayerSafeObject will scrub away circular references that cause problems downstream.
// returns the same object with garantue to be safe for datalayer consumption.
const datalayerSafeObject = (a: any): any => {
  const safeJson = JSON.stringify(a, getDataLayerJSONReplacer(), 2)
  if (!safeJson) {
    // data was empty
    return undefined
  }
  return JSON.parse(safeJson)
}

export const sendNavigationEvent = (ctx: ILogContextData, navEvent: NavigationEvent) => {
  const { pathname, search, hash } = navEvent
  const analyticsCTX = ctxToAnalyticsCTX(ctx)

  const dataLayerArgs = {
    dataLayer: datalayerSafeObject({
      ...analyticsCTX,
      event: 'Pageview',
      contentGroup: normalizePath(pathname),
      pagePath: pathname,
      urlSearch: search,
      urlHash: hash
    }),
    dataLayerName: DATA_LAYER_NAME
  }
  TagManager.dataLayer(dataLayerArgs)
}

/*
  HTML tags can be self closing, innertext isn't always present.
  In that case use fallback.
 */
export const sendClickEvent = (
  event: React.MouseEvent<HTMLElement> & { target: { innerText?: string } },
  ctx: ILogContextData,
  data?: any
) => {
  TagManager.dataLayer({
    dataLayer: datalayerSafeObject({
      ...ctxToAnalyticsCTX(ctx),
      event: data?.customEvent || event.target.innerText || data['data-testid'] || 'Unknown click',
      eventData: event,
      data
    }),
    dataLayerName: DATA_LAYER_NAME
  })
}

export const clearEcommerce = () => {
  TagManager.dataLayer({
    dataLayer: { ecommerce: null },
    dataLayerName: DATA_LAYER_NAME
  })
}

export const sendPurchaseEvent = (ctx: ILogContextData, order: Order) => {
  // purge old ecommerce values from datalayer
  clearEcommerce()

  const data = orderToPurchase(ctx, order)
  TagManager.dataLayer({
    dataLayer: datalayerSafeObject({
      ...ctxToAnalyticsCTX(ctx),
      event: CommerceActions.Purchase,
      ecommerce: data
    }),
    dataLayerName: DATA_LAYER_NAME
  })
}

export const sendViewCartEvent = (ctx: ILogContextData, order: Order) => {
  const data = orderToPurchase(ctx, order)
  TagManager.dataLayer({
    dataLayer: datalayerSafeObject({
      ...ctxToAnalyticsCTX(ctx),
      event: CommerceActions.ViewCart,
      ecommerce: data
    }),
    dataLayerName: DATA_LAYER_NAME
  })
}

export const sendAddToCartEvent = (ctx: ILogContextData, lineCollection: OrderLineCollection) => {
  const data = lineCollectionToAddToCart(ctx, lineCollection)
  TagManager.dataLayer({
    dataLayer: datalayerSafeObject({
      ...ctxToAnalyticsCTX(ctx),
      event: CommerceActions.AddToCart,
      ecommerce: data
    }),
    dataLayerName: DATA_LAYER_NAME
  })
}

export const sendRemoveFromCartEvent = (ctx: ILogContextData, lineCollection: OrderLineCollection) => {
  const data = lineCollectionToRemoveFromCart(ctx, lineCollection)
  TagManager.dataLayer({
    dataLayer: datalayerSafeObject({
      ...ctxToAnalyticsCTX(ctx),
      event: CommerceActions.RemoveFromCart,
      ecommerce: data
    }),
    dataLayerName: DATA_LAYER_NAME
  })
}

const setupTagManager = (gtmID: string) => {
  if (gtmID) {
    // do nothing is no gtmID is provided
    const tagManagerArgs = {
      gtmId: gtmID,
      dataLayerName: DATA_LAYER_NAME
    }
    TagManager.initialize(tagManagerArgs)
  }
}

export default setupTagManager
