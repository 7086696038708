import * as React from 'react'
import { VariantType } from 'notistack'
import { Box } from '@mui/material'
import { blue, green, grey, orange, red } from '@mui/material/colors'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface SnackBarVariantIconProps {
  variant: VariantType
}

const SnackBarVariantIcon: React.FC<SnackBarVariantIconProps> = ({ variant }) => {
  let icon: React.ReactNode = ''
  let bgColor = ''

  switch (variant) {
    case 'success':
      icon = <CheckCircleOutlineOutlinedIcon sx={{ fill: theme => theme.palette.success.main }} />
      bgColor = green[50]
      break
    case 'warning':
      icon = <WarningAmberOutlinedIcon sx={{ fill: theme => theme.palette.warning.main }} />
      bgColor = orange[50]
      break
    case 'error':
      icon = <CancelOutlinedIcon sx={{ fill: theme => theme.palette.error.main }} />
      bgColor = red[50]
      break
    case 'info':
      icon = <InfoOutlinedIcon sx={{ fill: theme => theme.palette.info.main }} />
      bgColor = blue[50]
      break
    default:
      icon = <InfoOutlinedIcon sx={{ fill: '#333' }} />
      bgColor = grey[50]
      break
  }

  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        p: 1.5,
        borderRadius: theme => `${theme.shape.borderRadius}px`,
        display: 'flex',
        alignSelf: 'baseline'
      }}
    >
      {icon}
    </Box>
  )
}

export default SnackBarVariantIcon
