export const LOAD_LIST_OF_SMS_PROVIDERS = 'LOAD_LIST_OF_SMS_PROVIDERS'
export const LOAD_LIST_OF_SMS_PROVIDERS_SUCCESS = 'LOAD_LIST_OF_SMS_PROVIDERS_SUCCESS'
export const LOAD_LIST_OF_SMS_PROVIDERS_FAILED = 'LOAD_LIST_OF_SMS_PROVIDERS_FAILED'

export const LOAD_SMS_PROVIDER = 'LOAD_SMS_PROVIDER'
export const LOAD_SMS_PROVIDER_SUCCESS = 'LOAD_SMS_PROVIDER_SUCCESS'
export const LOAD_SMS_PROVIDER_FAILED = 'LOAD_SMS_PROVIDER_FAILED'

export const UPDATE_SMS_PROVIDER = 'UPDATE_SMS_PROVIDER'
export const UPDATE_SMS_PROVIDER_SUCCESS = 'UPDATE_SMS_PROVIDER_SUCCESS'
export const UPDATE_SMS_PROVIDER_FAILED = 'UPDATE_SMS_PROVIDER_FAILED'

export const VALIDATE_SMS_PROVIDER = 'VALIDATE_SMS_PROVIDER'
export const VALIDATE_SMS_PROVIDER_SUCCESS = 'VALIDATE_SMS_PROVIDER_SUCCESS'
export const VALIDATE_SMS_PROVIDER_FAILED = 'VALIDATE_SMS_PROVIDER_FAILED'

export const LOAD_SMS_BALANCE = 'LOAD_SMS_BALANCE'
export const LOAD_SMS_BALANCE_SUCCESS = 'LOAD_SMS_BALANCE_SUCCESS'
export const LOAD_SMS_BALANCE_FAILED = 'LOAD_SMS_BALANCE_FAILED'

export const RESET_SMS_BALANCE = 'RESET_SMS_BALANCE'
