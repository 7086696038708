import { IArrayResponse } from '../../response'
import { NotificationSchedule } from './notificationSchedule'
import { HttpMethod } from '../../http-method'

export type GETNotificationSchedulesResponse = IArrayResponse<NotificationSchedule>

export const getNotificationSchedulesRequest = () => ({
  method: HttpMethod.GET,
  url: 'modules/notification-schedules'
})
