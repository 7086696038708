import React from 'react'

export interface IGridV1ContextExpose {
  viewHeightPx: number
  topbarHeightPx: number
  margin?: number
  setTopBarHeight: (height: number) => void
  setMargin: (margin: number) => void
}

export const GridV1Context = React.createContext<IGridV1ContextExpose>({
  topbarHeightPx: 0,
  viewHeightPx: 0,
  setTopBarHeight: () => {},
  setMargin: () => {}
})

export const useGridV1Context = (): IGridV1ContextExpose => {
  return React.useContext(GridV1Context)
}
