import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useApiUrl } from 'utils/apiUrl'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { getFeatureFlags } from '@cbgms/api/public/feature-flags/requests'
const useGmsFeatures = () => {
  const gmsApiurl = useApiUrl('gms')
  const dispatch = useDispatch()

  const enabledFeaturesQuery = useQuery(
    ['enabled-features', gmsApiurl],
    async () => {
      const result = await dispatch(getFeatureFlags())

      // .then(res => res.json())
      const schema = yup.object({
        Data: yup.array(yup.string().defined())
      })
      return (await schema.validate(result)).Data
    },
    {
      refetchOnWindowFocus: false
    }
  )

  return React.useMemo(() => enabledFeaturesQuery.data ?? [], [enabledFeaturesQuery.data])
}

export default useGmsFeatures
