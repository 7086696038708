import i18next from 'i18next'
import { CountryCode } from '@carsys/enums/country-code'
import { Locales } from '@carsys/enums/locales'
import { Environment } from '@carsys/enums/environment'

export const defaultLanguage = Locales.en_GB
export const supportedLanguages = [Locales.en_GB, Locales.nl_NL, Locales.fr_FR, Locales.de_DE, Locales.it_IT]

export const supportedLanguagesPerEnvironment: Record<Environment, Locales[]> = {
  [Environment.Develop]: supportedLanguages,
  [Environment.Localhost]: supportedLanguages,
  [Environment.RC]: supportedLanguages,
  [Environment.Staging]: supportedLanguages,
  [Environment.Production]: supportedLanguages.filter(x => x !== Locales.de_DE && x !== Locales.it_IT)
}
// enumToLanguageOptions returns translated language dropdown options for given locales,
// NOTE: Make sure languages are loaded! ie: i18next.loadLanguages(...)
export function enumToLanguageOptions(locales: Locales[]) {
  return locales.map(locale => {
    return {
      key: locale,
      text: i18next.getFixedT(`${locale}`)(`Enum.EnumLocale`, { context: locale }),
      value: locale,
      flag: locale.split('-')[1].toLowerCase() // Taking the country for flag
    }
  })
}

// localeOptionsByCountry returns the languages we support by the given country.
export const localeOptionsByCountry = (country: CountryCode): Locales[] => {
  switch (country) {
    case CountryCode.NL:
      return [Locales.nl_NL]
    case CountryCode.BE:
      return [Locales.nl_BE, Locales.fr_BE]
    case CountryCode.GB:
      return [Locales.en_GB]
    case CountryCode.FR:
      return [Locales.fr_FR]
    case CountryCode.IT:
      return [Locales.it_IT]
    case CountryCode.DE:
      return [Locales.de_DE]
    case CountryCode.CH:
      return [Locales.de_CH, Locales.fr_CH, Locales.it_CH, Locales.rm_CH]
    case CountryCode.LU:
      return [Locales.de_LU, Locales.fr_LU, Locales.lb_LU]
    case CountryCode.CZ:
      return [Locales.cs_CZ]
    case CountryCode.PL:
      return [Locales.pl_PL]
    default:
      return []
  }
}
