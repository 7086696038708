// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type IPaginatedRequest = {
  fields?: string
  orderBy?: string
  page?: number
  perPage?: number
  search?: string
  format?: string
}

export enum Format {
  csv,
  xlsx
}

export interface IProductivityReportRequest {
  employeeId?: string
  startDate?: Date
  endDate?: Date
  mechanic?: string
  year?: number
  reportType?: string
}
