import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import { getMaintenancePeriods } from 'app/vehicles/state/selectors'
import * as types from 'app/vehicles/state/types'

export interface IPeriods {
  ID: string
  Combinable: boolean
  Name: string
  SortOrder: number
  DurationInHours?: number
}

export interface IMaintenancePeriods {
  ID: string
  MaintenancePeriods: IPeriods[]
  Name: string
  SortOrder: number
}

export type MaintenancePeriodsReducerState = ReadonlyArray<IMaintenancePeriods>

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type MaintenancePeriodsReducerBehavior<S = MaintenancePeriodsReducerState> = Record<
  typeof types.GET_MAINTENANCE_SYSTEMS_SUCCESS,
  Reducer<S, any>
> &
  Record<typeof types.RESET_MAINTENANCE_SYSTEMS, Reducer<S, any>> &
  Record<typeof types.GET_MAINTENANCE_SYSTEMS_FAILED, Reducer<S, any>>

const maintenancePeriods = createReducer<MaintenancePeriodsReducerState, MaintenancePeriodsReducerBehavior>([], {
  [types.GET_MAINTENANCE_SYSTEMS_SUCCESS]: (_, { payload }) => {
    return getMaintenancePeriods(payload)
  },
  [types.GET_MAINTENANCE_SYSTEMS_FAILED]: () => {
    return []
  },
  [types.RESET_MAINTENANCE_SYSTEMS]: () => {
    return []
  }
})

export default maintenancePeriods
