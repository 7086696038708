import {
  ICreateLicenseForCompanyBody,
  IDistributor,
  IExistingHaynesCustomer,
  ILicenseSearchInput,
  INewHaynesCustomer,
  INewHaynesLicense,
  IUpdateLicenseForCompanyBody
} from '.'
import { IPaginatedRequest } from '@cbgms/api/request'

export const getDistributors = (params: IPaginatedRequest) => ({
  method: 'GET',
  url: `/modules/third-party-licenses/distributors`,
  params
})

export const getDistributorByUUID = (uuid: string) => ({
  method: 'GET',
  url: `/modules/third-party-licenses/distributors/${uuid}`
})

export const createDistributor = (data: Partial<IDistributor>) => ({
  method: 'POST',
  url: `/modules/third-party-licenses/distributors`,
  data
})

export const updateDistributor = (uuid: string, data: Partial<IDistributor>) => ({
  method: 'PUT',
  url: `/modules/third-party-licenses/distributors/${uuid}`,
  data
})

export const getLicenses = (params: IPaginatedRequest) => ({
  method: 'GET',
  url: `/modules/third-party-licenses/licenses`,
  params
})

export const getLicenseByUUID = (uuid: string) => ({
  method: 'GET',
  url: `/modules/third-party-licenses/licenses/${uuid}`
})

export const getLicense = (licenseUUID: string) => ({
  method: 'GET',
  url: `/modules/third-party-licenses/licenses/${licenseUUID}`
})

export const createLicenseForCompany = (data: ICreateLicenseForCompanyBody) => ({
  method: 'POST',
  url: `/modules/third-party-licenses/licenses`,
  data
})

export const updateLicenseForCompany = (data: IUpdateLicenseForCompanyBody) => ({
  method: 'PUT',
  url: `/modules/third-party-licenses/licenses/${data.LicenseUUID}`,
  data
})

export const deleteLicenseForCompany = (licenseUUID: string, dryRun: boolean) => ({
  method: 'DELETE',
  url: `/modules/third-party-licenses/licenses/${licenseUUID}`,
  params: {
    DryRun: dryRun
  }
})

export const getHaynesCustomerByClientIDRequest = (distributorID: string, clientID: string) => ({
  method: 'GET',
  url: `/modules/third-party-licenses/haynes/distributor/${distributorID}/customers/search`,
  params: {
    clientID
  }
})

export const getHaynesParamsRequest = (distributorID: string) => ({
  method: 'GET',
  url: `/modules/third-party-licenses/haynes/distributor/${distributorID}/params`
})

export const postNewHaynesCustomerRequest = (data: INewHaynesCustomer) => ({
  method: 'POST',
  url: `/modules/third-party-licenses/haynes/distributor/${data.DistributorID}/customers`,
  data
})

export const putExistingHaynesCustomerRequest = (data: IExistingHaynesCustomer) => ({
  method: 'PUT',
  url: `/modules/third-party-licenses/haynes/distributor/${data.DistributorID}/customers/${data.CustomerID}`,
  data
})

export const postNewHaynesLicenseRequest = (data: INewHaynesLicense) => ({
  method: 'POST',
  url: `/modules/third-party-licenses/haynes/distributor/${data.DistributorID}/licenses`,
  data
})

export const getHaynesLicensesByCustomerRequest = (distributorID: string, data: ILicenseSearchInput) => ({
  method: 'POST',
  url: `/modules/third-party-licenses/haynes/distributor/${distributorID}/licenses/search`,
  data
})

export const getExternalSystemsForCompanyRequest = (companyUUID: string, externalSystems: string[]) => ({
  method: 'GET',
  url: `/modules/external-systems-registrations`,
  params: {
    CompanyUUIDFilter: companyUUID,
    ExternalSystems: externalSystems.join(',')
  }
})

export const putTriggerHaynesProLinkerRequest = (distributorUUID: string) => ({
  method: 'PUT',
  url: `/modules/third-party-licenses/haynes/distributor/${distributorUUID}/import`
})

export const postSearchHaynesVoltLicenseRequest = (distributorID: string, data: ILicenseSearchInput) => ({
  method: 'POST',
  url: `/modules/third-party-licenses/haynes/distributor/${distributorID}/licenses/search`,
  data: { ...data, ProductTypeID: data.ProductTypeID ? Number(data.ProductTypeID) : undefined }
})
