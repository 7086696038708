export const LOAD_ARCHIVED_ORDER = 'LOAD_ARCHIVED_ORDER'
export const LOAD_ARCHIVED_ORDER_SUCCESS = 'LOAD_ARCHIVED_ORDER_SUCCESS'
export const LOAD_ARCHIVED_ORDER_FAILED = 'LOAD_ARCHIVED_ORDER_FAILED'

export const LOAD_ARCHIVED_ORDER_LINES = 'LOAD_ARCHIVED_ORDER_LINES'
export const LOAD_ARCHIVED_ORDER_LINES_SUCCESS = 'LOAD_ARCHIVED_ORDER_LINES_SUCCESS'
export const LOAD_ARCHIVED_ORDER_LINES_FAILED = 'LOAD_ARCHIVED_ORDER_LINES_FAILED'

export const LOAD_ARCHIVE_ORDERS = 'LOAD_ARCHIVE_ORDERS'
export const LOAD_ARCHIVE_ORDERS_SUCCESS = 'LOAD_ARCHIVE_ORDERS_SUCCESS'
export const LOAD_ARCHIVE_ORDERS_FAILED = 'LOAD_ARCHIVE_ORDERS_FAILED'

export const CLEAR_ARCHIVED_ORDER = 'CLEAR_ARCHIVED_ORDER'

export const GET_ARCHIVED_ORDER_LINES_FOR_RMA = 'GET_ARCHIVED_ORDER_LINES_FOR_RMA'
export const GET_ARCHIVED_ORDER_LINES_FOR_RMA_SUCCESS = 'GET_ARCHIVED_ORDER_LINES_FOR_RMA_SUCCESS'
export const GET_ARCHIVED_ORDER_LINES_FOR_RMA_FAILED = 'GET_ARCHIVED_ORDER_LINES_FOR_RMA_FAILED'

export const GET_ARCHIVED_ORDER_LINES_FOR_RMA_LIST = 'GET_ARCHIVED_ORDER_LINES_FOR_RMA_LIST'
export const GET_ARCHIVED_ORDER_LINES_FOR_RMA_LIST_SUCCESS = 'GET_ARCHIVED_ORDER_LINES_FOR_RMA_LIST_SUCCESS'
export const GET_ARCHIVED_ORDER_LINES_FOR_RMA_LIST_FAILED = 'GET_ARCHIVED_ORDER_LINES_FOR_RMA_LIST_FAILED'
