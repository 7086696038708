import { useCallback } from 'react'

import { useAccordionDispatch } from './AccordionContext'

export function useExpandAccordion(accordion: string) {
  const dispatch = useAccordionDispatch()

  return useCallback(() => {
    dispatch({ type: 'expand', payload: accordion })
  }, [accordion])
}
