import { Box, Theme, Typography } from '@mui/material'
import Upload from '@mui/icons-material/Upload'
import { useTranslation } from 'react-i18next'

export function DropZoneIndicator() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: (theme: Theme) => `${theme.shape.borderRadius}px`,
        display: 'flex',
        flexDirection: 'column',
        fontSize: (theme: Theme) => theme.typography.pxToRem(76),
        height: '100%',
        justifyContent: 'center',
        left: 0,
        opacity: 0.5,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: (theme: Theme) => theme.zIndex.modal
      }}
    >
      <Upload fontSize='inherit' />
      <Typography>{t('FileUploader.DropZone')}</Typography>
    </Box>
  )
}
