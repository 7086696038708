import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { ButtonGroup, Menu, MenuItem, MenuItemProps } from '@mui/material'

import { SplitButtonContext } from './SplitButtonContext'
export interface Option {
  id: string
  MenuItem: MenuItemProps<'li', { button?: true }>
  Button: ButtonProps
}

export function SplitButton({ children, ...props }: React.ComponentProps<typeof ButtonGroup>) {
  const [open, setOpen] = React.useState(false)
  const [options, setOption] = React.useState<Option[]>([])
  const anchorEl = React.useRef<HTMLDivElement>(null)
  const [currentId, setCurrentId] = React.useState(0)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelect = (index: number) => {
    setCurrentId(index)
    setOpen(false)
  }

  const handleRegisterOption = React.useCallback(
    (option: Option) => {
      setOption(prevState => {
        return [...prevState, option]
      })
    },
    [setOption]
  )

  const handleUnregisterOption = React.useCallback(
    (id: string) => {
      setOption(prevState => {
        return prevState.filter(option => option.id !== id)
      })
    },
    [setOption]
  )

  const value = React.useMemo(() => {
    return {
      registerOption: handleRegisterOption,
      unregisterOption: handleUnregisterOption
    }
  }, [handleRegisterOption, handleUnregisterOption])

  const selectedOption = options[currentId]
  return (
    <>
      <SplitButtonContext.Provider value={value}>{children}</SplitButtonContext.Provider>
      <ButtonGroup {...props} ref={anchorEl}>
        <Button {...selectedOption?.Button} />
        <Button onClick={handleOpen}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {options.map((option, index) => (
          <MenuItem {...option.MenuItem} onClick={() => handleSelect(index)} key={index} selected={currentId === index} />
        ))}
      </Menu>
    </>
  )
}

SplitButton.defaultProps = {
  color: 'primary',
  variant: 'contained'
}
