import { APIResponse } from '../../helpers'
import { HttpMethod } from '../../http-method'
import { NotificationSchedule } from './notificationSchedule'

export type GETNotificationScheduleResponse = APIResponse<NotificationSchedule>

export const getNotificationScheduleRequest = (uuid: string) => ({
  method: HttpMethod.GET,
  url: `modules/notification-schedules/${uuid}`
})
