import { useCallback } from 'react'
import { sendNavigationEvent } from '../../utils/TagManager/tag-manager'
import { ILogContextData } from './useLogContextData'

export interface NavigationEventParams {
  pathname: string
  search: string
  hash: string
}
export type NavigationEvent = (data: NavigationEventParams) => void

/**
 * useNavigationLoggerFunc will create the navigation logging function,
 * Calling Google analytics / tag manager and other logging platforms
 */
export const useNavigationLoggerFunc = (ctx: ILogContextData): NavigationEvent => {
  const googleAnalyticsLogger = useCallback(
    (p: NavigationEventParams) => {
      const ga = window.ga
      if (ga) {
        ga('set', 'page', p.pathname)
        ga('send', 'pageview')
      }
    },
    [window.ga]
  )

  const googleTagManagerLogger = useCallback((p: NavigationEventParams) => sendNavigationEvent(ctx, p), [sendNavigationEvent, ctx])

  return useCallback(
    (data: NavigationEventParams) => {
      try {
        googleAnalyticsLogger(data)
        googleTagManagerLogger(data)
      } catch (e) {
        console.error('unable to log navigation event', e)
      }
    },
    [googleAnalyticsLogger, googleTagManagerLogger]
  )
}
